export const de = {
    "de": {
        "common": {
            "tabs": {
                "calculations": "Kalkulationen",
                "applications": "Projekte",
                "offers": "Angebote",
                "contracts": "Verträge",
                "contact_persons": "Kontaktpersonen",
                "credit_scoring": "Bonität",
                "self_declaration": "Selbstauskunft",
                "documents": "Doxis"
            },
            "data": {
                "name": "Name",
                "first_name": "Vorname",
                "last_name": "Nachname",
                "title": "Titel",
                "name_company_name": "Firmierung / Name",
                "email": "E-Mail",
                "email_address": "E-Mail-Addresse",
                "telephone": "Telefon",
                "telephone_number": "Telefonnummer",
                "address": "Anschrift",
                "address_2": "Addresse",
                "addon": "Zusatz",
                "street": "Strasse",
                "house_number": "Hausnummer",
                "street_and_house_number": "Strasse und Hausnummer",
                "zip": "PLZ",
                "city": "Stadt",
                "country": "Land",
                "fax": "Fax",
                "fax_number": "Faxnummer",
                "mobile": "Mobil",
                "mobile_number": "Mobilnummer",
                "mobile_number_2": "Handynummer",
                "id_number": "Personennummer",
                "tax_number": "Steuernummer",
                "tax_id": "SteuerID",
                "description": "Bezeichnung",
                "manufacturer": "Hersteller",
                "machine_number": "Maschinen Nr.",
                "vehicle_type": "Fahrzeugtyp",
                "type": "Typ",
                "year_of_construction": "Baujahr / Erstzulassung",
                "serial_number": "Fabriknummer",
                "location": "Standort",
                "delivery_date": "Liefertermin",
                "contact_person": "Ansprechpartner",
                "purchase_price": "Kaufpreis",
                "trade_in": "Inzahlungnahme",
                "cash_deposit": "Baranzahlung",
                "net_financing_amount": "Finanzierungsbestrag netto",
                "net_block_rate": "Blockrate netto",
                "contract_type": "Vertragsart",
                "loan_duration": "Laufzeit",
                "interest_rate": "Zinsrate",
                "payment_method": "Zahlungsweise",
                "employed_since": "Beschäftigt seit",
                "employer": "Arbeitgeber",
                "net_income": "Nettoeinkommen",
                "founded_on": "Datum der Betriebsgründung",
                "first_founded_on": "Datum der Erstgründung",
                "employee": "Mitarbeiter",
                "equity": "Eigenkapital",
                "turnover": "Umsatz",
                "result_profit": "Ergebnis / Gewinn",
                "total_assets": "Bilanzsumme",
                "date_of_birth": "Geburtsdatum",
                "place_of_birth": "Geburtsort",
                "gender": "Geschlecht",
                "nationality": "Nationalität",
                "years_of_experience": "Berufserfahrung (Jahre)",
                "others": "Sonstiges",
                "contact_details": "Kontakdaten",

                "owned_area": "Eigentumesfläche",
                "leased_area": "Pachtfläche",
                "crop_area": "Ackerfläche",
                "grain_area": "Getreide",
                "corn_area": "Mais",
                "special_area": "Sonderkulturen",
                "number_milk_cows": "Milchkühe",
                "number_bulls": "Mastrinder-/bullen",
                "number_breeding_pigs": "Zuchtschweine",
                "number_eating_pigs": "Mastschweine",
                "number_other_animals": "Sonstige Tierarten",
                "number_tractors": "Schlepper",
                "number_harvesters": "Vollernter",
                "number_food_harvesters": "Futtererntetechnik",
                "number_other_driving_vehicles": "sonstige Selbstfahrer",
                "number_combines": "Mähdrescher / Hechsler",
                "number_manure_transporters": "Gülle / Transport",
                "number_tillers": "Bodenbearbeitung",

                "market_value": "Verkehrswert",
                "thereof_remaining_debt": "davon aktuelle Restschuld",
                "other_assets": "Sonstiges Vermögen",

                "account_owner": "Kontoinhaber",
                "credit_institution": "Kreditinstitut",
                "bank_contact_person": "Ansprechpartner bei der Bank",

                "role": "Rolle",
                "role_s": "Rolle(n)",
                "salutation": "Anrede",
                "function": "Funktion",

                "person_types": "Personentypen",

                "created_at": "Datum Hochgeladen",
                "created_at_2": "Erstellungsdatum",
                "created_at_3": "Erstellt",
                "document_name": "Dokumentname",

                "finished_at": "Beendet am",
            },
            "user_types": {
                "kunde": "Kunde",
                "person": "Person",
                "lieferant": "Lieferant",
                "handler": "Händler",
                "handler-lieferant": "Händler/Lieferant",
                "vermittler": "Vermittler",
                "hersteller": "Hersteller",
            },
            "errors": {
                "csv_format_error": "Liste entspricht nicht dem Beispielformat. Bitte das Dokument löschen und erneut versuchen.",
                "account_disabled": "Account deaktiviert",
                "could_not_login": "Konnte nicht einloggen",
            },
            "placeholders": {
                "address_line_1_2": `Anschrift Zeile 1\nAnschrift Zeile 2`,
            },
            "months": "Monate",
            "month_keys": {
                "01": "Januar",
                "02": "Februar",
                "03": "März",
                "04": "April",
                "05": "Mai",
                "06": "Juni",
                "07": "Juli",
                "08": "August",
                "09": "September",
                "10": "Oktober",
                "11": "November",
                "12": "Dezember",
            },
            "document_category_groups": {
                "general_correspondence": "Allg. Korrespondenz",
                "information": "Auskünfte",
                "credit_records": "Bonitätsunterlagen",
                "permits": "Genehmigungen",
                "notes_for_person": "Notizen zur Person",
                "collateral": "Sicherheiten",
            },
            "document_categories": {
                "general_correspondence_person": "Allg. Schriftverkehr / Person",
                "hek_debit_note": "HEK-Belastungsanzeige",
                "proof_of_identity": "Identitätsnachweis",
                "balance_confirmation": "Saldenbestätigung",
                "service_account_documents": "Unterlagen Servicekonto",
                "bank_info_power_of_attorney": "Bankauskünfte Vollmachten",
                "sa": "SA",
                "schufa": "Schufa",
                "vc": "VC",
                "balance_financial_statement": "Bilanz Jahresabschluß",
                "balance_sheet_evaluations": "Bilanzauswertungen",
                "bwa": "BWA",
                "income_records": "Einkommensunterlagen",
                "fuser_rating": "Füser / Rating",
                "commercial_hr": "Gewerbe / HR",
                "commitment": "Obligo",
                "identity_card": "Personalausweis",
                "other_credit_documents": "Sonstige Bonitätsunterlagen",
                "calculation_offer": "Kalkulationsbogen",
                "asset_records": "Vermögensunterlagen",
                "approval_rejected_oe": "Genehmigung Abgelehnt / O.E.",
                "approval_of_engagement": "Genehmigung des Engagements",
                "votes": "Voten",
                "an_of_person": "AN zur Person",
                "application_scan": "Antragsscan",
                "visit_notes": "Besuchsnotizen",
                "reminders_p": "Mahnungen / P",
                "connection_security_transfer": "Anschlußsicherungsübereignung",
                "bank_guarantees": "Bankbürgschaften",
                "guarantees": "Bürgschaften",
                "land_charge": "Grundschuld",
                "deposit": "Kaution",
                "other_additional_securities": "sonstige zus. Sicherheiten",

                "property_info_or_aaw_oa_1401": "Objektinfo und Vorschlag LDG Kurve bzw. Individualbewertung AM gem. AAW OA 14.01",
                "visit_report": "Besuchsbericht",
                "property_valuation_by_akf": "Objektbewertung von akf Asset Management",
                "creditreform_info": "Auskunft Creditreform",
                "main_lenders_bank_info": "Bankauskünfte der wesentlichen Kreditgeber und Hausbanken des Kunden",
                "self_declaration_with_schufa_approval": "Selbstauskunft mit Schufa-Freigabe",
                "business_evaluation_of_customer": "betriebswirtschaftliche Auswertung des Kunden",
                "last_annual_financial_statements_of_customer": "letzter Jahresabschluss des Kunden",
                "balance_sheet_and_last_est_notice_of_customer": "Vermögensübersicht und letzter Est-Bescheid des Kunden (nicht bei GmbH)",
                "business_evaluation_of_holding_company": "betriebswirtschaftliche Auswertung der Besitzgesellschaft",
                "last_annual_financial_statements_of_holding_company": "letzter Jahresabschluss der Besitzgesellschaft",
                "organization_chart_of_group": "Organigramm des Konzerns",
                "last_consolidated_financial_statement_of_group": "letzter Konzernabschluss",
                "assets_liabilities_income_statement_of_last_consolidated_financial_statements": "mindestens Aktiva, Passiva und GuV des letzten Konzernabschlusses",
                "business_evaluation_of_group": "ggf. betriebswirtschaftliche Auswertung des Konzerns",
                "annual_financial_statements_of_major_companies_of_group": "ggf. Jahresabschlüsse wesentlicher Unternehmen des Konzerns",
                "annual_financial_statements_of_parent_company": "Jahresabschluss der Muttergesellschaft",
                "business_evaluation_of_parent_company": "betriebswirtschaftliche Auswertung der Muttergesellschaft",
                "last_financial_statements_of_guarantor": "letzter Jahresabschluss der Bürgin",
                "income_tax_assessment_and_declaration_of_guarantor": "Einkommensteuerbescheid und -erklärung des Bürgen",
                "business_evaluation_of_guarantor": "aktuelle betriebswirtschaftliche Auswertung des Bürgen",
                "balance_sheet_of_guarantor": "aktuelle Vermögensübersicht des Bürgen",
                "plan_calculation_of_current_and_next_financial_year": "Planrechnung für das laufende und folgende Geschäftsjahr",
                "liquidity_planning": "Liquiditätsplanung",
                "profitability_calculation_of_investment": "Wirtschaftlichkeitsberechnung für die Investition",

                "marktvotum": "Marktvotum",
                "purchase_offer": "Angebot",
                "order_confirmation": "Auftragsbestätigung",
                "invoice": "Rechnung",
                "proof_of_payment": "Bezahltnachweis",
                "other_documents": "Sonstige Dokumente",
            },
            "document_origins": {
                "cold": "Cold",
                "fax": "Fax",
                "internal_documents": "Interne Belege",
                "outbox": "Postausgang",
                "inbox": "Posteingang",
                "other": "Sonstiges",
                "undeliverable": "Unzustellbar",
            },
            "vehicle_types": {
                "new": "Neu",
                "second_hand": "Gebraucht",
                "demo": "Vorführ",
            },
            "payment_methods": {
                "monthly": "Monatlich",
                "quarterly": "Vierteljährlich",
                "half_yearly": "Halbjährlich",
                "yearly": "Jährlich",
            },
            "employment_types": {
                "full_employment": "Vollerwerb",
                "side_employment": "Nebenerwerb",
            },
            "contact_person_roles": {
                "managing_director": "Geschäftsführer",
                "beneficial_owner": "Wirtschaftlich Berechtigter",
                "shareholder": "Gesellschafter",
            },
            "contact_person_roles_short": {
                "managing_director": "GF",
                "beneficial_owner": "WB",
                "shareholder": "GS",
            },
            "bo_user_roles": {
                "admin": "Innendienst",
                "sales_rep": "Aussendienst",
            },
            "currency_sign": "€",
            "decimal_separator": ",",
            "thousand_separator": ".",
            "yes": "Ja",
            "no": "Nein",
            "gross": "Brutto",
            "net": "Netto",
            "hectare": "ha",
            "piece": "Stück",
            "generated_self_declarations": "Digitale Selbstauskünfte",
            "uploaded_self_declarations": "Hochgeladene Selbstauskünfte",
            "company_name": "Firmenname",
            "public_self_declaration_header_1": "Bitte füllen Sie die Selbstauskunft für Ihren Kreditantrag aus. Sie können jederzeit Ihre Eingaben speichern und zu einem späteren Zeitpunkt weiter ausfüllen.",
            "public_self_declaration_header_2": "Ihr Ansprechpartner ist:",
            "details_of_applicant_company": "Angaben zum Antragsteller / Unternehmen",
            "financing_object": "Finanzierungsobjekt",
            "vehicle_letter_available": "KFZ-Brief (ZB II) vorhanden",
            "dealer": "Händler",
            "condition_type": "Konditionenart",
            "dealer_data": "Händlerdaten",
            "manage_dealer_data": "Händlertableaus verwalten",
            "offer_data": "Angebotsdaten",
            "credit": "Kredit",
            "rental_purchase": "Mietkauf",
            "company_briefs": "Art des Betriebs / Unternehmenskurzdaten",
            "agriculture_in": "Landwirtschaft im",
            "company_may_deduct_input_tax": "Betrieb darf Vorsteuer abziehen",
            "contractor": "Lohnunternehmer",
            "with_sideline": "Bei Nebenwerweb",
            "profession": "Beruf",
            "applicant_personal_data": "Persönliche Daten zum Antragsteller",
            "sa_person_header": "Inhaber oder geschäftsführende Gesellschafter / bei GbR Angabe aller Mitglieder",
            "remove": "Entfernen",
            "add_person": "Person hinzufügen",
            "add_contact_person": "Kontaktperson hinzufügen",
            "update_contact_person": "Kontaktperson aktualisieren",
            "operational_information": "Betriebliche Angaben",
            "details_of_real_estate": "Angaben zu Immobilien",
            "building": "Gebäude",
            "area": "Flächen",
            "technical_information": "Angaben zur Technik",
            "data_protection_notices": "Auskunfteien / Datenschutzhinweise",
            "sd_public_data_protection_notice": `Die Datenerhebung von personenbezogenen Daten (Name, Adresse,
Geburtsdatum) im Rahmen dieses Vertragsverhältnisses dient der
Anbahnung eines Kredit-/Darlehen- /Bürgschaftsvertrages zwischen
dem Kreditnehmer (KN) /Mithaftenden (MH) /Bürgen (B) und der akf
bank GmbH & Co KG (Bank), den der KN/MH/B abschließen möchte.
Die Bank, Am Diek 50, 42277 Wuppertal, übermittelt Ihre aus diesem
Vertragsverhältnis heraus erhobenen personenbezogenen Daten zum
Zwecke der Bonitätsprüfung an die Infoscore Consumer Data GmbH,
Rheinstr. 99, 76532 Baden-Baden. 

Detaillierte Informationen zur
Infoscore Consumer Data GmbH i. S. d. Datenschutz-Grundverordnung
(DSGVO), d.h. Informationen zum Geschäftszweck, zu Zwecken
der Datenspeicherung, zu den Datenempfängern, zum Selbstauskunftsrecht,
zum Anspruch auf Löschung oder Berichtigung etc.
finden Sie online unter https://finance.arvato.com/icdinfoblatt.


Zudem werden im Rahmen dieses Vertragsverhältnisses erhobene
personenbezogene Daten über die Beantragung, die Durchführung
und Beendigung dieser Geschäftsbeziehung sowie Daten über nicht
vertrags-gemäßes Verhalten oder betrügerisches Verhalten an die
SCHUFA Holding AG, Kormoranweg 5, 65201 Wiesbaden übermittelt.
Der Datenaustausch mit der SCHUFA dient zudem der Erfüllung
gesetzlicher Pflichten zur Durchführung von Kreditwürdigkeitsprüfungen
(§ 505a des Bürgerlichen Gesetzbuches, § 18a des Kreditwesengesetzes)
sowie zum Zwecke der Profilbildung (sog. Scoring),
um ihren Vertragspartnern im Europäischen Wirtschaftsraum und in
der Schweiz sowie ggf. weiteren Drittländern (sofern zu diesen ein
Angemessenheitsbeschluss der Europäischen Kommission besteht)
Informationen unter anderem zur Beurteilung der Kreditwürdigkeit
von natürlichen Personen zu geben. 

Detaillierte Informationen zur Tätigkeit der SCHUFA können dem SCHUFA-Informationsblatt
nach Art. 14 DSGVO entnommen oder online unter
www.schufa.de/datenschutz eingesehen werden. Unabhängig davon
wird die Bank der Schufa sowie der Infoscore auch Daten aufgrund
nicht vertragsgemäßen Verhaltens (z. B. Forderungsbetrag nach
Kündigung) übermitteln. Der KN befreit die Bank insoweit auch vom
Bankgeheimnis in diesem Zusammenhang. Rechtsgrundlage der
genannten Übermittlungen von personenbezogenen Daten sind
Artikel 6 Absatz 1 Buchstabe b und Artikel 6 Absatz 1 Buchstabe f
der Datenschutz-Grundverordnung (DSGVO). 

Übermittlungen auf
der Grundlage von Artikel 6 Absatz 1 Buchstabe f DSGVO dürfen nur
erfolgen, soweit dies zur Wahrung berechtigter Interessen der Bank
oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte
und Grundfreiheiten der betroffenen Person, die den Schutz
personenbezogener
Daten erfordern, überwiegen.

Die aktuellen Datenschutzhinweise sind auch online unter
www.akf.de/datenschutz abrufbar.

Mit seiner Unterschrift bestätigt der KN/MH/B die Kenntnisnahme
der Datenschutzhinweise.`,
            "sd_check_1": "Der Antragsteller hat die Datenschutzhinweise zur Kenntnis genommen.",
            "sd_check_2": `Der Antragsteller ermächtigt die akf bank GmbH & Co KG, Am Diek
50, 42277 Wuppertal, widerruflich, bei dem unten genannten Kreditinstitut
eine Bankauskunft einzuholen und beantragt diesbezüglich
zugleich, eventuell anfallende Kosten für die Auskunftserteilung
direkt dem unten genannten Konto zu belasten.

Der Antragsteller ist damit einverstanden, dass die akf bank GmbH
& Co KG, Am Diek 50, 42277 Wuppertal, eine Kopie dieses Formulars
(inkl. aller gemachten Angaben) dem Kreditinstitut zur Verfügung
stellt.`,
            "obtaining_bank_report": "Einholung einer Bankauskunft",
            "signatory_name": "Name des Unterzeichners",
            "submit": "Abschicken",
            "reset": "Zurückstellen",
            "personal_data": "Personendaten",
            "address_data": "Adressdaten",
            "settings": "Einstellungen",
            "saved_calculations": "Gespeicherte Kalkulationen",
            "object": "Objekt",
            "new_object": "Neu Objekt",
            "objects_created": "Erstellte Objekte",
            "object_information": "Objektinformationen",
            "object_description": "Objektbezeichnung",
            "investment_object": "Investitionsobjekt",
            "calculator": "Rechner",
            "example_calculator": "Beispielrechner",
            "recalculate_with_desired_monthly_rate": "Mit der gewünschten monatlichen Rate neu berechnen",
            "reset_password": "Passwort zurücksetzen",
            "current_password": "Derzeitiges Passwort",
            "new_password": "Neues Passwort",
            "repeat_new_password": "Neues Passwort wiederholen",
            "forgot_password_info": "Falls Sie ihr derzeitiges Passwort vergessen haben, kontaktieren Sie bitte einen Administrator.",
            "manage_employees": "Mitarbeiter Verwalten",
            "add_employee": "Mitarbeiter Hinzufügen",
            "employee_data": "Mitarbeiterdaten",
            "show_inactive_users": "Inaktive Nutzer zeigen",
            "manage_lists": "Listen Verwalten",
            "manage_lists_info": "Listen können nur im .csv Format hochgeladen werden. Ein Beispielformat kann neben der Liste runtergeladen werden.",
            "active_list": "Aktive Liste",
            "company_types": "Rechtsformen",
            "contact_person_functions": "Funktionen für Kontaktpersonen",
            "countries": "Länder",
            "credit_institutions": "BLZ und Bankenliste",
            "industry_types": "Branchenarten",
            "credit_analysts": "Bonitätsmitarbeiter",
            "object_types": "Objekttypen",
            "reward_shop_recipient": "Prämienshopempfänger",
            "vendor": "Vendors",
            "choose_file": "Datei Auswählen",
            "import_list": "Liste einspielen",
            "upload_new_conditions": "Neue Konditionen hochladen",
            "upload_dealer_conditions_part_1": "Konditionen können nur als .csv Datei hochgeladen werden. Eine Beispiel Datei kann",
            "upload_dealer_conditions_part_2": "hier runtergeladen",
            "upload_dealer_conditions_part_3": "werden",
            "application_number": "Antragsnummer",
            "offer_number": "Angebotsnummer",
            "calculation": "Kalkulation",
            "calculation_number_short": "Kalkulation No.",
            "amount": "Betrag",
            "contracts": "Verträge",
            "other": "Andere",
            "personal_information": "Personen Informationen",
            "new_application": "Neues Projekt",
            "edit_data": "Daten anpassen",
            "": "",
        }
    }
}
