import * as React from "react";
import styles from "components/common/presenters/list-contacts/ListContacts.module.scss";
import {List, Card} from "antd";
import {UserOutlined, FileOutlined, CheckCircleFilled} from "@ant-design/icons";
import classnames from "classnames";


export interface ListContactsProps {
    contacts: {contactId: number; contactName: string; selected: boolean, tick?:boolean }[];
    onClick?: (e) => void;
}

/**
 * Used for lists with calculations, offers, documents.
 * @param props 
 */
export const ListContacts = (props: ListContactsProps) => {


    return (
        <List
            grid={{gutter: 16, column: 3}}
            dataSource={props.contacts}
            renderItem={o => (
                <List.Item onClick={props.onClick ? () => props.onClick(o) : () => { }}>
                    <div className={classnames(styles.item, {[styles.selected]: o.selected})}>
                        <div className={styles.icon}><UserOutlined /></div>
                        <div className={styles.textElement}>{o.contactName}</div>
                        <div className={
                            classnames(styles.icon, {
                                [styles.iconGreen]: o.selected
                            })
                        }>{o.tick !== undefined ? o.tick ? <CheckCircleFilled /> : null
                        : o.selected ? <CheckCircleFilled /> : null}</div>
                    </div>
                </List.Item>
            )}
        />
    );
};
