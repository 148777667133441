import * as React from "react";
import {DealershipCondition} from "../../../../services/api/types";
import {Table} from "antd";

export interface DealershipConditionTablesHolderProps {
	dealershipConditions: DealershipCondition[]
}

export const DealershipConditionTablesHolder = (props: DealershipConditionTablesHolderProps) => {
	const conditionTypes: string[] = Array.from(new Set<string>(
		props.dealershipConditions.map(dc => dc.conditionType)
	)).sort()

	const columns = [
		{
			title: "Laufzeit",
			dataIndex: "loanDuration",
		},
		{
			title: "Raten",
			dataIndex: "rate",
		},
		{
			title: "Faktor",
			dataIndex: "factor",
		},
		{
			title: "Zinssatz",
			dataIndex: "interestRate",
		},
		{
			title: "Provision",
			dataIndex: "commission",
		},
		{
			title: "Interner Zinssatz",
			dataIndex: "internalInterestRate",
		},
		{
			title: "Disagio",
			dataIndex: "discount",
		},
		{
			title: "Refi Zins",
			dataIndex: "refinancingInterest",
		},
		{
			title: "Zinsmarge",
			dataIndex: "interestMargin",
		},
		{
			title: "Beispielrate",
			dataIndex: "sampleRate",
		},
	]

	return (
		<>
			{
				conditionTypes.map((conditionType, i) => {
						const dataSource: DealershipCondition[] = props.dealershipConditions.filter(dc => dc.conditionType === conditionType)

						return (
							<div key={i}>
								<h2>{conditionType}</h2>
								<Table rowKey={record => record.dealershipConditionId}
									   columns={columns}
									   dataSource={dataSource}
									   pagination={{position: ["bottomCenter"], showSizeChanger: false, pageSize: 10, total: dataSource.length}}
								/>
								<br/>
								<br/>
							</div>
						)
					}
				)
			}
		</>
	)
}
