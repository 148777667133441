import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SettingsPage.module.scss";
import { Col, Form, Input, Modal, Radio, Row, Select } from "antd";
import { BoUserProfile, BoUserRole } from "../../../../services/api/types";
import { AnchoredSelect } from "../../presenters/anchored-select/AnchoredSelect";
import {areFieldsInvalid, isEmailInvalid} from "components/common/presenters/user-modal/fieldsValidation";

export interface EmployeeModalProps {
    isOpen: boolean;
    employeeData: BoUserProfile;
    onOk: (data: BoUserProfile) => void;
    onCancel: () => void;
}

export const EmployeeModal = (props: EmployeeModalProps) => {
    const { t } = useTranslation();

    const [data, setData] = useState(null as BoUserProfile);
    const defaultState = {
        emailError: false,
        emailFormatError: false,
        nameError: false,
        salutationError: false,
        phone_numberError: false,
        locationError: false
    };
    const [ui, setUi] = useState({...defaultState});

    useEffect(() => {
        if (!props.employeeData) {
            setData({
                bo_user_id: null,
                email: "",
                name: "",
                salutation: "Herr",
                phone_number: "",
                location: "Berlin",
                is_available: true,
                role: BoUserRole.SALES_REPRESENTATIVE,
            });
        } else {
            setData(props.employeeData);
        }
    }, [props]);

    const onOkClicked = (data) => {
        const validationData = {...areFieldsInvalid(data,
            ["salutation", "email", "name", "phone_number", "location"]), emailFormatError: isEmailInvalid(data.email)};
        const invalid = Object.values(validationData).some(e => e === true);
        if(invalid) {
            setUi({...ui, ...validationData});
            return;
        }
        setUi({...defaultState})
        props.onOk(data)
    }

    return (
        <Modal
            className={styles.modal}
            visible={props.isOpen}
            title={
                props.employeeData?.bo_user_id
                    ? t("manage_employees")
                    : t("add_employee")
            }
            onOk={() => onOkClicked(data)}
            onCancel={() => props.onCancel()}
        >
            <h3>{t("employee_data")}</h3>
            <Row gutter={24}>
                <Col span={12}>
                    <h4>{t("data:salutation")}</h4>
                    <Form.Item
                                        validateStatus={ui.salutationError ? "error" : ''}
                                        help={ui.salutationError ? "Bitte geben Sie diese Daten an." : ''}
                                    >
                    <AnchoredSelect
                        data-cy={"modal_input_salutation"}
                        value={data?.salutation}
                        onChange={(e) => {
                            setData({ ...data, salutation: e });
                        }}
                    >
                        <Select.Option key={0} value={"Herr"}>
                            {"Herr"}
                        </Select.Option>
                        <Select.Option key={1} value={"Frau"}>
                            {"Frau"}
                        </Select.Option>
                    </AnchoredSelect>
                    </Form.Item>
                    <br />
                </Col>
                <Col span={12}>
                    <h4>{t("data:name")}</h4>
                    <Form.Item
                                        validateStatus={ui.nameError ? "error" : ''}
                                        help={ui.nameError ? "Bitte geben Sie diese Daten an." : ''}
                                    >
                    <Input
                        data-cy={"modal_input_name"}
                        value={data?.name}
                        onChange={(e) => {
                            setData({ ...data, name: e.target.value });
                        }}
                    />
                         </Form.Item>
                    <br />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <h4>{t("data:email")}</h4>
                    <Form.Item
                                        validateStatus={ui.emailError ? "error" : ''}
                                        help={ui.emailError ? "Bitte geben Sie diese Daten an." : 
                                    (ui.emailFormatError ? "Bitte geben Sie eine gültige E-Mail ein." : "")}
                                    >
                    <Input
                        data-cy={"modal_input_email"}
                        value={data?.email}
                        onChange={(e) => {
                            setData({ ...data, email: e.target.value });
                        }}
                    />
                         </Form.Item>
                    <br />
                </Col>
                <Col span={12}>
                    <h4>{t("data:telephone")}</h4>
                    <Form.Item
                                        validateStatus={ui.phone_numberError ? "error" : ''}
                                        help={ui.phone_numberError ? "Bitte geben Sie diese Daten an." : ''}
                                    >
                    <Input
                        data-cy={"modal_input_phone"}
                        value={data?.phone_number}
                        onChange={(e) => {
                            setData({ ...data, phone_number: e.target.value });
                        }}
                    />
                         </Form.Item>
                    <br />
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <h4>{t("data:location")}</h4>
                    <Form.Item
                                        validateStatus={ui.locationError ? "error" : ''}
                                        help={ui.locationError ? "Bitte geben Sie diese Daten an." : ""}
                                    >
                    <AnchoredSelect
                        data-cy={"modal_input_location"}
                        value={data?.location}
                        onChange={(e) => {
                            setData({ ...data, location: e });
                        }}
                    >
                        <Select.Option key={0} value={"Berlin"}>
                            {"Berlin"}
                        </Select.Option>
                        <Select.Option key={1} value={"Wuppertal"}>
                            {"Wuppertal"}
                        </Select.Option>
                        <Select.Option key={2} value={"Stuttgart"}>
                            {"Stuttgart"}
                        </Select.Option>
                    </AnchoredSelect>
                    </Form.Item>
                    <br />
                </Col>
                <Col span={12}>
                    <h4>Kontoart</h4>
                    <Radio.Group
                        onChange={(e) => {
                            setData({ ...data, role: e.target.value });
                        }}
                        value={data?.role}
                        style={{marginTop: '10px'}}
                    >
                        <Radio value={BoUserRole.SALES_REPRESENTATIVE}>
                            Normaler Nutzer
                        </Radio>
                        <Radio value={BoUserRole.ADMIN}>Admin Nutzer</Radio>
                    </Radio.Group>
                    <br />
                    <br />
                </Col>
            </Row>
        </Modal>
    );
};
