import styles from "components/common/pages/jobs-page/JobsPage.module.scss";
import {SideMenu} from "components/common/presenters/side-menu/SideMenu";
import * as React from "react";
import {JobsTableSection} from "./JobsTableSection";

export const JobsPage = () => {

    return (
        <div className={styles.page}>

            <SideMenu />

            <JobsTableSection />
        </div>
    );
};

