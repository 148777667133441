import * as React from "react";
import {useState} from "react";
import {useHistory, match} from "react-router-dom";
import {Button, Col, Form, Input, Row} from 'antd';
import styles from "./ResetPassword.module.scss";
import {Auth, useAuth} from "../../../../hooks/Auth";
import {PasswordValidator} from "helpers/passwordValidators";
import {RouteStrings} from "routing/Routes";

interface ResetPasswordPageProps {
    match: match<any>;
}

export const ResetPasswordPage = (props: ResetPasswordPageProps) => {
  const history = useHistory();
  const auth: Auth = useAuth();

  const [newPassword, setNewPassword] = useState(null);
  const [newPasswordRepeated, setNewPasswordRepeated] = useState(null);
  const [errors, setErrors] = useState({
		newPassword: "",
		newPasswordRepeated: "",
		general: "",
  })

  const [ui, setUi] = useState({
    isSendingData: false
  });

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 24},
  };
  const tailLayout = {
    wrapperCol: {offset: 0, span: 16},
  };

const validateNewPassword = () => {
    return PasswordValidator.validatePassword(newPassword);
}

const validateReenteredPassword = () => {
    return PasswordValidator.validateReenteredPassword(newPassword, newPasswordRepeated);
}

  const validateFields = () => {
    const validationResults = [];

    if (validateNewPassword().validationError) {
        validationResults.push({ fieldName: "newPassword", ...validateNewPassword() });
    }
    if (validateReenteredPassword().validationError) {
        validationResults.push({ fieldName: "newPasswordRepeated", ...validateReenteredPassword() });
    }

    return validationResults;
}

const setErrorState = (validationResults) => {
    const newErrorsState = {
       ...errors
    };

    for (let i = 0; i < validationResults.length; i++) {
        newErrorsState[validationResults[i].fieldName] = validationResults[i].errorMessage;
    }

   setErrors(newErrorsState)
}

  const validateForm = () =>{
    const validationResults = validateFields();

    if (validationResults.length) {
        setErrorState(validationResults);
        return false;
    }

    return true;
}

  const handleNewPassword = async (): Promise<void> => {
    if (!validateForm()) {
        return;
    }
    await setPassword();

  };

  const setPassword = async () => {
    const { resetCode } = props.match.params;

    if(ui.isSendingData) {
        return;
    }
    setUi({...ui, isSendingData: true})

    try {
        const req = await auth.registerBoUser(resetCode, newPassword);
        setUi({...ui, isSendingData: false})

        if (req?.status === 200) {
            history.push(RouteStrings.Login);
        }
    } catch (error) {
        console.error(error);
    }
    return;
};

  return (
      <div className={styles.page}>
              <Form
                  className={styles.form}
                  {...layout}
                  layout="vertical"
                  name="basic"
                  initialValues={{ remember: true }}
              >
                  <div className={styles.logo}></div>

                  <Row>
                      <Col>
                          <Form.Item
                              className={styles.formItem}
                              label="Neues Passwort"
                              name="newPassword"
                              rules={[
                                  {
                                      required: true,
                                      message: "Bitte neues Passwort eingeben!",
                                  },
                              ]}
                              validateStatus={
                                  errors.newPassword ? "error" : null
                              }
                              help={
                                  errors.newPassword ? errors.newPassword : null
                              }
                          >
                              <Input.Password
                                  data-cy="input_newPassword"
                                  className={styles.inputPassword}
                                  type="password"
                                  onChange={(e) => {
                                      setNewPassword(e.target.value);
                                  }}
                              />
                          </Form.Item>
                      </Col>
                  </Row>

                  <Row>
                      <Col>
                          <Form.Item
                              className={styles.formItem}
                              label="Passwort wiederholen"
                              name="newPasswordRepeated"
                              rules={[
                                  {
                                      required: true,
                                      message: "Ihre Passwörter stimmen nicht über ein.",
                                  },
                              ]}
                              validateStatus={
                                  errors.newPasswordRepeated ? "error" : null
                              }
                              help={
                                  errors.newPasswordRepeated
                                      ? errors.newPasswordRepeated
                                      : null
                              }
                          >
                              <Input.Password
                                  data-cy="input_newPasswordRepeated"
                                  className={styles.inputPassword}
                                  type="password"
                                  onChange={(e) => {
                                      setNewPasswordRepeated(e.target.value);
                                  }}
                              />
                          </Form.Item>
                      </Col>
                  </Row>

                  <Form.Item {...tailLayout}>
                      <Button
                          data-cy="button_submit"
                          className={styles.button}
                          type="primary"
                          htmlType="submit"
                          onClick={() => {
                              handleNewPassword();
                          }}
                      >
                          Konto aktivieren
                      </Button>
                  </Form.Item>
              </Form>
      </div>
  );
};
