import * as React from "react";
import styles from "./MarketVoteTab.module.scss";
import {Checkbox, Col, Divider, Row} from "antd";

export interface DocumentRowProps {
	documentName: string
	isExists: boolean
	isExistsInWU?: boolean
	onUploadedChecked?: (isChecked: boolean) => void
	onWUChecked?: (isChecked: boolean) => void
}

export const DocumentRow = (props: DocumentRowProps) => {
	return (
		<>
			<Row gutter={16} justify={'space-between'}>
				<Col span={16}>
					<p className={styles.documentFont}>
						{props.documentName}
					</p>
				</Col>
				<Col span={5}>
					<Checkbox checked={props.isExists}
							  onChange={(e) => props.onUploadedChecked(e.target.checked)}
					/>
				</Col>
				<Col span={3}>
					<Checkbox checked={props.isExistsInWU}
							  onChange={(e) => props.onWUChecked(e.target.checked)}
					/>
				</Col>
			</Row>
			<Divider className={styles.rowDivider}/>
		</>
	)
}
