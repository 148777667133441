import * as React from "react";
import {useEffect, useState} from "react";
import styles from "components/common/presenters/calculator/Calculator.module.scss";
import {Button, Col, DatePicker, InputNumber, Row, Space} from "antd";
import {CheckOutlined, RedoOutlined, SaveOutlined} from "@ant-design/icons";
import {CalculatorLoanObject, RepaymentPlanInstallment} from "services/calculator/types";
import {DATE_FORMAT} from "../../../../services/api/Globals";
import moment from "moment";
import {RateAdjustmentRow} from "./RatesAdjustmentRow";
import {applyBulkEdit} from "../../../../services/calculator/calculation-actions/handleBulkEdit";
import {LocalizedNumberInput} from "../localized-number-input/LocalizedNumberInput";
import {useTranslation} from "react-i18next";
import {recalculatePaymentPlanByCustomization} from "../../../../services/calculator/calculation-actions/calculateRepaymentPlan";

export interface RatesAdjustmentFormProps {
    isLocked: boolean;
    loanObject: CalculatorLoanObject;
    repaymentPlan: RepaymentPlanInstallment[];
    onChange: (newRepaymentPlan: RepaymentPlanInstallment[]) => void;
    onReset: () => void;
    onSaveCalculation: () => void;
}

export interface RatesAdjustmentFormState {
    dateClickCounter: number
    bulkEditFromId: number
    bulkEditToId: number
    bulkEditStep: number
    bulkEditRate: number
}

export const RatesAdjustmentForm = (props: RatesAdjustmentFormProps) => {
    const {t} = useTranslation()

    const defaultState = {
        dateClickCounter: 0,
        bulkEditFromId: null,
        bulkEditToId: null,
        bulkEditStep: 1,
        bulkEditRate: 0,
    } as RatesAdjustmentFormState

    const [state, setState] = useState(defaultState)

    useEffect(() => {
        setState(defaultState)
    }, [props.repaymentPlan.length])

    const handlePaymentRowInputValueChange = (rowId: number, paymentValue: number) => {
        if (!props.repaymentPlan[rowId]) {
            return
        }

        const planCopy = props.repaymentPlan.map(p => {return {...p}})
        planCopy[rowId].isCustomAmount = true
        planCopy[rowId].amount = paymentValue

        const newRepaymentPlan = recalculatePaymentPlanByCustomization(planCopy, props.loanObject)
        props.onChange(newRepaymentPlan)
    }

    const handlePaymentRowToggle = (rowId: number, isChecked: boolean) => {
        if (!props.repaymentPlan[rowId]) {
            return
        }

        const planCopy = props.repaymentPlan.map(p => {return {...p}})
        planCopy[rowId].isChecked = isChecked

        const newRepaymentPlan = recalculatePaymentPlanByCustomization(planCopy, props.loanObject)
        props.onChange(newRepaymentPlan)
    }

    const handlePaymentRowDateClick = (rowId: number) => {
        console.log(state)
        if (state.dateClickCounter % 2 === 0) {
            setState(prevState => {
                return {
                    ...prevState,
                    dateClickCounter: prevState.dateClickCounter + 1,
                    bulkEditFromId: rowId,
                }
            })
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    dateClickCounter: prevState.dateClickCounter + 1,
                    bulkEditToId: rowId,
                }
            })
        }
    };

    const handleBulkEdit = () => {
        const planCopy = props.repaymentPlan.map(p => {return {...p}})
        const editedPlan = applyBulkEdit(state, planCopy)

        const newRepaymentPlan = recalculatePaymentPlanByCustomization(editedPlan, props.loanObject)
        props.onChange(newRepaymentPlan)
    };

    const renderPaymentRows = () => {
        if (props.repaymentPlan.length === 0) {
            return (
                <div className={styles.msgEmpty}>Sie haben keine Offerte gewählt.</div>
            )
        }

        const rows = [];
        props.repaymentPlan.forEach((installment, i) => {
            rows.push(
                <RateAdjustmentRow
                    key={i}
                    id={i + 1}
                    isLocked={props.isLocked}
                    isLast={false}
                    repaymentPlanInstallment={installment}
                    onChange={(paymentValue: number) => {handlePaymentRowInputValueChange(i, paymentValue)}}
                    onCheckBoxToggle={(isChecked: boolean) => {handlePaymentRowToggle(i, isChecked)}}
                    onDateClick={() => {handlePaymentRowDateClick(i)}}
                />
            )
        })

        // Last row should show Restwert and be non-editable
        if (props.loanObject.remainingValue > 0) {
            const lastPaymentDate = props.repaymentPlan[props.repaymentPlan.length - 1].date.clone()

            const remainingInstallment: RepaymentPlanInstallment = {
                date: lastPaymentDate.endOf('month'),
                amount: props.loanObject.remainingValue,
                isChecked: false,
                isCustomAmount: false
            }

            rows.push(
                <RateAdjustmentRow
                    key={props.repaymentPlan.length}
                    isLocked={props.isLocked}
                    id={props.repaymentPlan.length + 1}
                    isLast={true}
                    repaymentPlanInstallment={remainingInstallment}
                    onChange={(paymentValue: number) => { }}
                    onCheckBoxToggle={(isChecked: boolean) => { }}
                    onDateClick={() => { }}
                />
            )
        }

        return rows
    };

    return (
        <div className={styles.ratesForm}>
            <h3>Staffelanpassung</h3>
            <div className={styles.ratesInner}>
                <Row>
                    <Col span={12}>

                        <div className={styles.headerRow}>
                            <Row gutter={24}>
                                <Col span={2}>

                                </Col>
                                <Col span={2}>
                                    <div className={styles.labelSmall}>ID</div>
                                </Col>
                                <Col span={9}>
                                    <div className={styles.labelSmall}>Valuta</div>
                                </Col>
                                <Col span={9}>
                                    <div className={styles.labelSmall}>Zahlung</div>
                                </Col>
                            </Row>
                        </div>

                        <div className={styles.ratesList}>
                            {renderPaymentRows()}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={styles.ratesContainer}>
                            <h3>Bereicheingabe</h3>

                            <Row gutter={24}>
                                <Col span={12}>
                                    <h4>von</h4>
                                    <DatePicker format={DATE_FORMAT.DE}
                                        className={styles.antPickerSmall}
                                        disabled={true}
                                        value={
                                            state.bulkEditFromId != null ?
                                                moment(props.repaymentPlan[state.bulkEditFromId].date)
                                                : null
                                        }
                                    />
                                </Col>
                                <Col span={12}>
                                    <h4>Schrittweite</h4>
                                    <InputNumber data-cy="input_bulk_edit_step"
                                        className={"ant-input-number--small"}
                                        disabled={props.isLocked}
                                        value={state.bulkEditStep}
                                        min={1}
                                        max={props.repaymentPlan.length}
                                        onChange={(e) => {
                                            setState({...state, bulkEditStep: e as number})
                                        }}
                                    />
                                </Col>
                            </Row>

                            <br />

                            <Row gutter={24}>
                                <Col span={12}>
                                    <h4>bis</h4>
                                    <DatePicker format={DATE_FORMAT.DE}
                                        className={styles.antPickerSmall}
                                        disabled={true}
                                        value={
                                            state.bulkEditToId != null ?
                                                moment(props.repaymentPlan[state.bulkEditToId].date)
                                                : null
                                        }
                                    />
                                </Col>
                                <Col span={12}>
                                    <h4>Rate</h4>
                                    <LocalizedNumberInput data-cy="input_bulk_edit_rate"
                                        className={"ant-input-affix-wrapper--small"}
                                        disabled={props.isLocked}
                                        value={state.bulkEditRate}
                                        prefix={t('currency_sign')}
                                        precision={2}
                                        onChange={(e) => {
                                            setState({...state, bulkEditRate: e as number})
                                        }}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row gutter={[24, 12]}>
                                <Col span={8}>
                                    <Button data-cy="button_bulk_edit"
                                        className={styles.button}
                                        type="primary"
                                        size="small"
                                        htmlType="submit"
                                        disabled={props.isLocked}
                                        onClick={() => {
                                            handleBulkEdit()
                                        }}
                                    >
                                        <CheckOutlined /> Übertragen
						</Button>
                                </Col>
                                <Col span={24}>
                                    <Button data-cy="button_reset_payment_plan"
                                        className={styles.button}
                                        type="primary"
                                        size="small"
                                        htmlType="submit"
                                        disabled={props.isLocked}
                                        onClick={() => {
                                            props.onReset()
                                        }}
                                    >
                                        <RedoOutlined /> Reset
						</Button>
                                </Col>
                                <Col span={24}>
                                    <Button data-cy="button_save_calculation_2"
                                        className={styles.button}
                                        type="primary"
                                        size="large"
                                        htmlType="submit"
                                        disabled={props.isLocked}
                                        onClick={() => {
                                            props.onSaveCalculation()
                                        }}
                                    >
                                        <SaveOutlined /> Berechnung speichern
						</Button>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
