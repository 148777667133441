import React from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {AuthenticationPage} from "components/common/pages/authentication-page/AuthenticationPage";
import {PeoplePage} from "components/common/pages/people-page/PeoplePage";
import {AccountInformationPage} from "components/common/pages/account-information-page/AccountInformationPage";
import {ApplicationPage} from "components/common/pages/application-page/ApplicationPage";
import {ApplicationsPage} from "components/common/pages/applications-page/ApplicationsPage";
import {PublicSelfDeclarationPage} from "../components/common/pages/public-self-declaration-page/PublicSelfDeclarationPage";
import {ExampleCalculatorPage} from "../components/common/pages/example-calculator-page/ExampleCalculatorPage";
import {SettingsPage} from "../components/common/pages/settings-page/SettingsPage";
import {PrivateRoute} from "./PrivateRoute";
import {DealershipConditionsPage} from "../components/common/pages/dealership-conditions-page/DealershipConditionsPage";
import {JobsPage} from "components/common/pages/jobs-page/JobsPage";
import {OffersPage} from "../components/common/pages/offers-page/OffersPage";
import {ResetPasswordPage} from "components/common/pages/reset-password/ResetPassword";

export class RouteStrings {
    // Admin
    public static AdminRouteBase = '/admin';
    public static AdminApplicationDrilldown = RouteStrings.AdminRouteBase + "/applications/:applicationid";
    public static AdminUserDrilldown = RouteStrings.AdminRouteBase + "/users/:userid";
    public static AdminPeople = RouteStrings.AdminRouteBase + "/people";
    public static AdminOffers = RouteStrings.AdminRouteBase + "/offers";
    public static AdminApplications = RouteStrings.AdminRouteBase + "/applications";
    public static AdminExampleCalculator = RouteStrings.AdminRouteBase + '/example-calculator';
    public static AdminSettings = RouteStrings.AdminRouteBase + '/settings';
    public static AdminDealershipConditions = RouteStrings.AdminRouteBase + '/dealership-conditions';
    public static AdminJobs = RouteStrings.AdminRouteBase + '/jobs';

    // Home
    public static Index = '/';
    public static Login = '/login';

    // Public routes
    public static PublicSelfDeclaration = "/users/share/:sharetoken";

    public static ResetPassword = "/users/reset/:resetCode";
}

// TODO: add authenticated routes
export const Routes: React.FC<any> = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const tabName = queryParams.get("tab")
    const selectedCalcId = queryParams.get("selected_calculation_id")
    const selectedOfferId = queryParams.get("selected_offer_id")
    queryParams.delete("selected_calculation_id") // TODO remove properly, this might conflict with TabMenu's update
    queryParams.delete("selected_offer_id")

    const queryString = queryParams.toString().length > 0 ? `?${queryParams.toString()}` : "";
    window.history.replaceState({pageID: getRandomInt(0, Number.MAX_SAFE_INTEGER).toString()}, 'AKF Loan', window.location.origin + window.location.pathname + queryString);

    return (
        <Switch>
            <Route exact path={RouteStrings.Index}>
                <Redirect to={RouteStrings.Login} />
            </Route>

            <Route path={RouteStrings.Login} component={AuthenticationPage} />

            <PrivateRoute path={RouteStrings.AdminExampleCalculator} exact>
                <ExampleCalculatorPage />
            </PrivateRoute>
            <PrivateRoute path={RouteStrings.AdminPeople} exact>
                <PeoplePage />
            </PrivateRoute>
            <PrivateRoute path={RouteStrings.AdminUserDrilldown} exact>
                <AccountInformationPage tabName={tabName} />
            </PrivateRoute>
            <PrivateRoute path={RouteStrings.AdminOffers} exact>
                <OffersPage />
            </PrivateRoute>
            <PrivateRoute path={RouteStrings.AdminApplications} exact>
                <ApplicationsPage />
            </PrivateRoute>
            <PrivateRoute path={RouteStrings.AdminApplicationDrilldown} exact>
                <ApplicationPage tabName={tabName}
                    selectedCalculationId={Number.parseInt(selectedCalcId)}
                    selectedOfferId={Number.parseInt(selectedOfferId)}
                />
            </PrivateRoute>
            <PrivateRoute path={RouteStrings.AdminSettings} exact>
                <SettingsPage />
            </PrivateRoute>
            <PrivateRoute path={RouteStrings.AdminDealershipConditions} exact>
                <DealershipConditionsPage />
            </PrivateRoute>
            <PrivateRoute path={RouteStrings.AdminJobs} exact>
                <JobsPage />
            </PrivateRoute>

            <Route path={RouteStrings.PublicSelfDeclaration} exact component={PublicSelfDeclarationPage} />
            <Route path={RouteStrings.ResetPassword} exact component={ResetPasswordPage} />
        </Switch>
    );
};

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
