import {CalculatorLoanObject} from "../types";
import formulajs from "@formulajs/formulajs/lib/financial";
import {nanOrInfinityToZero, roundToDecimalPoints} from "../../../helpers/numberHelpers";
import {
	getBgInternal,
	getCustomPeriodRateByCustomMonthlyRate,
	getInterestRatePerPaymentPeriod,
	getNumberOfPayments
} from "./loanObjectGetters";

export const reCalculateCommissionFromDesiredMonthlyRate = (loanObject: CalculatorLoanObject): CalculatorLoanObject => {
	const {
		loanAmount,
		remainingValue,
		prePay,
	} = loanObject;

	const rate = getInterestRatePerPaymentPeriod(loanObject)
	const numberOfPayments = getNumberOfPayments(loanObject)
	const customPeriodRate = getCustomPeriodRateByCustomMonthlyRate(loanObject)

	let commission = loanAmount - formulajs.PV(rate, numberOfPayments, -customPeriodRate, remainingValue, prePay ? 1 : 0)
	commission = nanOrInfinityToZero(commission)
	commission = roundToDecimalPoints(commission, 2)

	return {
		...loanObject,
		commission,
	}
};

export const reCalculateDiscountKpFromDesiredMonthlyRate = (loanObject: CalculatorLoanObject): CalculatorLoanObject => {
	const {
		loanAmount,
		remainingValue,
		prePay,
	} = loanObject;

	const rate = getInterestRatePerPaymentPeriod(loanObject)
	const numberOfPayments = getNumberOfPayments(loanObject)
	const customPeriodRate = getCustomPeriodRateByCustomMonthlyRate(loanObject)

	let discountKp = loanAmount - formulajs.PV(rate, numberOfPayments, -customPeriodRate, remainingValue, prePay ? 1 : 0)
	discountKp = nanOrInfinityToZero(discountKp)
	discountKp = roundToDecimalPoints(discountKp, 2)

	return {
		...loanObject,
		discountKp,
	}
};

export const reCalculateDownPaymentFromDesiredMonthlyRate = (loanObject: CalculatorLoanObject): CalculatorLoanObject => {
	const {
		loanAmount,
		remainingValue,
		prePay,
	} = loanObject;

	const rate = getInterestRatePerPaymentPeriod(loanObject)
	const numberOfPayments = getNumberOfPayments(loanObject)
	const customPeriodRate = getCustomPeriodRateByCustomMonthlyRate(loanObject)

	let downpayment = loanAmount - formulajs.PV(rate, numberOfPayments, -customPeriodRate, remainingValue, prePay ? 1 : 0)
	downpayment = nanOrInfinityToZero(downpayment)
	downpayment = roundToDecimalPoints(downpayment, 2)

	return {
		...loanObject,
		downpayment,
	};
};

export const reCalculateInterestRateFromDesiredMonthlyRate = (loanObject: CalculatorLoanObject): CalculatorLoanObject => {
	const {
		paymentsPerYear,
		remainingValue,
		prePay,
	} = loanObject;

	const bgInternal = getBgInternal(loanObject)
	const numberOfPayments = getNumberOfPayments(loanObject)
	const customPeriodRate = getCustomPeriodRateByCustomMonthlyRate(loanObject)

	const newInterestRatePerPeriod = formulajs.RATE(numberOfPayments, -customPeriodRate, bgInternal, -remainingValue, prePay ? 1 : 0)

	let newInterestRate = newInterestRatePerPeriod * paymentsPerYear * 100
	newInterestRate = nanOrInfinityToZero(newInterestRate)
	newInterestRate = roundToDecimalPoints(newInterestRate, 2)

	return {
		...loanObject,
		annualInterestRate: newInterestRate,
	}
}

export const reCalculateRemainingValueFromDesiredMonthlyRate = (loanObject: CalculatorLoanObject): CalculatorLoanObject => {
	const bgInternal = getBgInternal(loanObject)
	const rate = getInterestRatePerPaymentPeriod(loanObject)
	const numberOfPayments = getNumberOfPayments(loanObject)
	const customPeriodRate = getCustomPeriodRateByCustomMonthlyRate(loanObject)

	let remainingValue = formulajs.FV(rate, numberOfPayments, customPeriodRate, -bgInternal, loanObject.prePay ? 1 : 0)
	remainingValue = nanOrInfinityToZero(remainingValue)
	remainingValue = roundToDecimalPoints(remainingValue)

	return {
		...loanObject,
		remainingValue,
	}
};
