import * as React from "react";
import {useEffect, useState} from "react";
import styles from "./DealershipConditionsPage.module.scss";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {SideMenu} from "../../presenters/side-menu/SideMenu";
import {Select} from "antd";
import ApiManager from "../../../../services/api/ApiManager";
import {DealershipCondition} from "../../../../services/api/types";
import {DealershipConditionTablesHolder} from "./DealershipConditionTablesHolder";
import {AnchoredSelect} from "../../presenters/anchored-select/AnchoredSelect";

export const DealershipConditionsPage = () => {
	const history = useHistory()
	const {t} = useTranslation()

	const [dealershipConditions, setDealershipConditions] = useState([] as DealershipCondition[])
	const [dealerNames, setDealerNames] = useState([] as string[])
	const [selectedDealerName, setSelectedDealerName] = useState("")

	useEffect(() => {
		fetchDealershipConditions()
	}, [history[history.lenght - 1]])

	const fetchDealershipConditions = async () => {
		try {
			const result = await ApiManager.UserService.getDealershipConditions()
			const dConditions = result.data;
			const dNames: string[] = Array.from(new Set<string>(
				dConditions.map(dc => dc.dealerName)
			)).sort()

			setDealershipConditions(dConditions)
			setDealerNames(dNames)
			setSelectedDealerName(dNames[0])
		} catch (e) {
			console.error(e)
			setDealershipConditions([])
		}
	}

	return (
		<div className={styles.page}>
			<SideMenu/>

			<div className={styles.sectionRight}>
				<div className={styles.rightContainer}>
					<AnchoredSelect data-cy={"select_dealer_name"}
							value={selectedDealerName}
							onChange={(e) => {
								setSelectedDealerName(e)
							}}
							showSearch={true}
							optionFilterProp={"children"}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
					>
						{
							dealerNames.map((name, i) =>
								<Select.Option data-cy={`select_dealer_name_option_${i}`} key={i} value={name}>{name}</Select.Option>
							)
						}
					</AnchoredSelect>
					<br/>
					<br/>

					<DealershipConditionTablesHolder dealershipConditions={
						dealershipConditions.filter(dc => dc.dealerName === selectedDealerName) || []
					}/>
				</div>
			</div>
		</div>
	);
}
