import * as React from "react";
import {useEffect, useState} from "react";
import styles from "./ContactPersonsTab.module.scss"
import {ContactPerson} from "../../../../../services/api/types";
import {Button, Col, Empty, Row} from "antd";
import {useTranslation} from "react-i18next";
import { PlusOutlined} from "@ant-design/icons";
import {ContactPersonModal} from "./ContactPersonModal";
import {ContactPersonCard} from "./contact-person-card/ContactPersonCard";
import {getUserContactsFromAkf} from "services/api/akfIntegrationService";

export interface ContactsTabProps {
	userId: number
    akfApiCustomerId?: number
	contactPersons: ContactPerson[]
	onListChanged: () => void
}

export const ContactPersonsTab = (props: ContactsTabProps) => {
	const {t} = useTranslation()

    const [contacts, setContacts] = useState([]);
	const [modalOpen, setModalOpen] = useState(false)
	const [editedContactPerson, setEditedContactPerson] = useState(null as ContactPerson)

    const fetchData = async () => {
        try {
     //       debugger;
            if(props.akfApiCustomerId) {
                const contacts = await getUserContactsFromAkf(props.akfApiCustomerId);

                if(contacts.length) {
                    setContacts(contacts);
                }
            }

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setContacts(props.contactPersons);
        fetchData();
    }, [props.userId]);


	const onEditClicked = (contactPersonId: number) => {
        if(!contactPersonId) {
            return;
        }
        setEditedContactPerson(
            props.contactPersons.find(p => p.contactPersonId === contactPersonId)
        )
        setModalOpen(true)
    }

	return (
        <div className={styles.tab}>
                        {!contacts.length ? <Empty className={"ant-empty-normal-custom"} description={
            <span>
              Es wurden noch keine Kontaktpersonen für diesen Kunden angelegt. 
            </span>
          } image={Empty.PRESENTED_IMAGE_SIMPLE} ></Empty> : null}
            <Row gutter={[16, 16]}>
                {contacts.map((c) => (
                    <Col span={8}>
                        <ContactPersonCard
                            contactPerson={c}
                            onEditClicked={onEditClicked}
                        />
                    </Col>
                ))}
            </Row>

            <br />
            <br />

            <Button
                type={"primary"}
                onClick={() => {
                    setEditedContactPerson(null);
                    setModalOpen(true);
                }}
            >
                <PlusOutlined /> {t("add_person")}
            </Button>

            <ContactPersonModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                userId={props.userId}
                onDataSaved={() => props.onListChanged()}
                existingContactPerson={editedContactPerson}
            />
        </div>
    );
}
