import {RepaymentPlanInstallment} from "services/calculator/types";
import {RatesAdjustmentFormState} from "../../../components/common/presenters/calculator/RatesAdjustmentForm";

/**
 * (x) Pressing the bulk edit button should change the rates of all the payments
 * in between the dates in the input fields to the rate given here.
 * 
 * The default value for “Schritte” is 1, which allows just a bulk edit. If its anything other than 1,
 * it should only change the rates in the date range according to those steps 
 * (eg. if the value for Schritte is 4, it should change every 4th payment in the date range).
 * @param state 
 */
export const applyBulkEdit = (state: RatesAdjustmentFormState, repaymentPlan: RepaymentPlanInstallment[]): RepaymentPlanInstallment[] => {
	const idsToEdit: number[] = []
	for (let i = state.bulkEditFromId; i <= state.bulkEditToId; i += state.bulkEditStep) {
		idsToEdit.push(i)
	}

	const newPlan = repaymentPlan.map((month, i) => {
		if (idsToEdit.includes(i)) {
			return {
				...month,
				amount: state.bulkEditRate,
				isChecked: state.bulkEditRate > 0,
				isCustomAmount: true
			}
		}

		return {...month}
	})

	return newPlan
};
