import {SearchOutlined} from "@ant-design/icons";
import {Button, Col, Form, Input, Row} from "antd";
import * as React from "react";
import {SearchFields} from "services/api/akfIntegrationService";

import styles from "./PeopleSearch.module.scss";

export interface PeopleSearchProps {
    onSearchClicked?: (searchFields: SearchFields) => void
    onSearchModified?: (searchFields: SearchFields) => void
    loading: boolean
}

export const PeopleSearch = (props: PeopleSearchProps) => {

    const [ui, setUi] = React.useState({
        bank_user_id: '',
        match_code: '',
        address_line_1: '',
        email: '',
        last_name: '',
        address_postal_code: '',
        address_city: '',
        mobile_number: ''
    })

    return (
        <div className={styles.peopleSearch}>
            <Row gutter={[16, 8]}>
                <Col span={8}>
                    <h4>Personennummer</h4>
                    <Form.Item>
                        <Input
                            data-cy={"input_person_number"}
                            placeholder={""}
                            onChange={(e) => {
                                setUi({ ...ui, bank_user_id: e.target.value });
                                props.onSearchModified({ ...ui, bank_user_id: e.target.value })
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={8}>
                    <h4>Matchcode</h4>
                    <Form.Item>
                        <Input
                            data-cy={"input_matchcode"}
                            placeholder={""}
                            onChange={(e) => {
                                setUi({ ...ui, match_code: e.target.value });
                                props.onSearchModified({ ...ui, match_code: e.target.value })
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <h4>Strasse</h4>
                    <Form.Item>
                        <Input
                            data-cy={"input_street"}
                            placeholder={""}
                            onChange={(e) => {
                                setUi({ ...ui, address_line_1: e.target.value });
                                props.onSearchModified({ ...ui, address_line_1: e.target.value })
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <h4>Email</h4>
                    <Form.Item>
                        <Input
                            data-cy={"input_email"}
                            placeholder={""}
                            onChange={(e) => {
                                setUi({ ...ui, email: e.target.value });
                                props.onSearchModified({ ...ui, email: e.target.value })
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={8}>
                    <h4>Name</h4>
                    <Form.Item>
                        <Input
                            data-cy={"input_name"}
                            placeholder={""}
                            onChange={(e) => {
                                setUi({ ...ui, last_name: e.target.value });
                                props.onSearchModified({ ...ui, last_name: e.target.value })
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <h4>PLZ</h4>
                    <Form.Item>
                        <Input
                            data-cy={"input_postcode"}
                            placeholder={""}
                            onChange={(e) => {
                                setUi({ ...ui, address_postal_code: e.target.value });
                                props.onSearchModified({ ...ui, address_postal_code: e.target.value })
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <h4>Ort</h4>
                    <Form.Item>
                        <Input
                            data-cy={"input_city"}
                            placeholder={""}
                            onChange={(e) => {
                                setUi({ ...ui, address_city: e.target.value });
                                props.onSearchModified({ ...ui, address_city: e.target.value })
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <h4>Telefon</h4>
                    <Form.Item>
                        <Input
                            data-cy={"input_mobile_number"}
                            placeholder={""}
                            onChange={(e) => {
                                setUi({ ...ui, mobile_number: e.target.value });
                                props.onSearchModified({ ...ui, mobile_number: e.target.value })
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <div>
                <span className={styles.searchButton}>
                    <Button
                        data-cy="button_search"
                        type="primary"
                        onClick={() => props.onSearchClicked(ui)}
                        disabled={props.loading}
                    >
                      <SearchOutlined />  {props.loading ? 'Suchen ...' : 'Suchen'}
                    </Button>
                </span>
            </div>
        </div>
    );
}
