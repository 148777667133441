import * as React from "react";
import {useEffect, useState} from "react";
import styles from "components/common/pages/public-self-declaration-page/PublicSelfDeclarationPage.module.scss";
import {useParams} from "react-router-dom";
import {SelfDeclarationTab} from "../../presenters/account-information-page-tabs/self-declaration-tab/SelfDeclarationTab";
import ApiManager from "../../../../services/api/ApiManager";
import {PublicSelfDeclarationInfo} from "../../../../services/api/types";
import {Spin} from "antd";
import {SideMenu} from "../../presenters/side-menu/SideMenu";

export const PublicSelfDeclarationPage = () => {
	const { sharetoken } = useParams();

	const [isTokenExpired, setIsTokenExpired] = useState(false);
	const [isTokenUsed, setIsTokenUsed] = useState(false);
	const [isTokenNotFound, setIsTokenNotFound] = useState(false);
	const [publicSelfDeclarationInfo, setPublicSelfDeclarationInfo] = useState(null as PublicSelfDeclarationInfo);

	const fetchData = async () => {
		try {
			const result = await ApiManager.UserService.getLatestSelfDeclarationByShareToken(sharetoken);
			setPublicSelfDeclarationInfo(result.data as PublicSelfDeclarationInfo);
		} catch (error) {
			switch (error.response?.status) {
				case 403:
					if (error.response.data?.errors[0] === 'ShareRequestUsed') {
						setIsTokenUsed(true)
					} else if (error.response.data?.errors[0] === 'ShareRequestExpired') {
						setIsTokenExpired(true)
					}
					break
				case 404:
					setIsTokenNotFound(true)
			}
		}
	}

	useEffect(() => {
		fetchData()
	}, [sharetoken])

	const tokenErrorText = () => {
		if (isTokenNotFound) {
			return 'Unfortunately, this link is invalid.'
		}
		if (isTokenExpired) {
			return 'Unfortunately, this link expired.'
		}
		if (isTokenUsed) {
			return 'Unfortunately, this link is already used.'
		}

		return null
	}

	return (
		<div className={styles.page}>
			<SideMenu showOnlyPerson={true} />
			<div className={styles.sectionRight}>
				{
					isTokenNotFound || isTokenExpired || isTokenUsed ?
						<p>{tokenErrorText()}</p>
						:
						<>
							{
								publicSelfDeclarationInfo ?
									<SelfDeclarationTab isPublicPage={true}
														latestSelfDeclaration={publicSelfDeclarationInfo.latestSelfDeclaration}
														shareToken={sharetoken}
														publicInfo={publicSelfDeclarationInfo}
                                                        accountInformation={{}}
                                                        offers={[]}
									/>
									:
									<Spin/>
							}
						</>
				}
			</div>
		</div>
	)
}
