import * as React from "react";
import styles from "./SettingsPage.module.scss";
import {useTranslation} from "react-i18next";
import {Button} from "antd";
import Dropzone from "react-dropzone";

export interface SelectFileButtonProps {
	disabled?: boolean
	onDropFiles: (files: any[]) => void
}

export const SelectFileButton = (props: SelectFileButtonProps) => {
	const {t} = useTranslation()

	return (
		<Dropzone onDrop={props.onDropFiles}>
			{({getRootProps, getInputProps}) => (

				<section className={styles.fitContentWidth}>
					<div {...getRootProps()}>
						<input {...getInputProps()} />
						<Button data-cy="button_upload_file"
								type="primary"
								htmlType="submit"
								disabled={props.disabled}
								onClick={() => {
								}}>
							{t('choose_file')}
						</Button>
					</div>
				</section>
			)}
		</Dropzone>
	)
}
