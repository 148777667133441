import React, {createContext, useContext, useState} from "react";
import {BoUserProfile, SettingsProperties} from "../services/api/types";

export interface Store {
	data: StoreData
	setData: (data: StoreData) => void
	isEmpty: () => boolean
	reset: () => void
}

export interface UIState {
    isSidebarCollapsed: boolean
}

export interface StoreData {
    ui?: UIState
	profile?: BoUserProfile
	settings?: SettingsProperties
}

const initialState: StoreData = {
    ui: {
        isSidebarCollapsed: false,
    },
	profile: null,
	settings: {
		companyTypes: [],
		contactPersonFunctions: [],
		countries: [],
		creditInstitutions: [],
		industryTypes: [],
		marketVoteAnalysts: [],
		objectGroups: [],
        rewardShopRecipients: [],
        vendors: []
	}
};

export const StoreContextProvider = ({children}) => {
	const [state, setState] = useState(initialState)

	const data: StoreData = { // returning a deep copy, so state can only be modified via setData() from the outside world
	    ui: {...state.ui},
		profile: {...state.profile},
		settings: {
			companyTypes: state.settings?.companyTypes?.map(v => {return {...v}}),
			contactPersonFunctions: state.settings?.contactPersonFunctions?.map(v => {return {...v}}),
			countries: state.settings?.countries?.map(v => {return {...v}}),
			creditInstitutions: state.settings?.creditInstitutions?.map(v => {return {...v}}),
			industryTypes: state.settings?.industryTypes?.map(v => {return {...v}}),
			marketVoteAnalysts: state.settings?.marketVoteAnalysts?.map(v => {return {...v}}),
			objectGroups: state.settings?.objectGroups?.map(v => {return {...v}}),
            rewardShopRecipients: state.settings?.rewardShopRecipients?.map(v => {return {...v}}),
            vendors: state.settings?.vendors?.map(v => {return {...v}})
		}
	}

	const setData = (newData: StoreData) => {
		setState(prevState => {
			return {...prevState, ...newData}
		})
	}

	const isEmpty = (): boolean => {
		return state === initialState
	}

	const reset = () => {
		setState({
            ...initialState,
            ui: state.ui, // keep UI related state
        })
	}

	const ctxValue: Store = {
		data,
		setData,
		isEmpty,
		reset,
	}

	return (
		<storeContext.Provider value={ctxValue}>
			{children}
		</storeContext.Provider>
	)
}

const storeContext = createContext(null as Store)

export const useStore = (): Store => {
	const store = useContext(storeContext)

	return store
}
