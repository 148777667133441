import * as React from "react";
import {ArrowRightOutlined} from "@ant-design/icons";
import {Table} from "antd";
import {Link} from "react-router-dom";
import styles from "./AccountInformationPageTabs.module.scss";
import {RouteStrings} from "routing/Routes";
import moment from "moment";
import {Calculation, ObjectData, ObjectType} from "../../../../services/api/types";
import {useTranslation} from "react-i18next";
import {currencyLocaleFormatter} from "../../../../helpers/formatters";

export interface CalculationsTabProps {
    calculations: Calculation[]
    objects: ObjectData[]
}

export const CalculationsTab = (props: CalculationsTabProps) => {
    const {t} = useTranslation()

    const dataSource = props.calculations ?
        props.calculations.map(calc => {
            return {
                applicationId: calc.applicationId,
                calculationId: calc.calculationId,
                akfCalculationId: calc.akfCalculationId,
                createdAt: calc.createdAt,
                objectDescription: props.objects
                    ?.filter(obj => obj.applicationId === calc.applicationId)
                    ?.sort((o1, o2) => o1.type === ObjectType.MAIN ? -1 : 0)
                    ?.map(obj => obj.objectName).join(" + "),
                loanAmount: calc.loanAmount,
                loanDuration: calc.loanDuration,
                interestRate: calc.annualInterestRate,
            }
        }) : []
    const columns = [
        {
            title: t('calculation_number_short'),
            dataIndex: 'akfCalculationId',
            render: (value) => value ? `#${value}` : "-"
        },
        {
            title: t('data:created_at_3'),
            dataIndex: 'createdAt',
            width: 150,
            render: (value) => value ? moment(value).format('DD-MM-yyyy hh:mm') : "-"
        },
        {
            title: t('object_description'),
            dataIndex: 'objectDescription',
            render: (value) => value ? value : ""
        },
        {
            title: t('data:purchase_price'),
            dataIndex: 'loanAmount',
            render: (value) => value ? <p className={styles.noWrap}>{`€ ${currencyLocaleFormatter(value)}`}</p> : "-",
        },
        {
            title: t('data:loan_duration'),
            dataIndex: 'loanDuration',
        },
        {
            title: t('data:interest_rate'),
            dataIndex: 'interestRate',
            render: (value) => value ? `${Number.parseFloat(value).toFixed(6)}%` : ""
        },
        {
            title: '',
            dataIndex: 'calculationId',
            render: (value, row) =>
                <Link to={
                    `${RouteStrings.AdminApplicationDrilldown.replace(":applicationid", row.applicationId)}?tab=calculations&selected_calculation_id=${row.calculationId}`
                }>
                    <ArrowRightOutlined/>
                </Link>
        },
    ]

    return (
        <div className={styles.tab}>
            <Table
                rowKey={record => record.calculationId}
                columns={columns}
                dataSource={dataSource}
                pagination={{position: ["bottomCenter"], showSizeChanger: false, pageSize: 10, total: props.calculations.length}}
            />
        </div>
    );
};
