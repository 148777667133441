import * as React from "react";
import {ArrowRightOutlined} from "@ant-design/icons";
import {Table} from "antd";
import {Link} from "react-router-dom";
import styles from "./AccountInformationPageTabs.module.scss";
import {RouteStrings} from "routing/Routes";
import moment from "moment";
import {ApplicationData} from "../../../../services/api/types";

export interface ApplicationsTabProps {
	applications: ApplicationData[]
}

export const ApplicationsTab = (props: ApplicationsTabProps) => {

    const columns = [
        {
            title: 'App Nr.',
            dataIndex: 'bankId',
            render: (value) => value ? value : "-"
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            render: (value) => <div>{moment(value).format('DD-MM-yyyy hh:mm')}</div>
        },
        {
            title: '',
            dataIndex: 'applicationId',
            render: (value) => <Link to={`${RouteStrings.AdminApplicationDrilldown.replace(":applicationid", value)}`}><ArrowRightOutlined /></Link>
        },
    ];

	return (
		<div className={styles.tab}>
			<Table
				rowKey={record => record.applicationId}
				columns={columns}
				dataSource={props.applications}
			/>
		</div>
	);
};
