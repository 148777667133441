import * as React from "react";
import {useState} from "react";
import styles from "../SettingsPage.module.scss";
import {useTranslation} from "react-i18next";
import {CSVLink} from "react-csv";
import {Button, Input} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {SelectFileButton} from "../SelectFileButton";

export interface ManageListFragmentProps {
	listName: string
	dataSource: any[]
	onUpload: (csvFile: any) => void
}

export const ManageListFragment = (props: ManageListFragmentProps) => {
	const {t} = useTranslation()

	const [selectedFile, setSelectedFile] = useState(null)

	const handleUpload = () => {
		props.onUpload(selectedFile)
		setSelectedFile(null)
	}

	return (
		<div>
			<h4>
				{props.listName + " - ("}
				{
					props.dataSource?.length > 0 ?
						<span className={styles.blueLink}>
								<CSVLink filename={`${props.listName}.csv`}
										 data={props.dataSource}
								>
									{t('active_list')}
								</CSVLink>
							</span> : null
				}
				{")"}
			</h4>

			<Input disabled={true}
				   value={selectedFile?.name}
				   suffix={
					   selectedFile?.name ?
						   <DeleteOutlined onClick={() => {
							   setSelectedFile(null)
						   }}/>
						   : null
				   }
			/>
			<br/>
			<br/>

			{
				selectedFile ?
					<Button type={"primary"}
							onClick={() => {
								handleUpload()
							}}
					>
						{t('import_list')}
					</Button>
					:
					<SelectFileButton onDropFiles={(files: any[]) => {
						const file = files[0]
						setSelectedFile(file)
					}}/>
			}
			<br/>
			<br/>

		</div>
	)
}
