import * as React from "react";
import {useEffect, useState} from "react";
import styles from "components/common/presenters/application-page-tabs/calculator-tab/CalculatorTab.module.scss";
import {CalculationsList} from "components/common/presenters/application-page-tabs/calculator-tab/CalculationsList";
import {ObjectsList} from "components/common/presenters/application-page-tabs/calculator-tab/ObjectsList";
import {ObjectInformationForm} from "components/common/presenters/application-page-tabs/calculator-tab/ObjectInformationForm";
import {Calculator} from "components/common/presenters/calculator/Calculator";
import {Calculation, ObjectData} from "../../../../../services/api/types";
import {useTranslation} from "react-i18next";
import {Col, Row} from "antd";

export interface CalculatorTabProps {
    applicationId: number;
    userId: number;
    objects: ObjectData[];
    calculations: Calculation[];
    onDataChanged: () => void;
    setActiveTabIndex: (index: string) => void;
    selectedCalculationId?: number
}

export const CalculatorTab = (props: CalculatorTabProps) => {
    const {t} = useTranslation()

    const [selectedObjectId, setSelectedObjectId] = useState(null as number)
    const [selectedCalculationId, setSelectedCalculationId] = useState(null as number)

    useEffect(() => {
        // try resetting selection, if the selected Calc is no more
        const selectedCalc = props.calculations?.find(c => c.calculationId === selectedCalculationId)
        if (!selectedCalc) {
            setSelectedCalculationId(props.calculations[0]?.calculationId)
        }
    }, [props.calculations])

    useEffect(() => {
        // reset selection, if the selected Obj is no more
        const selectedObj = props.objects?.find(o => o.objectId === selectedObjectId)
        if (!selectedObj) {
            setSelectedObjectId(null)
        }
    }, [props.objects])

    useEffect(() => {
        if (props.selectedCalculationId) {
            setSelectedCalculationId(props.selectedCalculationId)
        }
    }, [props.selectedCalculationId])

    return (
        <div className={styles.calcTab}>

            {/* <div className={styles.calcTabInner}> */}
            <Row>
                <Col span={24}>
                    <CalculationsList
                        applicationId={props.applicationId}
                        userId={props.userId}
                        calculations={props.calculations || []}
                        selectedCalculationId={selectedCalculationId}
                        onCalculationSelected={(calculationId) => {
                            setSelectedCalculationId(calculationId)
                        }}
                        onDataChanged={() => {
                            props.onDataChanged()
                        }}
                    />

                    {/* <ObjectsList
                        objects={props.objects || []}
                        selectedObjectId={selectedObjectId}
                        onObjectSelected={(objectId) => {
                            setSelectedObjectId(objectId)
                        }}
                        onDataChanged={() => {
                            props.onDataChanged()
                        }}
                    /> */}
                </Col>

                {/* <Col span={12}>


                    <ObjectInformationForm
                        applicationId={props.applicationId}
                        userId={props.userId}
                        existingObjects={props.objects || []}
                        selectedObjectId={selectedObjectId}
                        onDataChanged={() => props.onDataChanged()}
                    />
                </Col> */}

            </Row>
            <Row>
                <Col span={24}>

                    <h3>{t('calculator')}</h3>
                    <Calculator isExampleCalculator={false}
                        applicationId={props.applicationId}
                        userId={props.userId}
                        selectedCalculation={
                            props.calculations.find(c => c.calculationId === selectedCalculationId)
                        }
                        setActiveTabIndex={(index) => props.setActiveTabIndex(index)}
                        onDataChange={() => props.onDataChanged()}
                    />
                </Col>

            </Row>
            {/* </div> */}

            <br />
            <br />
            <br />
        </div>
    );
};
