import moment from 'moment'

const types: typesStruct = {
    movableMachines: {
        surchargeGroups: [
                    { machineTypeGroup: "Fahrbare Gerätegruppe 1", groups: [
                        { sb: 3000, value: 0.84 },
                        { sb: 2500, value: 0.88 },
                        { sb: 1500, value: 0.95 },
                        { sb: 1000, value: 1.01 },
                        { sb: 500 , value: 1.16}
                    ]}, 
                    { machineTypeGroup: "Fahrbare Gerätegruppe 2", groups: [
                        { sb: 3000, value: 1.51 },
                        { sb: 2500, value: 1.59 },
                        { sb: 1500, value: 1.72 },
                        { sb: 1000, value: null },
                        { sb: 500 , value: null }
                    ]}, 
                    { machineTypeGroup: "Fahrbare Gerätegruppe 3", groups: [
                        { sb: 3000, value: 0.93 },
                        { sb: 2500, value: 0.95 },
                        { sb: 1500, value: 1.03 },
                        { sb: 1000, value: null },
                        { sb: 500 , value: null }
                    ]}
                ],
        deductibleRocommandationLookup: function(value) {
            if(value < 75000) {
                return 'SB 500,00';
            } else if(value >= 75000 && value < 175000) {
                return 'SB 1.000,00 / SB 1.500,00';
            } else if(value >= 175000 && value < 500000) {
                return 'SB 2.500,00';
            } else if(value >= 500000 && value < 2500000) {
                return 'SB 3.000,00';
            }

            throw new Error("deductible range not found: " + value)
        },
        types: [
            {
                machineType: "Feuerspritzen, Kehrmaschinen usw.",
                machineTypeGroup: "Fahrbare Gerätegruppe 1",
                surchargeGroup: 2,
            },
            {
                machineType: "Hebezeuge, Krane, Autohebebühnen",
                machineTypeGroup: "Fahrbare Gerätegruppe 1",
                surchargeGroup: 2,
            },
            {
                machineType: "LKW-Muldenkipper, Dumper Fahrbare",
                machineTypeGroup: "Fahrbare Gerätegruppe 2",
                surchargeGroup: 2,
            },
            {
                machineType: "Mähdrescher, Häcksler, Schlepper",
                machineTypeGroup: "Fahrbare Gerätegruppe 2",
                surchargeGroup: 1,
            },
            {
                machineType: "Manipulatoren, Schweißumformer usw.",
                machineTypeGroup: "Fahrbare Gerätegruppe 1",
                surchargeGroup: 2,
            },
            {
                machineType:
                    "Misch-/Trocknungsanlagen, Steinbrecher, Mühlen, Silos",
                machineTypeGroup: "Fahrbare Gerätegruppe 2",
                surchargeGroup: 2,
            },
            {
                machineType:
                    "Ölerhitzungsanlagen, Heizungs- und Warmwasserkessel",
                machineTypeGroup: "Fahrbare Gerätegruppe 1",
                surchargeGroup: 2,
            },
            {
                machineType:
                    "Pumpen incl. Rohrleitungen und Armaturen für Brunnen",
                machineTypeGroup: "Fahrbare Gerätegruppe 1",
                surchargeGroup: 2,
            },
            {
                machineType: "Pumpen, Förderanlagen, Autobetonpumpen Fahrbare",
                machineTypeGroup: "Fahrbare Gerätegruppe 1",
                surchargeGroup: 2,
            },
            {
                machineType:
                    "sonstige fahrbare Baumaschinen (z.B. Kompaktbagger, Minibagger, Großbagger, Longfrontbagger)",
                machineTypeGroup: "Fahrbare Gerätegruppe 1",
                surchargeGroup: 2,
            },
            {
                machineType: "Stapler, Elektroschlepper",
                machineTypeGroup: "Fahrbare Gerätegruppe 1",
                surchargeGroup: 2,
            },
            {
                machineType: "Stromaggregate mit Verbrennungsmotoren",
                machineTypeGroup: "Fahrbare Gerätegruppe 2",
                surchargeGroup: 2,
            },
            {
                machineType: "übrige Landwirtschaft",
                machineTypeGroup: "Fahrbare Gerätegruppe 3",
                surchargeGroup: 1,
            },
            {
                machineType: "Unterwasser- und Tauchpumpen",
                machineTypeGroup: "Fahrbare Gerätegruppe 2",
                surchargeGroup: 2,
            },
        ],
    },
    stationaryMachines: {
        surchargeGroups: [
                    { machineTypeGroup: "Metallbearbeitung (Wärmebehandlung, Pressen)", groups: [
                            // 0.91	0.96	1.03	1.09	1.2
                        { sb: 3000, value: 0.91 },
                        { sb: 2500, value: 0.96 },
                        { sb: 1500, value: 1.03 },
                        { sb: 1000, value: 1.09 },
                        { sb: 500 , value: 1.2 }
                    ]},
                    { machineTypeGroup: "Metallbearbeitung (Drehmaschinen, Erodier, etc.)", groups: [
                        // 0.31	0.33	0.35	0.37	0.41
                        { sb: 3000, value: 0.31 },
                        { sb: 2500, value: 0.33 },
                        { sb: 1500, value: 0.35 },
                        { sb: 1000, value: 0.37 },
                        { sb: 500 , value: 0.41 }
                    ]},
                    { machineTypeGroup: "Stromerzeugungsanlagen", groups: [
                        // 0.82	0.86	0.92	0.98	1.08
                        { sb: 3000, value: 0.82 },
                        { sb: 2500, value: 0.86 },
                        { sb: 1500, value: 0.92 },
                        { sb: 1000, value: 0.98 },
                        { sb: 500 , value: 1.08 }
                    ]},
                    { machineTypeGroup: "Textilmaschinen", groups: [
                        // 0.33	0.34	0.36	0.39	0.43
                        { sb: 3000, value: 0.33 },
                        { sb: 2500, value: 0.34 },
                        { sb: 1500, value: 0.36 },
                        { sb: 1000, value: 0.39 },
                        { sb: 500 , value: 0.43 }
                    ]},
                    { machineTypeGroup: "Kunststoffmaschinen", groups: [
                        // 0.6	0.62	0.66	0.71	0.79
                        { sb: 3000, value: 0.6 },
                        { sb: 2500, value: 0.62 },
                        { sb: 1500, value: 0.66 },
                        { sb: 1000, value: 0.71 },
                        { sb: 500 , value: 0.79 }
                    ]},
                    { machineTypeGroup: "Baustoff- und Holzverarbeitungsmaschinen", groups: [
                        // 1.07	1.13	1.21	1.28	1.41
                        { sb: 3000, value: 1.07 },
                        { sb: 2500, value: 1.13 },
                        { sb: 1500, value: 1.21 },
                        { sb: 1000, value: 1.28 },
                        { sb: 500 , value: 1.41 }
                    ]},
                    { machineTypeGroup: "Druckmaschinen", groups: [
                        // 0.28	0.29	0.31	0.33	0.37
                        { sb: 3000, value: 0.28 },
                        { sb: 2500, value: 0.29 },
                        { sb: 1500, value: 0.31 },
                        { sb: 1000, value: 0.33 },
                        { sb: 500 , value: 0.37 }
                    ]},
                    { machineTypeGroup: "Landwirtschaftliche Maschinen", groups: [
                        // 1.5	1.64	1.76	x	x
                        { sb: 3000, value: 1.5 },
                        { sb: 2500, value: 1.64 },
                        { sb: 1500, value: 1.76 },
                        { sb: 1000, value: null },
                        { sb: 500 , value: null }
                    ]},
                    { machineTypeGroup: "Sonstige Maschinen", groups: [
                        // 0.54	0.56	0.61	0.64	0.71
                        { sb: 3000, value: 0.54 },
                        { sb: 2500, value: 0.56 },
                        { sb: 1500, value: 0.61 },
                        { sb: 1000, value: 0.64 },
                        { sb: 500 , value: 0.71 }
                    ]}
        ],
        deductibleRocommandationLookup: function(value) {
            if(value < 75000) {
                return 'SB 500,00';
            } else if(value >= 75000 && value < 175000) {
                return 'SB 1.000,00 / SB 1.500,00';
            } else if(value >= 175000 && value < 500000) {
                return 'SB 2.500,00';
            } else if(value >= 500000 && value < 2500000) {
                return 'SB 3.000,00';
            }

            throw new Error("deductible range not found: " + value)
        },
        types: [
            {
              machineType:
                    "Metallverarbeitung - Warmverformende Pressen, Hämmer",
                machineTypeGroup:
                    "Metallbearbeitung (Wärmebehandlung, Pressen)",
                surchargeGroup: 2,
            },
            {
              machineType: "Metallverarbeitung - Wärmebehandlung wie Öfen",
                machineTypeGroup:
                    "Metallbearbeitung (Wärmebehandlung, Pressen)",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Metallverarbeitung - Kaltverformende Pressen, Stanzen",
                machineTypeGroup:
                    "Metallbearbeitung (Wärmebehandlung, Pressen)",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Metallverarbeitung - CNC-gesteuerte Werkzeugmaschinen",
                machineTypeGroup:
                    "Metallbearbeitung (Drehmaschinen, Erodier, etc.)",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Sonstige Metallbehandlung, wie Erodier-, Schweiß-, Galvanikanlagen",
                machineTypeGroup:
                    "Metallbearbeitung (Drehmaschinen, Erodier, etc.)",
                surchargeGroup: 2,
            },
            {
              machineType: "Verbrennungsmotoren, Notstromerzeugungsanlagen",
                machineTypeGroup: "Stromerzeugungsanlagen",
                surchargeGroup: 2,
            },
            {
              machineType: "Generatoren, Elektromotoren",
                machineTypeGroup: "Stromerzeugungsanlagen",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Elektrische Versorgungsanlagen, z.B. Schaltanlagen, Akkus",
                machineTypeGroup: "Stromerzeugungsanlagen",
                surchargeGroup: 2,
            },
            {
              machineType: "Textilverarbeitung",
                machineTypeGroup: "Textilmaschinen",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Kunststoff-, Gummiverarbeitung, z.B. Spritzgießmaschinen, Kalander",
                machineTypeGroup: "Kunststoffmaschinen",
                surchargeGroup: 2,
            },
            {
              machineType: "Holzverarbeitungsmaschinen, wie Sägen, Gatter",
                machineTypeGroup: "Baustoff- und Holzverarbeitungsmaschinen",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Baustoff- und Glasindustrie u.a. Steinsägen, Trennmaschinen, Walzwerke",
                machineTypeGroup: "Baustoff- und Holzverarbeitungsmaschinen",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Anlagen zur Papier-, Pappe-, Zellstoff-, Zelluloseverarbeitung",
                machineTypeGroup: "Druckmaschinen",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Graphisches Gewerbe, u.a. Rotations-, Offsetmaschinen, Druckpressen",
                machineTypeGroup: "Druckmaschinen",
                surchargeGroup: 2,
            },
            {
              machineType: "Landwirtschaft",
                machineTypeGroup: "Landwirtschaftliche Maschinen",
                surchargeGroup: 1,
            },
            {
              machineType:
                    "Pumpen zur Wasserversorgung einschließlich Rohrleitungen",
                machineTypeGroup: "Sonstige Maschinen",
                surchargeGroup: 2,
            },
            {
              machineType: "Kompressoren zur Gas- und Luftversorgung",
                machineTypeGroup: "Sonstige Maschinen",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Kälteanlagen incl. Verdampfer, Kondensatoren, Armaturen",
                machineTypeGroup: "Sonstige Maschinen",
                surchargeGroup: 2,
            },
            {
              machineType: "Tanks",
                machineTypeGroup: "Sonstige Maschinen",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Büro- und haustechnische Anlagen, wie Kessel, Aufzüge",
                machineTypeGroup: "Sonstige Maschinen",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Hebezeuge/Transporteinrichtungen, z.B. Hallenlaufkrane",
                machineTypeGroup: "Sonstige Maschinen",
                surchargeGroup: 2,
            },
            {
              machineType: "Nahrungs-, Genußmittel-, Futtermittelindustrie",
                machineTypeGroup: "Sonstige Maschinen",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Krankenhäuser, Sanatorien, Betriebsküchen, Haus, Hotel",
                machineTypeGroup: "Sonstige Maschinen",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Tankstellen, Waschanlagen, Service-Stationen, Kfz-Reparatur-Betriebe",
                machineTypeGroup: "Sonstige Maschinen",
                surchargeGroup: 2,
            },
            {
              machineType:
                    "Lager und Umschlaganlagen Dosiervorrichtungen und Waagen",
                machineTypeGroup: "Sonstige Maschinen",
                surchargeGroup: 2,
            },
        ],
    },
    surchargeGroupLookup:  function(surchargeGroup) {
        if(surchargeGroup == 1) {
            return 0.05;
        }
        if(surchargeGroup == 2) {
            return 0.35;
        }

        throw new Error("surchargeGroup range not found: " + surchargeGroup)
    }
};

interface groupType {
  sb: deductibleValue,
  value: number
}

interface surchargeGroupType {
  machineTypeGroup: movableMachineTypeGroup | stationaryMachineTypeGroup,
  groups: groupType[]
}

interface typeType{
  machineType: movableMachineTypes | stationaryMachineTypes,
  machineTypeGroup: movableMachineTypeGroup | stationaryMachineTypeGroup,
  surchargeGroup: 1 | 2,
}

interface machinesType {
  surchargeGroups: surchargeGroupType[],
  deductibleRocommandationLookup: (value) => string,
  types: typeType[]
}

interface typesStruct {
    movableMachines: machinesType,
    stationaryMachines: machinesType,
    surchargeGroupLookup: (surchargeGroup) => number
}


export enum PriceType {
    Brutto,
    Netto
}

export type deductibleValue = 500 | 1000 | 1500 | 2000 | 2500 | 3000;

export const deductibleValues: deductibleValue[] = [500, 1000, 1500, 2000, 2500, 3000];

const objectTypes = ['stationary', 'movable'] as const;
type objectType = typeof objectTypes[number];

const movableMachineTypeGroups = ["Fahrbare Gerätegruppe 1" , "Fahrbare Gerätegruppe 2" , "Fahrbare Gerätegruppe 3"] as const;
type movableMachineTypeGroup = typeof movableMachineTypeGroups[number];

const stationaryMachineTypeGroups = ["Metallbearbeitung (Wärmebehandlung, Pressen)", "Metallbearbeitung (Drehmaschinen, Erodier, etc.)", "Stromerzeugungsanlagen", "Textilmaschinen", "Kunststoffmaschinen", "Baustoff- und Holzverarbeitungsmaschinen", "Druckmaschinen", "Landwirtschaftliche Maschinen", "Sonstige Maschinen"] as const; 
type stationaryMachineTypeGroup = typeof stationaryMachineTypeGroups[number];

export const movableMachineTypesList =[
"Feuerspritzen, Kehrmaschinen usw.",
"Hebezeuge, Krane, Autohebebühnen",
"LKW-Muldenkipper, Dumper Fahrbare",
"Mähdrescher, Häcksler, Schlepper",
"Manipulatoren, Schweißumformer usw.",
"Misch-/Trocknungsanlagen, Steinbrecher, Mühlen, Silos",
"Ölerhitzungsanlagen, Heizungs- und Warmwasserkessel",
"Pumpen incl. Rohrleitungen und Armaturen für Brunnen",
"Pumpen, Förderanlagen, Autobetonpumpen Fahrbare",
"sonstige fahrbare Baumaschinen (z.B. Kompaktbagger, Minibagger, Großbagger, Longfrontbagger)",
"Stapler, Elektroschlepper",
"Stromaggregate mit Verbrennungsmotoren",
"übrige Landwirtschaft",
"Unterwasser- und Tauchpumpen"] as const;
type movableMachineTypes = typeof movableMachineTypesList[number];

export const stationaryMachineTypesList = [
'Metallverarbeitung - Warmverformende Pressen, Hämmer',
'Metallverarbeitung - Wärmebehandlung wie Öfen',
'Metallverarbeitung - Kaltverformende Pressen, Stanzen',
'Metallverarbeitung - CNC-gesteuerte Werkzeugmaschinen',
'Sonstige Metallbehandlung, wie Erodier-, Schweiß-, Galvanikanlagen',
'Verbrennungsmotoren, Notstromerzeugungsanlagen',
'Generatoren, Elektromotoren',
'Elektrische Versorgungsanlagen, z.B. Schaltanlagen, Akkus',
'Textilverarbeitung',
'Kunststoff-, Gummiverarbeitung, z.B. Spritzgießmaschinen, Kalander',
'Holzverarbeitungsmaschinen, wie Sägen, Gatter',
'Baustoff- und Glasindustrie u.a. Steinsägen, Trennmaschinen, Walzwerke',
'Anlagen zur Papier-, Pappe-, Zellstoff-, Zelluloseverarbeitung',
'Graphisches Gewerbe, u.a. Rotations-, Offsetmaschinen, Druckpressen',
'Landwirtschaft',
'Pumpen zur Wasserversorgung einschließlich Rohrleitungen',
'Kompressoren zur Gas- und Luftversorgung',
'Kälteanlagen incl. Verdampfer, Kondensatoren, Armaturen',
'Tanks',
'Büro- und haustechnische Anlagen, wie Kessel, Aufzüge',
'Hebezeuge/Transporteinrichtungen, z.B. Hallenlaufkrane',
'Nahrungs-, Genußmittel-, Futtermittelindustrie',
'Krankenhäuser, Sanatorien, Betriebsküchen, Haus, Hotel',
'Tankstellen, Waschanlagen, Service-Stationen, Kfz-Reparatur-Betriebe',
'Lager und Umschlaganlagen Dosiervorrichtungen und Waagen'] as const;
type stationaryMachineTypes = typeof stationaryMachineTypesList[number];

export interface RequestData {
    purchasePrice: number;
    priceType: PriceType;
    deductible: deductibleValue;
    constructionYear: number;
    constructionMonth: number;
    vehicleLetterAvailable: boolean;
    objectType: objectType;
    machineType: movableMachineTypes | stationaryMachineTypeGroup;
}

export interface ResultData {
  deductibleRecommendation: string;
  machineTypeGroup: string;
  tariff: string;
  tariffRate: number;
  surchargeGroup: number;
  akfSurcharge: number;
  tariffRateWithAkfSurcharge: number;
  monthsDiffrance: number;
  premiumDetermination: number;
  calculationBasis: number;
  ratePerMonth: number;
  ratePerYear: number;
}

export class InsuranceCalculator {
    constructor() {
    }

    calculateInsurance(requestData: RequestData) : ResultData {
      const type = this.getDataForTypeObject(requestData);

      const deductibleRecommendation = type.deductibleRocommandationLookup(requestData.purchasePrice);

      const machineTypeGroup = this.getMachineTypeGroup(requestData);

      const tariff = this.getTariff(requestData);

      const tariffRate = this.getTariffRate(requestData);

      const surchargeGroup = this.getSurchargeGroup(requestData);

      const akfSurcharge = this.getAkfSurcharge(requestData);

      const tariffRateWithAkfSurcharge = parseFloat((tariffRate + tariffRate * akfSurcharge).toFixed(3))

      const monthsDiffrance = this.getMonthsDiffrance(requestData);

      if(monthsDiffrance > 73) {
        throw new Error('Not subject to inquiry');
      }

      const premiumDetermination = this.getPremiumDetermination(requestData);

      const calculationBasis = requestData.purchasePrice;

      const ratePerMonth = parseFloat((calculationBasis/1000*tariffRateWithAkfSurcharge).toFixed(2))

      const ratePerYear = parseFloat((ratePerMonth*12).toFixed(2))

      return {
          deductibleRecommendation,
          machineTypeGroup,
          tariff,
          tariffRate,
          surchargeGroup,
          akfSurcharge,
          tariffRateWithAkfSurcharge,
          monthsDiffrance,
          premiumDetermination,
          calculationBasis,
          ratePerMonth,
          ratePerYear
      }
    }

    private getDataForTypeObject({ objectType }: RequestData) {
      if (objectType === "stationary") {
        return types.stationaryMachines;
      } else {
        return types.movableMachines;
      }
    }

    private getMachineTypeGroup(requestData: RequestData) {
      const type = this.getDataForTypeObject(requestData);

      const machineTypeGroup = type.types.find(t => t.machineType == requestData.machineType).machineTypeGroup;

      return machineTypeGroup;
    }

    private getTariff({ objectType }: RequestData) {
      return objectType === "stationary" ? "stationary" : "movable"
    }

    private getTariffRate(requestData: RequestData) {
      const type = this.getDataForTypeObject(requestData);
      const machineTypeGroup = this.getMachineTypeGroup(requestData);

      let tariffRate;
      type.surchargeGroups.forEach(s => {
        if(s.machineTypeGroup == machineTypeGroup) {
          s.groups.forEach(g => {
            if(g.sb === requestData.deductible) {
              tariffRate = g.value;
            }
          })
        }
      })

      return tariffRate;
    }

    private getSurchargeGroup(requestData: RequestData) {
      const type = this.getDataForTypeObject(requestData);

      const surchargeGroup = type.types.find(t => t.machineType == requestData.machineType).surchargeGroup;

      return surchargeGroup;
    }

    private getAkfSurcharge(requestData: RequestData) {
      const surchargeGroup = this.getSurchargeGroup(requestData);

      return types.surchargeGroupLookup(surchargeGroup);
    }

    private getAbsoluteMonths(momentDate) {
      var months = Number(momentDate.format("MM"));
      var years = Number(momentDate.format("YYYY"));
      return months + (years * 12);
    }

    private getMonthsDiffrance({constructionYear, constructionMonth}: RequestData) {
      const startMonths = this.getAbsoluteMonths(moment(`01-${constructionMonth}-${constructionYear}`, "DD-MM-YYYY"));
      const endMonths = this.getAbsoluteMonths(moment());

      return endMonths - startMonths;
    }

    private getPremiumDetermination(requestData: RequestData) {
      return requestData.priceType == PriceType.Brutto ? requestData.purchasePrice : requestData.purchasePrice / 1.19;
    }
}

export default new InsuranceCalculator();
