import {Checkbox, Input, Select, Form} from "antd";
import * as React from "react";
import {useEffect, useState} from "react";
import styles from "components/common/presenters/application-page-tabs/ApplicationPageTabs.module.scss";
import {CreditInstitutionSetting} from "../../../../services/api/types";
import {Store, useStore} from "../../../../hooks/Store";
import {AnchoredSelect} from "../anchored-select/AnchoredSelect";
import iban from "iban";

export interface BankConnectionProps {
    bankConnections: any[];
    onDataChanged: (data) => void
    bankConnectionId?: number;
    userName?: string;
    infoTextVisible?: boolean;
}

export const BankConnection = (props: BankConnectionProps) => {
    const store: Store = useStore()

    const enabledCreditInstitutions: CreditInstitutionSetting[] = store.data?.settings?.creditInstitutions?.filter(ci => ci.isEnabled) || []

    const [data, setData] = useState({
        bankConnectionId: props.bankConnectionId,
        creditInstitutionId: null,
        accountOwner: '',
        iban: '',
        newBankAccount: false,
    });

    const [ui, setUi] = useState({
        ibanErrorMsg: '',
        bicLookupFailed: false
    });

    useEffect(() => {
        if (props.userName) {
            setData({...data, accountOwner: props.userName})
        }
    }, [props.userName])

    const [selectedCreditInstitutionId, setSelectedCreditInstitutionId] = useState(null as number)

    const validateIban = (value) => {
        iban.isValid(value)
        if (iban.isValid(value)) {
            setUi({...ui, bicLookupFailed: false, ibanErrorMsg: ''});
        } else {
            setUi({...ui, bicLookupFailed: false, ibanErrorMsg: 'IBAN ist falsch!'});
        }

    };

    const onBicLookupClicked = (evt) => {
        const startsWithDE = data.iban.substring(0, 2);
        const startsWithNumber = /([0-9])/.test(data.iban);
        let foundCreditInstite;

        if(startsWithDE.toLowerCase() == 'de') {
            const extractedBlz = data.iban.substring(4, 12);
            foundCreditInstite = enabledCreditInstitutions.find(c => c.blz.replace(/ /ig, '') == extractedBlz);
        } else if(startsWithNumber) {
            const extractedBlz = data.iban.substring(2, 10);
            foundCreditInstite = enabledCreditInstitutions.find(c => c.blz.replace(/ /ig, '') == extractedBlz);
        }

        if(foundCreditInstite) {
            setData({...data, 
                creditInstitutionId: foundCreditInstite.creditInstitutionId
            });
            setSelectedCreditInstitutionId(foundCreditInstite.creditInstitutionId)
            return;
        }

        setUi({...ui, bicLookupFailed: true, ibanErrorMsg: "" })   
    }

    return (
        <div>
            <h2 style={{textAlign: 'left'}}>Bankverbindungen</h2>
            <div>
                <div>
                    <h3>Existierende Bankverbindungen</h3>
                </div>

                <div>
                    <AnchoredSelect data-cy="dropdown_type"
                        size="large"
                        className={styles.dropdown}
                        disabled={data.newBankAccount}
                        value={data.bankConnectionId}
                        onChange={(v) => {
                            const newData = {
                                ...data,
                                bankConnectionId: v,
                                creditInstitutionId: null,
                                accountOwner: '',
                                iban: '',
                                newBankAccount: false,
                            }
                            setData(newData)
                            props.onDataChanged(newData)
                            setSelectedCreditInstitutionId(null)
                        }}
                    >
                        {
                            (props.bankConnections || []).map((b, i) => {
                                const bankName = store.data?.settings?.creditInstitutions?.find(ci => ci.creditInstitutionId === b.creditInstitutionId)?.name || ""
                                return (<Select.Option data-cy={`option_${i}`} key={i} value={b.bankConnectionId}>{`${b.iban} - ${bankName}`}</Select.Option>)
                            })
                        }
                    </AnchoredSelect>
                </div>
                <br />
                <div>
                    <Checkbox onChange={(e) => {
                        const newData = {
                            ...data,
                            newBankAccount: e.target.checked,
                            bankConnectionId: e.target.checked ? null : data.bankConnectionId
                        };
                        setData(newData);
                        props.onDataChanged(newData);
                    }}
                    >
                        Neue Bankverbindung
					</Checkbox>
                </div>
                <br />
                {
                    data.newBankAccount ?
                        <>
                            <h4>Kontoinhaber</h4>
                            <Input data-cy=""
                                placeholder=""
                                value={data.accountOwner}
                                onChange={(e) => {
                                    const newData = {...data, accountOwner: e.target.value};
                                    setData(newData);
                                    props.onDataChanged(newData);
                                }}
                            />
                            <br />
                            <br />

                            <h4>Kreditinstitut</h4>
                            <AnchoredSelect data-cy={"dropdown_institutions"}
                                value={selectedCreditInstitutionId}
                                onChange={(e) => {
                                    setSelectedCreditInstitutionId(e)

                                    const newData = {
                                        ...data,
                                        creditInstitutionId: e,
                                    }
                                    setData(newData)
                                    props.onDataChanged(newData)
                                }}
                                showSearch={true}
                                optionFilterProp={"children"}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    enabledCreditInstitutions.map((ci, i) => {
                                        return <Select.Option data-cy={`dropdown_institutions_option_${i}`} key={i} value={ci.creditInstitutionId}>{`${ci.name} - ${ci.bic}`}</Select.Option>
                                    })
                                }
                            </AnchoredSelect>
                            <br />
                            <div className={styles.bicIban}>
                                <div className={styles.bic}>
                                    <h4>BIC</h4>
                                    <Input disabled={true}
                                        value={
                                            selectedCreditInstitutionId ?
                                                enabledCreditInstitutions.find(ci => ci.creditInstitutionId === selectedCreditInstitutionId)?.bic
                                                : ""
                                        }
                                    />
                                </div>
                                <div className={styles.iban}>
                                    <h4>IBAN</h4>
                                    <Form.Item
                                        validateStatus={!!ui.ibanErrorMsg ? "error" : ''}
                                        help={ui.ibanErrorMsg}
                                    >
                                        <Input data-cy=""
                                            placeholder=""
                                            value={data.iban}
                                            onChange={(e) => {
                                                const newData = {...data, iban: e.target.value};
                                                setData(newData);
                                                validateIban(e.target.value)
                                                props.onDataChanged(newData);
                                            }}
                                        />
                                         {ui.bicLookupFailed ? <span className={styles.bicLookup}>Kein passendes Kreditinstitut gefunden. Überprüfen Sie die IBAN.</span>
                                                : <a onClick={onBicLookupClicked} className={styles.bicLookup}>Kreditinstitu und BIC automatisch suchen.</a>
                                        }
                                    </Form.Item>
                                </div>
                            </div>
                        </> : null
                }
                  {props.infoTextVisible ? <div>
                    Diese Bankverbindung wird nicht gespeichert. Sie dient nur zur Vertragserstellung.
                </div> : null }
            </div>
        </div>
    );
}
