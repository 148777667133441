import {Moment} from "moment";
import {PaymentMethod} from "services/api/types";

export enum PaymentsPerYear {
    Monthly = 12,
    Quarterly = 4,
    Yearly = 1,
}

export function mapPaymentsPerYearToPaymentMethod(ppy: PaymentsPerYear): PaymentMethod {
    switch (ppy) {
        case 12:
            return PaymentMethod.YEARLY;
        case 4:
            return PaymentMethod.QUARTERLY;
        case 1:
            return PaymentMethod.MONTHLY;
        default:
            return PaymentMethod.MONTHLY;
    }
}

export function mapPaymentsPerYearToString(ppy: PaymentsPerYear | number): string {
    switch (ppy) {
        case 12:
            return 'Monatlich';
        case 4:
            return 'Quartal';
        case 1:
            return 'Jährlich';
        default:
            return 'Monatlich';
    }
}

// The object that's holding the values required for calculation business logic.
export interface CalculatorLoanObject {
    loanAmount: number;
    loanDuration: number;
    customMonthlyRate: number;
    acquisitionCost: number;
    annualInterestRate: number;
    discountKp: number;
    workGrant: number;
    downpayment: number;
    commission: number;
    discount: number;
    remainingValue: number;
    hedgedProceeds: number;
    additionalProceeds: number;
    dateOfFirstPayment: string;
    paidOutAt: string;
    contractStartAt: string;
    paymentsPerYear: PaymentsPerYear;
    runUpTime: number;
    prePay: boolean;
    discountKpPercentage: number;
    discountPercentage: number;
    downpaymentPercentage: number;
    workGrantPercentage: number;
    commissionPercentage: number;
    remainingValuePercent: number;
    bgInternal: number;
}

export interface RepaymentPlanInstallment {
    isChecked: boolean;
    date: Moment;
    amount: number;
    isCustomAmount: boolean;
}

export interface CalculatorState {
    isLocked: boolean;
    isSelectedCalculationJustLoaded: boolean;
    loanObject: CalculatorLoanObject;
    repaymentPlan: RepaymentPlanInstallment[];
}
