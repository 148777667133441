import {ArrowRightOutlined, SearchOutlined} from "@ant-design/icons";
import {Input, Table} from "antd";
import {Link} from "react-router-dom";
import styles from "components/common/pages/applications-page/ApplicationsPage.module.scss";
import {SideMenu} from "components/common/presenters/side-menu/SideMenu";
import * as React from "react";
import {useEffect, useState} from "react";
import ApiManager from "services/api/ApiManager";
import {RouteStrings} from "routing/Routes";
import moment from "moment";
import {ApplicationData} from "../../../../services/api/types";
import {useTranslation} from "react-i18next";
import {DATE_FORMAT} from "../../../../services/api/Globals";
import {currencyLocaleFormatter} from "../../../../helpers/formatters";

// TODO: extract add user modal to a component
export const ApplicationsPage = () => {
    const {t} = useTranslation()

    const [applications, setApplications] = useState([] as ApplicationData[]);
	const [totalApplications, setTotalApplications] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(0);
	const [pageIndex, setPageIndex] = useState(1);

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
		fetchData();
	}, [pageIndex]);

    const fetchData = async () => {
        try {
            const result = await ApiManager.ApplicationService.getApplications(pageIndex, searchTerm);

            setApplications(result.data);
            setTotalApplications(result.pagination.numberOfRows);
            setItemsPerPage(result.pagination.itemsPerPage);
        } catch (error) {
            console.error(error);
        }
    };

	const getCompanyName = (application: ApplicationData): string => {
	    if (application.companyName?.length > 0) {
	        return application.companyName
        }

        return `${application.firstName ? application.firstName : ''} ${application.lastName ? application.lastName : ''}`;
    }

	const dataSource = applications.map(application => {
	    return {
            applicationId: application.applicationId,
            akfInternalApplicationIds: application.offers.map(offer => offer.akfOfferId ? `#${offer.akfOfferId}` : "-").join(' | '),
            createdAt: application.createdAt,
            customerName: getCompanyName(application),
            mainObjectName: application.mainObjectName,
            loanAmount: application.calculations.map(calc => `€ ${currencyLocaleFormatter(calc.loanAmount)}`).join(' | '),
        }
    })

    const columns = [
        {
            title: t('offer_number'),
            dataIndex: 'akfInternalApplicationIds',
            render: (value) => value ? value : "-",
        },
        {
            title: t('data:created_at'),
            dataIndex: 'createdAt',
            render: (value) => value ? moment(value).format(DATE_FORMAT.DE) : "-"
        },
        {
            title: t('company_name'),
            dataIndex: 'customerName',
        },
        {
            title: t('object_description'),
            dataIndex: 'mainObjectName',
            render: (value) => value ? value : "-"
        },
        {
            title: t('amount'),
            dataIndex: 'loanAmount',
            render: (value) => value ? value : "-"
        },
        {
            title: '',
            dataIndex: 'applicationId',
            render: (value) => <Link to={`${RouteStrings.AdminApplicationDrilldown.replace(":applicationid", value)}`}><ArrowRightOutlined /></Link>
        },
    ]

	return (
		<div className={styles.page}>

			<SideMenu />

			<div className={styles.sectionRight}>
                <h1>Anträge</h1>
                <br/>
                <br/>

                <Input data-cy={"input-search"}
                       suffix={
                           <SearchOutlined />
                       }
                       value={searchTerm}
                       onChange={(e) => {
                           setSearchTerm(e.target.value)
                       }}
                       onPressEnter={() => {
                           fetchData()
                       }}
                       onBlur={() => {
                           fetchData()
                       }}
                />
                <br/>
                <br/>

                <Table rowKey={record => record.applicationId}
                       pagination={{ position: ["bottomCenter"], showSizeChanger: false, current: pageIndex, pageSize: itemsPerPage, total: totalApplications, }}
                       columns={columns}
                       dataSource={dataSource}
                       onChange={(pagination) => { setPageIndex(pagination.current)}}
                />
			</div>
		</div>
	);
};
