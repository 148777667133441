// import history from "../history";
import ApiManager from "./ApiManager";
import {ApiWrapper} from "./ApiWrapper";
import {ApplicationData} from "./types";
import {PostMarketVoteRequest} from "./request/marketVote";
import throttle from 'lodash.throttle';

enum ApplicationEmbedEnum {
    ContactPerson = 'contact_persons',
    BankConnections = 'bank_connections',
    Applications = 'applications',
    Calculations = 'calculations',
    ApplicationDocuments = 'application_documents',
    UserDocuments = 'user_documents',
    Objects = 'objects',
    Offers = 'offers',
    Contracts = 'contracts',
    ContractDrafts = 'contract_drafts',
    MarketVotes = 'market_votes'
}

const uploadApplicationDocument = async (file: any, userId, applicationId, documentName, documentCategory) => {
    const path = '/application_documents';

    let formData = new FormData();
    formData.append("file", file);
    formData.append("userId", userId);
    formData.append("documentName", documentName);
    formData.append("documentCategory", documentCategory);
    formData.append("applicationId", applicationId);

    const apiCall = ApiWrapper.postFormWithFile(path, formData);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getApplicationDocuments = async (applicationId: number) => {
    const path = `/applications/${applicationId}/documents`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getApplicationDocumentSignedUrl = async (applicationDocumentId: number) => {
    const path = `/application_documents/${applicationDocumentId}/signed_url`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getMarktvotumDocumentSignedUrl = async (applicationId: number) => {
    const path = `/applications/${applicationId}/markt_vote/signed_url`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const deleteFile = async (fileId) => {
    const path = `/application_documents/${fileId}`;
    const body = {
    };

    const apiCall = ApiWrapper.del(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};


const createObject = async (data) => {
    const path = '/objects';
    const body = {
        ...data
    };

    const apiCall = ApiWrapper.post(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const editObject = async (data, objectId) => {
    const path = `/objects/${objectId}`;
    const body = {
        ...data
    };

    const apiCall = ApiWrapper.patch(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const assignToCalculation = async (data, objectId) => {
    const path = `/objects/${objectId}/assign`;
    const body = {
        ...data
    };

    const apiCall = ApiWrapper.patch(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const unassignFromCalculation = async (objectId, data) => {
    const path = `/objects/${objectId}/unassign`;
    const body = {
        ...data
    };

    const apiCall = ApiWrapper.patch(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const deleteObject = async (objectId) => {
    const path = `/objects/${objectId}`;
    const body = {
    };

    const apiCall = ApiWrapper.del(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const createCalculation = async (data) => {
    const path = '/calculations';
    const body = {
        ...data
    };

    const apiCall = ApiWrapper.post(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const updateCalculation = async (calculationId: number, data) => {
    const path = `/calculations/${calculationId}`;
    const body = {
        ...data
    };

    const apiCall = ApiWrapper.patch(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const deleteCalculation = async (calculationId) => {
    const path = `/calculations/${calculationId}`;
    const body = {
    };

    const apiCall = ApiWrapper.del(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const duplicateCalculation =  async (calculationId) => {
    console.log('Duplikat running')
    const path = `/calculations/duplicate/${calculationId}`;

    const apiCall = ApiWrapper.post(path, {});

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getCalculations = async (pageIndex) => {
    let path = `/calculations?page=${pageIndex}`;

    const apiCall = ApiWrapper.get(path);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getOffers = async (pageIndex, searchTerm) => {
    let path = `/offers?page=${pageIndex}`;
    if (searchTerm) {
        path = `${path}&q=${encodeURIComponent(searchTerm)}`;
    }

    const apiCall = ApiWrapper.get(path);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const createContractDraft = async (data) => {
    const path = '/contract_drafts';
    const body = {
        ...data
    };

    const apiCall = ApiWrapper.post(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const patchContractDraft = async (data, contractDraftId) => {
    const path = `/contract_drafts/${contractDraftId}`;
    const body = {
        ...data
    };

    const apiCall = ApiWrapper.patch(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};


const createOffer = async (data) => {
    const path = '/offers';
    const body = {
        ...data
    };

    const apiCall = ApiWrapper.post(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const generatePdfOffer = async (data) => {
    const path = `/offers/pdfoffers`;
    const body = {
        ...data
    };

    const apiCall = ApiWrapper.patch(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout(30 * 1000)]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const editOffer = async (data, offerid) => {
    const path = `/offers/${offerid}`;
    const body = {
        ...data
    };

    const apiCall = ApiWrapper.patch(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const addGuarantor = async (data, offerid) => {
    const path = `/offers/${offerid}/guarantors`;
    const body = {
        ...data
    };

    const apiCall = ApiWrapper.patch(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const deleteGuarantor = async (guarantorId, offerid) => {
    const path = `/offers/${offerid}/guarantors/${guarantorId}`;
    const body = {
    };

    const apiCall = ApiWrapper.del(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const updateGuarantor = async (guarantorId, offerid, body) => {
    const path = `/offers/${offerid}/guarantors/${guarantorId}`;

    const apiCall = ApiWrapper.patch(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

export enum ContentDisposition {
    Attachment = "attachment",
    Inline = "inline"
}

const getOfferPdf = async (offerId, contentDisposition?: ContentDisposition) => {
    let path = `/offers/${offerId}/pdf`;

    if(contentDisposition) {
        path += `?content_disposition=${contentDisposition.toString()}`
    }

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const createContract = async (data) => {
    const path = '/contracts';
    const body = {
        ...data
    };

    const apiCall = ApiWrapper.post(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout(40 * 1000)]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getContractPdf = async (contractId, contentDisposition?: ContentDisposition) => {
    let path = `/contracts/${contractId}/pdf`;

    if(contentDisposition) {
        path += `?content_disposition=${contentDisposition.toString()}`
    }

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const signContractPdf = async (contractId, data) => {
    const path = `/contracts/${contractId}/sign`;
    const body = {
        // base64Signature,
        // signatoryName,
        // userId

        ...data
    };

    const apiCall = ApiWrapper.patch(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout(40 * 1000)]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const createApplication = async (userId) => {
    const path = '/applications';
    const body = {
        userId
    };

    const apiCall = ApiWrapper.post(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getApplication = async (applicationId, embed) => {
    let path = `/applications/${applicationId}`;
    if (embed && embed.length) {
        path = `${path}?_embed=${embed.join(',')}`
    }

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getApplications = async (pageIndex, searchTerm) => {
    let path = `/applications?page=${pageIndex}`;
    if (searchTerm) {
        path = `${path}&q=${encodeURIComponent(searchTerm)}`;
    }

    const apiCall = ApiWrapper.get(path);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getJobs = async (pageIndex, jobStatuses: string[]) => {
    let path = `/jobs?page=${pageIndex}`;

    if (jobStatuses.length) {
        path = `${path}&job_status=${jobStatuses.join(',')}`;
    }

    const apiCall = ApiWrapper.get(path);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const retryJob = async (jobId) => {
    let path = `/jobs/${jobId}`;

    const apiCall = ApiWrapper.patch(path, {});

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getJobFiles = async (jobId) => {
    let path = `/jobs/${jobId}/zipped`;

    const apiCall = ApiWrapper.get(path);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const updateApplication = async (data: ApplicationData) => {
    let path = `/applications/${data.applicationId}`;
    const body = {
        ...data
    };
    Reflect.deleteProperty(body, 'applicationId')
    Reflect.deleteProperty(body, 'isLocked')

    const apiCall = ApiWrapper.patch(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const submitApplication = async (applicationId: number) => {
    let path = `/applications/${applicationId}/submit`;
    const body = {
    };
    Reflect.deleteProperty(body, 'applicationId')
    Reflect.deleteProperty(body, 'isLocked')

    const apiCall = ApiWrapper.patch(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const upsertMarketVote = async (applicationId: number, body: PostMarketVoteRequest) => {
    let path = `/applications/${applicationId}/market_vote`;

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const deleteMarketVote = async (applicationId: number) => {
    let path = `/applications/${applicationId}/market_vote`;

    const apiCall = ApiWrapper.del(path, {});

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

export default {
    ApplicationEmbedEnum,
    uploadApplicationDocument,
    getApplicationDocuments,
    getApplicationDocumentSignedUrl,
    getMarktvotumDocumentSignedUrl,
    deleteFile,
    createObject: throttle(createObject, 2000, {trailing: false}),
    editObject,
    assignToCalculation,
    unassignFromCalculation,
    deleteObject,
    createCalculation: throttle(createCalculation, 2000, {trailing: false}),
    updateCalculation: throttle(updateCalculation, 2000, {trailing: false}),
    deleteCalculation,
    duplicateCalculation: throttle(duplicateCalculation, 2000, {trailing: false}),
    getCalculations,
    getOffers,
    getOfferPdf,
    createOffer: throttle(createOffer, 2000, {trailing: false}),
    generatePdfOffer,
    editOffer,
    addGuarantor: throttle(addGuarantor, 2000, {trailing: false}),
    deleteGuarantor,
    updateGuarantor,
    createContractDraft: throttle(createContractDraft, 2000, {trailing: false}),
    patchContractDraft,
    createContract: throttle(createContract, 2000, {trailing: false}),
    getContractPdf,
    signContractPdf: throttle(signContractPdf, 2000, {trailing: false}),
    createApplication: throttle(createApplication, 2000, {trailing: false}),
    getApplication,
    getApplications,
    getJobs,
    retryJob: throttle(retryJob, 2000, {trailing: false}),
    getJobFiles,
    updateApplication,
    submitApplication: throttle(submitApplication, 2000, {trailing: false}),
    upsertMarketVote: throttle(upsertMarketVote, 2000, {trailing: false}),
    deleteMarketVote
};
