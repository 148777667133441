import * as React from "react";
import {useEffect, useState} from "react";
import styles from "./ContactPersonsTab.module.scss"
import {ContactPerson, ContactPersonFunctionSetting, ContactPersonRole, CountrySetting} from "../../../../../services/api/types";
import {Checkbox, Col, DatePicker, Divider, Form, Input, message, Modal, Row, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import ApiManager from "../../../../../services/api/ApiManager";
import {DATE_FORMAT} from "../../../../../services/api/Globals";
import UiHelper from "../../../../../helpers/uiHelpers";
import moment from "moment";
import {Store, useStore} from "../../../../../hooks/Store";
import {AnchoredSelect} from "../../anchored-select/AnchoredSelect";
import {areFieldsInvalid, isTaxNumberInvalid, taxNumberAutoFormat} from "components/common/presenters/user-modal/fieldsValidation";

export interface ContactPersonModalProps {
    modalOpen: boolean
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    onDataSaved: () => void

    userId: number
    existingContactPerson?: ContactPerson
}

export const ContactPersonModal = (props: ContactPersonModalProps) => {
    const {t} = useTranslation()
    const store: Store = useStore()

    const defaultData: ContactPerson = {
        userId: props.userId,
        roles: []
    }

    const [data, setData] = useState(defaultData)
    const [isCreateNewPerson, setIsCreateNewPerson] = useState(true)

    const [isEnableFinishedAt, setIsEnableFinishedAt] = useState(false)

    const validationFields = {
        contactPersonFunctionIdError: false,
        salutationError: false,
        lastNameError: false,
        addressLine1Error: false,
        addressStreetNumberError: false,
        addressPostalCodeError: false,
        addressCityError: false,
        taxNumberError: false,
        isSendingData: false
    };
    const [ui, setUi] = useState({...validationFields});

    const enabledContactPersonFunctions: ContactPersonFunctionSetting[] = store.data?.settings?.contactPersonFunctions?.filter(f => f.isEnabled) || []
    if (!isCreateNewPerson && store.data && !enabledContactPersonFunctions.find(f => f.contactPersonFunctionId === props.existingContactPerson?.contactPersonFunctionId)) {
        const oldDisabledOption = store.data.settings.contactPersonFunctions.find(f => f.contactPersonFunctionId === props.existingContactPerson?.contactPersonFunctionId)
        if (oldDisabledOption) {
            enabledContactPersonFunctions.push(oldDisabledOption)
        }
    }

    const enabledCountries: CountrySetting[] = store.data?.settings?.countries?.filter(c => c.isEnabled) || []
    if (!isCreateNewPerson && store.data && !enabledCountries.find(c => c.countryId === props.existingContactPerson?.addressCountryId)) {
        const oldDisabledOption = store.data.settings.countries.find(c => c.countryId === props.existingContactPerson?.addressCountryId)
        if (oldDisabledOption) {
            enabledCountries.push(oldDisabledOption)
        }
    }

    useEffect(() => {
        if (props.existingContactPerson) {
            const existingData: ContactPerson = props.existingContactPerson
            if (existingData.roles?.length === 0) {
                existingData.roles = []
            }

            setData(props.existingContactPerson)
            setIsCreateNewPerson(false)
            setIsEnableFinishedAt(existingData.finishedAt?.length > 0)
        } else {
            setData(defaultData)
            setIsCreateNewPerson(true)
        }
    }, [props.modalOpen])

    const sendData = async () => {
        try {
            const invdalidState = isInvalid();
            if (invdalidState.invalid) {
                message.error(invdalidState.msg, 3)
                return;
            }

            if (isCreateNewPerson) {
                await ApiManager.UserService.createContactPerson(data)
            } else {
                await ApiManager.UserService.updateContactPerson(data)
            }

            props.setModalOpen(false)
            props.onDataSaved()

            message.success('Daten Gespeichert', 2)
        } catch (e) {
            console.error(e)
            message.error('Konnte nicht Speichern', 2)
        }
    }

    const isInvalid = (): {invalid: boolean, msg: string} => {
        const validationData = areFieldsInvalid(data,
            ["contactPersonFunctionId", "salutation", "lastName", "addressLine1", "addressStreetNumber", "addressPostalCode", "addressCity"]) as typeof validationFields;

        validationData.taxNumberError = data.taxNumber && isTaxNumberInvalid(data.taxNumber)
        setUi({...ui, ...validationData});

        if (validationData.taxNumberError) {
            return {invalid: true, msg: 'Steuernummer ist nicht im korrekten Format'}
        }

        const invalid = Object.values(validationData).some(e => e === true);
        return {invalid, msg: invalid ? 'Bitte alle notwendigen Felder ausfüllen' : ''}
    }

    return (
        <Modal title={isCreateNewPerson ? t('add_contact_person') : t('update_contact_person')}
            width={1000}
            visible={props.modalOpen}
            destroyOnClose={true}
            onOk={async (e) => {

                e.preventDefault();
                try {
                    if (ui.isSendingData) {
                        return;
                    }
                    setUi({...ui, isSendingData: true})
    
                    await sendData();
    
                    message.success('Daten Gespeichert', 2)
                } catch (error) {
                    console.error(error)
                    message.error('Konnte nicht Speichern', 2)
                }

                await UiHelper.timeout(1000);
                setUi({...ui, isSendingData: true})
            }}
            okButtonProps={{ disabled: ui.isSendingData }}
            onCancel={() => {
                props.setModalOpen(false);
            }}
        >
            <Row gutter={36}>
                <Col span={24}>
                    <h4 className={styles.bold}>{t('personal_data')}</h4>
                    <br />
                </Col>
                <Col span={12}>
                    <h4>{t('data:function')}</h4>
                    <Form.Item
                        validateStatus={ui.contactPersonFunctionIdError ? "error" : ''}
                    >
                        <AnchoredSelect data-cy="select_function"
                            value={data.contactPersonFunctionId}
                            onChange={(e) => {
                                setData({...data, contactPersonFunctionId: e})
                            }}
                            showSearch={true}
                            optionFilterProp={"children"}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                enabledContactPersonFunctions.map((f, i) => {
                                    return <Select.Option data-cy={`select_function_option_${i}`} key={i} value={f.contactPersonFunctionId}>{f.functionName}</Select.Option>
                                })
                            }
                        </AnchoredSelect>
                    </Form.Item>
                    <h4>{t('data:salutation')}</h4>
                    <Form.Item
                        validateStatus={ui.salutationError ? "error" : ''}
                    >
                        <AnchoredSelect data-cy={"input_salutation"}
                            value={data?.salutation}
                            onChange={(e) => {
                                setData({...data, salutation: e})
                            }}
                        >
                            <Select.Option key={0} value={"Herr"}>{"Herr"}</Select.Option>
                            <Select.Option key={1} value={"Frau"}>{"Frau"}</Select.Option>
                            <Select.Option key={1} value={"K/A"}>{"K/A"}</Select.Option>
                        </AnchoredSelect>
                    </Form.Item>
                    <h4>{t('data:first_name')}</h4>
                    <Form.Item>
                        <Input data-cy="input_first_name"
                            value={data.firstName}
                            onChange={(e) => {
                                setData({...data, firstName: e.target.value})
                            }}
                        />
                    </Form.Item>
                    <h4>{t('data:last_name')}</h4>
                    <Form.Item
                        validateStatus={ui.lastNameError ? "error" : ''}
                    >
                        <Input data-cy="input_last_name"
                            value={data.lastName}
                            onChange={(e) => {
                                setData({...data, lastName: e.target.value})
                            }}
                        />
                    </Form.Item>
                    <br />
                    <br />
                </Col>
                <Col span={12}>

                    <h4>{t('data:gender')}</h4>
                    <AnchoredSelect data-cy="select_gender"
                        value={data.gender}
                        onChange={(e) => {
                            setData({...data, gender: e});
                        }}
                    >
                        {[
                            <Select.Option key={0} value={"Männlich"}>{"Männlich"}</Select.Option>,
                            <Select.Option key={1} value={"Weiblich"}>{"Weiblich"}</Select.Option>,
                            <Select.Option key={1} value={"K/A"}>{"K/A"}</Select.Option>
                        ]}
                    </AnchoredSelect>
                    <br />

                    <h4>{t('data:date_of_birth')}</h4>
                    <DatePicker data-cy={"input_date_of_birth"}
                        format={DATE_FORMAT.DE}
                        showToday={false}
                        value={data.dateOfBirth ? moment(data.dateOfBirth) : null}
                        onChange={(e) => {
                            setData({...data, dateOfBirth: e ? e.format(DATE_FORMAT.API) : null})
                        }}
                    />
                    <br />
                    <br />

                    <h4>{t('data:tax_number')}</h4>
                    <Form.Item
                        validateStatus={ui.taxNumberError ? "error" : ''}
                    >
                        <Input data-cy="input_tax_id"
                            value={data.taxNumber}
                            placeholder="xx/xxx/xxxxx"
                            onChange={(e) => {
                                setData({...data, taxNumber: taxNumberAutoFormat(e.target.value)})
                            }}
                        />
                    </Form.Item>

                    <h4>{t('data:tax_id')}</h4>
                    <Input data-cy="input_tax_id"
                        value={data.taxId}
                        onChange={(e) => {
                            setData({...data, taxId: e.target.value})
                        }}
                    />
                    <br />
                </Col>
                <Col span={24}>
                    <Divider />
                </Col>
                <Col span={12}>
                    <h4 className={styles.bold}>{t('address_data')}</h4>
                    <Row gutter={12}>
                        <Col span={16}>
                            <h4>Strasse</h4>
                            <Form.Item
                                validateStatus={ui.addressLine1Error ? "error" : ''}
                            >
                                <Input data-cy="input_contact_street" className={styles.shortInput} value={data.addressLine1} onChange={(e) => {
                                    setData({...data, addressLine1: e.target.value})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <h4>Hausnummer</h4>
                            <Form.Item
                                validateStatus={ui.addressStreetNumberError ? "error" : ''}
                            >
                                <Input data-cy="input_contact_street_num" className={styles.shortInput} value={data.addressStreetNumber} onChange={(e) => {
                                    setData({...data, addressStreetNumber: e.target.value})
                                }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col span={8}>
                            <h4>{t('data:zip')}</h4>
                            <Form.Item
                                validateStatus={ui.addressPostalCodeError ? "error" : ''}
                            >
                                <Input data-cy="input_zip"
                                    className={styles.shortInput}
                                    value={data.addressPostalCode}
                                    onChange={(e) => {
                                        setData({...data, addressPostalCode: e.target.value})
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <h4>{t('data:city')}</h4>
                            <Form.Item
                                validateStatus={ui.addressCityError ? "error" : ''}
                            >
                                <Input data-cy="input_city"
                                    value={data.addressCity}
                                    onChange={(e) => {
                                        setData({...data, addressCity: e.target.value})
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <h4>{t('data:others')}</h4>
                    <Input.TextArea rows={7} data-cy="input_others"
                        value={data.others}
                        onChange={(e) => {
                            setData({...data, others: e.target.value})
                        }}
                    />
                </Col>
                <Col span={12}>
                    <h4 className={styles.bold}>{t('data:contact_details')}</h4>
                    <h4>{t('data:telephone')}</h4>
                    <Input data-cy="input_phone"
                        value={data.phoneNumber}
                        onChange={(e) => {
                            setData({...data, phoneNumber: e.target.value})
                        }}
                    />
                    <br />
                    <br />

                    <h4>{t('data:mobile_number')}</h4>
                    <Input data-cy="input_mobile"
                        value={data.mobilePhone}
                        onChange={(e) => {
                            setData({...data, mobilePhone: e.target.value})
                        }}
                    />
                    <br />
                    <br />

                    <h4>{t('data:fax_number')}</h4>
                    <Input data-cy="input_fax"
                        value={data.fax}
                        onChange={(e) => {
                            setData({...data, fax: e.target.value})
                        }}
                    />
                    <br />
                    <br />

                    <h4>{t('data:email')}</h4>
                    <Input data-cy="input_email"
                        value={data.email}
                        onChange={(e) => {
                            setData({...data, email: e.target.value})
                        }}
                    />
                    <br />
                    <br />
                    <Row align="middle">
                        <Col>
                            <Checkbox data-cy={"check_enable_finished_at"}
                                checked={isEnableFinishedAt}
                                onChange={(e) => {
                                    const isChecked = e.target.checked
                                    if (!isChecked) {
                                        setData({
                                            ...data,
                                            finishedAt: null
                                        })
                                    }
                                    setIsEnableFinishedAt(isChecked)
                                }}
                            >
                                {`${t("data:finished_at")}: `}
                            </Checkbox>
                        </Col>
                        <Col flex={"auto"}>
                            <DatePicker data-cy={"input_finished_at"}
                                format={DATE_FORMAT.DE}
                                disabled={!isEnableFinishedAt}
                                showToday={true}
                                value={data.finishedAt ? moment(data.finishedAt) : null}
                                onChange={(e) => {
                                    setData({...data, finishedAt: e ? e.format(DATE_FORMAT.API) : null})
                                }}
                            />
                        </Col>


                    </Row>
                </Col>
            </Row>
        </Modal >
    )
}
