import * as React from "react";
import styles from "components/common/presenters/list-signatories/ListSignatories.module.scss";
import {List, Card} from "antd";
import {UserOutlined, FileOutlined, CheckCircleFilled, CheckCircleOutlined} from "@ant-design/icons";
import classnames from "classnames";


export interface ListSignatoriesProps {
    signatories: {
        id: number;
        text: string;
        signed: boolean;
        selected: boolean;
    }[];
    onClick?: (e) => void;
}

/**
 * Used for lists with calculations, offers, documents.
 * @param props 
 */
export const ListSignatories = (props: ListSignatoriesProps) => {

    return (
        <List
            grid={{gutter: 16, column: 3}}
            dataSource={props.signatories}
            renderItem={o => (
                <List.Item onClick={props.onClick ? () => props.onClick(o) : () => { }}>
                    <div className={classnames(styles.item, {[styles.selected]: o.selected})}>
                        <div className={styles.icon}><UserOutlined /></div>
                        <div className={styles.textElement}>{o.text}</div>
                        <div className={
                            classnames(styles.icon, {
                                [styles.iconGreen]: o.selected,
                                [styles.iconGreen]: o.signed
                            })
                        }>{o.signed ? <><CheckCircleFilled /><span className={styles.signedText}>Unterschrieben</span></> : <CheckCircleOutlined />}</div>
                    </div>
                </List.Item>
            )}
        />
    );
};
