import * as React from "react";
import {useEffect, useState} from "react";
import {Checkbox, Col, DatePicker, Divider, Form, Input, message, Modal, Radio, Row, Select, Space, Tooltip} from "antd";
import styles from "components/common/presenters/user-modal/UserModal.module.scss";
import {BankConnection, CompanyTypeSetting, CountrySetting, CreditInstitutionSetting, DistributionChannel, DocumentType, IndustryTypeSetting, SalesPerson, UserData, UserType} from "services/api/types";
import ApiManager from "services/api/ApiManager";
import {DATE_FORMAT} from "../../../../services/api/Globals";
import {Store, useStore} from "../../../../hooks/Store";
import {AnchoredSelect} from "../anchored-select/AnchoredSelect";
import UiHelper from "../../../../helpers/uiHelpers";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {InfoCircleOutlined} from "@ant-design/icons";
import {areFieldsInvalid, isTaxNumberInvalid, taxNumberAutoFormat} from "components/common/presenters/user-modal/fieldsValidation";
import {AddressSearchInput, SelectedAddress} from "components/common/presenters/user-modal/AddressSearchInput";
import iban from "iban";


const {Option} = Select;

export interface UserModalProps {
    className?: string;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    userData?: UserData;
    onDataSaved: () => void;
    salesPersons: SalesPerson[];
}

export const UserModal = (props: UserModalProps) => {
    const {t} = useTranslation()
    const store: Store = useStore()

    const enabledCompanyTypes: CompanyTypeSetting[] = store.data?.settings?.companyTypes?.filter(ct => ct.isEnabled || props.userData?.companyTypeId === ct.companyTypeId) || []
    const enabledIndustryTypes: IndustryTypeSetting[] = store.data?.settings?.industryTypes?.filter(t => t.isEnabled || props.userData?.industryTypeId === t.industryTypeId) || []
    const enabledCountries: CountrySetting[] = store.data?.settings?.countries?.filter(c => c.isEnabled || props.userData?.addressCountryId === c.countryId || props.userData?.birthCountryId === c.countryId) || []
    const enabledCreditInstitutions: CreditInstitutionSetting[] = store.data?.settings?.creditInstitutions?.filter(ci => ci.isEnabled) || []
    const germanyCountryId = (enabledCountries.filter(k => k.name.toLowerCase() === 'deutschland')[0]||{}).countryId;
    const privatPersonCompanyTypeId = (store.data?.settings?.companyTypes?.find(ct => ct.name.toLowerCase() == 'privatperson') || {}).companyTypeId;
    const defaultData = {
        distributionChannel: DistributionChannel.Direct,
        userType: [],
        isPrivate: false,
        companyTypeId: enabledCompanyTypes[0]?.companyTypeId,
        birthCountryId: germanyCountryId,
        nationalityCountryId: germanyCountryId,
        contactLanguageId: germanyCountryId,
        addressCountryId: germanyCountryId
    } as UserData

    const defaultBankConnectionData = {
        creditInstitutionId: null,
        accountOwner: "",
        iban: "",
    } as BankConnection

    const [data, setData] = useState(defaultData);
    const [newBankConnection, setNewBankConnection] = useState(defaultBankConnectionData as BankConnection);

    useEffect(() => {
        if (props.modalOpen && props.userData) {
            setData({
                userType: [...props.userData.userType],
                distributionChannel: props.userData.distributionChannel,
                isPrivate: props.userData.isPrivate,
                companyTypeId: props.userData.companyTypeId,
                salesRepresentativeId: props.userData.salesRepresentativeId,
                industryTypeId: props.userData.industryTypeId,
                salutation: props.userData.salutation,
                title: props.userData.title,
                firstName: props.userData.firstName,
                lastName: props.userData.lastName,
                birthDate: props.userData.birthDate,
                birthCity: props.userData.birthCity,
                birthCountryId: props.userData.birthCountryId,
                contactLanguageId: props.userData.contactLanguageId,
                taxNumber: props.userData.taxNumber,
                addon: props.userData.addon,
                addressLine1: props.userData.addressLine1,
                addressStreetNumber: props.userData.addressStreetNumber,
                addressPostalCode: props.userData.addressPostalCode,
                addressCity: props.userData.addressCity,
                addressCountryId: props.userData.addressCountryId,
                oldschoolphoneNumber: props.userData.oldschoolphoneNumber,
                mobileNumber: props.userData.mobileNumber,
                faxNumber: props.userData.faxNumber,
                email: props.userData.email,
                matchCode: props.userData.matchCode,
                idDoc: props.userData.idDoc,
                idOn: props.userData.idOn,
                idBy: props.userData.idBy,
                idNumber: props.userData.idNumber,
                idIssuedBy: props.userData.idIssuedBy,
                foundedOn: props.userData.foundedOn,
                companyName: props.userData.companyName,
                companyRegisterNumber: props.userData.companyRegisterNumber,
                taxId: props.userData.taxId,
                vatTaxId: props.userData.vatTaxId,
                nationalityCountryId: props.userData.nationalityCountryId,
            })
        } else if (props.modalOpen) {
            setData(defaultData)
            setNewBankConnection(defaultBankConnectionData as BankConnection)
        }
    }, [props.modalOpen, props.userData])

    useEffect(() => {
        if (props.salesPersons.length > 0) {
            setData({...data, salesRepresentativeId: props.salesPersons[0].id})
        }
    }, [props.salesPersons])

    const validationFields = {
        lastNameError: false,
        addressLine1Error: false,
        addressStreetNumberError: false,
        addressPostalCodeError: false,
        addressCityError: false,
        salesRepresentativeIdError: false,
        companyTypeIdError: false,
        userTypeError: false,
        taxNumberError: false,
        isSendingData: false,
        bicLookupFailed: false,
        ibanErrorMsg: ''
    };
    const [ui, setUi] = useState({...validationFields});


    const validateIban = (value) => {
        iban.isValid(value)
        if (iban.isValid(value)) {
            setUi({...ui, bicLookupFailed: false, ibanErrorMsg: ''});
        } else {
            setUi({...ui, bicLookupFailed: false, ibanErrorMsg: 'IBAN ist falsch!'});
        }

    };

    // "0123456789" -> "01/234/56789"
    const formatTaxId = (withoutSlashes: string): string => {
        if (!withoutSlashes || withoutSlashes.length === 0) {
            return ""
        }

        const fragment1 = withoutSlashes.slice(0, 2)
        const fragment2 = withoutSlashes.slice(2, 5)
        const fragment3 = withoutSlashes.slice(5, 10)

        let result = fragment1
        if (fragment2) {result += `/${fragment2}`}
        if (fragment3) {result += `/${fragment3}`}

        return result
    }

    const sendData = async () => {
        try {
            const invdalidState = isInvalid();
            if (invdalidState.invalid) {
                message.error(invdalidState.msg, 3)
                return;
            }
         //   await UiHelper.timeout(300);
            console.log('Befor isSendingData check', ui.isSendingData)
            if(ui.isSendingData) {
                return;
            }
            setUi({...ui, isSendingData: true})

            if (!props.userData) {
                const bankConnectionData = newBankConnection.iban.length > 0 && newBankConnection.creditInstitutionId > 0 && newBankConnection.accountOwner.length > 0 ?
                    newBankConnection : null
                await ApiManager.UserService.addUser(data, bankConnectionData)
            } else {
                await ApiManager.UserService.updateUser(props.userData.userId, data)
            }
            console.log('After response from server isSendingData', ui.isSendingData)
            props.setModalOpen(false);

            props.onDataSaved()
            message.success('Daten Gespeichert', 2)
        } catch (error) {
            console.error(error)
            message.error('Konnte nicht Speichern', 2)
        }
        console.log('At the end isSendingData', ui.isSendingData)
        await UiHelper.timeout(1000);
        setUi({...ui, isSendingData: false})
    };

    const isInvalid = (): {invalid: boolean, msg: string} => {

        const validationData = areFieldsInvalid(data,
            ["lastName", "addressLine1", "addressStreetNumber", "addressPostalCode", "addressCity"]) as typeof validationFields;
        if (!data.isPrivate) {
            validationData.companyTypeIdError = !data.companyTypeId
        }
       // validationData.userTypeError = data.userType.length === 0
        validationData.salesRepresentativeIdError = !data.salesRepresentativeId
        validationData.taxNumberError = data.taxNumber && isTaxNumberInvalid(data.taxNumber)
        setUi({...ui, ...validationData});

        if (validationData.taxNumberError) {
            return {invalid: true, msg: 'Steuernummer ist nicht im korrekten Format'}
        }

        const invalid = Object.values(validationData).some(e => e === true);
        return {invalid, msg: invalid ? 'Bitte alle notwendigen Felder ausfüllen' : ''}
    }

    const onBicLookupClicked = (evt) => {
        const startsWithDE = newBankConnection.iban.substring(0, 2);
        const startsWithNumber = /([0-9])/.test(newBankConnection.iban);
        let foundCreditInstite;

        if(startsWithDE.toLowerCase() == 'de') {
            const extractedBlz = newBankConnection.iban.substring(4, 12);
            foundCreditInstite = enabledCreditInstitutions.find(c => c.blz.replace(/ /ig, '') == extractedBlz);
        } else if(startsWithNumber) {
            const extractedBlz = newBankConnection.iban.substring(2, 10);
            foundCreditInstite = enabledCreditInstitutions.find(c => c.blz.replace(/ /ig, '') == extractedBlz);
        }

        if(foundCreditInstite) {
            setNewBankConnection({...newBankConnection, 
                creditInstitutionId: foundCreditInstite.creditInstitutionId
            });
            return;
        }

        setUi({...ui, bicLookupFailed: true })   
    }

    return (
        <div className={`svea-h ${props.className}`}>
            <Modal title="Person hinzufügen"
                width={1000}
                visible={props.modalOpen}
                onOk={() => {
                    sendData();
                }}
                okButtonProps={{ disabled: ui.isSendingData }}
                onCancel={() => {props.setModalOpen(false);}}
            >
                {/* <Form className={styles.form}
                    layout="vertical"
                    name="basic"
                    initialValues={{remember: true}}
                > */}
                <Row gutter={36}>
                    <Col span={12}>
                        <h4 className={styles.bold}>Personenart</h4>
                        <Form.Item
                            validateStatus={ui.userTypeError ? "error" : ''}
                        >
                            <Checkbox.Group data-cy={"checkbox_group_user_types"}
                                value={data.userType}
                                onChange={(checkedValues) => {
                                    setData({...data, userType: checkedValues.map(v => v.toString())})
                                }}
                            >
                                <Row>
                                    {/* <Col span={8}>
                                        <Checkbox data-cy={"checkbox_person"} value={UserType.Person}>{t('user_types:person')}</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox data-cy={"checkbox_lieferant"} value={UserType.Lieferant}>{t('user_types:lieferant')}</Checkbox>
                                    </Col> */}
                                    <Col span={24}>
                                        <Checkbox data-cy={"checkbox_handler-lieferant"} value={UserType.HandlerLieferant}>{t('user_types:handler') + '/' +t('user_types:lieferant')}</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox data-cy={"checkbox_vermittler"} value={UserType.Vermittler}>{t('user_types:vermittler')}</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox data-cy={"checkbox_hersteller"} value={UserType.Hersteller}>{t('user_types:hersteller')}</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox data-cy={"checkbox_kunde"} value={UserType.Kunde}>{t('user_types:kunde')}</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                        <Divider />
                    </Col>
                    <Col span={12}>
                        <h4 className={styles.bold}>Angaben</h4>
                        <Checkbox data-cy="checkbox_4"
                            className={styles.checkbox}
                            checked={data.isPrivate}
                            disabled={!!props.userData?.userId}
                            onChange={(e) => {
                                const isChecked = e.target.checked
                                setData((prevState) => {
                                    return {
                                        ...prevState,
                                        isPrivate: isChecked,
                                        companyTypeId: isChecked ? privatPersonCompanyTypeId : enabledCompanyTypes[0]?.companyTypeId
                                    }
                                })
                            }}>
                            Privatperson
                        </Checkbox>
                        <br />
                        <br />
                        {
                            !data.isPrivate ?
                                <>


                                    <Form.Item
                                        validateStatus={ui.companyTypeIdError ? "error" : ''}
                                    >
                                        <AnchoredSelect data-cy="dropdown_company_type"
                                            disabled={!!props.userData?.userId}
                                            value={data.companyTypeId}
                                            onChange={(e) => {
                                                setData({...data, companyTypeId: e});
                                            }}
                                            showSearch={true}
                                            optionFilterProp={"children"}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                enabledCompanyTypes.map((companyType, i) => {
                                                    return <Select.Option key={i} data-cy={`option_${i}`} value={companyType.companyTypeId}>{companyType.name}</Select.Option>
                                                })
                                            }
                                        </AnchoredSelect>
                                    </Form.Item>
                                </> : null
                        }
                        <Divider />
                    </Col>
                </Row>
                <Row gutter={36}>
                    <Col span={12}>
                        <h4 className={styles.bold}>Vertriebsweg</h4>
                        <Radio.Group data-cy="radio_distribution_channel"
                            value={data.distributionChannel}
                            onChange={(e) => {
                                setData({...data, distributionChannel: e.target.value})
                            }}>
                            <Radio value={DistributionChannel.Direct}>Direkt</Radio>
                            <Radio value={DistributionChannel.Sales}>Absatz</Radio>
                        </Radio.Group>
                        <br />
                        <br />
                        <Divider />
                    </Col>

                    <Col span={12}>
                        <h4 className={styles.bold}>Aussendienstmitarbeiter</h4>
                        <Form.Item
                            validateStatus={ui.salesRepresentativeIdError ? "error" : ''}
                        >
                            <AnchoredSelect data-cy="dropdown_sales_person"
                                value={data.salesRepresentativeId}
                                onChange={(e) => {
                                    setData({...data, salesRepresentativeId: e});
                                }}
                                showSearch={true}
                                optionFilterProp={"children"}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    props.salesPersons.map((p, i) =>
                                        <Select.Option data-cy={`dropdown_sales_person_${i}`} key={p.id} value={p.id.toString()}>{p.name}</Select.Option>
                                    )
                                }
                            </AnchoredSelect>
                        </Form.Item>
                        <Divider />
                    </Col>
                </Row>



                <h4 className={styles.bold}>Personendaten</h4>

                <Row gutter={36}>
                    <Col span={12}>
                        <h4>Match Code</h4>
                        <Input data-cy="input_match_code" value={data.matchCode} onChange={(e) => {
                            setData({...data, matchCode: e.target.value})
                        }} />
                        <br />
                        <br />
                        <h4>Anrede</h4>
                        <AnchoredSelect data-cy={"input_contact_anrede"}
                            value={data?.salutation}
                            onChange={(e) => {
                                setData({...data, salutation: e})
                            }}
                        >
                            <Select.Option key={0} value={"Sehr geehrte Damen und Herren"}>{"Sehr geehrte Damen und Herren"}</Select.Option>
                            <Select.Option key={1} value={"Sehr geehrter Herr"}>{"Sehr geehrter Herr"}</Select.Option>
                            <Select.Option key={2} value={"Sehr geehrte Frau"}>{"Sehr geehrte Frau"}</Select.Option>
                        </AnchoredSelect>

                        <br />
                        <h4>Titel</h4>
                        <Form.Item>
                            <AnchoredSelect data-cy={"input_title"}
                                value={data?.title}
                                onChange={(e) => {
                                    setData({...data, title: e})
                                }}
                            >
                                <Select.Option key={0} value={"Herr"}>{"Herr"}</Select.Option>
                                <Select.Option key={1} value={"Frau"}>{"Frau"}</Select.Option>
                                <Select.Option key={2} value={"Erbengemeinschaft"}>{"Erbengemeinschaft"}</Select.Option>
                                <Select.Option key={3} value={"Firma"}>{"Firma"}</Select.Option>
                            </AnchoredSelect>
                        </Form.Item>
                        <h4>Vorname</h4>
                        <Form.Item>
                            <Input data-cy="input_contact_firstname" value={data.firstName}
                                disabled={!!props.userData?.userId}
                                onChange={(e) => {
                                    setData({...data, firstName: e.target.value})
                                }} />
                        </Form.Item>
                        <h4>Zusatz</h4>
                        <Input data-cy="input_contact_addon" value={data.addon}
                            disabled={!!props.userData?.userId}
                            onChange={(e) => {
                                setData({...data, addon: e.target.value})
                            }} />
                              <br />
                              <br />
                         <h4>Nachname</h4>
                        <Form.Item
                            validateStatus={ui.lastNameError ? "error" : ''}
                        >
                            <Input data-cy="input_contact_lastname" value={data.lastName}
                                disabled={!!props.userData?.userId}
                                onChange={(e) => {
                                    setData({...data, lastName: e.target.value})
                                }} />
                        </Form.Item>
                        <h4>Sprache</h4>
                        <AnchoredSelect data-cy="select_language"
                            value={data.contactLanguageId}
                            onChange={(value) => {
                                setData({...data, contactLanguageId: value});
                            }}
                            showSearch={true}
                            optionFilterProp={"children"}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                enabledCountries.map((c, i) => {
                                    return <Select.Option data-cy={`option_birth_country_${i}`} key={i} value={c.countryId}>{c.name}</Select.Option>
                                })
                            }
                        </AnchoredSelect>
                    </Col>
                    <Col span={12}>
                        <h4>Geburtsort</h4>
                        <Input data-cy="input_contact_street" value={data.birthCity} onChange={(e) => {
                            setData({...data, birthCity: e.target.value})
                        }} />
                        <br />
                        <br />
                        <h4>Geburtsdatum</h4>
                        <DatePicker data-cy={"picker_birthdate"}
                            format={DATE_FORMAT.DE}
                            showToday={false}
                            value={data.birthDate ? moment(data.birthDate, DATE_FORMAT.API) : null}
                            onChange={(e) => {
                                setData({...data, birthDate: e.format(DATE_FORMAT.API)})
                            }}
                        />
                        <br />
                        <br />
                        <h4>Geburtsland</h4>
                        <AnchoredSelect data-cy="select_birth_country"
                            value={data.birthCountryId}
                            onChange={(e) => {
                                setData({...data, birthCountryId: e});
                            }}
                            showSearch={true}
                            optionFilterProp={"children"}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                enabledCountries.map((c, i) => {
                                    return <Select.Option data-cy={`option_birth_country_${i}`} key={i} value={c.countryId}>{c.name}</Select.Option>
                                })
                            }
                        </AnchoredSelect>
                        <br />
                        <h4>Nationaliät</h4>
                        <AnchoredSelect data-cy="select_nationality"
                            value={data.nationalityCountryId}
                            onChange={(e) => {
                                setData({...data, nationalityCountryId: e});
                            }}
                            showSearch={true}
                            optionFilterProp={"children"}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                enabledCountries.map((c, i) => {
                                    return <Select.Option data-cy={`option_country_${i}`} key={i} value={c.countryId}>{c.name}</Select.Option>
                                })
                            }
                        </AnchoredSelect>
                        <br />
                        <h4>Geschlecht</h4>
                        <AnchoredSelect data-cy="select_gender"
                            value={data.gender}
                            onChange={(e) => {
                                setData({...data, gender: e});
                            }}
                        >
                            {[
                                <Select.Option data-cy={`option_gender_m`} key={1} value={"M"}>{"M"}</Select.Option>,
                                <Select.Option data-cy={`option_gender_f`} key={2} value={"F"}>{"F"}</Select.Option>,
                                <Select.Option data-cy={`option_gender_k`} key={3} value={"K"}>{"K"}</Select.Option>,
                            ]}
                        </AnchoredSelect>
                        <br />
                        <h4>Steuernummer</h4>
                        <Form.Item
                            validateStatus={ui.taxNumberError ? "error" : ''}
                        >
                            <Input data-cy="input_tax_number"
                                placeholder="xx/xxx/xxxxx"
                                value={data.taxNumber} onChange={(e) => {
                                    setData({...data, taxNumber: taxNumberAutoFormat(e.target.value)})
                                }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />

                <h4 className={styles.bold}>Adressdaten</h4>

                <Row gutter={36}>
                    <Col span={12}>
                        <h4>Adresse Suchen</h4>
                        <Form.Item
                            validateStatus={ui.addressLine1Error || ui.addressStreetNumberError || ui.addressPostalCodeError ||
                                ui.addressCityError  ? "error" : ''}
                        >
                            <AddressSearchInput 
                                onAddressSelected={(selectedAddress: SelectedAddress) => {
                                    setData({...data,
                                        addressStreetNumber: selectedAddress.addressStreetNumber,
                                        addressLine1: selectedAddress.addressLine1,
                                        addressPostalCode: selectedAddress.addressPostalCode,
                                        addressCity: selectedAddress.addressCity,
                                        addressCountryId: selectedAddress.addressCountryId
                                    })
                                }}
                            />
                        </Form.Item>
                        <Row gutter={12}>
                            <Col span={16}>
                                <h4>Strasse</h4>
                                <Form.Item
                                    // validateStatus={ui.addressLine1Error ? "error" : ''}
                                >
                                    <Input data-cy="input_contact_street" className={styles.shortInput} value={data.addressLine1}
                                    disabled={true}
                                    onChange={(e) => {
                                        setData({...data, addressLine1: e.target.value})
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <h4>Hausnummer</h4>
                                <Form.Item
                                    // validateStatus={ui.addressStreetNumberError ? "error" : ''}
                                >
                                    <Input data-cy="input_contact_street_num" className={styles.shortInput} value={data.addressStreetNumber}
                                    disabled={true} onChange={(e) => {
                                        setData({...data, addressStreetNumber: e.target.value})
                                    }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={8}>
                                <h4>PLZ</h4>
                                <Form.Item
                                    // validateStatus={ui.addressPostalCodeError ? "error" : ''}
                                >
                                    <Input data-cy="input_contact_postcode" className={styles.shortInput} value={data.addressPostalCode}
                                    disabled={true} onChange={(e) => {
                                        setData({...data, addressPostalCode: e.target.value})
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <h4>Stadt</h4>
                                <Form.Item
                                    // validateStatus={ui.addressCityError ? "error" : ''}
                                >
                                    <Input data-cy="input_contact_city" value={data.addressCity}
                                    disabled={true} onChange={(e) => {
                                        setData({...data, addressCity: e.target.value})
                                    }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <h4>Land</h4>
                        <AnchoredSelect data-cy="select_address_country"
                            value={data.addressCountryId}
                            onChange={(e) => {
                                setData({...data, addressCountryId: e});
                            }}
                            showSearch={true}
                            optionFilterProp={"children"}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                enabledCountries.map((c, i) => {
                                    return <Select.Option data-cy={`option_country_${i}`} key={i} value={c.countryId}>{c.name}</Select.Option>
                                })
                            }
                        </AnchoredSelect>

                    </Col>
                    <Col span={12}>
                        <h4>Telefon</h4>
                        <Input data-cy="input_contact_tel" value={data.oldschoolphoneNumber} onChange={(e) => {
                            setData({...data, oldschoolphoneNumber: e.target.value})
                        }} />
                        <br />
                        <br />
                        <h4>Mobilnummer</h4>
                        <Input data-cy="input_contact_mobile" value={data.mobileNumber} onChange={(e) => {
                            setData({...data, mobileNumber: e.target.value})
                        }} />
                           <br />
                           <br />
                                         <h4>Faxnummer</h4>
                        <Input data-cy="input_contact_fax" value={data.faxNumber} onChange={(e) => {
                            setData({...data, faxNumber: e.target.value})
                        }} />
                           <br />
                           <br />
                                     <h4>E-Mail</h4>
                        <Input data-cy="input_contact_email" value={data.email} onChange={(e) => {
                            setData({...data, email: e.target.value})
                        }} />
                    </Col>
                </Row>

                <Divider />

                {
                    !data.isPrivate ?
                        <h4 className={styles.bold}>Gewerbliches</h4>
                        : null
                }

                {
                    data.isPrivate ?
                        <>
                            <Row gutter={36}>
                                <Col span={12}>
                                    <h4 className={styles.bold}>Identifikation</h4>
                                </Col>
                                <Col span={12}>
                                    <Radio.Group data-cy="radio_doc_type"
                                        value={data.idDoc}
                                        onChange={(e) => {
                                            setData({...data, idDoc: e.target.value});
                                        }}
                                    >
                                        <Radio data-cy="option_id_card"
                                            value={DocumentType.IdCard}>
                                            Personalausweis
                                        </Radio>
                                        <Radio data-cy="option_passport"
                                            value={DocumentType.Passport}>
                                            Reisepass
                                        </Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <br />
                            <Row gutter={36}>
                                <Col span={12}>
                                    <h4>Am</h4>
                                    <DatePicker format={DATE_FORMAT.DE}
                                        value={data.idOn ? moment(data.idOn, DATE_FORMAT.API) : null}
                                        onChange={(e) => {
                                            setData({...data, idOn: e.format(DATE_FORMAT.API)})
                                        }}
                                    />
                                              <br />
                                              <br />
                                    <h4>Von</h4>
                                    <AnchoredSelect data-cy="select_id_by"
                                        value={data.idBy}
                                        onChange={(e) => {
                                            setData({...data, idBy: e});
                                        }}
                                        showSearch={true}
                                        optionFilterProp={"children"}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            idByTypes.map((c, i) => {
                                                return <Select.Option data-cy={`option_id_by_${i}`} key={i} value={c}>{c}</Select.Option>
                                            })
                                        }
                                    </AnchoredSelect>
                                </Col>
                                <Col span={12}>
                                    <h4>Nummer</h4>
                                    <Input data-cy="input_id_nr"
                                        value={data.idNumber}
                                        onChange={(e) => {
                                            setData({...data, idNumber: e.target.value});
                                        }}
                                    />
                                              <br />
                                              <br />
                                    <h4>Behörde</h4>
                                    <Input data-cy="input_id_issuer"
                                        value={data.idIssuedBy}
                                        onChange={(e) => {
                                            setData({...data, idIssuedBy: e.target.value});
                                        }}
                                    />
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row gutter={36}>
                                <Col span={12}>
                                    <h4>Firmenname</h4>
                                    <Input data-cy="input_company_register"
                                        value={data.companyName}
                                        disabled={!!props.userData?.userId}
                                        onChange={(e) => {
                                            setData({...data, companyName: e.target.value})
                                        }}
                                    />
                                    <br />
                                    <br />
                                    <h4>Handelsregisternummer</h4>
                                    <Input data-cy="input_company_register_number"
                                        value={data.companyRegisterNumber}
                                        onChange={(e) => {
                                            setData({...data, companyRegisterNumber: e.target.value})
                                        }}
                                    />
                                    <br />
                                    <br />
                                    <Space align={"baseline"} size={"middle"}>
                                        <h4>Steuer ID</h4>
                                        <Tooltip placement={"topLeft"} title={"xx/xxx/xxxxx"}>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </Space>
                                    <Input data-cy="input_tax_id"
                                        value={data.taxId}
                                        onChange={(e) => {
                                            const withoutSlashes = e.target.value?.split("/").join("")
                                            const withSlashes = formatTaxId(withoutSlashes)

                                            setData({...data, taxId: withSlashes})
                                        }}
                                    />
                                </Col>
                                <Col span={12}>
                                    <h4>Gründungsdatum</h4>
                                    <DatePicker format={DATE_FORMAT.DE}
                                        value={data.foundedOn ? moment(data.foundedOn, DATE_FORMAT.API) : null}
                                        showToday={false}
                                        onChange={(e) => {
                                            setData({...data, foundedOn: e.format(DATE_FORMAT.API)})
                                        }}
                                    />
                                    <br />
                                    <br />
                                    <h4>Umsatzsteuer ID</h4>
                                    <Input data-cy="input_tax_id"
                                        value={data.vatTaxId}
                                        onChange={(e) => {
                                            setData({...data, vatTaxId: e.target.value})
                                        }}
                                    />
                                    <br />
                                    <br />
                                    <h4>Branche</h4>
                                    <AnchoredSelect data-cy="dropdown_industry_type"
                                        value={data.industryTypeId}
                                        onChange={(e) => {
                                            setData({...data, industryTypeId: e});
                                        }}
                                        showSearch={true}
                                        optionFilterProp={"children"}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            enabledIndustryTypes.map((industryType, i) => {
                                                return <Select.Option key={i} data-cy={`option_${i}`} value={industryType.industryTypeId}>{industryType.description}</Select.Option>
                                            })
                                        }
                                    </AnchoredSelect>
                                </Col>
                            </Row>
                        </>
                }

                {
                    !props.userData ?
                        <>
                            <Divider />

                            <h4 className={styles.bold}>Bankverbindung</h4>
                            <Row gutter={36}>
                                <Col span={12}>
                                    <h4>Kontoinhaber</h4>
                                    <Input data-cy="input_account_owner"
                                        value={newBankConnection.accountOwner}
                                        onChange={(e) => {
                                            setNewBankConnection({...newBankConnection, accountOwner: e.target.value})
                                        }}
                                    />
                                    <br />
                                    <br />
                                    <h4 className={styles.marginTop35}>Kreditinstitut</h4>
                                    <AnchoredSelect data-cy={"dropdown_institutions"}
                                        value={newBankConnection.creditInstitutionId}
                                        onChange={(e) => {
                                            setNewBankConnection({...newBankConnection, creditInstitutionId: e})
                                        }}
                                        showSearch={true}
                                        optionFilterProp={"children"}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            enabledCreditInstitutions.map((ci, i) => {
                                                return <Select.Option data-cy={`dropdown_institutions_option_${i}`} key={i} value={ci.creditInstitutionId}>{`${ci.name} - ${ci.bic}`}</Select.Option>
                                            })
                                        }
                                    </AnchoredSelect>
                                </Col>
                                <Col span={12}>
                                    <div className={styles.bicIban}>
                                        <div>
                                            <h4>IBAN</h4>
                                            <Form.Item
                                        validateStatus={!!ui.ibanErrorMsg ? "error" : ''}
                                        help={ui.ibanErrorMsg}
                                    >
                                            <Input data-cy="iban"
                                                value={newBankConnection.iban}
                                                onChange={(e) => {
                                                    validateIban(e.target.value)
                                                    setNewBankConnection({...newBankConnection, iban: e.target.value})
                                                }}
                                            />
                                            {ui.bicLookupFailed ? <span className={styles.bicLookup}>Kein passendes Kreditinstitut gefunden. Überprüfen Sie die IBAN.</span>
                                                : <a onClick={onBicLookupClicked} className={styles.bicLookup}>Kreditinstitu und BIC automatisch suchen.</a>
                                            }
                                            </Form.Item>
                                        </div>
                                        <br />
                                        <h4>BIC</h4>
                                        <Input disabled={true}
                                            value={
                                                newBankConnection.creditInstitutionId ?
                                                    enabledCreditInstitutions.find(ci => ci.creditInstitutionId === newBankConnection.creditInstitutionId)?.bic
                                                    : ""
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                        : null
                }
                {/* </Form> */}
            </Modal>
        </div >
    );
};

const idByTypes = [
    'Selbstauskunft / Händler',
    'Selbstauskunft / Außendienst',
    'Ausweis / Händler',
    'Ausweis / Außendienst',
    'Ausweis / Innendienst',
    'Registerauszug',
    'Post-Ident',
    'Kreditinstitut',
    'VC',
    'Notar',
    'Altkunde / bereits identifiziert',
    'Vermittler'
]
