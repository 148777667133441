import * as React from "react";
import styles from "components/common/presenters/list-objects-acceptance/ListObjectsAcceptance.module.scss";
import { List, Card } from "antd";
import { CloudDownloadOutlined, FileOutlined, CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import classnames from "classnames";


export interface ListObjectsAcceptanceProps {
	objectsInGroups: {groupIndex: number; groupName: string; selected: boolean }[];
	onClick?: (e) => void;
}

/**
 * Used for lists with calculations, offers, documents.
 * @param props 
 */
export const ListObjectsAcceptance = (props: ListObjectsAcceptanceProps) => {


	return (
		<List
		grid={{ gutter: 8, column: 5 }}
		dataSource={props.objectsInGroups}
		renderItem={o => (
			<List.Item onClick={props.onClick ? () => props.onClick(o.selected ? {} : o) : () => {}}>
				<div className={classnames(styles.item, {[styles.selected]: o.selected})}>
					<div className={styles.textElement}>{o.groupName}</div>
					<div className={
						classnames(styles.icon, {
							[styles.iconGreen]: o.selected
						})
					}>{o.selected ? <CheckCircleFilled /> : <CheckCircleOutlined />}</div>
				</div>
			</List.Item>
		)}
	/>
	);
};
