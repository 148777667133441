export default {
	s3: {
		REGION: "eu-central-1",
		BUCKET: process.env.REACT_APP_AWS_S3_BUCKET_NAME
	},
	cognito: {
		REGION: "eu-central-1",
		USER_POOL_ID: process.env.REACT_APP_AWS_AUTH_USER_POOL_ID,
		APP_CLIENT_ID: process.env.REACT_APP_AWS_AUTH_USER_POOL_WEB_CLIENT_ID,
		IDENTITY_POOL_ID: process.env.REACT_APP_AWS_AUTH_IDENTITY_POOL_ID
	},
	api: {
		USER_SERVICE_URL: process.env.REACT_APP_USER_SERVICE_URL,
		LENDING_SERVICE_URL: process.env.REACT_APP_LENDING_SERVICE_URL,
		URL_BASE: process.env.REACT_APP_API_URL_BASE,
        AKF_INTEGRATION_API: process.env.REACT_APP_LENDING_SERVICE_URL
	},
	hideFeatures: (process.env.REACT_APP_HIDE_FEATURES && process.env.REACT_APP_HIDE_FEATURES === 'true'),
	isProduction: (process.env.REACT_APP_IS_PRODUCTION && process.env.REACT_APP_IS_PRODUCTION === 'true'),
	nodeEnv: process.env.NODE_ENV,
	companySearchEnabled: (process.env.REACT_APP_COMPANY_SEARCH_ENABLED && process.env.REACT_APP_COMPANY_SEARCH_ENABLED === 'true'),
	landingContactFormUrl: process.env.REACT_APP_LANDING_CONTACT_FORM_URL,
	supportOverlayContactFormUrl: process.env.REACT_APP_SUPPORT_OVERLAY_CONTACT_FORM_URL,
	reactAppVersion: process.env.REACT_APP_VERSION,
	termsAndConditionsLink: process.env.REACT_APP_LINK_TERMS_AND_CONDITIONS,
	privacyPolicyLink: process.env.REACT_APP_LINK_PRIVACY_POLICY,
	applicationFlowAbTestEnabled: (process.env.REACT_APP_APPLICATION_FLOW_A_B_TEST_ENABLED && process.env.REACT_APP_APPLICATION_FLOW_A_B_TEST_ENABLED === 'true'),
	aboutUsPageEnabled: (process.env.REACT_APP_ABOUT_US_PAGE_ENABLED && process.env.REACT_APP_ABOUT_US_PAGE_ENABLED === 'true'),
	oldFlowProbability: process.env.REACT_APP_OLD_FLOW_PROBABILITY,
	newFlowProbability: process.env.REACT_APP_NEW_FLOW_PROBABILITY,
	reorderedFlowProbability: process.env.REACT_APP_REORDERED_FLOW_PROBABILITY
};
