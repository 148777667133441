import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Col, DatePicker, Form, Input, List, message, Radio, Row, Select} from 'antd';
import styles from "./ObjectsTab.module.scss";
import Tabs from "antd/lib/tabs";
import "./ObjectsTab.scss";
import ApiManager from "services/api/ApiManager";
import moment from "moment";
import {DATE_FORMAT} from "services/api/Globals";
import {ObjectData, ObjectType, UserType} from "services/api/types";
import {Store, useStore} from "../../../../../hooks/Store";
import {LocalizedNumberInput} from "components/common/presenters/localized-number-input/LocalizedNumberInput";
import {useTranslation} from "react-i18next";
import {AnchoredSelect} from "../../anchored-select/AnchoredSelect";
import {areFieldsInvalid} from "components/common/presenters/user-modal/fieldsValidation";
import {SearchPeopleModal} from "components/common/presenters/application-page-tabs/objects-tab/SearchPeopleModal";

const {TabPane} = Tabs;

export interface ObjectsTabProps {
    objects: ObjectData[];
    applicationInformation: any;
    objectActiveKey: string;
    onDataUpdated: () => void;
}

interface SmallObject {
    objectId?: number,
    objectName: string;
    description: string;
    price: number;
    serialNumber: string;
    payoutAmount: number;
    lieferantId: number;
    lieferantPhrase: string;
}

export interface ObjectsTabState {
    selectedObjectIndex: number;
    objectId: number;
    objectName: string;
    description: string;
    objectTypeId: number;
    serialNumber: string;
    price: number;
    payoutAmount: number;
    lieferantId: number;
    lieferantPhrase: string;
    deliveryDate: string;
    manufacturer: string;
    features: string;
    owner: string;
    yearBuilt: string;
    firstRegistration: string;
    machineType: string;
    smallObjects: SmallObject[];
}

export const ObjectsTab = (props: ObjectsTabProps) => {
    const store: Store = useStore()
    const {t} = useTranslation();

    const objectsInitialState = {
        selectedObjectIndex: 1,
        objectId: undefined,
        objectName: '',
        description: '',
        objectTypeId: null,
        serialNumber: '',
        price: 0,
        payoutAmount: 0,
        lieferantId: null,
        lieferantPhrase: null,
        deliveryDate: null,
        manufacturer: '',
        features: '',
        owner: '',
        yearBuilt: null,
        firstRegistration: null,
        machineType: '',
        smallObjects: []
    };

    const validationFields = {
        objectNameError: false,
        smallObjectsError: [{objectNameError: false}, {objectNameError: false}, {objectNameError: false}, {objectNameError: false}],
        searchPeopleModalOpen: false
    };
    const [ui, setUi] = useState({...validationFields});

    const [lieferantSearchTerm, setLieferantSearchTerm] = useState("")
    const [lieferantSearchResult, setLieferantSearchResult] = useState([])

    const [data, setData] = useState({
        ...objectsInitialState
    } as ObjectsTabState);

    useEffect(() => {
        const mainObject = props.objects.find(o => o.type === ObjectType.MAIN) as any || objectsInitialState;
        const smallObjects = props.objects.filter(o => o.type === ObjectType.CHILD).sort((o1, o2) => o1.objectId < o2.objectId ? -1 : 1) as any[] || [];
        setData({...{...objectsInitialState, selectedObjectIndex: data.selectedObjectIndex}, ...mainObject, smallObjects: smallObjects})
    }, [props.objects]);

    const userId = props.applicationInformation.userId;
    const applicationId = props.applicationInformation.applicationId;

    const enabledObjectTypes = store.data?.settings?.objectGroups?.filter(o => o.isEnabled) || []

    // const fetchLieferantsByUserIds = async () => {
    //     try {
    //         const userIds = [];
    //         if(data.lieferantId) {
    //             userIds.push(data.lieferantId);
    //         }
    //         data.smallObjects.forEach(k => {
    //             if(k.lieferantId) {
    //                 userIds.push(k.lieferantId)
    //             }
    //         })
    //         if(userIds) {
    //             const usersResult = await ApiManager.UserService.getUsers(1, [], lieferantSearchTerm, null, userIds);
    //             setLieferantSearchResult(usersResult.data)
    //         }

    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // useEffect(() => {
    //     if (lieferantSearchTerm != null) {
    //         fetchLieferantsByUserIds()
    //     }
    // }, [lieferantSearchTerm])

    async function updateObject(index) {
        try {
            if (isInvalid(index)) {
                message.error('Bitte füllen Sie alle notwendigen Felder aus', 2)
                return;
            }

            const request = {
                objectId: data.objectId,
                objectName: data.objectName,
                description: data.description,
                objectTypeId: data.objectTypeId,
                serialNumber: data.serialNumber,
                price: data.price,
                payoutAmount: data.payoutAmount,
                lieferantId: data.lieferantId,
                deliveryDate: data.deliveryDate,
                manufacturer: data.manufacturer,
                features: data.features,
                owner: data.owner,
                firstRegistration: data.firstRegistration,
                machineType: data.machineType,
                smallObjects: data.smallObjects.map(s => {
                    return {
                        applicationId: applicationId,
                        userId: userId,
                        objectId: s.objectId,
                        objectName: s.objectName || "",
                        description: s.description,
                        price: s.price,
                        serialNumber: s.serialNumber,
                        payoutAmount: s.payoutAmount,
                        lieferantId: s.lieferantId
                    }
                }),
                userId,
                applicationId,
                yearBuilt: data.yearBuilt ? (data.yearBuilt.length === 4 ? `${data.yearBuilt}-01-01` : data.yearBuilt) : null
            };
            if (data.objectId) {
                await ApiManager.ApplicationService.editObject(request, data.objectId);
            } else {
                await ApiManager.ApplicationService.createObject(request);
            }
            message.success('Daten Gespeichert', 2)
            props.onDataUpdated();
        } catch (error) {
            console.error(error);
            const errorText = error?.response?.data?.errors?.join(' ');
            message.error('Konnte nicht Speichern' + ' ' + errorText, 2)
        }
    }

    const isInvalid = (objectIndex): boolean => {
        if(objectIndex == 0) {
            const validationData = areFieldsInvalid(data,
                ["objectName"]) as typeof validationFields;
            validationData.smallObjectsError = [];

            setUi({...ui, ...validationData});

            return Object.values(validationData).some(e => e === true);
        }


        const sO = data.smallObjects[objectIndex];

        var validationResult = {smallObjectsError: []};
        if(sO) {

            if (!sO.objectName) {
                validationResult.smallObjectsError[objectIndex] = {objectNameError: true};
            }
            setUi({...ui, ...validationResult});
            return validationResult.smallObjectsError.length > 0
        }

        setUi({...ui, ...validationResult});
        return false;     
    }

    async function deleteObject(index) {
        try {
            const o = data.smallObjects[index];
            setData({...data, selectedObjectIndex: data.smallObjects.length, smallObjects: data.smallObjects.filter((e, i) => i != index)})
            if (o && o.objectId) {
                await ApiManager.ApplicationService.deleteObject(o.objectId);
            }
            message.success('Daten gelöscht', 2)
            props.onDataUpdated();
        } catch (error) {
            console.error(error);
            const errorText = error?.response?.data?.errors?.join(' ');
            message.error('Konnte nicht gelöscht werden' + ' ' + errorText, 2)
        }

    }

    return (
        <div className={styles.objectsTab}>
            <Row>
                <Col span={24}>
                    <h2 style={{textAlign: 'left'}}>Objekte</h2>
                </Col>
                <Col xs={{span: 4}} sm={{span: 4}} span={3}>
                    <div>
                        <h4>Erstellte objekte</h4>
                    </div>
                    <List grid={{gutter: 8, column: 1}}
                        dataSource={[data, ...data.smallObjects]}
                        renderItem={(o, i) => (
                            <List.Item>
                                <Button type={data.selectedObjectIndex === i + 1 ? "primary" : "default"}
                                    onClick={() => setData({...data, selectedObjectIndex: i + 1})}
                                >Objekt {o.objectName ? `${o.objectName.substring(0, 10)}...` : i + 1}</Button>
                            </List.Item>
                        )}
                    />
                    <div>
                        <Button type="primary"
                            disabled={data.smallObjects?.length === 4}
                            onClick={() => {
                                setData({...data, smallObjects: [...data.smallObjects, {} as SmallObject], selectedObjectIndex: data.smallObjects.length + 2})
                            }}>
                            + Objekt</Button>
                    </div>
                </Col>
                <Col xs={{span: 20}} sm={{span: 20}} span={21}>
                    <div className={styles.tab}>
                        <h4>Objektinformationen</h4>
                        <div className={styles.tabInner}>
                            {data.selectedObjectIndex === 1 ? <>
                                <div className={styles.objectTitle}>Objekt 1</div>
                                <h4>Objektbezechnung </h4>
                                <Form.Item
                                    validateStatus={ui.objectNameError ? "error" : ''}
                                >
                                    <Input data-cy="input_object_name"
                                        value={data.objectName}
                                        onChange={(e) => {
                                            setData({...data, objectName: e.target.value})
                                        }}
                                    />
                                </Form.Item>
                                <br />
                                <br />

                                <Row gutter={[24, 24]} justify={'space-between'}>
                                    <Col span={7}>
                                        <h4>Objekttyp</h4>
                                        <AnchoredSelect data-cy="dropdown_object_type"
                                            value={data.objectTypeId}
                                            onChange={(e) => setData({...data, objectTypeId: e, })}
                                            showSearch={true}
                                            optionFilterProp={"children"}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                enabledObjectTypes.map((ot, i) =>
                                                    <Select.Option data-cy={`dropdown_object_type_option_${i}`} key={i} value={ot.objectTypeId}>{ot.description}</Select.Option>
                                                )
                                            }
                                        </AnchoredSelect>
                                    </Col>
                                    <Col span={7}>
                                        <h4>Seriennummer </h4>
                                        <Input data-cy="input_serial_number"
                                            value={data.serialNumber}
                                            onChange={(e) => {
                                                setData({...data, serialNumber: e.target.value})
                                            }} />
                                    </Col>
                                    <Col span={10}>
                                        <h4>Nettokaufpreis </h4>
                                        <Form.Item
                                            validateStatus={data.price ? (data.price === parseFloat(data.price?.toString()) ? '' : "error") : ''}
                                        >
                                            <LocalizedNumberInput
                                                className={styles.shortInput}
                                                data-cy="input_price"
                                                prefix={t('currency_sign')}
                                                value={data.price || 0}
                                                precision={2}
                                                onChange={(e) => {
                                                    setData({...data, price: e as number})
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]} justify={'space-between'}>
                                    <Col span={8}>
                                        <h4>Lieferdatum </h4>
                                        <DatePicker data-cy="picker_delivery_date"
                                            className={styles.shortInput}
                                            format={DATE_FORMAT.DE}
                                            value={data.deliveryDate ? moment(data.deliveryDate, DATE_FORMAT.API) : null}
                                            showToday={false}
                                            allowClear={true}
                                            onChange={(e) => {
                                                setData({...data, deliveryDate: e?.format(DATE_FORMAT.API)})
                                            }}
                                        />
                                    </Col>
                                    <Col span={7}>
                                        <h4>Hersteller</h4>
                                        <Input data-cy="input_manufacturer"
                                            className={styles.shortInput}
                                            value={data.manufacturer}
                                            onChange={(e) => {
                                                setData({...data, manufacturer: e.target.value})
                                            }}></Input>
                                    </Col>
                                    <Col span={9}>
                                        <h4>Auszahlungsbetrag</h4>
                                        <Form.Item
                                            validateStatus={data.payoutAmount ? (data.payoutAmount === parseFloat(data.payoutAmount?.toString()) ? '' : "error") : ''}
                                        >
                                            <LocalizedNumberInput
                                                className={styles.shortInput}
                                                data-cy="input_payout_amount"
                                                prefix={t('currency_sign')}
                                                value={data.payoutAmount || 0}
                                                precision={2}
                                                onChange={(e) => {
                                                    setData({...data, payoutAmount: e as number})
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]}>
                                    <Col xs={{span: 16}} sm={{span: 16}} span={12}>
                                        <h4>Ausstattung </h4>
                                        <Input.TextArea data-cy="input_features"
                                            rows={4}
                                            value={data.features}
                                            onChange={(e) => {
                                                setData({...data, features: e.target.value})
                                            }}></Input.TextArea>
                                    </Col>
                                    <Col xs={{span: 8}} sm={{span: 8}} span={12}>
                                        <h4>Lieferant </h4>
                                        <Input data-cy="input_manufacturer"
                                            className={styles.shortInput}
                                            value={data.lieferantPhrase}
                                            onClick={() => {
                                                setUi({...ui, searchPeopleModalOpen: true})
                                            }}></Input>
                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]}>
                                    <Col span={7}>
                                        <h4>Halter </h4>
                                        <Input data-cy="input_owner"
                                            className={styles.shortInput}
                                            value={data.owner}
                                            onChange={(e) => {
                                                setData({...data, owner: e.target.value})
                                            }}></Input>
                                    </Col>
                                    <Col span={7}>
                                        <h4>Baujahr </h4>
                                        <Form.Item
                                            validateStatus={data.yearBuilt ? (moment(data.yearBuilt?.substr(0, 4), 'YYYY', true).isValid() ? '' : "error") : ''}
                                        >
                                            <Input data-cy="input_year_built"
                                                className={styles.shortInput}
                                                value={data.yearBuilt?.substr(0, 4)}
                                                placeholder="YYYY"
                                                onChange={(e) => {
                                                    setData({...data, yearBuilt: e.target.value.substr(0, 4)})
                                                }}>
                                            </Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{span: 5}} sm={{span: 5}} span={10}>
                                        <h4>Erstzulassung</h4>
                                        <DatePicker data-cy="picker_first_registration"
                                            className={styles.shortInput}
                                            format={DATE_FORMAT.DE}
                                            value={data.firstRegistration ? moment(data.firstRegistration, DATE_FORMAT.API) : null}
                                            showToday={false}
                                            allowClear={true}
                                            onChange={(e) => {
                                                setData({...data, firstRegistration: e?.format(DATE_FORMAT.API)})
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]} justify={'space-between'}>
                                    <Col xs={{span: 24}} sm={{span: 24}} span={14}>
                                        <h4>Fahrzeugtyp </h4>
                                        <Radio.Group onChange={(e) => {
                                            setData({...data, machineType: e.target.value})
                                        }} value={data.machineType} >
                                            {machineTypesRadios()}
                                        </Radio.Group>
                                    </Col>
                                    <Col style={{marginTop: 'auto'}} offset={14} span={10}>
                                        <div className={styles.buttonsGroup} >
                                            <Button data-cy="button_save"
                                                style={{marginLeft: 'auto', display: 'block'}}
                                                type="primary"
                                                size="small"
                                                htmlType="submit"
                                                onClick={() => setData({...objectsInitialState, objectId: data.objectId })}>
                                                Zurücksetzen
							</Button>
                                            <Button data-cy="button_save"
                                                style={{marginLeft: '20px', display: 'block'}}
                                                type="primary"
                                                size="small"
                                                htmlType="submit"
                                                onClick={updateObject}>
                                                Speichern
							</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </> : null}

                            {data.smallObjects.map((sO, i) =>
                                data.selectedObjectIndex === i + 2 ? <React.Fragment>
                                    <div className={styles.objectTitle}>Objekt {i + 2}</div>
                                    <Row gutter={[24, 24]}>
                                        <Col span={12}>
                                            <h4>Objektbezechnung </h4>
                                            <Form.Item
                                                validateStatus={ui.smallObjectsError[i]?.objectNameError ? "error" : ''}
                                            >
                                                <Input data-cy="input_object_name"
                                                    value={sO.objectName}
                                                    onChange={(e) => {
                                                        setData({...data, smallObjects: data.smallObjects.map((o, j) => {if (i === j) {return {...o, objectName: e.target.value}} return {...o}})})
                                                    }}></Input>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <h4>Nettokaufpreis </h4>
                                            <Form.Item
                                                validateStatus={sO.price ? (sO.price === parseFloat(sO.price?.toString()) ? '' : "error") : ''}
                                            >
                                                <LocalizedNumberInput
                                                    className={styles.shortInput}
                                                    data-cy="input_price"
                                                    prefix={t('currency_sign')}
                                                    value={sO.price || 0}
                                                    precision={2}
                                                    onChange={(e) => {
                                                        setData({...data, smallObjects: data.smallObjects.map((o, j) => {if (i === j) {return {...o, price: e as number}} return {...o}})})
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 24]}>
                                        <Col span={12}>
                                            <h4>Seriennummer </h4>
                                            <Input data-cy="input_serial_number"
                                                value={sO.serialNumber}
                                                onChange={(e) => {
                                                    setData({...data, smallObjects: data.smallObjects.map((o, j) => {if (i === j) {return {...o, serialNumber: e.target.value}} return {...o}})})
                                                }}></Input>
                                        </Col>
                                        <Col span={12}>
                                            <h4>Auszahlungsbetrag </h4>
                                            <Form.Item
                                                validateStatus={sO.payoutAmount ? (sO.payoutAmount === parseFloat(sO.payoutAmount?.toString()) ? '' : "error") : ''}
                                            >
                                                <LocalizedNumberInput
                                                    className={styles.shortInput}
                                                    data-cy="input_payout_amount"
                                                    prefix={t('currency_sign')}
                                                    value={sO.payoutAmount || 0}
                                                    precision={2}
                                                    onChange={(e) => {
                                                        setData({...data, smallObjects: data.smallObjects.map((o, j) => {if (i === j) {return {...o, payoutAmount: e as number}} return {...o}})})
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <h4>Lieferant </h4>
                                    <Input data-cy="input_manufacturer"
                                            className={styles.shortInput}
                                            value={sO.lieferantPhrase}
                                            onClick={() => {
                                                setUi({...ui, searchPeopleModalOpen: true})
                                            }}></Input>

                                    <Row gutter={[24, 24]} justify={'space-between'}>
                                        <Col offset={16} span={8}>
                                            <div className={styles.buttonsGroup} >
                                                <Button data-cy="button_save"
                                                    style={{marginLeft: '20px', display: 'block'}}
                                                    type="primary"
                                                    size="small"
                                                    htmlType="submit"
                                                    onClick={() => {
                                                        deleteObject(i)
                                                    }}>
                                                    Löschen
												</Button>
                                                <Button data-cy="button_save"
                                                    style={{marginLeft: '20px', display: 'block'}}
                                                    type="primary"
                                                    size="small"
                                                    htmlType="submit"
                                                    onClick={() => {
                                                        updateObject(i)
                                                    }}>
                                                    Speichern
							        </Button>
                                            </div>
                                        </Col>

                                    </Row>


                                </React.Fragment> : null)
                            }
                            {/* <hr />
                        <Row gutter={[24, 24]} justify={'space-between'}>
                            <Col span={4}>
                                <Button data-cy="button_save"
                                    style={{marginLeft: '20px', display: 'block'}}
                                    type="primary"
                                    size="small"
                                    htmlType="submit"
                                    disabled={data.smallObjects?.length === 4}
                                    onClick={() => {
                                        setData({...data, smallObjects: [...data.smallObjects, {} as SmallObject]})
                                    }}>
                                    + Objekt
							</Button>
                            </Col>
                        </Row> */}
                        </div>
                    </div>
                </Col>
            </Row>
            <SearchPeopleModal 
            	isOpen={ui.searchPeopleModalOpen}
                onOk={(row) => {
                    const phrase = `${row.firstName || ""} ${row.lastName || ""}`;
                    if(data.selectedObjectIndex>1) {
                        const newObjects = data.smallObjects.map((o, j) => {if (data.selectedObjectIndex - 2 === j) {return {...o, lieferantId: row.userId,
                            lieferantPhrase: phrase }} return {...o}});
                        setData({...data,
                             smallObjects: newObjects})
                    } else {
                        setData({...data, lieferantId: row.userId, lieferantPhrase: phrase})
                    }
                    setLieferantSearchTerm(phrase)
     
                     setUi({...ui, searchPeopleModalOpen: false })

                }}
                onCancel={() => {
                    setLieferantSearchTerm(null)
                    setUi({...ui, searchPeopleModalOpen: false })
                }}
            />
        </div>
    );
};

export const manufacturerOptions = () => {
    return Object.keys(manufacturers).map((k, i) =>
        <Select.Option key={i} data-cy={`manufacturer_option_${i}`} value={k}>{manufacturers[k]}</Select.Option>
    )
}

const machineTypesRadios = () => {
    return Object.keys(machineTypes).map((k, i) =>
        <Radio value={k}>{machineTypes[k]}</Radio>
    )
}


export const machineTypesOptions = () => {
    return Object.keys(machineTypes).map((k, i) =>
        <Select.Option data-cy={`machine_types_option_${i}`} key={i} value={machineTypes[k]}>{machineTypes[k]}</Select.Option>
    )
}

const manufacturers = {
    'johndeere': 'John Deere',
    'newholland': 'New Holland'
};

const machineTypes = {
    'Neu': 'Neu',
    'Gebraucht': 'Gebraucht',
    'Vorführ': 'Vorführ',
    'Jahres': 'Jahres',
    'Liebhaber': 'Liebhaber'
}

const objectGroups = [
    {value: 'backofen', label: 'Backöfen', objectCurve: 8},
    {value: 'baugewerbliche_ausrustung', label: 'Baugewerbliche Ausrüstung', objectCurve: 4},
    {value: 'baumaschinen_baufahrzeuge', label: 'Baumaschinen / Baufahrzeuge', objectCurve: 3},
    {value: 'druckereimaschinen', label: 'Druckereimaschinen', objectCurve: 3},
    {value: 'entsorgungs_recyclingfahrzeuge', label: 'Entsorgungs-/Recyclingfahrzeuge', objectCurve: 3},
    {value: 'fotokopierer', label: 'Fotokopierer', objectCurve: 8},
    {value: 'gabelstapler', label: 'Gabelstapler', objectCurve: 3},
    {value: 'getranke_kaffeeautomaten', label: 'Getränke-/Kaffeeautomaten', objectCurve: 7},
    {value: 'gretag_filmentwicklungsgerate', label: 'Gretag Filmentwicklungsgeräte', objectCurve: 5},
    {value: 'großrechner', label: 'Großrechner', objectCurve: 8},
    {value: 'kleinbus_transporter', label: 'Kleinbus / Transporter', objectCurve: 4},
    {value: 'kodak_filmentwicklungsgerat', label: 'Kodak Filmentwicklungsgerät', objectCurve: 5},
    {value: 'kunststoffmaschinen', label: 'Kunststoffmaschinen', objectCurve: 3},
    {value: 'ladeneinrichtungen', label: 'Ladeneinrichtungen', objectCurve: 8},
    {value: 'landwirtschaftliche_maschinen', label: 'Landwirtschaftliche Maschinen', objectCurve: 3},
    {value: 'lkw', label: 'LKW', objectCurve: 4},
    {value: 'lkw_aufbau_hanger_auflieger', label: 'LKW-Aufbau/Hänger/Auflieger', objectCurve: 3},
    {value: 'medizinische_gerate', label: 'Medizinische Geräte', objectCurve: 4},
    {value: 'motorboot_binnen', label: 'Motorboot Binnen', objectCurve: 2},
    {value: 'motorboot_hochsee', label: 'Motorboot Hochsee', objectCurve: 2},
    {value: 'motorboot_kuste', label: 'Motorboot Küste', objectCurve: 2},
    {value: 'motorrader', label: 'Motorräder', objectCurve: 4},
    {value: 'noritsu_filmentwicklungsgerate', label: 'Noritsu Filmentwicklungsgeräte', objectCurve: 2},
    {value: 'pc', label: 'PC', objectCurve: 8},
    {value: 'pkw_alfa_romeo', label: 'PKW Alfa Romeo', objectCurve: 4},
    {value: 'pkw_aston_martin', label: 'PKW Aston Martin', objectCurve: 4},
    {value: 'pkw_audi', label: 'PKW Audi', objectCurve: 4},
    {value: 'pkw_bmw', label: 'PKW BMW', objectCurve: 4},
    {value: 'pkw_chevrolet', label: 'PKW Chevrolet', objectCurve: 4},
    {value: 'pkw_chrysler', label: 'PKW Chrysler', objectCurve: 5},
    {value: 'pkw_citroen', label: 'PKW Citroen', objectCurve: 6},
    {value: 'pkw_daewoo', label: 'PKW Daewoo', objectCurve: 4},
    {value: 'pkw_daihatsu', label: 'PKW Daihatsu', objectCurve: 4},
    {value: 'pkw_ferrari', label: 'PKW Ferrari', objectCurve: 3},
    {value: 'pkw_fiat', label: 'PKW Fiat', objectCurve: 5},
    {value: 'pkw_ford', label: 'PKW Ford', objectCurve: 5},
    {value: 'pkw_honda', label: 'PKW Honda', objectCurve: 4},
    {value: 'pkw_hyundai', label: 'PKW Hyundai', objectCurve: 6},
    {value: 'pkw_isuzu', label: 'PKW Isuzu', objectCurve: 4},
    {value: 'pkw_jaguar', label: 'PKW Jaguar', objectCurve: 4},
    {value: 'pkw_jeep', label: 'PKW Jeep', objectCurve: 5},
    {value: 'pkw_kia', label: 'PKW Kia', objectCurve: 5},
    {value: 'pkw_lada', label: 'PKW Lada', objectCurve: 4},
    {value: 'pkw_lancia', label: 'PKW Lancia', objectCurve: 4},
    {value: 'pkw_lexus', label: 'PKW Lexus', objectCurve: 4},
    {value: 'pkw_mazda', label: 'PKW Mazda', objectCurve: 4},
    {value: 'pkw_mercedes', label: 'PKW Mercedes', objectCurve: 4},
    {value: 'pkw_mitsubishi', label: 'PKW Mitsubishi', objectCurve: 5},
    {value: 'pkw_nissan', label: 'PKW Nissan', objectCurve: 4},
    {value: 'pkw_opel', label: 'PKW Opel', objectCurve: 5},
    {value: 'pkw_peugeot', label: 'PKW Peugeot', objectCurve: 4},
    {value: 'pkw_pontiac', label: 'PKW Pontiac', objectCurve: 4},
    {value: 'pkw_porsche', label: 'PKW Porsche', objectCurve: 3},
    {value: 'pkw_renault', label: 'PKW Renault', objectCurve: 4},
    {value: 'pkw_rover', label: 'PKW Rover', objectCurve: 3},
    {value: 'pkw_saab', label: 'PKW Saab', objectCurve: 4},
    {value: 'pkw_seat', label: 'PKW Seat', objectCurve: 4},
    {value: 'pkw_skoda', label: 'PKW Skoda', objectCurve: 4},
    {value: 'pkw_smart', label: 'PKW Smart', objectCurve: 4},
    {value: 'pkw_sportwagen_sonst_fabrikate', label: 'PKW Sportwagen sonst.Fabrikate', objectCurve: 4},
    {value: 'pkw_subaru', label: 'PKW Subaru', objectCurve: 4},
    {value: 'pkw_suzuki', label: 'PKW Suzuki', objectCurve: 4},
    {value: 'pkw_toyota', label: 'PKW Toyota', objectCurve: 4},
    {value: 'pkw_us_fabrikate', label: 'PKW US Fabrikate', objectCurve: 4},
    {value: 'pkw_volvo', label: 'PKW Volvo', objectCurve: 4},
    {value: 'pkw_vw', label: 'PKW VW', objectCurve: 3},
    {value: 'produktionsmaschinen', label: 'Produktionsmaschinen', objectCurve: 5},
    {value: 'recyclingmaschinen', label: 'Recyclingmaschinen', objectCurve: 8},
    {value: 'san_marco_filmentwicklungsgerate', label: 'San Marco Filmentwicklungsgeräte', objectCurve: 5},
    {value: 'schlusselfrasmaschinen', label: 'Schlüsselfräsmaschinen', objectCurve: 4},
    {value: 'segelboote', label: 'Segelboote', objectCurve: 2},
    {value: 'sonstige_agrar', label: 'Sonstige Agrar', objectCurve: 3},
    {value: 'sonstige_bga', label: 'Sonstige BGA', objectCurve: 7},
    {value: 'sonstige_buromaschinen', label: 'Sonstige Büromaschinen', objectCurve: 8},
    {value: 'sonstige_edv', label: 'Sonstige EDV', objectCurve: 8},
    {value: 'sonstige_fahrzeuge', label: 'Sonstige Fahrzeuge', objectCurve: 3},
    {value: 'sonstige_fertigungsmaschinen', label: 'Sonstige Fertigungsmaschinen', objectCurve: 5},
    {value: 'sonstige_filmentwicklungsgerate', label: 'Sonstige Filmentwicklungsgeräte', objectCurve: 5},
    {value: 'sonstige_finanzierungen', label: 'Sonstige Finanzierungen', objectCurve: 9},
    {value: 'sonstige_landw_mobile_objekte', label: 'Sonstige landw. mobile Objekte', objectCurve: 3},
    {value: 'sonstige_medizin_labortechnik', label: 'Sonstige Medizin-/Labortechnik', objectCurve: 5},
    {value: 'telefaxgerate', label: 'Telefaxgeräte', objectCurve: 8},
    {value: 'telefonanlagen', label: 'Telefonanlagen', objectCurve: 8},
    {value: 'traktoren_schlepper', label: 'Traktoren/Schlepper', objectCurve: 3},
    {value: 'warensicherungsanlagen', label: 'Warensicherungsanlagen', objectCurve: 7},
    {value: 'werkzeugmaschinen', label: 'Werkzeugmaschinen', objectCurve: 5},
    {value: 'wohnmobile_wohnwagen', label: 'Wohnmobile/Wohnwagen', objectCurve: 3},
]
