import * as React from "react";
import styles from "components/common/presenters/list-item/ListItem.module.scss";
import classnames from "classnames";
import {CalculatorOutlined, CheckCircleFilled, CheckCircleOutlined, CopyOutlined, DeleteOutlined, DownloadOutlined, EyeOutlined, FileOutlined, FilePdfOutlined, FileTextOutlined, LockOutlined} from "@ant-design/icons";

export enum ListItemTypes {
    Calculation,
    CalculationCopiable,
    CalculationDeletable,
    CalculationCopiableDeletable,
    CalculationLocked,
    Offer,
    SelectOffer,
    GeneratedOffer,
    ContractTemplate,
    Contract,
    ContractSigned,
    ContractSigning,
    Document,
    Marktvotum,
    UserDocumentsNotDeletable,
    ObjectsInCalculation,
    UserObjects,
    SelfDeclaration,
}

export interface ListItemProps {
    type: ListItemTypes;
    textNextToIcons?: string;
    text?: string;
    htmlText?: string;
    selected?: boolean;
    selectedDark?: boolean;
    className?: string;
    onClick?: (e) => void;
    onLastIconClicked?: (e) => void;
    onNextToLastIconClicked?: (e) => void;
    dataCy?: string;
    dataCyLastIcon?: string;
    dataCyNextToLastIcon?: string;
}

/**
 * Used for lists with calculations, offers, documents.
 * @param props 
 */
export const ListItem = (props: ListItemProps) => {

    const renderLeftIcon = () => {
        if (props.type === ListItemTypes.Calculation ||
            props.type === ListItemTypes.CalculationCopiable ||
            props.type === ListItemTypes.CalculationDeletable ||
            props.type === ListItemTypes.CalculationCopiableDeletable) {

            return <CalculatorOutlined />;
        }

        if (props.type === ListItemTypes.GeneratedOffer) {
            return null;
        }

        if (props.type === ListItemTypes.Offer || props.type === ListItemTypes.ContractTemplate) {
            return <FilePdfOutlined />;
        }

        if (props.type === ListItemTypes.Contract || props.type === ListItemTypes.ContractSigned) {
            return <FileTextOutlined />;
        }

        if (props.type === ListItemTypes.Document) {
            return <FileOutlined />;
        }

        if (props.type === ListItemTypes.Marktvotum) {
            return <FilePdfOutlined />;
        }

        if (props.type === ListItemTypes.SelfDeclaration) {
            return <FileOutlined />;
        }

        return null;
    };

    const renderRightIcons = () => {
        if (props.type === ListItemTypes.UserDocumentsNotDeletable) {
            return (
                <div className={styles.iconsRight}>
                    <div className={styles.icon}>
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.icon} onClick={props.onLastIconClicked}>
                        <EyeOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                    </div>
                </div>
            )
        }
        if (props.type === ListItemTypes.Document) {
            return (
                <div className={styles.iconsRight}>
                    <div className={styles.icon} onClick={props.onNextToLastIconClicked}>
                        <EyeOutlined data-cy={props.dataCyNextToLastIcon ? props.dataCyNextToLastIcon : ''} />
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.icon} onClick={props.onLastIconClicked}>
                        <DeleteOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                    </div>
                </div>
            )
        }
        if (props.type === ListItemTypes.Calculation ||
            props.type === ListItemTypes.CalculationCopiable ||
            props.type === ListItemTypes.CalculationDeletable ||
            props.type === ListItemTypes.CalculationCopiableDeletable ||
            props.type === ListItemTypes.CalculationLocked ||
            props.type === ListItemTypes.GeneratedOffer) {

            if (props.selectedDark) {
                return (
                    <div className={styles.iconsRight}>
                        <div className={styles.icon + ' ' + styles.iconGreen} onClick={props.onLastIconClicked}>
                            <CheckCircleFilled data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                        </div>
                    </div>
                );
            }

            switch (props.type) {
                case ListItemTypes.Calculation:
                    return null;
                case ListItemTypes.CalculationCopiable:
                    return (
                        <div className={styles.iconsRight}>
                            <div className={styles.icon} onClick={props.onNextToLastIconClicked}>
                                <CopyOutlined data-cy={props.dataCyNextToLastIcon ? props.dataCyNextToLastIcon : ''} />
                            </div>
                            <div className={styles.separator}></div>
                            <div className={styles.icon} onClick={props.onLastIconClicked}>
                                <LockOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                            </div>

                        </div>
                    )
                case ListItemTypes.CalculationLocked:
                        return (
                            <div className={styles.iconsRight}>
                                <div className={styles.icon} onClick={props.onLastIconClicked}>
                                    <LockOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                                </div>
                            </div>
                        )
                case ListItemTypes.CalculationDeletable:
                    return (
                        <div className={styles.iconsRight}>
                            <div className={styles.icon} onClick={props.onLastIconClicked}>
                                <DeleteOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                            </div>
                        </div>
                    )
                case ListItemTypes.CalculationCopiableDeletable:
                    return (
                        <div className={styles.iconsRight}>
                            <div className={styles.icon} onClick={props.onNextToLastIconClicked}>
                                <CopyOutlined data-cy={props.dataCyNextToLastIcon ? props.dataCyNextToLastIcon : ''} />
                            </div>
                            <div className={styles.separator}></div>
                            <div className={styles.icon} onClick={props.onLastIconClicked}>
                                <DeleteOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                            </div>
                        </div>
                    )
            }
        }

        if (props.type === ListItemTypes.GeneratedOffer) {
            return null;
        }

        if (props.type === ListItemTypes.Offer || props.type === ListItemTypes.ContractTemplate) {
            if (props.selectedDark) {
                return (
                    <div className={styles.iconsRight}>
                        <div className={styles.icon + ' ' + styles.iconGreen} onClick={props.onLastIconClicked}>
                            <CheckCircleFilled data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                        </div>
                    </div>
                );
            }
            return (
                <div className={styles.iconsRight}>
                    <div className={styles.icon} onClick={props.onNextToLastIconClicked}>
                        <EyeOutlined data-cy={props.dataCyNextToLastIcon ? props.dataCyNextToLastIcon : ''} />
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.icon} onClick={props.onLastIconClicked}>
                        <DownloadOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                    </div>
                </div>
            )
        }

        if (props.type === ListItemTypes.Contract) {
            if (props.selectedDark) {
                return null;
            }
            return (
                <div className={styles.iconsRight}>
                    <div className={styles.icon} onClick={props.onNextToLastIconClicked}>
                        <EyeOutlined />
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.icon} onClick={props.onLastIconClicked}>
                        <DownloadOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                    </div>
                </div>
            )
        }

        if (props.type === ListItemTypes.ContractSigned) {
            if (props.selectedDark) {
                return (
                    <div style={{color: 'white'}} className={styles.iconsRight}>
                        <div className={styles.iconGreen}>Signed</div>
                        <div className={styles.separator}></div>
                        <div className={styles.icon + ' ' + styles.iconGreen}>
                            <CheckCircleFilled data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                        </div>
                    </div>
                );
            }
            return (
                <div className={styles.iconsRight}>
                    <div className={styles.iconGreen}>Signed</div>
                    <div className={styles.separator}></div>
                    <div className={styles.icon + ' ' + styles.iconGreen}>
                        <CheckCircleOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.icon} onClick={props.onNextToLastIconClicked}>
                        <EyeOutlined />
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.icon} onClick={props.onLastIconClicked}>
                        <DownloadOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                    </div>
                </div>
            )
        }

        if (props.type === ListItemTypes.ContractSigning) {
            return (
                <div className={styles.iconsRight}>
                    <div>{props.textNextToIcons}</div>
                    <div className={styles.separator}></div>
                    <div className={styles.icon} onClick={props.onNextToLastIconClicked}>
                        <EyeOutlined />
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.icon} onClick={props.onLastIconClicked}>
                        <DownloadOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                    </div>
                </div>
            )
        }

        if (props.type === ListItemTypes.ObjectsInCalculation) {
            return (
                <div className={styles.iconsRight}>
                    <div className={styles.icon}>
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.icon} onClick={props.onLastIconClicked}>
                        <DeleteOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                    </div>
                </div>
            )
        }
        if (props.type === ListItemTypes.UserObjects) {
            return (
                <div className={styles.iconsRight}>
                    <div className={styles.icon} onClick={props.onNextToLastIconClicked}>
                        <CopyOutlined data-cy={props.dataCyNextToLastIcon ? props.dataCyNextToLastIcon : ''} />
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.icon} onClick={props.onLastIconClicked}>
                        <DeleteOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                    </div>
                </div>
            )
        }
        if (props.type === ListItemTypes.Marktvotum) {
            return (
                <div className={styles.iconsRight}>
                    <div className={styles.icon} onClick={props.onNextToLastIconClicked}>
                        <EyeOutlined data-cy={props.dataCyNextToLastIcon ? props.dataCyNextToLastIcon : ''} />
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.icon} onClick={props.onLastIconClicked}>
                        <DeleteOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                    </div>
                </div>
            )
        }
        if (props.type === ListItemTypes.SelfDeclaration) {
            return (
                <div className={styles.iconsRight}>
                    <div className={styles.icon} onClick={props.onNextToLastIconClicked}>
                        <EyeOutlined data-cy={props.dataCyNextToLastIcon ? props.dataCyNextToLastIcon : ''} />
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.icon} onClick={props.onLastIconClicked}>
                        <DownloadOutlined data-cy={props.dataCyLastIcon ? props.dataCyLastIcon : ''} />
                    </div>
                </div>
            )
        }

        return null;
    };

    let dynamicClasses = classnames(styles.listItem, props.className, {
        [styles.notSelected]: !props.selected,
        [styles.selected]: props.selected && !props.selectedDark,
        [styles.selectedDark]: props.selected && props.selectedDark,
    });

    return (
        <div className={dynamicClasses}
            data-cy={props.dataCy ? props.dataCy : ''}
            onClick={props.onClick ? props.onClick : () => { }}
        >
            <div className={styles.icon}>
                {renderLeftIcon()}
            </div>
            {props.htmlText ? 
                   <div className={styles.text}  dangerouslySetInnerHTML={{__html: props.htmlText}}>
               </div>
               :
               <div className={styles.text}>
                    {props.text}
                </div>
            }
            { renderRightIcons()}
        </div>
    );
};
