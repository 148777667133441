import * as React from "react";
import {useEffect, useState} from "react";
import classnames from "classnames";
import styles from "components/common/presenters/collapsible-section/CollapsibleSection.module.scss";
import {PlusCircleOutlined, MinusCircleOutlined} from "@ant-design/icons";
import {Children} from "react";
import {Divider} from "antd";

export interface CollapsibleSectionProps {
    className?: string;
    text: string;
    onClick?: () => void;
    nonCollapsible?: boolean;
    children?: any;
    buttonsTop?: any;
    hideIfEmpty?: boolean;
    showDivider?: boolean;
    initialCollapsedState?: boolean;
}

export const CollapsibleSection = (props: CollapsibleSectionProps) => {
    const [isCollapsed, setIsCollapsed] = useState(props.initialCollapsedState != null ? props.initialCollapsedState : false);

    useEffect(() => {
        setIsCollapsed(props.initialCollapsedState)
    }, [props.initialCollapsedState])

    let dynamicClasses = classnames(styles.section, {
        [styles.sectionCollapsed]: isCollapsed,
    });

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);

        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        props.hideIfEmpty && !Children.toArray(props.children).find(c => c !== null) ?
            null
            :
            <div className={dynamicClasses}>

                <div className={styles.top}>
                    <h2>{props.text}</h2>
                    {
                        props.nonCollapsible ?
                            null
                            :
                            <div className={styles.control}
                                onClick={() => {handleToggle();}}>
                                {
                                    isCollapsed ?
                                        <PlusCircleOutlined className={styles.icon} />
                                        :
                                        <MinusCircleOutlined className={styles.icon} />
                                }
                            </div>
                    }
                    {props.buttonsTop ?
                        <div className={styles.buttons}>
                            {props.buttonsTop}
                        </div> : null
                    }
                </div>

                {props.showDivider ? <Divider className={styles.divider} /> : null}

                <div className={styles.content}>
                    {isCollapsed ? null : props.children}
                </div>
            </div>
    );
};

export default CollapsibleSection;
