import styles from "components/common/pages/people-page/PeoplePage.module.scss";
import {SideMenu} from "components/common/presenters/side-menu/SideMenu";
import * as React from "react";
import {ColumnsType, PeopleTable} from "components/common/presenters/people-table/PeopleTable";

// TODO: extract add user modal to a component
export const PeoplePage = () => {

    return (
        <div className={styles.page}>

            <SideMenu />

            <PeopleTable fieldsSearch={true} columnsType={ColumnsType.ExtraFields} />
        </div>
    );
};

