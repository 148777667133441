import * as React from "react";
import {ArrowRightOutlined} from "@ant-design/icons";
import {Table} from "antd";
import {Link} from "react-router-dom";
import styles from "./AccountInformationPageTabs.module.scss";
import {Calculation, ObjectData, ObjectType, OffersData} from "../../../../services/api/types";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {DATE_FORMAT} from "../../../../services/api/Globals";
import {RouteStrings} from "../../../../routing/Routes";
import {currencyLocaleFormatter} from "../../../../helpers/formatters";

export interface OffersTabProps {
    offers: OffersData[]
    calculations: Calculation[]
    objects: ObjectData[]
}

interface OffersTableRow {
    offerId: number
    applicationId: number
    akfOfferId: string
    createdAt: Date
    loanAmount: number
    objectName: string
}

export const OffersTab = (props: OffersTabProps) => {
    const {t} = useTranslation()

    const dataSource: OffersTableRow[] = props.offers ?
        props.offers.map(offer => {
            return {
                offerId: offer.offerId,
                applicationId: offer.applicationId,
                akfOfferId: offer.akfOfferId,
                createdAt: offer.createdAt,
                loanAmount: props.calculations?.find(calc => calc.calculationId === offer.calculationId)?.loanAmount,
                objectName: props.objects?.filter(obj => obj.applicationId === offer.applicationId)?.find(obj => obj.type === ObjectType.MAIN)?.objectName,
            }
        }) : []

    const columns = [
        {
            title: t('offer_number'),
            dataIndex: 'akfOfferId',
            render: (value) => value ? `#${value}` : "-"
        },
        {
            title: t('amount'),
            dataIndex: 'loanAmount',
            render: (value) => value ? <p className={styles.noWrap}>{`€ ${currencyLocaleFormatter(value)}`}</p> : "-",
        },
        {
            title: t('object'),
            dataIndex: 'objectName',
            render: (value) => value ? value : "-",
        },
        {
            title: t('data:created_at_2'),
            dataIndex: 'createdAt',
            render: (value) => value ? moment(value).format(DATE_FORMAT.DE) : "-"
        },
        {
            title: '',
            dataIndex: 'applicationId',
            render: (value, record: OffersTableRow) =>
                <Link to={
                    RouteStrings.AdminApplicationDrilldown.replace(":applicationid", record.applicationId.toString()) + `?tab=offers&selected_offer_id=${record.offerId.toString()}`}
                >
                    <ArrowRightOutlined />
                </Link>
        },
    ]

    return (
        <div className={styles.tab}>
            <Table rowKey={record => record.offerId}
                columns={columns}
                dataSource={dataSource}
                pagination={{position: ["bottomCenter"], showSizeChanger: false, pageSize: 10, total: props.offers.length}}
            />
        </div>
    );
};
