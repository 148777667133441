import {RequiredDocumentsSectionTabState} from "components/common/presenters/application-page-tabs/marketvote-tab/RequiredDocumentsSection";
import {MarketVoteTabState} from "./MarketVoteTab";

export const objectCurveClassification = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): ObjCurv => {
    switch (data.objectCurve) {
        case 2:
            return ObjCurv.GUT;
        case 3:
            return ObjCurv.GUT;
        case 4:
            return ObjCurv.BEFRIEDIGEND;
        case 5:
            return ObjCurv.BEFRIEDIGEND;
        case 6:
            return ObjCurv.BEFRIEDIGEND;
        case 7:
            return ObjCurv.BEFRIEDIGEND;
        case 8:
            return ObjCurv.MASSIG;
        case 9:
            return ObjCurv.MASSIG;
        case 10:
            return ObjCurv.MASSIG;
        default:
            return null;
    }
}

export const vcIndexClassification = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): Vc => {
    const n = data.customerVcIndex
    if (100 <= n && n <= 199) {
        return Vc.GUT
    }
    if (200 <= n && n <= 284) {
        return Vc.BEFRIEDIGEND
    }
    if (285 <= n && n <= 600) {
        return Vc.MASSIG
    }
    return null
}

export const obligoClassification = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): Obligo => {
    const n = data.openLeaseCommitment + data.upcomingInvestment
    if (n <= 100) {
        return Obligo.BIS_100
    }
    if (n <= 250) {
        return Obligo.BIS_250
    }
    if (n <= 500) {
        return Obligo.BIS_500
    }
    return Obligo.GT_500
}

export const isVisitReportRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.upcomingInvestment >= 250
}

export const isAkfPropertyValuationRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.upcomingInvestment > 100
}

export const isMainLendersBankInfoRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.openLeaseCommitment + data.upcomingInvestment > 150
}

export const isSelfDeclarationWithSchunfaApprovalRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.isOwnerManaged || data.isHoldingCompanyPresent
}

export const isBusinessEvaluationOfCustomerRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return (data.openLeaseCommitment + data.upcomingInvestment > 100 &&
        (objectCurveClassification(data) !== ObjCurv.GUT || vcIndexClassification(data) !== Vc.GUT)
    ) ||
        data.openLeaseCommitment + data.upcomingInvestment > 499
}

export const isLatestAccountsOfCustomerRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return ((obligoClassification(data) === Obligo.BIS_250 && objectCurveClassification(data) !== ObjCurv.GUT && vcIndexClassification(data) !== Vc.GUT) ||
        (obligoClassification(data) === Obligo.BIS_500 && vcIndexClassification(data) !== Vc.GUT)) ||
        obligoClassification(data) === Obligo.GT_500
}

export const isBalanceSheetAndLastESTNoticeRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.isOwnerManaged && obligoClassification(data) === Obligo.GT_500
}

export const isBusinessEvalOfHoldingCompanyRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.isHoldingCompanyPresent && isBusinessEvaluationOfCustomerRequired(data)
}

export const isLastStatementOfHoldingCompanyRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.isHoldingCompanyPresent && isLatestAccountsOfCustomerRequired(data)
}

export const isOrgChartRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.isInGroupAffiliation &&
        (obligoClassification(data) === Obligo.BIS_500 || obligoClassification(data) === Obligo.GT_500)
}

export const isFinancialStatementOfGroupRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.isInGroupAffiliation && obligoClassification(data) === Obligo.GT_500
}

export const isAssetsStatementOfGroupRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.isInGroupAffiliation && isLatestAccountsOfCustomerRequired(data)
}

export const isBusinessEvalOfGroupRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.isInGroupAffiliation && isBusinessEvaluationOfCustomerRequired(data)
}

export const isFinancialStatementOfMajorCompaniesInGroupRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.isInGroupAffiliation && obligoClassification(data) === Obligo.GT_500
}

export const isFinancialStatementOfParentCompanyRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.isCompanyWithFiftyPercentPresent && isLatestAccountsOfCustomerRequired(data)
}

export const isBusinessEvalOfParentCompanyRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return data.isCompanyWithFiftyPercentPresent && isBusinessEvaluationOfCustomerRequired(data)
}

export const isFinancialStatementOfGuarantorRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return obligoClassification(data) !== Obligo.BIS_100 && data.isGuaranteePresent && !data.isGuarantorPrivate && isLatestAccountsOfCustomerRequired(data)
}

export const isIncomeTaxAssessmentOfGuarantorRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return obligoClassification(data) !== Obligo.BIS_100 && data.isGuaranteePresent && data.isGuarantorPrivate && isLatestAccountsOfCustomerRequired(data)
}

export const isBusinessEvalOfGuarantorRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return obligoClassification(data) !== Obligo.BIS_100 && data.isGuaranteePresent && !data.isGuarantorPrivate && isBusinessEvaluationOfCustomerRequired(data)
}

export const isBalanceSheetOfGuarantorRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return obligoClassification(data) !== Obligo.BIS_100 && data.isGuaranteePresent && data.isGuarantorPrivate && isBusinessEvaluationOfCustomerRequired(data)
}

export const isPlanCalculationRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return obligoClassification(data) === Obligo.GT_500 && data.upcomingInvestment >= 250
}

export const isLiquidityPlanningRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return obligoClassification(data) === Obligo.GT_500 && data.upcomingInvestment >= 250
}

export const isProfitabilityCalculationRequired = (data: MarketVoteTabState | RequiredDocumentsSectionTabState): boolean => {
    return obligoClassification(data) === Obligo.GT_500 && data.upcomingInvestment >= 250
}

export enum Vc {
    GUT, BEFRIEDIGEND, MASSIG
}

export enum ObjCurv {
    GUT, BEFRIEDIGEND, MASSIG
}

export enum Obligo {
    BIS_100, BIS_250, BIS_500, GT_500
}
