import * as React from "react";
import { Card } from "antd";
import {ContactPerson, ContactPersonFunctionSetting} from "../../../../../../services/api/types";
import Icon, {PhoneOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Store, useStore} from "hooks/Store";
import styles from "./ContactPersonCard.module.scss"


export interface ContactPersonCardProps {
    contactPerson: ContactPerson
    onEditClicked: (contactPersonId: number) => void
	// userId: number

	// onListChanged: () => void
}

const PhoneSvg = () => (
    <svg width="22" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.557 3.59453L16.0609 1.10078C15.7562 0.796094 15.3484 0.625 14.9172 0.625C14.4859 0.625 14.0781 0.79375 13.7734 1.10078L11.0852 3.78437C10.7805 4.08906 10.6094 4.49922 10.6094 4.93047C10.6094 5.36406 10.7781 5.76953 11.0852 6.07656L13.1852 8.17891C12.7068 9.29356 12.0194 10.3063 11.1602 11.1625C10.3 12.0273 9.29453 12.7094 8.17891 13.1922L6.07891 11.0898C5.77422 10.7852 5.36641 10.6141 4.93516 10.6141C4.72244 10.6133 4.51172 10.655 4.31532 10.7366C4.11892 10.8183 3.9408 10.9384 3.79141 11.0898L1.10078 13.7734C0.796094 14.0781 0.625 14.4883 0.625 14.9195C0.625 15.3531 0.79375 15.7586 1.10078 16.0656L3.59453 18.5594C4.11484 19.0797 4.83203 19.3773 5.56797 19.3773C5.72031 19.3773 5.86797 19.3656 6.01797 19.3398C9.12109 18.8289 12.2008 17.1766 14.6875 14.6922C17.1719 12.2031 18.8219 9.12344 19.3375 6.01797C19.4852 5.13672 19.1898 4.22969 18.557 3.59453ZM17.6758 5.73906C17.2188 8.50234 15.7328 11.2586 13.4945 13.4969C11.2562 15.7352 8.50234 17.2211 5.73906 17.6781C5.39219 17.7367 5.03594 17.6195 4.78281 17.3687L2.33359 14.9195L4.93047 12.3203L7.73828 15.1328L7.75937 15.1539L8.26562 14.9664C9.80061 14.402 11.1945 13.5107 12.3508 12.354C13.507 11.1974 14.3979 9.80315 14.9617 8.26797L15.1492 7.76172L12.318 4.93281L14.9148 2.33359L17.3641 4.78281C17.6172 5.03594 17.7344 5.39219 17.6758 5.73906Z" fill="black" fill-opacity="0.45"/>
</svg>

  );

const MobileSvg = () => (
    <svg width="22" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.4375 0.453125H1.5625C0.735156 0.453125 0.0625 1.12578 0.0625 1.95312V19.9531C0.0625 20.7805 0.735156 21.4531 1.5625 21.4531H12.4375C13.2648 21.4531 13.9375 20.7805 13.9375 19.9531V1.95312C13.9375 1.12578 13.2648 0.453125 12.4375 0.453125ZM12.25 19.7656H1.75V2.14062H12.25V19.7656ZM6.0625 17.375C6.0625 17.6236 6.16127 17.8621 6.33709 18.0379C6.5129 18.2137 6.75136 18.3125 7 18.3125C7.24864 18.3125 7.4871 18.2137 7.66291 18.0379C7.83873 17.8621 7.9375 17.6236 7.9375 17.375C7.9375 17.1264 7.83873 16.8879 7.66291 16.7121C7.4871 16.5363 7.24864 16.4375 7 16.4375C6.75136 16.4375 6.5129 16.5363 6.33709 16.7121C6.16127 16.8879 6.0625 17.1264 6.0625 17.375Z" fill="black" fill-opacity="0.45"/>
</svg>
  );

  const EmailSvg = () => (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.75 0.75H1.25C0.835156 0.75 0.5 1.08516 0.5 1.5V16.5C0.5 16.9148 0.835156 17.25 1.25 17.25H20.75C21.1648 17.25 21.5 16.9148 21.5 16.5V1.5C21.5 1.08516 21.1648 0.75 20.75 0.75ZM19.8125 3.34687V15.5625H2.1875V3.34687L1.54063 2.84297L2.46172 1.65938L3.46484 2.43984H18.5375L19.5406 1.65938L20.4617 2.84297L19.8125 3.34687ZM18.5375 2.4375L11 8.29688L3.4625 2.4375L2.45938 1.65703L1.53828 2.84062L2.18516 3.34453L10.1914 9.56953C10.4217 9.74841 10.7049 9.84551 10.9965 9.84551C11.2881 9.84551 11.5713 9.74841 11.8016 9.56953L19.8125 3.34687L20.4594 2.84297L19.5383 1.65938L18.5375 2.4375Z" fill="black" fill-opacity="0.45"/>
    </svg>
    
  );

const AddressSvg = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM11 19.7188C6.18594 19.7188 2.28125 15.8141 2.28125 11C2.28125 6.18594 6.18594 2.28125 11 2.28125C15.8141 2.28125 19.7188 6.18594 19.7188 11C19.7188 15.8141 15.8141 19.7188 11 19.7188ZM15.65 5.93516C15.5582 5.89929 15.4603 5.88186 15.3617 5.88387C15.2632 5.88588 15.166 5.90729 15.0758 5.94687L8.72422 8.72656L5.94687 15.0781C5.8625 15.2703 5.8625 15.4883 5.94687 15.6805C6.02656 15.8633 6.17422 16.0063 6.36172 16.0789C6.45078 16.1141 6.54219 16.1305 6.63594 16.1305C6.73906 16.1305 6.83984 16.1094 6.93594 16.0672L13.2875 13.2875L16.0648 6.93594C16.1054 6.84324 16.1268 6.74332 16.1277 6.64216C16.1287 6.541 16.1092 6.44068 16.0705 6.34723C16.0317 6.25377 15.9745 6.16911 15.9023 6.0983C15.83 6.02749 15.7442 5.972 15.65 5.93516ZM12.5187 11.525L13.1328 12.1391L12.1391 13.1328L11.525 12.5187L7.90625 14.1031L9.48828 10.4844L8.89062 9.88437L9.88437 8.89062L10.482 9.48828L14.1031 7.90625L12.5187 11.525ZM9.88437 8.89062L8.89062 9.88437L9.48828 10.4844L11.525 12.5187L12.1391 13.1328L13.1328 12.1391L12.5187 11.525L10.4844 9.48828L9.88437 8.89062Z" fill="black" fill-opacity="0.45"/>
    </svg> 
  );

const RowDown = () => (
    <svg width="14" height="14" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1462 0H10.9743C10.8946 0 10.8196 0.0390626 10.7728 0.103125L6.33369 6.22188L1.89463 0.103125C1.84776 0.0390626 1.77276 0 1.69307 0H0.521194C0.419631 0 0.360256 0.115625 0.419631 0.198438L5.92901 7.79375C6.12901 8.06875 6.53838 8.06875 6.73682 7.79375L12.2462 0.198438C12.3071 0.115625 12.2478 0 12.1462 0Z" fill="#003671"/>
    </svg>
)

const RowUp = () => (
<svg width="14" height="14" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.247 7.80156L6.73762 0.20625C6.53762 -0.06875 6.12824 -0.06875 5.92981 0.20625L0.418867 7.80156C0.405305 7.82024 0.397181 7.84231 0.395394 7.86533C0.393608 7.88834 0.39823 7.9114 0.408747 7.93195C0.419264 7.9525 0.435266 7.96974 0.454979 7.98175C0.474692 7.99376 0.497346 8.00008 0.520429 8H1.6923C1.77199 8 1.84699 7.96094 1.89387 7.89688L6.33293 1.77812L10.772 7.89688C10.8189 7.96094 10.8939 8 10.9736 8H12.1454C12.247 8 12.3064 7.88437 12.247 7.80156Z" fill="#003671"/>
</svg>

)

export const ContactPersonCard = (props: ContactPersonCardProps) => {
    const {t} = useTranslation()
    const store: Store = useStore()

    const [extended, setExtended] = React.useState(false)

    const enabledContactPersonFunctions: ContactPersonFunctionSetting[] = store.data?.settings?.contactPersonFunctions?.filter(f => f.isEnabled) || []
    if (store.data && !enabledContactPersonFunctions.find(f => f.contactPersonFunctionId === props.contactPerson?.contactPersonFunctionId)) {
        const oldDisabledOption = store.data.settings.contactPersonFunctions.find(f => f.contactPersonFunctionId === props.contactPerson?.contactPersonFunctionId)
        if (oldDisabledOption) {
            enabledContactPersonFunctions.push(oldDisabledOption)
        }
    }

    const getFullName = (p: ContactPerson): string => {
		return `${p.firstName ? p.firstName : ''} ${p.lastName ? p.lastName : ''}`;
	}

    const getFullAddress = (p: ContactPerson) => {
		return <div style={{display: 'inline-block', marginLeft: '2px'}}>
                <div>{`${p.streetAndHouseNumber ? p.streetAndHouseNumber : ''}`}</div>
                <div>{`${p.addressPostalCode ? p.addressPostalCode : ''} ${p.addressCity ? p.addressCity : ''}`}</div>
                <div>{`${p.addressCountry ? p.addressCountry : ''}`}</div>
        </div>
	}

    const getRoles = (p: ContactPerson): string => {
        const foundRole = enabledContactPersonFunctions.find(f => f.contactPersonFunctionId === props.contactPerson?.contactPersonFunctionId);
		if(foundRole) {
            return foundRole.functionName;
        } else {
            return '-';
        }
	}

    const getStatus = (p: ContactPerson): string => {
		if (p.finishedAt) {
            if (moment().isBefore(p.finishedAt)) {
                return 'Aktiv';
            } else {
                return `Inaktiv seit ${moment(p.finishedAt).format('DD.MM.YYYY')}`
            }
        } else {
            return p.active ? 'Aktiv' : 'Inaktiv'
        }
	}

    const boolToYesOrNot = (p: boolean): string => {
		return p ? 'Ja' : 'Nein'
	}

	return (
                <Card onClick={() => props.onEditClicked(props.contactPerson.contactPersonId)} className="contact-person-card" title={getFullName(props.contactPerson)}  style={{ width: 300 }}>
      <p><Icon component={PhoneSvg} /> {props.contactPerson.phoneNumber}</p>
      <p><Icon component={MobileSvg} /> {props.contactPerson.mobilePhone}</p>
      <p><Icon component={EmailSvg} /> {props.contactPerson.email}</p>
      <p style={{display: 'table'}}><Icon component={AddressSvg} style={{display: 'table-cell', verticalAlign: 'middle'}} /> {getFullAddress(props.contactPerson)}</p>
      <hr/>
      <p><span className={styles.title}>Funktion:</span> {props.contactPerson.function}</p>
      <p><span className={styles.title}>Position:</span> {props.contactPerson.companyPosition}</p>

      {extended ? <>  
        <p><span className={styles.title}>Geburtsdatum:</span> {props.contactPerson.dateOfBirth ? moment(props.contactPerson.dateOfBirth).format('DD.MM.YYYY') : ''}</p>
        <p><span className={styles.title}>Fax:</span> {props.contactPerson.fax}</p>
        <p><span className={styles.title}>Status:</span> {getStatus(props.contactPerson)}</p>
        <p><span className={styles.title}>Geschäftsführer:</span> {boolToYesOrNot(props.contactPerson.isManagingDirector)}</p>
        <p><span className={styles.title}>Gesellschafter:</span> {boolToYesOrNot(props.contactPerson.isShareholder)}</p>
        <p><span className={styles.title}>Wirtschaftlich Berechtigter:</span> {boolToYesOrNot(props.contactPerson.isBeneficialOwner)}</p>
      </> : null}
      <hr/>
      <p><a href="#" onClick={(e) => { 
          e.stopPropagation();
          setExtended(!extended);}}>{extended ? <Icon component={RowUp} />  : <Icon component={RowDown} />} {extended ? 'Weniger anzeigen' : 'Mehr anzeigen'} </a></p>
    </Card>

	)
}
