import {CalculatorLoanObject} from "../types";
import {nanOrInfinityToZero, roundToDecimalPoints} from "../../../helpers/numberHelpers";
import formulajs from "@formulajs/formulajs/lib/financial";
import {Calculation} from "services/api/types";
import moment from "moment";

export const refinancingRate = 0.009 // fixed 0.9% for now

export const getInterestRatePerPaymentPeriod = (loanObject: CalculatorLoanObject | Calculation): number => {
    return (loanObject?.annualInterestRate / 100) / loanObject?.paymentsPerYear
}

export const getNumberOfPayments = (loanObject: CalculatorLoanObject | Calculation): number => {
    let numberOfPayments = Math.floor(loanObject?.loanDuration / (12 / loanObject?.paymentsPerYear))
    numberOfPayments = nanOrInfinityToZero(numberOfPayments)
    numberOfPayments = roundToDecimalPoints(numberOfPayments, 2)

    return numberOfPayments
}

export const getCustomPeriodRateByCustomMonthlyRate = (loanObject: CalculatorLoanObject): number => {
    let customPeriodRate = loanObject?.customMonthlyRate * (12 / loanObject?.paymentsPerYear)
    customPeriodRate = nanOrInfinityToZero(customPeriodRate)
    customPeriodRate = roundToDecimalPoints(customPeriodRate, 2)

    return customPeriodRate
}

export const getDiscountKpPercentage = (loanObject: CalculatorLoanObject): number => {
    let percentage = (loanObject?.discountKp / loanObject?.loanAmount) * 100
    percentage = nanOrInfinityToZero(percentage)
    percentage = roundToDecimalPoints(percentage, 2)

    return percentage
}

export const getWorkGrantPercentage = (loanObject: CalculatorLoanObject): number => {
    let percentage = (loanObject?.workGrant / loanObject?.loanAmount) * 100
    percentage = nanOrInfinityToZero(percentage)
    percentage = roundToDecimalPoints(percentage, 2)

    return percentage
}

export const getRemainingValuePercentage = (loanObject: CalculatorLoanObject): number => {
    let percentage = (loanObject?.remainingValue / loanObject?.loanAmount) * 100
    percentage = nanOrInfinityToZero(percentage)
    percentage = roundToDecimalPoints(percentage, 2)

    return percentage
}

export const getDownpaymentPercentage = (loanObject: CalculatorLoanObject): number => {
    let percentage = (loanObject?.downpayment / loanObject?.loanAmount) * 100
    percentage = nanOrInfinityToZero(percentage)
    percentage = roundToDecimalPoints(percentage, 2)

    return percentage
}

export const getCommissionPercentage = (loanObject: CalculatorLoanObject): number => {
    let percentage = (loanObject?.commission / (loanObject?.loanAmount - loanObject?.downpayment)) * 100
    percentage = nanOrInfinityToZero(percentage)
    percentage = roundToDecimalPoints(percentage, 2)

    return percentage
}

export const getDiscountPercentage = (loanObject: CalculatorLoanObject): number => {
    let percentage = (loanObject?.discount * 100) / (loanObject?.loanAmount - loanObject?.discountKp)
    percentage = nanOrInfinityToZero(percentage)
    percentage = roundToDecimalPoints(percentage, 2)

    return percentage
}

export const getBgInternal = (loanObject: CalculatorLoanObject | Calculation): number => {
    const {
        loanAmount,
        downpayment,
        workGrant,
        discountKp,
        commission,
        discount
    } = loanObject;

    let bgInternal: any = ((loanAmount - downpayment) - workGrant - discountKp + commission - discount);
    if (isNaN(bgInternal)) {
        bgInternal = 0
    }

    return bgInternal
}

export const getBgInternal2 = (loanObject: CalculatorLoanObject | Calculation): number => {
    const {
        annualInterestRate
    } = loanObject;


    const bgInternal = getBgInternal(loanObject)
    const runUpMonths = getRunUpTime(loanObject)

	let bgInternal2 = formulajs.PV(annualInterestRate/1200, runUpMonths, 0, -bgInternal)
    return bgInternal2
}

export const getRunUpTime = (loanObject: CalculatorLoanObject | Calculation): number => {
    const {
        dateOfFirstPayment,
        paidOutAt,
        prePay
    } = loanObject;

    let months = 1;

    if(prePay) {
        months = 0;
    }

    var start = moment(dateOfFirstPayment, "YYYY-MM-DD");
    var end = moment(paidOutAt, "YYYY-MM-DD");


    const daysDiff = Math.abs(moment.duration(start.diff(end)).asDays()); 
    const v = parseFloat((daysDiff / 29).toFixed(1));
    return roundRunUpTime(v + months)
}

const roundRunUpTime = (v: number): number => {
    const returnedValue = parseInt(v.toString());
    const rest = v - returnedValue;
    if(rest <= 0.4) {
        return returnedValue;
    }
    if(0.6 <= rest && rest <= 0.9) {
        return returnedValue + 0.5;
    }
    return v;

}

export const getPeriodRate = (loanObject: CalculatorLoanObject | Calculation): number => {
    let periodRate = getPeriodRateNotRounded(loanObject)
    periodRate = roundToDecimalPoints(Math.abs(periodRate), 2)

    return periodRate
}

export const getPeriodRateNotRounded = (loanObject: CalculatorLoanObject | Calculation): number => {
    const {
        remainingValue,
        hedgedProceeds,
        prePay,
    } = loanObject

    const bgInternal2 = getBgInternal2(loanObject)
    const rate = getInterestRatePerPaymentPeriod(loanObject)
    const numberOfPayments = getNumberOfPayments(loanObject)

    let periodRate = formulajs.PMT(rate, numberOfPayments, -bgInternal2, remainingValue + hedgedProceeds, prePay ? 1 : 0)
    periodRate = nanOrInfinityToZero(periodRate)

    return periodRate
}

export const getMonthlyRate = (loanObject: CalculatorLoanObject | Calculation): number => {
    let monthlyRate = roundToDecimalPoints(Math.abs(getMonthlyRateNotRounded(loanObject)), 2)

    return monthlyRate
}

export const getMonthlyRateNotRounded = (loanObject: CalculatorLoanObject | Calculation): number => {

    const periodRate = getPeriodRateNotRounded(loanObject)

    let monthlyRate = periodRate / 12 * loanObject?.paymentsPerYear
    monthlyRate = nanOrInfinityToZero(monthlyRate)

    return monthlyRate
}


export const getCustomerInterestRate = (loanObject: CalculatorLoanObject): number => {
    let customerInterestRate = roundToDecimalPoints(Math.abs(getCustomerInterestRateNotRounded(loanObject)), 2)

    return customerInterestRate
}

export const getCustomerInterestRateNotRounded = (loanObject: CalculatorLoanObject): number => {
    const {
        loanAmount,
        loanDuration,
        downpayment,
        remainingValue,
        hedgedProceeds,
        discount
    } = loanObject

    const monthlyRate = getMonthlyRateNotRounded(loanObject)

    // Same as nominalInterestRate, except that this uses the given postPay option, to give the exact interest rate.
    let customerInterestRate = formulajs.RATE(loanDuration, -monthlyRate, (loanAmount - downpayment)-discount, -(remainingValue + hedgedProceeds), 0) * 12 * 100
    customerInterestRate = nanOrInfinityToZero(customerInterestRate)

    return customerInterestRate
}

export const getNominalInterestRate = (loanObject: CalculatorLoanObject): number => {
    const nominalInterestRate = roundToDecimalPoints(Math.abs(getNominalInterestRateNotRounded(loanObject)), 2)

    return nominalInterestRate
}

export const getNominalInterestRateNotRounded = (loanObject: CalculatorLoanObject): number => {
    const {
        loanAmount,
        loanDuration,
        downpayment,
        prePay,
        remainingValue,
        paymentsPerYear
    } = loanObject

    const periodRate = getPeriodRateNotRounded(loanObject)
    const numberOfPayments = getNumberOfPayments(loanObject)

    // Nominal interest rate is always calculated on a 12PPY
    let nominalInterestRate = formulajs.RATE(numberOfPayments, -periodRate, loanAmount - downpayment, -remainingValue, prePay ? 1 : 0) * (1200/(12/paymentsPerYear))
    nominalInterestRate = nanOrInfinityToZero(nominalInterestRate)

    return nominalInterestRate
}

export const getPANGVnterestRate = (loanObject: CalculatorLoanObject): number => {
    const {
        paymentsPerYear
    } = loanObject
    const customerInterestRate = getCustomerInterestRateNotRounded(loanObject)

    let pangvInterestRate = (Math.pow((1 + customerInterestRate / 100 / paymentsPerYear), paymentsPerYear) - 1) * 100
    pangvInterestRate = nanOrInfinityToZero(pangvInterestRate)
    pangvInterestRate = roundToDecimalPoints(Math.abs(pangvInterestRate), 6)

    return pangvInterestRate
}

export const getCashMargin = (loanObject: CalculatorLoanObject): number => {

    const debtValue = getDebtValue(loanObject)
    const lendingRevenue = getLendingRevenue(loanObject)

    let cashMargin = debtValue - lendingRevenue
    cashMargin = nanOrInfinityToZero(cashMargin)
    cashMargin = roundToDecimalPoints(Math.abs(cashMargin), 2)

    return cashMargin
}

export const getCashMarginPercentage = (loanObject: CalculatorLoanObject): number => {
    const cashMargin = getCashMargin(loanObject)
    const lendingRevenue = getLendingRevenue(loanObject)

    let cashMarginPercentage = cashMargin / lendingRevenue * 100
    cashMarginPercentage = nanOrInfinityToZero(cashMarginPercentage)
    cashMarginPercentage = roundToDecimalPoints(Math.abs(cashMarginPercentage), 6)

    return cashMarginPercentage
}

export const getLendingRevenue = (loanObject: CalculatorLoanObject): number => {
    const bgInternal = getBgInternal(loanObject)
    const runUpNominalValue = getRunUpNominalValue(loanObject)

    let lendingRevenue = bgInternal - runUpNominalValue
    lendingRevenue = nanOrInfinityToZero(lendingRevenue)

    return lendingRevenue
}

export const getDebtValue = (loanObject: CalculatorLoanObject): number => {
    const cashValueNormalRates = getCashValueNormalRates(loanObject)
    const runUpInterestValue = getRunUpInterestValue(loanObject)
    const runUpNominalValue = getRunUpNominalValue(loanObject)

    let debtValue = cashValueNormalRates + runUpInterestValue - runUpNominalValue
    debtValue = nanOrInfinityToZero(debtValue)

    return debtValue
}

export const getCashValueNormalRates = (loanObject: CalculatorLoanObject): number => {
    const {
        paymentsPerYear,
        loanDuration,
        remainingValue,
        hedgedProceeds,
        prePay,
    } = loanObject

    const runUpRates = getRunUpRates(loanObject)
    const periodRate = getPeriodRateNotRounded(loanObject)

    let cashValueNominalRates = formulajs.PV(refinancingRate / paymentsPerYear, (loanDuration/12*paymentsPerYear) - runUpRates, -periodRate, -(remainingValue + hedgedProceeds), prePay ? 1 : 0)
    cashValueNominalRates = nanOrInfinityToZero(cashValueNominalRates)

    return roundToDecimalPoints(cashValueNominalRates, 2) 
}

export const getRunUpInterestValue = (loanObject: CalculatorLoanObject): number => {
    const {
        paymentsPerYear,
    } = loanObject

    const periodRate = getPeriodRateNotRounded(loanObject)
    const runUpTime = getRunUpTime(loanObject);

    let result = formulajs.FV(refinancingRate / paymentsPerYear, runUpTime, -periodRate, 0, 0)
    result = nanOrInfinityToZero(result)

    return roundToDecimalPoints(result, 2) 
}

export const getRunUpNominalValue = (loanObject: CalculatorLoanObject): number => {

    const periodRate = getPeriodRateNotRounded(loanObject)
    const runUpTime = getRunUpTime(loanObject)

    let result = runUpTime * periodRate
    result = nanOrInfinityToZero(result)

    return result
}

export const getRunUpRates = (loanObject: CalculatorLoanObject): number => {
    const {
        paymentsPerYear,
    } = loanObject

    const runUpTime = getRunUpTime(loanObject)

    let result = Math.ceil(runUpTime / (12 / paymentsPerYear))
    result = nanOrInfinityToZero(result)

    return result
}

export const getSumOfRepayments = (loanObject: CalculatorLoanObject): number => {
    const periodRate = getPeriodRate(loanObject)
    const numberOfPayments = getNumberOfPayments(loanObject)

    return periodRate * numberOfPayments
}
