import config from '../../config';

const axios = require('axios').default;
axios.defaults.withCredentials = true;

export enum ApiError {
	ErrorUnauthorized = "ErrorUnauthorized"
}

export class ApiWrapper {
	public static async get(path: string) {
		if (path == null) {
			return;
		}

		const modifiedPath = ApiWrapper.getModifiedPath(path);

		try {
			return await axios.get(modifiedPath);
		} catch (error) {
			if (error.response?.status === 401) {
				throw new Error(ApiError.ErrorUnauthorized)
			}
			throw error;
		}
	}

	public static async post(path: string, body: any) {
		const modifiedPath = ApiWrapper.getModifiedPath(path);

		try {
			return await axios.post(modifiedPath, {...body});
		} catch (error) {
			if (error.response?.status === 401) {
				throw new Error(ApiError.ErrorUnauthorized)
			}
			throw error;
		}
	}

	public static async postFormWithFile(path: string, formData: FormData) {
		const modifiedPath = ApiWrapper.getModifiedPath(path);

		try {
			return await axios.post(modifiedPath, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (error) {
			if (error.response?.status === 401) {
				throw new Error(ApiError.ErrorUnauthorized)
			}
			throw error;
		}
	}

	public static async patch(path: string, body: any) {
		const modifiedPath = ApiWrapper.getModifiedPath(path);

		try {
			return await axios.patch(modifiedPath, {...body});
		} catch (error) {
			if (error.response?.status === 401) {
				throw new Error(ApiError.ErrorUnauthorized)
			}
			throw error;
		}
	}

	public static async patchFormWithFile(path: string, formData: FormData) {
		const modifiedPath = ApiWrapper.getModifiedPath(path);

		try {
			return await axios.patch(modifiedPath, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		} catch (error) {
			if (error.response?.status === 401) {
				throw new Error(ApiError.ErrorUnauthorized)
			}
			throw error;
		}
	}

	public static async del(path: string, body: any) {
		const modifiedPath = ApiWrapper.getModifiedPath(path);

		try {
			return await axios.delete(modifiedPath, {data: {...body}});
		} catch (error) {
			if (error.response?.status === 401) {
				throw new Error(ApiError.ErrorUnauthorized)
			}
			throw error;
		}
	}

	public static getModifiedPath(path: string): any {
		const {URL_BASE} = config.api;

		const feVersion = ""; // config.reactAppVersion;

		return `${URL_BASE}${path}`;

		// if (path.indexOf("?") != -1) {
		// 	return `${URL_BASE}${path}&fe_version=${feVersion}`;
		// } else {
		// 	return `${URL_BASE}${path}?fe_version=${feVersion}`;
		// }
	}
}
