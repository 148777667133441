import * as React from "react";
import {useState} from "react";
import styles from "components/common/presenters/add-document-strip/AddDocumentStrip.module.scss";
import {Button, Col, Input, message, Modal, Row, Select} from "antd";
import {CameraOutlined, CloudUploadOutlined} from "@ant-design/icons";
import {DropzoneUpload} from "components/common/presenters/add-document-strip/DocumentUploadButton";
import {useTranslation} from "react-i18next";
import Camera, {FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import {dataUriAsFile} from "../../../../helpers/imageHelpers";
import {fileNameExtension} from "../../../../helpers/stringHelpers";
import {AnchoredSelect} from "../anchored-select/AnchoredSelect";

export interface AddDocumentStripProps {
    onDropFile: (file: any, documentName: string, documentCategory: string) => void
    documentCategories: string[]
}

export const AddDocumentStrip = (props: AddDocumentStripProps) => {
    const {t} = useTranslation();

    const [documentCategory, setDocumentCategory] = useState(props.documentCategories[0])
    const [comment, setComment] = useState('Notiz')
    const [showPhotoShooter, setShowPhotoShooter] = useState(false)
    const [imageDataUri, setImageDataUri] = useState('')

    const getDocumentName = (): string => {
        if (comment) {
            return `${t(`document_categories:${documentCategory}`)} - ${comment}`
        }

        return t(`document_categories:${documentCategory}`)
    }

    const hidePhotoShooter = () => {
        setShowPhotoShooter(false)
    }

    return (
        <div>
            <div className={styles.greyBox}>
                <Row justify={'space-between'} align={'middle'}>
                    <Col flex={'1 1'}>
                        <AnchoredSelect data-cy="dropdown_doc_category"
                            className={styles.dropdown}
                            value={documentCategory}
                            onChange={(value) => {setDocumentCategory(value)}}>
                            {
                                props.documentCategories.map((category, i) =>
                                    <Select.Option key={i} data-cy={`dropdown_doc_category_option_${i}`} value={category}>{t(`document_categories:${category}`)}</Select.Option>) // translated enum values
                            }
                        </AnchoredSelect>
                    </Col>
                    <Col flex={'0 1'}>
                        <div className={styles.divider}></div>
                    </Col>
                    <Col flex={'0 1'}>
                        <div className={styles.buttonsContainer}>
                            <Button data-cy="button_take_photo"
                                className={styles.button}
                                type="primary"
                                htmlType="submit"
                                onClick={() => {
                                    setImageDataUri('')
                                    setShowPhotoShooter(true)
                                }}>
                                <CameraOutlined /> Take Photo
							</Button>
                            <div className={styles.divider}></div>
                            <DropzoneUpload onDropFiles={(files: any[]) => {
                                const file = files[0]
                                const extension = fileNameExtension(file.name)
                                const docNameWithExtension = `${getDocumentName()}.${extension}`

                                props.onDropFile(file, docNameWithExtension, documentCategory)
                            }} />
                        </div>
                    </Col>
                </Row>
                <br />

                <h4>Notiz</h4>
                <Input data-cy={"input_doc_comment"}
                    maxLength={60}
                    value={comment}
                    onChange={(e) => {
                        setComment(e.target.value)
                    }}
                />
                <br />
                <br />

                <p>Wird eingestellt als:</p>
                <Input data-cy={"input_doc_name"}
                    disabled={true}
                    value={getDocumentName()}
                />

            </div>
            <br />

            {
                imageDataUri ?
                    <>
                        <img data-cy="image-taken" src={imageDataUri} width={'640'} height={'360'} />
                        <br />
                        <br />

                        <Button data-cy="button_upload_photo"
                            className={styles.button}
                            type="primary"
                            htmlType="submit"
                            onClick={async () => {
                                const docNameWithExtension = `${getDocumentName()}.png`
                                const file = await dataUriAsFile(imageDataUri, docNameWithExtension)
                                props.onDropFile(file, docNameWithExtension, documentCategory)
                                setImageDataUri('')
                            }}>
                            <CloudUploadOutlined /> Upload Photo
						</Button>
                    </> : null
            }

            <Modal visible={showPhotoShooter}
                destroyOnClose={true}
                footer={null}
                onCancel={() => hidePhotoShooter()}
                width={820}
            >
                <br />
                <br />
                <Camera
                    onTakePhotoAnimationDone={async (dataUri) => {
                        setImageDataUri(dataUri)
                        hidePhotoShooter()
                    }}
                    onCameraError={async (err) => {
                        hidePhotoShooter()
                        console.error(err)
                        message.error('Error using camera.', 2)
                    }}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    idealResolution={{width: 640, height: 360}}
                    imageType={IMAGE_TYPES.PNG}
                    isMaxResolution={true}
                    isImageMirror={false}
                    isDisplayStartCameraError={false}
                    isFullscreen={false}
                    sizeFactor={1}
                />
            </Modal>
        </div>
    );
};
