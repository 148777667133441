import * as React from "react";
import styles from "components/common/presenters/application-page-tabs/ApplicationPageTabs.module.scss";
import CollapsibleSection from "components/common/presenters/collapsible-section/CollapsibleSection";
import {ListItem, ListItemTypes} from "components/common/presenters/list-item/ListItem";
import {AddDocumentStrip} from "components/common/presenters/add-document-strip/AddDocumentStrip";
import ApiManager from "services/api/ApiManager";
import {ApplicationDocument, ApplicationDocumentCategory, UserDocument} from "../../../../services/api/types";
import {message} from "antd";
import UiHelper from "../../../../helpers/uiHelpers";

export interface DocumentsTabProps {
	onApplicationFilesChanged: () => void,
	userId: number;
	applicationId: number;
	applicationDocuments: ApplicationDocument[];
	userDocuments: UserDocument[];
}

export const DocumentsTab = (props: DocumentsTabProps) => {
	const onViewApplicationDocClicked = async (applicationDocId: number) => {
		try {
			const signedUrlResponse = await ApiManager.ApplicationService.getApplicationDocumentSignedUrl(applicationDocId)
			const signedUrl = signedUrlResponse.data
			UiHelper.openUrlInNewTab(signedUrl)
		} catch (e) {
			console.error(e)
		}
	}

	const onViewUserDocClicked = async (userDocId: number) => {
		try {
			const signedUrlResponse = await ApiManager.UserService.getUserDocumentSignedUrl(userDocId)
			const signedUrl = signedUrlResponse.data
			UiHelper.openUrlInNewTab(signedUrl)
		} catch (e) {
			console.error(e)
		}
	}

	const onDeleteFileClicked = async (applicationDocId) => {
		try {
			await ApiManager.ApplicationService.deleteFile(applicationDocId)
			props.onApplicationFilesChanged()

			message.success('File deleted', 2)
		} catch (e) {
			console.error(e);
			message.error('Could not delete file', 2)
		}
	}

	const onDropFile = async (file: any, documentName: string, documentCategory: string) => {
		try {
			await ApiManager.ApplicationService.uploadApplicationDocument(file, props.userId, props.applicationId, documentName, documentCategory)
			props.onApplicationFilesChanged()

			message.success('File(s) uploaded', 2)
		} catch (e) {
			console.log(e)
			message.error('Could not upload file', 2)
		}
	}

	return (
		<div className={styles.tab}>
			<CollapsibleSection text="Dokumente für den Antrag">
				{
					props.applicationDocuments.map(d =>
						<ListItem key={d.applicationDocumentId}
								  type={ListItemTypes.Document}
								  text={d.documentName}
								  onNextToLastIconClicked={() => {
									  onViewApplicationDocClicked(d.applicationDocumentId)
								  }}
								  onLastIconClicked={() => {
									onDeleteFileClicked(d.applicationDocumentId)
								  }}
						/>)
				}
			</CollapsibleSection>

			<CollapsibleSection text="Dokumentation Hinzufügen">
				<AddDocumentStrip documentCategories={Object.values(ApplicationDocumentCategory).filter((d: ApplicationDocumentCategory) => d !== ApplicationDocumentCategory.MARKTVOTUM)}
								  onDropFile={(file, documentName, documentCategory) => {
									  onDropFile(file, documentName, documentCategory)
								  }}
				/>
			</CollapsibleSection>

			<CollapsibleSection text="Alle Nutzerdokumente">
			{
				props.userDocuments.map(d =>
					<ListItem key={d.userDocumentId}
							  type={ListItemTypes.UserDocumentsNotDeletable}
							  text={d.documentName}
							  onLastIconClicked={() => {
								  onViewUserDocClicked(d.userDocumentId)
							  }}
					/>)
			}
			</CollapsibleSection>
		</div>
	);
};
