import * as React from "react";
import styles from "./MarketVoteTab.module.scss";
import {Divider} from "antd";
import {Children} from "react";

export interface DocumentRowGroupProps {
	groupName: string
	children?: any
}

export const DocumentRowGroup = (props: DocumentRowGroupProps) => {
	return (
		Children.toArray(props.children).length > 0 ?
			<>
				<h4>{props.groupName}</h4>
				<Divider className={styles.rowDivider}/>
				{props.children}
				<br/>
				<br/>
			</>
			:
			null
	)
}
