import {UserDocumentCategory} from "services/api/types";

/**
 * Global variables should go here.
 */
export const INPUTS_MAX_LENGTHS = {
	PHONE_INPUT: 22,
	DETAILED_LOAN_PURPOSE: 255
};

export const INPUTS_MIN_LENGTHS = {
    PHONE_INPUT: 8 // Solaris API phone number min length is 8 characters (plus sign and 7 digits)
};

export const PHONE_INPUT_FORMATTER_DEFAULT_LOCALE = "DE"; // so the library knows to add +49 before the formatted phone number

export const FINANCIAL = {
	MIN_NEGATIVE_VALUE: -1000000000,
	MIN_POSITIVE_VALUE: 0,
	MAX_POSITIVE_VALUE: 1000000000
};

export const COMPANY_SEARCH = {
	SEARCH_DELAY_MS: 400,
	SHOW_RESULTS_DELAY_MS: 400,
	NUMBER_OF_ROWS: 4
};

export const NETWORK_REQUEST_TIMEOUT_MS = 30000;

export const ADMIN = {
	APPLICATIONS_PIPELINE_PERIOD_DAYS: 21, // in the pipeline only show applications created in the last n days
	USER_EMAIL_SEARCH_CHAR_THRESHOLD: 3 // min. 3 chars need to be typed in the users table to start the search
};

export const TEST_DATA = {
	TEST_COMPANY_NAME_1: "Kundentestsystem Technik",
	TEST_COMPANY_NAME_2: "Test062018",
};

export const DOCUMENT_UPLOAD = {
	MAX_ALLOWED_NUMBER_OF_FILES: 24,
	MAX_ATTACHMENT_SIZE_MB: 200 * 1000000
};

export const LOAN = {
	MIN_AMOUNT_EUR: 20000,
	MAX_AMOUNT_EUR: 450000,
	ADMIN_MAX_AMOUNT_EUR: 450000,
	KFW_MAX_AMOUNT_EUR: 800000,
	MIN_DURATION_MONTHS: 6,
	MAX_DURATION_MONTHS: 60,
	KFW_MAX_DURATION_MONTHS: 120,
	LOAN_INFO_PAGE_DEFAULT_DURATION: 48,
	LOAN_INFO_PAGE_DEFAULT_AMOUNT: 100000,
	SLIDER_STEP_EUR: 10000,
	SLIDER_STEP_MONTHS: 1
};

export const UserDocumentCategoryGroups = {
	'general_correspondence': [
		UserDocumentCategory.GENERAL_CORRESPONDENCE_PERSON,
		UserDocumentCategory.HEK_DEBIT_NOTE,
		UserDocumentCategory.PROOF_OF_IDENTITY,
		UserDocumentCategory.BALANCE_CONFIRMATION,
		UserDocumentCategory.SERVICE_ACCOUNT_DOCUMENTS,
	],
	'information': [
		UserDocumentCategory.BANK_INFO_POWER_OF_ATTORNEY,
		UserDocumentCategory.SA,
		UserDocumentCategory.SCHUFA,
		UserDocumentCategory.VC,
	],
	'credit_records': [
		UserDocumentCategory.BALANCE_FINANCIAL_STATEMENT,
		UserDocumentCategory.BALANCE_SHEET_EVALUATIONS,
		UserDocumentCategory.BWA,
		UserDocumentCategory.INCOME_RECORDS,
		UserDocumentCategory.FUSER_RATING,
		UserDocumentCategory.COMMERCIAL_HR,
		UserDocumentCategory.COMMITMENT,
		UserDocumentCategory.IDENTITY_CARD,
		UserDocumentCategory.OTHER_CREDIT_DOCUMENTS,
		UserDocumentCategory.ASSET_RECORDS,
	],
	'permits': [
		UserDocumentCategory.APPROVAL_REJECTED_OE,
		UserDocumentCategory.APPROVAL_OF_ENGAGEMENT,
		UserDocumentCategory.VOTES,
	],
	'notes_for_person': [
		UserDocumentCategory.AN_OF_PERSON,
		UserDocumentCategory.APPLICATION_SCAN,
		UserDocumentCategory.VISIT_NOTES,
		UserDocumentCategory.REMINDERS_P,
	],
	'collateral': [
		UserDocumentCategory.CONNECTION_SECURITY_TRANSFER,
		UserDocumentCategory.BANK_GUARANTEES,
		UserDocumentCategory.GUARANTEES,
		UserDocumentCategory.LAND_CHARGE,
		UserDocumentCategory.DEPOSIT,
		UserDocumentCategory.OTHER_ADDITIONAL_SECURITIES,
	],
}

export const DATE_FORMAT = {
	API: 'YYYY-MM-DD',
	DE: 'DD.MM.YYYY', // TODO move to translations
	DE_HH_MM: 'DD.MM.YYYY hh:mm' // TODO move to translations
}

// TODO: rework for AKF
export const getMaxLoanAmount = (isAdmin, isKfw) => {
	if (isKfw) {
		return LOAN.KFW_MAX_AMOUNT_EUR;
	}
	if (isAdmin) {
		return LOAN.ADMIN_MAX_AMOUNT_EUR;
	}
	
	return LOAN.MAX_AMOUNT_EUR;
};

export const getMaxDuration = (isKfw) => {
	if (isKfw) {
		return LOAN.KFW_MAX_DURATION_MONTHS;
	}
	
	return LOAN.MAX_DURATION_MONTHS;
};

export const getMonths = (isKfw) => {
	if (isKfw) {
		return  [6, 12, 24, 36, 48, 60, 72, 84, 96, 108, 120];
	}
	
	return  [6, 12, 24, 36, 48, 60];
};

export const MonthTranslationKeys = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
