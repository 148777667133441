import * as React from "react";
import {useEffect, useState} from "react";
import styles from "./RequiredDocumentsSection.module.scss";
import {Button, Checkbox, Col, DatePicker, Input, InputNumber, message, Radio, Row} from "antd";
import {CheckSquareOutlined, SyncOutlined} from "@ant-design/icons";
import {ApplicationData, ClientType, UserData, UserDocumentCategory} from "../../../../../services/api/types";
import {DATE_FORMAT} from "../../../../../services/api/Globals";
import ApiManager from "../../../../../services/api/ApiManager";
import moment from "moment";
import {DocumentRow} from "./DocumentRow";
import {DocumentRowGroup} from "./DocumentRowGroup";
import {
    isAkfPropertyValuationRequired,
    isAssetsStatementOfGroupRequired,
    isBalanceSheetAndLastESTNoticeRequired,
    isBalanceSheetOfGuarantorRequired,
    isBusinessEvalOfGroupRequired,
    isBusinessEvalOfGuarantorRequired,
    isBusinessEvalOfHoldingCompanyRequired,
    isBusinessEvalOfParentCompanyRequired,
    isBusinessEvaluationOfCustomerRequired,
    isFinancialStatementOfGroupRequired,
    isFinancialStatementOfGuarantorRequired,
    isFinancialStatementOfMajorCompaniesInGroupRequired,
    isFinancialStatementOfParentCompanyRequired,
    isIncomeTaxAssessmentOfGuarantorRequired,
    isLastStatementOfHoldingCompanyRequired,
    isLatestAccountsOfCustomerRequired,
    isLiquidityPlanningRequired,
    isMainLendersBankInfoRequired,
    isOrgChartRequired,
    isPlanCalculationRequired,
    isProfitabilityCalculationRequired,
    isSelfDeclarationWithSchunfaApprovalRequired,
    isVisitReportRequired
} from "./requiredDocumentsHelper";
import {useTranslation} from "react-i18next";
import {MarketVoteTabState} from "components/common/presenters/application-page-tabs/marketvote-tab/MarketVoteTab";

export interface RequiredDocumentsSectionTabProps {
    applicationInformation?: ApplicationData
    userData?: UserData
    documentCategoriesUploaded: UserDocumentCategory[]
    documentCategoriesInWu: UserDocumentCategory[]
    marketVoteTabState?: MarketVoteTabState
    handleDocumentUploadedChecked: (isChecked: boolean, documentCategory: UserDocumentCategory) => void
    handleDocumentUploadedInWUChecked: (isChecked: boolean, documentCategory: UserDocumentCategory) => void
}

export interface RequiredDocumentsSectionTabState {
    clientType?: ClientType
    customerVcIndex?: number
    vcStandDate?: string // Date
    openLeaseCommitment?: number
    openLeaseCommitmentStandDate?: string // Date
    upcomingInvestment?: number
    objectGroup?: string
    objectCurve?: number
    isOwnerManaged?: boolean
    isHoldingCompanyPresent?: boolean
    isCompanyWithFiftyPercentPresent?: boolean
    isInGroupAffiliation?: boolean
    isGuaranteePresent?: boolean
    isGuarantorPrivate?: boolean
    crossSelling?: string
    isCommitment?: boolean
    comment?: string
}

/**
 * Copy this tab.
 * @param props
 */
export const RequiredDocumentsSectionTab = (props: RequiredDocumentsSectionTabProps) => {
    const {t} = useTranslation()

    const defaultState = {
        clientType: props.userData.isPrivate ? ClientType.PRIVATE : ClientType.COMMERCIAL,
        customerVcIndex: props.applicationInformation.marketVotes[0]?.customerVcIndex ? props.applicationInformation.marketVotes[0].customerVcIndex : 0,
        vcStandDate: props.applicationInformation.marketVotes[0]?.vcStandDate ? moment(props.applicationInformation.marketVotes[0].vcStandDate).format(DATE_FORMAT.API) : null,
        openLeaseCommitment: props.applicationInformation.marketVotes[0]?.openLeaseCommitment ? props.applicationInformation.marketVotes[0].openLeaseCommitment : 0,
        openLeaseCommitmentStandDate: props.applicationInformation.marketVotes[0]?.openLeaseCommitmentStandDate ? moment(props.applicationInformation.marketVotes[0].openLeaseCommitmentStandDate).format(DATE_FORMAT.API) : null,
        upcomingInvestment: props.applicationInformation.marketVotes[0]?.upcomingInvestment ? props.applicationInformation.marketVotes[0].upcomingInvestment : 0,
        isOwnerManaged: props.applicationInformation.marketVotes[0]?.isOwnerManaged ? props.applicationInformation.marketVotes[0].isOwnerManaged : false,
        isHoldingCompanyPresent: props.applicationInformation.marketVotes[0]?.isHoldingCompanyPresent ? props.applicationInformation.marketVotes[0].isHoldingCompanyPresent : false,
        isCompanyWithFiftyPercentPresent: props.applicationInformation.marketVotes[0]?.isCompanyWithFiftyPercentPresent ? props.applicationInformation.marketVotes[0].isCompanyWithFiftyPercentPresent : false,
        isInGroupAffiliation: props.applicationInformation.marketVotes[0]?.isInGroupAffiliation ? props.applicationInformation.marketVotes[0].isInGroupAffiliation : false,
        isGuaranteePresent: props.applicationInformation.marketVotes[0]?.isGuaranteePresent ? props.applicationInformation.marketVotes[0].isGuaranteePresent : false,
        isGuarantorPrivate: props.applicationInformation.marketVotes[0]?.isGuarantorPrivate ? props.applicationInformation.marketVotes[0].isGuarantorPrivate : false,
        crossSelling: props.applicationInformation.marketVotes[0]?.crossSelling ? props.applicationInformation.marketVotes[0].crossSelling : '',
        isCommitment: props.applicationInformation.marketVotes[0]?.isCommitment ? props.applicationInformation.marketVotes[0].isCommitment : false,
        comment: props.applicationInformation.marketVotes[0]?.comment ? props.applicationInformation.marketVotes[0].comment : '',
    }

    const [data, setData] = useState({...defaultState} as RequiredDocumentsSectionTabState)

    useEffect(() => {
        setData({...props.marketVoteTabState})
    }, [props.marketVoteTabState])

    return (
        <div className={styles.documentListContainer}>
            <h3>Einzureichende Dokumente</h3>
            <Row justify={'center'} align={'bottom'}>
                <Col span={16}>

                </Col>
                <Col span={5}>
                    <p className={styles.chechboxColumnFont}>Liegt bei</p>
                </Col>
                <Col span={3}>
                    <p className={styles.chechboxColumnFont}>{'Bereits in WU vorhanden'}</p>
                </Col>
            </Row>
            <DocumentRowGroup groupName={'Generelle Unterlagen'} >
                <DocumentRow documentName={'Objektinfo und Vorschlag LDG Kurve bzw. Individualbewertung AM gem. AAW OA 14.01'}
                    isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.PROPERTY_INFO_OR_AAW_OA_1401)}
                    isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.PROPERTY_INFO_OR_AAW_OA_1401)}
                    onUploadedChecked={(isChecked) => {
                        props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.PROPERTY_INFO_OR_AAW_OA_1401)
                    }}
                    onWUChecked={(isChecked) => {
                        props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.PROPERTY_INFO_OR_AAW_OA_1401)
                    }} />
                {isVisitReportRequired(data) ?
                    <DocumentRow documentName={'Besuchsbericht'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.VISIT_REPORT)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.VISIT_REPORT)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.VISIT_REPORT)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.VISIT_REPORT)
                        }} /> : null
                }
                {isAkfPropertyValuationRequired(data) ?
                    <DocumentRow documentName={'Objektbewertung von akf Asset Management'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.PROPERTY_VALUATION_BY_AKF)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.PROPERTY_VALUATION_BY_AKF)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.PROPERTY_VALUATION_BY_AKF)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.PROPERTY_VALUATION_BY_AKF)
                        }} /> : null
                }
            </DocumentRowGroup>

            <DocumentRowGroup groupName={'Bonitätsunterlagen zum Kunden'} >
                <DocumentRow documentName={'Auskunft Creditreform'}
                    isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.CREDITREFORM_INFO)}
                    isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.CREDITREFORM_INFO)}
                    onUploadedChecked={(isChecked) => {
                        props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.CREDITREFORM_INFO)
                    }}
                    onWUChecked={(isChecked) => {
                        props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.CREDITREFORM_INFO)
                    }} />
                {isMainLendersBankInfoRequired(data) ?
                    <DocumentRow documentName={'Bankauskünfte der wesentlichen Kreditgeber und Hausbanken des Kunden'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.MAIN_LENDERS_BANK_INFO)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.MAIN_LENDERS_BANK_INFO)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.MAIN_LENDERS_BANK_INFO)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.MAIN_LENDERS_BANK_INFO)
                        }} /> : null
                }
                {isSelfDeclarationWithSchunfaApprovalRequired(data) ?
                    <DocumentRow documentName={'Selbstauskunft mit Schufa-Freigabe'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.SELF_DECLARATION_WITH_SCHUFA_APPROVAL)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.SELF_DECLARATION_WITH_SCHUFA_APPROVAL)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.SELF_DECLARATION_WITH_SCHUFA_APPROVAL)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.SELF_DECLARATION_WITH_SCHUFA_APPROVAL)
                        }} /> : null
                }
                {isBusinessEvaluationOfCustomerRequired(data) ?
                    <DocumentRow documentName={'betriebswirtschaftliche Auswertung des Kunden'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.BUSINESS_EVALUATION_OF_CUSTOMER)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.BUSINESS_EVALUATION_OF_CUSTOMER)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.BUSINESS_EVALUATION_OF_CUSTOMER)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.BUSINESS_EVALUATION_OF_CUSTOMER)
                        }} /> : null
                }
                {isLatestAccountsOfCustomerRequired(data) ?
                    <DocumentRow documentName={'letzter Jahresabschluss des Kunden'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.LAST_ANNUAL_FINANCIAL_STATEMENTS_OF_CUSTOMER)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.LAST_ANNUAL_FINANCIAL_STATEMENTS_OF_CUSTOMER)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.LAST_ANNUAL_FINANCIAL_STATEMENTS_OF_CUSTOMER)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.LAST_ANNUAL_FINANCIAL_STATEMENTS_OF_CUSTOMER)
                        }} /> : null
                }
                {isBalanceSheetAndLastESTNoticeRequired(data) ?
                    <DocumentRow documentName={'Vermögensübersicht und letzter Est-Bescheid des Kunden (nicht bei GmbH)'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.BALANCE_SHEET_AND_LAST_EST_NOTICE_OF_CUSTOMER)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.BALANCE_SHEET_AND_LAST_EST_NOTICE_OF_CUSTOMER)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.BALANCE_SHEET_AND_LAST_EST_NOTICE_OF_CUSTOMER)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.BALANCE_SHEET_AND_LAST_EST_NOTICE_OF_CUSTOMER)
                        }} /> : null
                }
            </DocumentRowGroup>

            <DocumentRowGroup groupName={'Bonitätsunterlagen zur Besitzgesellschaft'} >
                {isBusinessEvalOfHoldingCompanyRequired(data) ?
                    <DocumentRow documentName={'betriebswirtschaftliche Auswertung der Besitzgesellschaft'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.BUSINESS_EVALUATION_OF_HOLDING_COMPANY)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.BUSINESS_EVALUATION_OF_HOLDING_COMPANY)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.BUSINESS_EVALUATION_OF_HOLDING_COMPANY)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.BUSINESS_EVALUATION_OF_HOLDING_COMPANY)
                        }} /> : null
                }
                {isLastStatementOfHoldingCompanyRequired(data) ?
                    <DocumentRow documentName={'letzter Jahresabschluss der Besitzgesellschaft'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.LAST_ANNUAL_FINANCIAL_STATEMENTS_OF_HOLDING_COMPANY)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.LAST_ANNUAL_FINANCIAL_STATEMENTS_OF_HOLDING_COMPANY)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.LAST_ANNUAL_FINANCIAL_STATEMENTS_OF_HOLDING_COMPANY)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.LAST_ANNUAL_FINANCIAL_STATEMENTS_OF_HOLDING_COMPANY)
                        }} /> : null
                }
            </DocumentRowGroup>

            <DocumentRowGroup groupName={'Bonitätsunterlagen zum Konzern'} >
                {isOrgChartRequired(data) ?
                    <DocumentRow documentName={'Organigramm des Konzerns'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.ORGANIZATION_CHART_OF_GROUP)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.ORGANIZATION_CHART_OF_GROUP)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.ORGANIZATION_CHART_OF_GROUP)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.ORGANIZATION_CHART_OF_GROUP)
                        }} /> : null
                }
                {isFinancialStatementOfGroupRequired(data) ?
                    <DocumentRow documentName={'letzter Konzernabschluss'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.LAST_CONSOLIDATED_FINANCIAL_STATEMENT_OF_GROUP)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.LAST_CONSOLIDATED_FINANCIAL_STATEMENT_OF_GROUP)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.LAST_CONSOLIDATED_FINANCIAL_STATEMENT_OF_GROUP)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.LAST_CONSOLIDATED_FINANCIAL_STATEMENT_OF_GROUP)
                        }} /> : null
                }
                {isAssetsStatementOfGroupRequired(data) ?
                    <DocumentRow documentName={'mindestens Aktiva, Passiva und GuV des letzten Konzernabschlusses'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.ASSETS_LIABILITIES_INCOME_STATEMENT_OF_LAST_CONSOLIDATED_FINANCIAL_STATEMENTS)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.ASSETS_LIABILITIES_INCOME_STATEMENT_OF_LAST_CONSOLIDATED_FINANCIAL_STATEMENTS)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.ASSETS_LIABILITIES_INCOME_STATEMENT_OF_LAST_CONSOLIDATED_FINANCIAL_STATEMENTS)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.ASSETS_LIABILITIES_INCOME_STATEMENT_OF_LAST_CONSOLIDATED_FINANCIAL_STATEMENTS)
                        }} /> : null
                }
                {isBusinessEvalOfGroupRequired(data) ?
                    <DocumentRow documentName={'ggf. betriebswirtschaftliche Auswertung des Konzerns'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.BUSINESS_EVALUATION_OF_GROUP)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.BUSINESS_EVALUATION_OF_GROUP)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.BUSINESS_EVALUATION_OF_GROUP)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.BUSINESS_EVALUATION_OF_GROUP)
                        }} /> : null
                }
                {isFinancialStatementOfMajorCompaniesInGroupRequired(data) ?
                    <DocumentRow documentName={'ggf. Jahresabschlüsse wesentlicher Unternehmen des Konzerns'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.ANNUAL_FINANCIAL_STATEMENTS_OF_MAJOR_COMPANIES_OF_GROUP)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.ANNUAL_FINANCIAL_STATEMENTS_OF_MAJOR_COMPANIES_OF_GROUP)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.ANNUAL_FINANCIAL_STATEMENTS_OF_MAJOR_COMPANIES_OF_GROUP)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.ANNUAL_FINANCIAL_STATEMENTS_OF_MAJOR_COMPANIES_OF_GROUP)
                        }} /> : null
                }
                {isFinancialStatementOfParentCompanyRequired(data) ?
                    <DocumentRow documentName={'Jahresabschluss der Muttergesellschaft'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.ANNUAL_FINANCIAL_STATEMENTS_OF_PARENT_COMPANY)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.ANNUAL_FINANCIAL_STATEMENTS_OF_PARENT_COMPANY)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.ANNUAL_FINANCIAL_STATEMENTS_OF_PARENT_COMPANY)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.ANNUAL_FINANCIAL_STATEMENTS_OF_PARENT_COMPANY)
                        }} /> : null
                }
                {isBusinessEvalOfParentCompanyRequired(data) ?
                    <DocumentRow documentName={'betriebswirtschaftliche Auswertung der Muttergesellschaft'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.BUSINESS_EVALUATION_OF_PARENT_COMPANY)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.BUSINESS_EVALUATION_OF_PARENT_COMPANY)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.BUSINESS_EVALUATION_OF_PARENT_COMPANY)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.BUSINESS_EVALUATION_OF_PARENT_COMPANY)
                        }} /> : null
                }
            </DocumentRowGroup>

            <DocumentRowGroup groupName={'Bonitätsunterlagen des Bürgen'} >
                {isFinancialStatementOfGuarantorRequired(data) ?
                    <DocumentRow documentName={'letzter Jahresabschluss der Bürgin'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.LAST_FINANCIAL_STATEMENTS_OF_GUARANTOR)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.LAST_FINANCIAL_STATEMENTS_OF_GUARANTOR)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.LAST_FINANCIAL_STATEMENTS_OF_GUARANTOR)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.LAST_FINANCIAL_STATEMENTS_OF_GUARANTOR)
                        }} /> : null
                }
                {isIncomeTaxAssessmentOfGuarantorRequired(data) ?
                    <DocumentRow documentName={'Einkommensteuerbescheid und -erklärung des Bürgen'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.INCOME_TAX_ASSESSMENT_AND_DECLARATION_OF_GUARANTOR)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.INCOME_TAX_ASSESSMENT_AND_DECLARATION_OF_GUARANTOR)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.INCOME_TAX_ASSESSMENT_AND_DECLARATION_OF_GUARANTOR)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.INCOME_TAX_ASSESSMENT_AND_DECLARATION_OF_GUARANTOR)
                        }} /> : null
                }

                {isBusinessEvalOfGuarantorRequired(data) ?
                    <DocumentRow documentName={'aktuelle betriebswirtschaftliche Auswertung des Bürgen'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.BUSINESS_EVALUATION_OF_GUARANTOR)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.BUSINESS_EVALUATION_OF_GUARANTOR)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.BUSINESS_EVALUATION_OF_GUARANTOR)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.BUSINESS_EVALUATION_OF_GUARANTOR)
                        }} /> : null
                }
                {isBalanceSheetOfGuarantorRequired(data) ?
                    <DocumentRow documentName={'aktuelle Vermögensübersicht des Bürgen'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.BALANCE_SHEET_OF_GUARANTOR)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.BALANCE_SHEET_OF_GUARANTOR)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.BALANCE_SHEET_OF_GUARANTOR)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.BALANCE_SHEET_OF_GUARANTOR)
                        }} /> : null
                }
            </DocumentRowGroup>

            <DocumentRowGroup groupName={'Planung'} >
                {isPlanCalculationRequired(data) ?
                    <DocumentRow documentName={'Planrechnung für das laufende und folgende Geschäftsjahr'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.PLAN_CALCULATION_OF_CURRENT_AND_NEXT_FINANCIAL_YEAR)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.PLAN_CALCULATION_OF_CURRENT_AND_NEXT_FINANCIAL_YEAR)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.PLAN_CALCULATION_OF_CURRENT_AND_NEXT_FINANCIAL_YEAR)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.PLAN_CALCULATION_OF_CURRENT_AND_NEXT_FINANCIAL_YEAR)
                        }} /> : null
                }
                {isLiquidityPlanningRequired(data) ?
                    <DocumentRow documentName={'Liquiditätsplanung'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.LIQUIDITY_PLANNING)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.LIQUIDITY_PLANNING)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.LIQUIDITY_PLANNING)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.LIQUIDITY_PLANNING)
                        }} /> : null
                }
            </DocumentRowGroup>

            <DocumentRowGroup groupName={'Investition'} >
                {isProfitabilityCalculationRequired(data) ?
                    <DocumentRow documentName={'Wirtschaftlichkeitsberechnung für die Investition'}
                        isExists={props.documentCategoriesUploaded.includes(UserDocumentCategory.PROFITABILITY_CALCULATION_OF_INVESTMENT)}
                        isExistsInWU={props.documentCategoriesInWu.includes(UserDocumentCategory.PROFITABILITY_CALCULATION_OF_INVESTMENT)}
                        onUploadedChecked={(isChecked) => {
                            props.handleDocumentUploadedChecked(isChecked, UserDocumentCategory.PROFITABILITY_CALCULATION_OF_INVESTMENT)
                        }}
                        onWUChecked={(isChecked) => {
                            props.handleDocumentUploadedInWUChecked(isChecked, UserDocumentCategory.PROFITABILITY_CALCULATION_OF_INVESTMENT)
                        }} /> : null
                }
            </DocumentRowGroup>
        </div>
    )
};
