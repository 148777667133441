import {ArrowRightOutlined, SearchOutlined} from "@ant-design/icons";
import {Input, Table} from "antd";
import {Link} from "react-router-dom";
import styles from "components/common/pages/offers-page/OffersPage.module.scss";
import {SideMenu} from "components/common/presenters/side-menu/SideMenu";
import * as React from "react";
import {useEffect, useState} from "react";
import ApiManager from "services/api/ApiManager";
import {RouteStrings} from "routing/Routes";
import moment from "moment";
import {OffersDataExtened} from "../../../../services/api/types";
import {useTranslation} from "react-i18next";
import {DATE_FORMAT} from "../../../../services/api/Globals";
import {currencyLocaleFormatter} from "../../../../helpers/formatters";

interface TableDataRow {
    applicationId: number
    offerId: number
    akfOfferId?: string
    createdAt: Date
    customerName?: string
    mainObjectName?: string
    loanAmount?: number
    loanDuration?: number
    annualInterestRate?: number
}

export const OffersPage = () => {
    const {t} = useTranslation()

    const [offers, setOffers] = useState([] as OffersDataExtened[]);
    const [totalOffers, setTotalOffers] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        fetchData();
    }, [pageIndex]);

    const fetchData = async () => {
        try {
            const result = await ApiManager.ApplicationService.getOffers(pageIndex, searchTerm);

            setOffers(result.data);
            setTotalOffers(result.pagination.numberOfRows);
            setItemsPerPage(result.pagination.itemsPerPage);
        } catch (error) {
            console.error(error);
        }
    };

    const getCompanyName = (offer: OffersDataExtened): string => {
        if (offer.companyName?.length > 0) {
            return offer.companyName
        }

        return `${offer.firstName ? offer.firstName : ''} ${offer.lastName ? offer.lastName : ''}`;
    }

    const dataSource = offers.map((offer: OffersDataExtened) => {
        return {
            applicationId: offer.applicationId,
            offerId: offer.offerId,
            akfOfferId: `${offer.akfOfferId ?  `${offer.akfOfferId} - `  : ''}Teylor ID: ${offer.internalId || ''}`,
            createdAt: offer.createdAt,
            customerName: getCompanyName(offer),
            mainObjectName: offer.mainObjectName,
            loanAmount: offer.loanAmount,
            loanDuration: offer.loanDuration,
            interestRate: offer.annualInterestRate,
        } as TableDataRow
    })

    const columns = [
        {
            title: t('offer_number'),
            dataIndex: 'akfOfferId',
            render: (value) => value ? value : "-",
        },
        {
            title: t('data:created_at'),
            dataIndex: 'createdAt',
            render: (value) => value ? moment(value).format(DATE_FORMAT.DE) : "-"
        },
        {
            title: t('company_name'),
            dataIndex: 'customerName',
        },
        {
            title: t('object_description'),
            dataIndex: 'mainObjectName',
            render: (value) => value ? value : "-"
        },
        {
            title: t('amount'),
            dataIndex: 'loanAmount',
            render: (value) => value ? <p className={styles.noWrap}>{`€ ${currencyLocaleFormatter(value)}`}</p> : "-",
        },
        {
            title: t('data:loan_duration'),
            dataIndex: 'loanDuration',
        },
        {
            title: t('data:interest_rate'),
            dataIndex: 'interestRate',
            render: (value) => value ? `${Number.parseFloat(value).toFixed(6)}%` : ""
        },
        {
            title: '',
            dataIndex: 'applicationId',
            render: (value, record: TableDataRow) =>
                <Link to={
                    `${RouteStrings.AdminApplicationDrilldown.replace(":applicationid", value)}?tab=offers&selected_offer_id=${record.offerId.toString()}`}
                >
                    <ArrowRightOutlined/>
                </Link>
        },
    ]

    return (
        <div className={styles.page}>

            <SideMenu/>

            <div className={styles.sectionRight}>
                <h1>Angebote</h1>
                <br/>
                <br/>

                <Input data-cy={"input-search"}
                       suffix={
                           <SearchOutlined/>
                       }
                       value={searchTerm}
                       onChange={(e) => {
                           setSearchTerm(e.target.value)
                       }}
                       onPressEnter={() => {
                           fetchData()
                       }}
                       onBlur={() => {
                           fetchData()
                       }}
                />
                <br/>
                <br/>

                <Table rowKey={record => record.applicationId}
                       pagination={{position: ["bottomCenter"], showSizeChanger: false, current: pageIndex, pageSize: itemsPerPage, total: totalOffers,}}
                       columns={columns}
                       dataSource={dataSource}
                       onChange={(pagination) => {
                           setPageIndex(pagination.current)
                       }}
                />
            </div>
        </div>
    );
};
