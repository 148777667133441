import * as React from "react";
import styles from "../SettingsPage.module.scss";
import {useTranslation} from "react-i18next";
import {SettingsProperties} from "../../../../../services/api/types";
import {ManageListFragment} from "./ManageListsFragment";
import ApiManager from "../../../../../services/api/ApiManager";
import {message} from "antd";

export interface AddEmployeesSectionProps {
	settings: SettingsProperties
	onDataChange: () => void
}

export const ManageListsSection = (props: AddEmployeesSectionProps) => {
	const {t} = useTranslation()

	const handleUpload = async (settingsPathName: string, csvFile: any) => {
		try {
			await ApiManager.UserService.updateSettings(settingsPathName, csvFile)
			props.onDataChange()
			message.success('Daten Gespeichert', 2)
		} catch (e) {
			console.error(e)
			if (e.response?.status === 400 && e.response.data?.errors[0] === 'ErrCsvHeadersMismatch') {
				message.error(t('errors:csv_format_error'), 6)
			} else {
				message.error('Konnte nicht gelöscht werden', 2)
			}
		}
	}

	return (
		<div>
			<h2 className={styles.textLeft}>{t('manage_lists')}</h2>
			<br/>

			<div className={styles.whiteBox}>
				<p>{t('manage_lists_info')}</p>

				<ManageListFragment listName={t('company_types')}
									dataSource={
										props.settings.companyTypes ? props.settings.companyTypes.map(r => {
											return {
												akf_sys_key: r.akfSysKey,
												name: r.name,
											}
										}) : []
									}
									onUpload={(csvFile) => {
										handleUpload("company_types", csvFile)
									}}
				/>

				<ManageListFragment listName={t('contact_person_functions')}
									dataSource={
										props.settings.contactPersonFunctions ? props.settings.contactPersonFunctions.map(r => {
											return {
												akf_sys_key: r.akfSysKey,
												function_name: r.functionName,
											}
										}) : []
									}
									onUpload={(csvFile) => {
										handleUpload("contact_person_functions", csvFile)
									}}
				/>

				<ManageListFragment listName={t('countries')}
									dataSource={
										props.settings.countries ? props.settings.countries.map(r => {
											return {
												akf_sys_key: r.akfSysKey,
												iso_code: r.isoCode,
												name: r.name,
											}
										}) : []
									}
									onUpload={(csvFile) => {
										handleUpload("countries", csvFile)
									}}
				/>

				<ManageListFragment listName={t('credit_institutions')}
									dataSource={
										props.settings.creditInstitutions ? props.settings.creditInstitutions.map(r => {
											return {
												akf_sys_key: r.akfSysKey,
												blz: r.blz,
												name: r.name,
												bic: r.bic,
                                                city: r.city
											}
										}) : []
									}
									onUpload={(csvFile) => {
										handleUpload("credit_institutions", csvFile)
									}}
				/>

				<ManageListFragment listName={t('industry_types')}
									dataSource={
										props.settings.industryTypes ? props.settings.industryTypes.map(r => {
											return {
												akf_sys_key: r.akfSysKey,
												key: r.key,
												description: r.description,
											}
										}) : []
									}
									onUpload={(csvFile) => {
										handleUpload("industry_types", csvFile)
									}}
				/>

				<ManageListFragment listName={t('credit_analysts')}
									dataSource={
										props.settings.marketVoteAnalysts ? props.settings.marketVoteAnalysts.map(r => {
											return {
												akf_sys_key: r.akfSysKey,
                                                rpa_command: r.rpaCommand,
                                                email: r.email,
												name: r.name,
											}
										}) : []
									}
									onUpload={(csvFile) => {
										handleUpload("credit_analysts", csvFile)
									}}
				/>

				<ManageListFragment listName={t('object_types')}
									dataSource={
										props.settings.objectGroups ? props.settings.objectGroups.map(r => {
											return {
												akf_sys_key: r.akfSysKey,
												description: r.description,
											}
										}) : []
									}
									onUpload={(csvFile) => {
										handleUpload("object_types", csvFile)
									}}
                                    
				/>

                <ManageListFragment listName={t('reward_shop_recipient')}
									dataSource={
										props.settings.rewardShopRecipients ? props.settings.rewardShopRecipients.map(r => {
											return {
                                                merchant: r.merchant,
                                                seller_last_name: r.sellerFirstName,
                                                seller_first_name: r.sellerFirstName,
                                                akf_sys_id: r.akfSysId
											}
										}) : []
									}
									onUpload={(csvFile) => {
										handleUpload("reward_shop_recipient", csvFile)
									}}
                                    
				/>
                                <ManageListFragment listName={t('vendor')}
									dataSource={
										props.settings.vendors ? props.settings.vendors.map(r => {
											return {
												Rang: r.Rang,
                                                Bezeichnung: r.Bezeichnung,
                                                Matchcode: r.Matchcode,
                                                Zusatz: r.Zusatz,
                                                Strasse: r.Strasse,
                                                PLZ: r.PLZ,
                                                Ort: r.Ort,
                                                ["ID Person"]: r["ID Person"],
                                                Code: r.Code
											}
										}) : []
									}
									onUpload={(csvFile) => {
										handleUpload("vendor", csvFile)
									}}
                                    
				/>
			</div>
		</div>
	)
}
