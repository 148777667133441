import * as React from "react";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import styles from "components/common/presenters/people-table/PeopleTable.module.scss";
import {Button, Checkbox, Col, Input, Row, Table} from "antd";
import {ArrowRightOutlined, CheckCircleOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {UserModal} from "components/common/presenters/user-modal/UserModal";
import {RouteStrings} from "routing/Routes";
import ApiManager from "services/api/ApiManager";
import {IndustryTypeSetting, SalesPerson, UserData, UserType} from "services/api/types";
import {PeopleSearch} from "components/common/presenters/people-table/PeopleSearch/PeopleSearch";
import { getUsersFromAkf, SearchFields} from "services/api/akfIntegrationService";
import {Store, useStore} from "hooks/Store";

export enum ColumnsType {
    Default = 'defaultColumns',
    Address = 'addressColumns',
    ExtraFields = 'extraFieldsColumns'
}

export interface PeopleTableProps {
    columnsType?: ColumnsType,
    onRowClicked?: (recodr, event?) => void,
    onArrowClicked?: (recodr, event?) => void,
    fieldsSearch?: boolean,
}

export const PeopleTable = (props: PeopleTableProps) => {
    const store: Store = useStore()
    const [modalOpen, setModalOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);

    const [personSelected, setPersonSelected] = useState(false);
    const [handlerLieferantSelected, setHandlerLieferantSelected] = useState(false);
    const [herstellerSelected, setHerstellerSelected] = useState(false);
    const [vermittlerSelected, setVermittlerSelected] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");

    const [shouldRenderNoResultsMessage, setShouldRenderNoResultsMessage] = useState(false);

    const [users, setUsers] = useState([] as UserData[]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const [searchFields, setSearchFields] = useState({} as SearchFields)

    const [salesPersons, setSalesPersons] = useState([] as SalesPerson[]);

    const [loading, setLoading] = React.useState(false);

    const enabledIndustryTypes: IndustryTypeSetting[] = store.data?.settings?.industryTypes?.filter(t => t.isEnabled) || []

    const fetchData = async (searchFields?: SearchFields) => {
        setLoading(true);
        try {
            
            const usersResult = await fetchUsers(searchFields);

            setItemsPerPage(10);
            setShouldRenderNoResultsMessage(usersResult.data.length === 0 && searchTerm.length > 0);


        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    const fetchUsers = async (searchFields?: SearchFields) => {

        const userTypes = [personSelected ? UserType.Person.toString() : null, handlerLieferantSelected ? UserType.Handler.toString() : null, handlerLieferantSelected ? UserType.Lieferant.toString() : null,
            herstellerSelected ? UserType.Hersteller.toString() : null, vermittlerSelected ? UserType.Vermittler.toString() : null]
        const usersFromAkf = await fetchDataFromAkfApi(pageIndex, searchFields);
        const modifiedPageIndex = usersFromAkf.data.length === 10 ? 1 : pageIndex - Math.floor(usersFromAkf.pagination.numberOfRows / 10)
        let usersResult = await ApiManager.UserService.getUsers(modifiedPageIndex, 10, userTypes.filter(k => k), searchTerm, searchFields);
         
        usersFromAkf.data = usersFromAkf.data.filter(a => !usersResult.data.find(u => a.akfApiCustomerId == u.bankUserId || a.bankUserId == u.bankUserId))
        usersResult.data = [...usersFromAkf.data, ...usersResult.data].slice(0, 10);

        setUsers(usersResult.data.map(k => {return {...k, key: k.userId, role: k.userType.join(', ')}}));
        setTotalUsers(parseInt(usersResult.pagination?.numberOfRows) + usersFromAkf.pagination.numberOfRows);

        return usersResult;
    }

    const fetchDataFromAkfApi = async (pageIndex: number, searchFields?: SearchFields) => {
        const pagination = {
            numberOfRows: 0,
            itemsPerPage: 10,
            current: pageIndex
        }
        try {


            // search in the akf only if we click search
            if(!searchFields || !Object.values(searchFields).filter(k => k).length) {
                return {
                    data: [],
                    pagination
                };
            }
            const users = await getUsersFromAkf(searchFields);
         //  const users = mockedAkfUsersData;
       // debugger;
            return {
                data: users.slice((pageIndex-1)*pagination.itemsPerPage, (pageIndex-1)*pagination.itemsPerPage + pagination.itemsPerPage),
                pagination: {
                    numberOfRows: users.length,
                    itemsPerPage: 10,
                    current: pageIndex
                }
            };
        } catch (error) {
            console.error(`Error from akf api`, error)
            return {
                data: [],
                pagination
            };
        }
    }

    const fetchSalesPersonInfo = async () => {
        const fetchResult = await ApiManager.UserService.getSalesPersons()
        let salesPersons: SalesPerson[] = fetchResult.data;
        setSalesPersons(salesPersons)
    }

    useEffect(() => {
        fetchData(searchFields);
    }, [pageIndex, personSelected, handlerLieferantSelected, herstellerSelected, vermittlerSelected, searchTerm]);

    const onNewUserAdded = () => {
        fetchData();
    }


    const defaultColumns = [
        {
            title: 'User Nr.',
            dataIndex: 'userId',
            render: (value) => value ? value : "-"
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'role',
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
        },
        {
            title: '',
            dataIndex: 'userId',
            render: (value) => <Link to={`${RouteStrings.AdminUserDrilldown.replace(":userid", value)}`}><ArrowRightOutlined /></Link>
        },
    ];


    const addressColumns = [
        {
            title: 'User Nr.',
            dataIndex: 'userId',
            render: (value) => value ? value : "-"
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Strasse',
            dataIndex: 'addressLine1'
        },
        {
            title: 'Postleitzahl',
            dataIndex: 'addressPostalCode'
        },
        {
            title: 'Ort',
            dataIndex: 'addressStreetNumber'
        },
        {
            title: '',
            dataIndex: 'userId',
            key: 'userId',
            render: (value) => <div className={styles.iconsRight}>
                <div className={styles.icon + ' ' + styles.iconGreen}>
                    <CheckCircleOutlined />
                </div>
            </div>
        },
    ];

    const extraFieldsColumns = [
        {
            title: 'Personennr.',
            dataIndex: 'bankUserId',
            render: (value) => value ? value : "-"
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Zusatz',
            dataIndex: 'addon',
        },
        {
            title: 'Vorname',
            dataIndex: 'firstName',
        },
        {
            title: 'PLZ',
            dataIndex: 'addressPostalCode',
        },
        {
            title: 'Ort',
            dataIndex: 'addressCity',
        },
        {
            title: '',
            dataIndex: 'userId',
            render: (value, record) =>{
                if(props.onArrowClicked) {
                    return <Link onClick={async (e) =>{ e.preventDefault(); e.stopPropagation(); 
                        props.onArrowClicked(record)}} to={"#"}><ArrowRightOutlined /></Link>
                } else {
                    return  <Link onClick={async (e) =>{ 
                     }}  to={`${RouteStrings.AdminUserDrilldown.replace(":userid", value)+ (record.isAkf ? `?akfApiCustomerId=${record.akfApiCustomerId}` : '')}`}><ArrowRightOutlined /></Link>
                }
            }
        },
    ];

    const columns = {
        defaultColumns: defaultColumns,
        addressColumns: addressColumns,
        extraFieldsColumns: extraFieldsColumns
    }


    return (
        <div className={styles.peopleTable}>

            <div className={styles.rowTop}>
                <h1>Personen</h1>
                <Button data-cy="button_add_person" className={styles.button} type="primary"
                    onClick={async () => {
                        await fetchSalesPersonInfo();
                        setModalOpen(true);
                    }}>
                    <PlusOutlined />
                            Add Person
                        </Button>
            </div>
            {props.fieldsSearch ? <PeopleSearch loading={loading} onSearchModified={(sf) => setSearchFields(sf)} onSearchClicked={() => {
                setPageIndex(1);
            fetchData(searchFields);
            }
        } /> :

            <div className={styles.rowSecond}>
                <Checkbox data-cy="checkbox_persons" className={styles.checkbox} onChange={(e) => {setPersonSelected(!personSelected); setPageIndex(1);}}>
                    Persons
                </Checkbox>
                <Checkbox data-cy="checkbox_haendler" className={styles.checkbox} onChange={() => {setHandlerLieferantSelected(!handlerLieferantSelected); setPageIndex(1);}}>
                    Händler/Lieferanten
                </Checkbox>
                <Checkbox data-cy="checkbox_hersteller" className={styles.checkbox} onChange={() => {setHerstellerSelected(!herstellerSelected); setPageIndex(1);}}>
                    Hersteller
                </Checkbox>
                <Checkbox data-cy="checkbox_vermittler" className={styles.checkbox} onChange={() => {setVermittlerSelected(!vermittlerSelected); setPageIndex(1);}}>
                    Vermittler
                </Checkbox>

                {
                    searchOpen ?
                        <Input data-cy="input_search" className={styles.searchInput}
                            placeholder="Beispiel: Max Mustermann"
                            onChange={(e) => {setSearchTerm(e.target.value);}}></Input>
                        :
                        <SearchOutlined data-cy="button_search" className={styles.iconSearch} onClick={() => {
                            setSearchOpen(true);
                        }} />
                }


            </div> }

            <div className={styles.rowMainContent}>
                {
                    shouldRenderNoResultsMessage ?
                        <div className={styles.noSearchResults}>
                            <div className={styles.iconNoSearchResults}></div>
                            <div>Keine Ergebnisse für {searchTerm}</div>
                        </div>
                        :
                        <Table
                            pagination={{position: ["bottomCenter"], showSizeChanger: false, current: pageIndex, pageSize: itemsPerPage, total: totalUsers, }}
                            columns={columns[props.columnsType ? props.columnsType.toString() : 'defaultColumns']}
                            dataSource={users.map(u => {return {...u, name: u.lastName};})}
                            onChange={(pagination) => {setPageIndex(pagination.current)}}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => props.onRowClicked ? props.onRowClicked(record, event) : (() => { })()
                                    
                                };
                            }}
                        />
                }
            </div>

            <UserModal modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                userData={null}
                onDataSaved={() => {
                    onNewUserAdded()
                }}
                salesPersons={salesPersons}
            />
        </div>

    );
}
