import { NETWORK_REQUEST_TIMEOUT_MS } from "./Globals";
import ApplicationService from "./applicationService";
import UserService from "./userService";

function createCustomError(errorCode: string) {
	const customError = new Error();
	(customError as any).code = errorCode;

	return customError;
}

export default class ApiManager {
	public static readonly ApplicationService = ApplicationService;
	public static readonly UserService = UserService;
	public static readonly NETWORK_REQUEST_TIMEOUT_MS = NETWORK_REQUEST_TIMEOUT_MS;
	public static readonly NetworkTimeoutException = createCustomError("NetworkRequestTimeoutException");

	/**
	 * Returns the timeout for network calls
	 */
	public static async generateTimeout(networkTimeout?: number) {
		return new Promise(async (resolve, reject) => {
			setTimeout(resolve, networkTimeout || ApiManager.NETWORK_REQUEST_TIMEOUT_MS, null);
		});
	}

	public static handleNetworkCallResult(networkCallResult: any, propToReturn?: string): any {
		if (networkCallResult === null)
			throw ApiManager.NetworkTimeoutException;
		else {
			if (propToReturn)
				return networkCallResult[propToReturn];	
			else
				return networkCallResult;
		}
	}
}
