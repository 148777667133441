import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./SettingsPage.module.scss";
import {Button, Checkbox, message, Table} from "antd";
import {EditOutlined, PlusOutlined, UserAddOutlined, UserDeleteOutlined} from "@ant-design/icons";
import ApiManager from "../../../../services/api/ApiManager";
import {EmployeeModal} from "./EmployeeModal";
import {BoUserProfile} from "../../../../services/api/types";

export interface ManageEmployeesSectionProps {
	existingBOUsers: BoUserProfile[]
	onDataChange: () => void
}

export interface ManageEmployeesSectionState {

}

interface ModalState {
	isOpen: boolean
	selectedBoUser: BoUserProfile
}

export const ManageEmployeesSection = (props: ManageEmployeesSectionProps) => {
	const {t} = useTranslation()

	const defaultModalState: ModalState = {
		isOpen: false,
		selectedBoUser: null
	}
	const [modalState, setModalState] = useState(defaultModalState)
	const [showHidden, setShowHidden] = useState(false)

	const columns = [
		{
			title: t('data:name'),
			dataIndex: 'name',
		},
		{
			title: t('data:email'),
			dataIndex: 'email',
		},
		{
			title: t('data:telephone'),
			dataIndex: 'phone_number',
		},
		{
			title: t('data:role'),
			dataIndex: 'role',
			render: (value) => value ? t(`bo_user_roles:${value}`) : "",
		},
		{
			title: t('data:location'),
			dataIndex: 'location',
		},
		{
			title: '',
			dataIndex: 'bo_user_id',
			width: 80,
			render: (value) => renderRowButtons(value),
		},
	]

	const dataSource = showHidden ?
		props.existingBOUsers :
		props.existingBOUsers.filter(bo => bo.is_available)

	const renderRowButtons = (boUserId: number) => {
		const boUserProfile = props.existingBOUsers.find(p => p.bo_user_id === boUserId)

		return (
			<div>
				<EditOutlined onClick={() => handleEdit(boUserProfile)}/>
				&nbsp;&nbsp;
				{
					boUserProfile.is_available ?
						<UserDeleteOutlined onClick={() => handleDisableBOUser(boUserProfile)}/>
						: <UserAddOutlined onClick={() => handleEnableBOUser(boUserProfile)}/>
				}
			</div>
		)
	}

	const handleEdit = (profileData: BoUserProfile) => {
		setModalState({
			isOpen: true,
			selectedBoUser: {...profileData}
		})
	}

	const handleAdd = () => {
		setModalState({
			isOpen: true,
			selectedBoUser: null
		})
	}

	const handleDisableBOUser = async (profileData: BoUserProfile) => {
		try {
            await ApiManager.UserService.disableBOUser(profileData.bo_user_id)
            props.onDataChange()
            message.success("Daten Gespeichert", 2)
        } catch (e) {
            console.error(e)
            message.error("Konnte nicht Speichern", 2)
        }
	}

	const handleEnableBOUser = async (profileData: BoUserProfile) => {
		try {
            await ApiManager.UserService.enableBOUser(profileData.bo_user_id)
            props.onDataChange()
            message.success("Daten Gespeichert", 2)
        } catch (e) {
            console.error(e)
            message.error("Konnte nicht Speichern", 2)
        }
	}

	const handleUpdateBOUser = async (profileData: BoUserProfile) => {
		try {
			await ApiManager.UserService.updateBOUser({...profileData})
			props.onDataChange()
            message.success('Success', 2)
		} catch (e) {
			console.error(e)
            message.error('Unexpected error')
		}
	}

	const handleCreateBOUser = async (profileData: BoUserProfile) => {
		try {
			await ApiManager.UserService.createBOUser({...profileData})
			props.onDataChange()
            message.success('Success', 2)
		} catch (e) {
			console.error(e)
            if((e as any)?.response?.status == 409) {
                message.error("Ein Konto mit dieser E-Mail existiert bereits.")
                return
            }
            message.error('Unexpected error')
		}
	}

	return (
		<div>
			<h2 className={styles.textLeft}>{t('manage_employees')}</h2>

			<Checkbox data-cy={"check_show_hidden"}
					  checked={showHidden}
					  onChange={(e) => {
						  setShowHidden(e.target.checked)
					  }}
			>
				{t('show_inactive_users')}
			</Checkbox>
			<br/>
			<br/>

			<Table rowKey={record => record.bo_user_id}
				   columns={columns}
				   dataSource={dataSource}
				   pagination={{position: ["bottomCenter"], showSizeChanger: false, pageSize: 10, total: dataSource.length}}
			/>

			<Button type={"primary"}
					onClick={() => {
						handleAdd()
					}}
			>
				<PlusOutlined/> {t('add_employee')}
			</Button>

			<EmployeeModal isOpen={modalState.isOpen}
						   employeeData={modalState.selectedBoUser}
						   onOk={(data: BoUserProfile) => {
							   data.bo_user_id ?
								   handleUpdateBOUser(data) :
								   handleCreateBOUser(data)

							   setModalState(defaultModalState)
						   }}
						   onCancel={() => {
							   setModalState(defaultModalState)
						   }}
			/>
		</div>
	)
}
