import * as React from "react";
import { useState } from "react";
import { Select } from "antd";
import { CountrySetting, UserData } from "services/api/types";
import ApiManager from "services/api/ApiManager";
import { Store, useStore } from "../../../../hooks/Store";
import {
    RietreveAddressResponse,
    SearchAddressResponse,
} from "services/api/userService";

export interface SelectedAddress {
    addressStreetNumber: string;
    addressLine1: string;
    addressPostalCode: string;
    addressCity: string;
    addressCountryId: number;
}

export interface AddressSearchInputProps {
    onAddressSelected: (data) => void;
}

export const AddressSearchInput = (props: AddressSearchInputProps) => {
    const containerId = 'address_search'
    const store: Store = useStore();

    const enabledCountries: CountrySetting[] =
        store.data?.settings?.countries?.filter((c) => c.isEnabled) || [];
    const germanyCountryId = (
        enabledCountries.filter(
            (k) => k.name.toLowerCase() === "deutschland"
        )[0] || {}
    ).countryId;

    const validationFields = {
        addressSearch: "",
        addressSearchContainerId: "",
        addressSearchResult: [],
        addressSearchOpen: false,
    };
    const [ui, setUi] = useState({ ...validationFields });

    const searchAddress = async (searchText, addressSearchContainerId) => {
        try {
            if (searchText.length < 3) {
                setUi({ ...ui, addressSearchResult: [] });
                return;
            }

            const result = await ApiManager.UserService.searchAddress(
                searchText,
                addressSearchContainerId
            );
            const addresses: SearchAddressResponse[] = result.data;

            setUi({
                ...ui,
                addressSearchOpen: true,
                addressSearchResult: addresses,
            });
        } catch (error) {
            console.error(error);
        }
    };

    const retrieveAddress = async (addressId) => {
        try {
            const result = await ApiManager.UserService.getAddress(addressId);
            const address: RietreveAddressResponse = result.data[0];

            props.onAddressSelected({
                addressStreetNumber: address.BuildingNumber,
                addressLine1: address.Street,
                addressPostalCode: address.PostalCode,
                addressCity: address.City,
                addressCountryId:
                address.CountryName == "Germany" ? germanyCountryId : null,
            });
            setUi({
                ...ui,
                addressSearchOpen: false,
                addressSearchContainerId: "",
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div style={{position: "relative"}} id={containerId}>
        <Select
            getPopupContainer={() => document.getElementById(containerId)}
            data-cy="select_address_search"
            //value={ui.addressSearch}
            //  searchValue={ui.addressSearch}
            open={ui.addressSearchOpen}
            onClick={() => {
                setUi({ ...ui, addressSearchOpen: true });
            }}
            onBlur={() => {
                setUi({ ...ui, addressSearchOpen: false });
            }}
            onSearch={(e) => {
                const addressSearchContainerId =
                    ui.addressSearch.length < e.length
                        ? ui.addressSearchContainerId
                        : "";
                setUi({ ...ui, addressSearch: e, addressSearchContainerId });

                searchAddress(e, addressSearchContainerId);
            }}
            onSelect={(e) => {
                const selectedAddress = ui.addressSearchResult.find(
                    (a) => a.Id === e
                );
                if (selectedAddress && selectedAddress.Type == "Address") {
                    retrieveAddress(e);
                } else {
                    setUi({ ...ui, addressSearchContainerId: e.toString() });
                    searchAddress(ui.addressSearch, e);
                }
            }}
            showSearch={true}
            optionFilterProp={"children"}
            filterOption={(input, option) => true}
        >
            {ui.addressSearchResult.map((c, i) => {
                return (
                    <Select.Option
                        data-cy={`address_search${c.Id}`}
                        key={c.Id}
                        value={c.Id}
                    >
                        {c.Text + " " + c.Description}
                    </Select.Option>
                );
            })}
        </Select>
        </div>
    );
};
