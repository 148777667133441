import {CalculatorLoanObject, CalculatorState, PaymentsPerYear} from "services/calculator/types";
import moment from "moment";
import {DATE_FORMAT} from "../api/Globals";

export const initCalculatorState = (): CalculatorState => {
    return {
        loanObject: getResetLoanObject(),
        isLocked: false,
        isSelectedCalculationJustLoaded: false,
        repaymentPlan: [],
    };
};

export const getResetLoanObject = (): CalculatorLoanObject | any => {
    const loanObject: CalculatorLoanObject = {
        loanAmount: 0,
        loanDuration: 12,
        customMonthlyRate: null,
        acquisitionCost: 0,
        annualInterestRate: 0,
        discountKp: 0,
        workGrant: 0,
        downpayment: 0,
        commission: 0,
        discount: 0,
        remainingValue: 0,
        hedgedProceeds: 0,
        additionalProceeds: 0,
        contractStartAt: moment().date(15).format(DATE_FORMAT.API),
        dateOfFirstPayment: moment().date(15).format(DATE_FORMAT.API),
        paidOutAt: moment().date(15).format(DATE_FORMAT.API),
        paymentsPerYear: PaymentsPerYear.Monthly,
        runUpTime: 0,
        prePay: false,
        discountKpPercentage: 0,
        discountPercentage: 0,
        downpaymentPercentage: 0,
        workGrantPercentage: 0,
        commissionPercentage: 0,
        remainingValuePercent: 0,
        bgInternal: 0
    };

    return loanObject;
};
