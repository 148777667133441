export const dataUriAsFile = async (dataUri, fileName) => {
	let file

	await fetch(dataUri)
		.then(res => res.blob())
		.then(blob => {
			file = blobAsFile(blob, fileName)
		})

	return file
}

const blobAsFile = (blob: Blob, fileName: string) => {
	const formData = new FormData();
	formData.append("temp", blob, fileName);
	const file = formData.get("temp")

	return file
}
