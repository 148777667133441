import {Button, Empty, Select, Spin} from "antd";
import styles from "components/common/pages/application-page/ApplicationPage.module.scss";
import {SideMenu} from "components/common/presenters/side-menu/SideMenu";
import * as React from "react";
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {OffersTab} from "components/common/presenters/application-page-tabs/OffersTab";
import {TabMenu, TabMenuContent} from "components/common/presenters/tab-menu/TabMenu";
import {DocumentsTab} from "components/common/presenters/application-page-tabs/DocumentsTab";
import {ChecklistTab} from "components/common/presenters/application-page-tabs/checklist-tab/ChecklistTab";
import {MarketVoteTab} from "components/common/presenters/application-page-tabs/marketvote-tab/MarketVoteTab";
import {ObjectsTab} from "components/common/presenters/application-page-tabs/objects-tab/ObjectsTab";
import ApiManager from "services/api/ApiManager";
import {CalculatorTab} from "components/common/presenters/application-page-tabs/calculator-tab/CalculatorTab";
import {ApplicationData, Calculation, ContractData, OffersData, SalesPerson, UserData, UserType} from "../../../../services/api/types";
import {ContractsTab} from "components/common/presenters/application-page-tabs/ContractsTab";
import {GuarantorsTab} from "components/common/presenters/application-page-tabs/guarantors-tab/GuarantorsTab";
import {getOfferBoundToTheCalculation} from "../../../../services/calculator/calculationHelper";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {RouteStrings} from "../../../../routing/Routes";

const {Option} = Select;

export interface ApplicationPageProps {
    tabName?: string
    selectedCalculationId?: number
    selectedOfferId?: number
}

// TODO: extract add user modal to a component
export const ApplicationPage = (props: ApplicationPageProps) => {
    const history = useHistory();
    const {applicationid} = useParams();
    const [defaultTabKey, setDefaultTabKey] = useState(props.tabName || 'calculations');
    const [objectActiveKey, setObjectActiveKey] = useState('newObject');
    const [applicationInformation, setApplicationInformation] = useState({} as ApplicationData);
    const [userInformation, setUserInformation] = useState({} as UserData);
    const [salesPersons, setSalesPersons] = useState([] as SalesPerson[]);

    const fetchData = async () => {
        try {
            const applicationResult = await ApiManager.ApplicationService.getApplication(applicationid,
                [
                    ApiManager.ApplicationService.ApplicationEmbedEnum.Calculations,
                    ApiManager.ApplicationService.ApplicationEmbedEnum.ApplicationDocuments,
                    ApiManager.ApplicationService.ApplicationEmbedEnum.Objects,
                    ApiManager.ApplicationService.ApplicationEmbedEnum.Offers,
                    ApiManager.ApplicationService.ApplicationEmbedEnum.Contracts,
                    ApiManager.ApplicationService.ApplicationEmbedEnum.ContractDrafts,
                    ApiManager.ApplicationService.ApplicationEmbedEnum.MarketVotes,
                ]);
            const userResult = await ApiManager.UserService.getUser(applicationResult.data.userId,
                [
                    ApiManager.UserService.UserEmbedEnum.UserDocuments,
                    ApiManager.UserService.UserEmbedEnum.BankConnections,
                    ApiManager.UserService.UserEmbedEnum.ContactPerson,
                    ApiManager.UserService.UserEmbedEnum.Creditworthiness
                ]);

                if(applicationResult.data.objects) {
                    const lieferantsResult = await fetchLieferantsByUserIds(applicationResult.data.objects);
                    applicationResult.data.objects = applicationResult.data.objects.map(o => { 
                        const f = lieferantsResult.filter(l => l.userId === o.lieferantId);
                        if(f && f.length) {
                            return {...o, lieferantPhrase: `${f[0].firstName || ''} ${f[0].lastName || ''}`}
                        } else {
                            return o;
                        }
                    })
                }

                await fetchSalesPersonInfo();

            setApplicationInformation({
                ...applicationResult.data,
                calculations: addCalculationFields(applicationResult.data.calculations, applicationResult.data.offers, applicationResult.data.contracts || [])
            });
            setUserInformation(userResult.data);
            setObjectActiveKey(applicationResult.data.objects && applicationResult.data.objects.length > 0 ? '0' : 'newObject');

        } catch (error) {
            console.error(error);
        }
    };

    const fetchSalesPersonInfo = async () => {
        try {
            const fetchResult = await ApiManager.UserService.getSalesPersons()
            let salesPersons: SalesPerson[] = fetchResult.data;
            setSalesPersons(salesPersons)
        } catch (error) {
            console.error(error);
        }
    }

    const fetchLieferantsByUserIds = async (objects) => {
        if(!objects) {
            return [];
        }
        try {
            const userIds = [];
            objects.forEach(k => {
                if(k.lieferantId) {
                    userIds.push(k.lieferantId)
                }
            })
            if(userIds) {
                const usersResult = await ApiManager.UserService.getUsers(1, 10, [], '', null, userIds);
                return usersResult.data;
            }

        } catch (error) {
            console.error(error);
        }
        return [];
    };

    useEffect(() => {
        fetchData();
    }, [applicationid]);

    useEffect(() => {
        if (tabMenuItems.map(item => item.key).includes(props.tabName)) {
            setDefaultTabKey(props.tabName)
        }
    }, [props.tabName]);

    const addCalculationFields = (calculations: Calculation[], offers: OffersData[], contracts: ContractData[]): Calculation[] => { // TODO calculate on backend (override tons of functions)
        const result: Calculation[] = [...calculations]

        for (const calc of result) {
            const offerBoundToTheCalculation = getOfferBoundToTheCalculation(offers, calc.calculationId)
            if (offerBoundToTheCalculation) {
                calc.isBoundToAnyOffer = true
                const boundContract = contracts.find(f => f.offerId === offerBoundToTheCalculation.offerId);
                if(boundContract) {
                    calc.isBoundToAnyContract = true;
                }
            }
        }

        return result
    }

    function onNewObjectAdded(newObjectAdded) {
        setObjectActiveKey((applicationInformation.objects.length).toString());
        setApplicationInformation({...applicationInformation, objects: [...applicationInformation.objects, newObjectAdded]});

    }

    function onObjectDeleted(objectId) {
        setObjectActiveKey(applicationInformation.objects && applicationInformation.objects.length > 1 ? '0' : 'newObject');
        setApplicationInformation({...applicationInformation, objects: applicationInformation.objects.filter(o => o.objectId !== objectId)});
    }

    function onNewCalculationAdded(newCalculationAdded) {
        setApplicationInformation({...applicationInformation, calculations: [...applicationInformation.calculations, newCalculationAdded]});
    }

    function onCalculationDeleted(calculationId) {
        fetchData()
    }

    function onObjectUpdated(objectUpdated) {
        const objects = applicationInformation.objects.map(o => o.objectId === objectUpdated.objectId ? objectUpdated : o);

        setApplicationInformation({...applicationInformation, objects: [...objects]});
    }

    function onContractGenerated(contractGenerated) {
        fetchData()
        setDefaultTabKey('4');
    }

    const refreshApplicationDocumentList = async () => {
        try {
            const docsResponse = await ApiManager.ApplicationService.getApplicationDocuments(applicationInformation.applicationId)
            setApplicationInformation((prevState: ApplicationData) => {
                return {...prevState, applicationDocuments: docsResponse.data}
            })
        } catch (e) {
            console.log(e)
        }
    }

    const [modalOpen, setModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const mockSearchResults = [];

    useEffect(() => {

    }, [searchTerm]);

    let objectsExtended = includeCalculationIds(applicationInformation.objects, applicationInformation.calculations);
    objectsExtended = includeOfferIds(objectsExtended, applicationInformation.offers);
    let contractDraftsExtended = includeObjectsInContractDrafts(objectsExtended, applicationInformation.contractDrafts || []);
    contractDraftsExtended = includeContactPersonsInContractDrafts(userInformation.contactPersons, contractDraftsExtended);
    contractDraftsExtended = includeContractInContractDrafts(applicationInformation.contracts, contractDraftsExtended)
    contractDraftsExtended = includeOfferInContractDrafts(applicationInformation.offers, contractDraftsExtended)
    let offersExtended = includeBankConnectionsInOffers(userInformation.bankConnections, applicationInformation.offers);
    offersExtended = includeObjectsInOffers(objectsExtended, offersExtended);
    offersExtended = includeCalculationsInOffers(applicationInformation.calculations, offersExtended);
    offersExtended = includeContractInOffers(applicationInformation.contracts, offersExtended);
    let contractsExtended = includeContractDraftInContract(applicationInformation.contracts, contractDraftsExtended);
    let calculationsExtended = includeOfferInCalculation(offersExtended, applicationInformation.calculations );

 
    const tabMenuItems: TabMenuContent[] = [
        {
            title: "Rechner",
            key: "calculations",
            content: <CalculatorTab objects={objectsExtended || []}
                calculations={calculationsExtended|| []}
                applicationId={applicationid}
                userId={applicationInformation.userId}
                onDataChanged={() => fetchData()}
                setActiveTabIndex={(index) => setDefaultTabKey(index)}
                selectedCalculationId={props.selectedCalculationId} />,
        },
        {
            title: "Objekte",
            key: "objects",
            content: <ObjectsTab applicationInformation={applicationInformation}
                objectActiveKey={objectActiveKey} objects={applicationInformation.objects || []}
                onDataUpdated={() => {fetchData()}} />,
        },
        {
            title: "Angebote",
            key: "offers",
            content: <OffersTab calculations={calculationsExtended || []}
                offers={offersExtended || []}
                bankConnections={userInformation.bankConnections}
                onCalculationDeleted={onCalculationDeleted}
                onOfferGenerated={(offer) => fetchData()}
                onContractGenerated={onContractGenerated}
                numberOfContracts={(applicationInformation.contracts || []).length}
                onDataUpdated={() => {fetchData()}}
                userData={userInformation}
                selectedOfferId={props.selectedOfferId} 
                salesPersons={salesPersons} />,
        },
        // {
        //     title: "Sicherheiten",
        //     key: "guarantors",
        //     content: <GuarantorsTab applicationInformation={applicationInformation}
        //         offers={offersExtended}
        //         onDataUpdated={() => {fetchData()}} />,
        // },
        {
            title: "Verträge",
            key: "contracts",
            content:
                <ContractsTab contracts={contractsExtended}
                contractDrafts={contractDraftsExtended || []}
                offers={offersExtended || []}
                onContractGenerated={onContractGenerated}
                onContractDraftGenerated={() => { }}
                onDataUpdated={() => {fetchData()}}
                applicationId={applicationid}
                user={userInformation} />,
        },
        {
            title: "Unterlagen",
            key: "documents",
            content: <DocumentsTab applicationDocuments={applicationInformation.applicationDocuments || []}
                userDocuments={userInformation.userDocuments || []}
                applicationId={applicationid}
                userId={applicationInformation.userId}
                onApplicationFilesChanged={() => refreshApplicationDocumentList()} />,
        },
        // {
        //     title: "Marktvotum",
        //     key: "market_vote",
        //     content: <MarketVoteTab applicationInformation={applicationInformation} userData={userInformation} />,
        // },
        {
            title: "Checkliste",
            key: "checklist",
            content: <ChecklistTab applicationInformation={applicationInformation}
                onApplicationUpdated={() => fetchData()}
                offers={offersExtended || []}
                userData={userInformation}
                onGoToCalculation={(calculationId) => setDefaultTabKey('0')} />
        },
    ];

    const getCompanyName = (): string => {
        if (userInformation?.companyName?.length > 0) {
            return userInformation?.companyName
        }

        return `${userInformation?.firstName ? userInformation?.firstName : ''} ${userInformation?.lastName ? userInformation?.lastName : ''}`;
    }

    return (
        <div className={styles.page}>

            <SideMenu />

            <div className={styles.sectionRight}>

                <div className={styles.rowInfoTop}>
                    <Button className={styles.buttonBack}
                        onClick={() => {
                            history?.push(RouteStrings.AdminUserDrilldown.replace(":userid", userInformation.userId?.toString()))
                        }}
                    >
                        <ArrowLeftOutlined />
                        Back
                    </Button>

                    <div className={styles.rowInfoTopDetails}>
                        <h2 className={styles.leftAlign}>{getCompanyName()}</h2>
                        <div>Kreditantragstatus: Offen</div>
                    </div>
                </div>

                <div className={styles.rowMainContent}>
                    {
                        !userInformation.userId ? // waiting for application + user data to fetch
                            <Spin /> :
                            <TabMenu items={tabMenuItems}
                                defaultItem={tabMenuItems.find(item => item.key === defaultTabKey)}
                                onTabChange={(activeKey) => setDefaultTabKey(activeKey)}
                            />
                    }
                </div>
            </div>
        </div>
    );
};


const includeCalculationIds = (objects: any[], calculations) => {
    if (!objects) {
        return [];
    }

    if (!calculations) {
        return objects;
    }

    const objectsWithCalculationIds = [];
    objects.forEach(o => {
        const includedCalculations = [...calculations];
        objectsWithCalculationIds.push({...o, includedCalculations});
    });

    return objectsWithCalculationIds;
}

const includeOfferIds = (objects: any[], offers) => {
    if (!objects) {
        return [];
    }

    if (!offers) {
        return objects;
    }

    const objectsWithOfferIds = [];
    objects.forEach(o => {
        objectsWithOfferIds.push({...o, includedOffers: offers});
    });

    return objectsWithOfferIds;
}

const includeObjectsInContractDrafts = (objects: any[], contractDrafts: any[]) => {
    if (!contractDrafts) {
        return [];
    }

    const contractDraftsWithObjects = contractDrafts.map(contractDraft => {
        return {...contractDraft, objects: objects};
    });

    return contractDraftsWithObjects;
}

const includeContactPersonsInContractDrafts = (contactPersons: any[], contractDrafts: any[]) => {
    if (!contractDrafts) {
        return [];
    }

    const contractDraftsWithContactPersons = contractDrafts.map(cd => {
        return {...cd, contactPersons: contactPersons || []};
    });

    return contractDraftsWithContactPersons;
}

const includeContractInContractDrafts = (contracts: any[], contractDrafts: any[]) => {
    if (!contractDrafts) {
        return [];
    }

    if (!contracts) {
        return contractDrafts;
    }

    const contractDraftsWithContract = [];
    contractDrafts.forEach(o => {
        const contractDraftExtened = {...o};

        contractDraftExtened.contract = contracts.filter(c => o.contractDraftId === c.contractDraftId)[0];
        if (contractDraftExtened.contract) {
            contractDraftExtened.contractTemplateName = `${contractDraftExtened.contractTemplateName} - VT`
        }
        contractDraftsWithContract.push(contractDraftExtened);
    });

    return contractDraftsWithContract;
}

const includeOfferInContractDrafts = (offers: any[], contractDrafts: any[]) => {
    if (!contractDrafts) {
        return [];
    }

    if (!offers) {
        return contractDrafts;
    }

    const contractDraftsWithContract = [];
    contractDrafts.forEach(o => {
        const contractDraftExtened = {...o};

        contractDraftExtened.offer = offers.find(c => o.offerId === c.offerId);
 
        contractDraftsWithContract.push(contractDraftExtened);
    });

    return contractDraftsWithContract;
}


const includeBankConnectionsInOffers = (bankConnections: any[], offers: any[]) => {
    if (!offers) {
        return [];
    }

    const offersWithBankConnections = offers.map(offer => {
        return {...offer, bankConnections: bankConnections || []};
    });

    return offersWithBankConnections;
}

const includeObjectsInOffers = (objects: any[], offers: any[]) => {
    if (!offers) {
        return [];
    }

    const offersWithObjects = offers.map(offer => {
        return {...offer, objects: objects || []};
    });

    return offersWithObjects;
}

const includeCalculationsInOffers = (calculations: any[], offers: any[]) => {
    if (!offers) {
        return [];
    }

    if (!calculations) {
        return offers;
    }

    const offersWithCalculations = [];
    offers.forEach(o => {
        const offersExtended = {...o};
        offersExtended.calculation = calculations.filter(c => o.calculationId === c.calculationId)[0];
        offersWithCalculations.push(offersExtended);
    });

    return offersWithCalculations;
}

const includeOfferInCalculation = (offers: any[], calculations: any[]) => {
    if (!calculations) {
        return [];
    }

    if (!offers) {
        return calculations;
    }

    const calculationsWithOffer = [];
    calculations.forEach(c => {
        const calculationExtended = {...c};
        calculationExtended.offer = offers.find(o => o.calculationId === c.calculationId);
        calculationsWithOffer.push(calculationExtended);
    });

    return calculationsWithOffer;
}


const includeContractInOffers = (contracts: any[], offers: any[]) => {
    if (!offers) {
        return [];
    }

    if (!contracts) {
        return offers;
    }

    const offersWithContracts = [];
    offers.forEach(o => {
        const offersExtended = {...o};
        offersExtended.contract = contracts.filter(c => o.offerId === c.offerId)[0];
        if(offersExtended.contract) {
            offersExtended.offerName = `${offersExtended.offerName} - VT${offersExtended.akfOfferId ? ` -  <span style="font-weight: 900;">${offersExtended.akfOfferId}-A</span>` : ''} ${offersExtended.internalId || ''}`
        } else {
            offersExtended.offerName = `${offersExtended.offerName} - ANG${offersExtended.akfOfferId ? ` - <span style="font-weight: 900;">${offersExtended.akfOfferId}-A</span>` : ''}  ${offersExtended.internalId || ''}`
        }
        offersWithContracts.push(offersExtended);
    });

    return offersWithContracts;
}

const includeContractDraftInContract = (contracts: any[], contractDrafts: any[]) => {
    if (!contracts) {
        return [];
    }

    const contractsWithContractDraft = contracts.map(c => {
        return {...c, contractDraft: contractDrafts.find(cd => cd.contractDraftId === c.contractDraftId)};
    });

    return contractsWithContractDraft;
}

