import * as React from "react";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import classnames from "classnames";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import styles from 'components/common/presenters/side-menu/SideMenu.module.scss';
import {RouteStrings} from "routing/Routes";
import {Auth, useAuth} from "../../../../hooks/Auth";
import {useStore} from "../../../../hooks/Store";

export interface SideMenuProps {
    showOnlyPerson?: boolean
}

export const SideMenu = (props: SideMenuProps) => {
    const history = useHistory();
    const auth: Auth = useAuth()
    const store = useStore()

    const [wasToggled, setWasToggled] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [activePageIndex, setActivePageIndex] = useState(null);

    const isCollapsed: boolean = store.data.ui.isSidebarCollapsed

    let dynamicClasses = classnames(styles.menu, {
        [styles.menuCollapsed]: isCollapsed,
        [styles.menuToggled]: wasToggled,
        [styles.menuFirstRender]: isFirstRender && !isCollapsed,
        [styles.active_3]: activePageIndex === 3,
    });

    let textClasses = classnames(styles.text, {
        [styles.textDisappear]: isCollapsed
    });

    const toggleMenu = () => {
        store.setData({
            ui: {
                ...store.data.ui,
                isSidebarCollapsed: !isCollapsed
            }
        })
        setIsFirstRender(false);
    };

    // Called on every re-render, including the first one.
    useEffect(() => {
        if (!isCollapsed && !wasToggled) {
            setWasToggled(true);
        }

        if (isCollapsed && wasToggled) {
            setWasToggled(false);
        }

        // TODO: fix re-rendering in this case
        const activePage = window.location.pathname;

        switch (activePage) {
            case "/admin/people":
                setActivePageIndex(1);
                break;
            case "/admin/applications":
                setActivePageIndex(3);
                break;
            default:
                break;
        }

    }, [isCollapsed]);

    return (
        <div className={dynamicClasses}>
            <div className={styles.containerLogo}>
                <div className={styles.logo}></div>
                <div className={styles.imgAkf}></div>
            </div>
            <div className={styles.scrolled}>
                <div className={styles.containerMid}>
                    {
                        props.showOnlyPerson ?
                            <div data-cy="button_people"
                                className={styles.menuItem}>
                                <div className={styles.iconPerson}></div>
                                <div className={textClasses}>Personen</div>
                            </div>
                            :
                            <>
                                <div className={styles.menuItem}
                                    onClick={() => {history.push(RouteStrings.AdminExampleCalculator);}}
                                >
                                    <div className={styles.iconCalculator}></div>
                                    <div className={textClasses}>Beispielrechner</div>
                                </div>
                                <div data-cy="button_people"
                                    className={styles.menuItem}
                                    onClick={() => {history.push(RouteStrings.AdminPeople);}}
                                >
                                    <div className={styles.iconPerson}></div>
                                    <div className={textClasses}>Personen</div>
                                </div>
                                <div className={styles.menuItem}
                                    onClick={() => {history.push(RouteStrings.AdminOffers);}}
                                >
                                    <div className={styles.iconOffer}></div>
                                    <div className={textClasses}>Angebote</div>
                                </div>
                                <div className={styles.menuItem}
                                    onClick={() => {history.push(RouteStrings.AdminApplications);}}
                                >
                                    <div className={styles.iconApplications}></div>
                                    <div className={textClasses}>Anträge</div>
                                </div>
                                <div className={styles.menuItem}
                                    onClick={() => {history.push(RouteStrings.AdminSettings);}}
                                >
                                    <div className={styles.iconSettings}></div>
                                    <div className={textClasses}>Einstellungen</div>
                                </div>
                                <div className={styles.menuItem}
                                    onClick={() => {history.push(RouteStrings.AdminDealershipConditions);}}
                                >
                                    <div className={styles.iconDealerships}></div>
                                    <div className={textClasses}>Händler Tableaus</div>
                                </div>
                                <div className={styles.menuItem}
                                    onClick={() => {history.push(RouteStrings.AdminJobs);}}
                                >
                                    <div className={styles.iconSettings}></div>
                                    <div className={textClasses}>RPA Dashboard</div>
                                </div>
                            </>
                    }
                </div>
            </div>
            <div className={styles.containerBot}>
                <div className={styles.menuItem} onClick={toggleMenu}>
                    <div className={`${styles.icon} ${styles.iconAntd}`}>
                        {isCollapsed ? <RightOutlined /> : <LeftOutlined />}
                    </div>
                    <div className={textClasses}>
                        {/* { isCollapsed ? "Expand" : "Shrink" }  */}
						Toggle
					</div>
                </div>

                {
                    auth?.profile ?
                        <div data-cy="button_logout" className={styles.menuItem} onClick={async () => {
                            await auth.logOut();
                            history.push(RouteStrings.Login);
                        }}>
                            <div className={styles.iconLogout}></div>
                            <div className={textClasses}>Abmelden</div>
                        </div>
                        : null
                }

            </div>
        </div>
    );
};
