import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Checkbox, Col, Input, Modal, Radio, Row, Select} from "antd";
import styles from "components/common/presenters/account-information-page-tabs/AccountInformationPageTabs.module.scss";
import {ContactPerson} from "services/api/types";

export interface SelectContactPersonModalProps {
    isOpen: boolean
    contactPersons: ContactPerson[]
    onOk: (selected: ContactPerson[]) => void
    onCancel: () => void
}

export const SelectContactPersonModal = (props: SelectContactPersonModalProps) => {
    const {t} = useTranslation()

    const [data, setData] = useState({
        selectedContactPersons: []
    })

    return (
        <Modal className={styles.modal}
            visible={props.isOpen}
            title={'Gesprächspartner auswählen'}
            onOk={() => props.onOk(data.selectedContactPersons.slice(0, 4))}
            onCancel={() => props.onCancel()}
            okText={'Gesprächspartner einfügen'}
            cancelText={'Abbrechen'}
        >
            <Row gutter={24}>
                {props.contactPersons.map(c => <Col span={24}><Checkbox data-cy={`checkbox_${c.contactPersonId}`}
                    checked={!!data.selectedContactPersons.find(s => s.contactPersonId === c.contactPersonId)} className={styles.checkbox}
                    onChange={(e) => {
                        const found = data.selectedContactPersons.find(s => s.contactPersonId === c.contactPersonId);
                        if (found) {
                            setData({selectedContactPersons: (data.selectedContactPersons.filter(s => s.contactPersonId !== c.contactPersonId))})
                        } else {
                            setData({selectedContactPersons: [...data.selectedContactPersons, c]})
                        }
                    }}>
                    {c.firstName + ' ' + c.lastName + ' ' + c.mobilePhone}
                </Checkbox></Col>)}
            </Row>
        </Modal>
    )
}
