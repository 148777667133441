import * as React from "react";
import {useEffect, useState} from "react";
import styles from "components/common/presenters/application-page-tabs/ApplicationPageTabs.module.scss";
import CollapsibleSection from "components/common/presenters/collapsible-section/CollapsibleSection";
import {ListItem, ListItemTypes} from "components/common/presenters/list-item/ListItem";
import {AutoComplete, Button, Checkbox, Form, Input, message, Radio, Select} from "antd";
import ApiManager from "services/api/ApiManager";
import UiHelper from "../../../../helpers/uiHelpers";
import {BankConnection} from "components/common/presenters/bank-connection/BankConnection";
import {Calculation, ObjectType, OffersDataExtened, SalesPerson, UserData, UserType, Vendor} from "../../../../services/api/types";
import {AnchoredSelect} from "../anchored-select/AnchoredSelect";
import {getDiscountPercentage, getMonthlyRate} from "services/calculator/calculation-actions/loanObjectGetters";
import {mapPaymentsPerYearToString} from "services/calculator/types";
import {SearchPeopleModal} from "components/common/presenters/application-page-tabs/objects-tab/SearchPeopleModal";
import {parseLocaleFormattedFloat} from "helpers/parsers";
import {currencyLocaleFormatter} from "helpers/formatters";
import {ContentDisposition} from "services/api/applicationService";
import {Store, useStore} from "hooks/Store";

export interface OffersTabProps {
    calculations: Calculation[];
    offers: OffersDataExtened[];
    bankConnections: any[];
    onContractGenerated: (contractGenerated) => void;
    onCalculationDeleted: (calculationId) => void;
    onOfferGenerated: (offer) => void;
    numberOfContracts: number;
    onDataUpdated: () => void;
    userData: UserData;
    selectedOfferId?: number
    salesPersons: SalesPerson[]
}

export const OffersTab = (props: OffersTabProps) => {
    const [selectedCalculations, setSelectedCalculations] = useState([]);
    const [selectedOffers, setSelectedOffers] = useState([]);
    const [selectedPdfOffers, setSelectedPdfOffers] = useState([]);
    const [showFields, setShowFields] = useState(false);
    const [showOfferGeneration, setShowOfferGeneration] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null);

    function ButtonsInCalkulationTop(props) {
        return <Button data-cy="button_save"
            disabled={props.disabled}
            type="primary"
            size="small"
            htmlType="submit"
            onClick={props.onClick}>
            Angebote Erstellen{props.extraText ? props.extraText : ''}
        </Button>;
    }

    function ButtonsInOfferTop(props) {
        return <Button data-cy="button_save"
            disabled={props.disabled}
            type="primary"
            size="small"
            htmlType="submit"
            onClick={props.onClick}>
            PDF Erstellen{props.extraText ? props.extraText : ''}
        </Button>;
    }

    async function onCalculationDeleted(calculationId) {
        try {
            await ApiManager.ApplicationService.deleteCalculation(calculationId);
            props.onCalculationDeleted(calculationId);
        } catch (error) {
            console.error(error);
        }
    }

    async function onDownloadOfferPdfClicked(offerId, offerName) {
        try {
            const result = await ApiManager.ApplicationService.getOfferPdf(offerId, ContentDisposition.Attachment);

            UiHelper.downloadPDFFromUrl(result.data.pdf, offerName);
        } catch (error) {
            console.error(error);
        }
    }

    async function onViewOfferPdfClicked(offerId) {
        try {
            const result = await ApiManager.ApplicationService.getOfferPdf(offerId);

            setSelectedPdf({url: result.data.pdf, offerId});
        } catch (error) {
            console.error(error);
        }
    }

    function onOfferGenerated(offer) {
        props.onOfferGenerated(offer);
        setShowFields(false);
        setSelectedCalculations([]);
        setSelectedPdfOffers([offer.offerId]);
    }

    return (
        <div className={styles.tab}>
            <CollapsibleSection text="Gespeicherte Kalkulationen" buttonsTop={ButtonsInCalkulationTop({disabled: !selectedCalculations.length, extraText: selectedCalculations.length ? ` (${selectedCalculations.length})` : '', onClick: () => setShowFields(true)})}>
                {props.calculations.map((c, i) =>
                    <React.Fragment>
                        <ListItem
                            key={i}
                            type={c.isBoundToAnyOffer ? ListItemTypes.CalculationLocked : ListItemTypes.CalculationDeletable}
                            selectedDark={!!selectedCalculations.find(k => k === c.calculationId)}
                            htmlText={toCalculationLabel(c)}
                            onLastIconClicked={(e) => {e.stopPropagation(); if(c.isBoundToAnyOffer) {return} onCalculationDeleted(c.calculationId)}} // applies only if deletable
                            onClick={() => {
                                if(c.isBoundToAnyOffer) {
                                    return;
                                }
                                const foundCalculation = selectedCalculations.find(k => k === c.calculationId);
                                if (!!foundCalculation) {
                                    setSelectedCalculations([...selectedCalculations.filter(k => k !== c.calculationId)]);
                                    return;
                                }
                                if (selectedCalculations.length >= 3) {
                                    return;
                                }
                                setSelectedCalculations([...selectedCalculations, c.calculationId])
                            }}
                        />
                        {showFields && selectedCalculations.sort()[0] === c.calculationId ?
                            <GenerateOfferFields
                                selectedCalculations={selectedCalculations}
                                onOfferGenerated={onOfferGenerated}
                                numberOfOffers={props.offers.length}
                                userId={props.calculations[0].userId}
                                applicationId={props.calculations[0].applicationId}
                                bankConnections={props.bankConnections}
                                userName={`${props.userData.firstName} ${props.userData.lastName}`}
                                salesPersons={props.salesPersons}
                            />

                            : null}
                    </React.Fragment>
                )}
            </CollapsibleSection>

            <CollapsibleSection text="Erstellte Angebote" buttonsTop={ButtonsInOfferTop({disabled: !selectedOffers.length, extraText: selectedOffers.length ? ` (${selectedOffers.length})` : '', onClick: () => setShowOfferGeneration(true)})}>
                {props.offers.map((o, i) =>
                    <React.Fragment key={i}>
                        <ListItem
                            type={ListItemTypes.GeneratedOffer}
                            selectedDark={!!selectedOffers.find(k => k.offerId === o.offerId)}
                            htmlText={o.offerName}
                            onClick={() => {
                                const foundOffer = selectedOffers.find(k => k.offerId === o.offerId);
                                if (!!foundOffer) {
                                    if (selectedOffers.length === 1) {
                                        setShowOfferGeneration(false)
                                    }
                                    setSelectedOffers([...selectedOffers.filter(k => k.offerId !== o.offerId)]);
                                    return;
                                }
                                if (selectedOffers.length >= 3) {
                                    return;
                                }
                                setSelectedOffers([...selectedOffers, o])
                            }}
                        />
                        {showOfferGeneration && selectedOffers.sort((a,b) => a.offerId-b.offerId)[0]?.offerId === o.offerId ?
                            <GenerateOfferPdf
                                onOfferGenerated={() => props.onDataUpdated()}
                                selectedOffers={selectedOffers}
                                userData={props.userData}
                            />

                            : null}
                    </React.Fragment>
                )}
            </CollapsibleSection>

            <CollapsibleSection text="Erstellte PDFs">
                {distinctOffersByDocumentUrl(props.offers).filter(o => !!o.offerDocumentUrl).map((o, i) =>
                    <React.Fragment>
                        <ListItem
                            type={ListItemTypes.Offer}
                            selectedDark={!!selectedPdfOffers.find(k => k === o.offerId)}
                            htmlText={o.offerName}
                            onClick={() => {
                                onViewOfferPdfClicked(o.offerId);
                                                        }}
                            onNextToLastIconClicked={(e) => {e.stopPropagation(); onViewOfferPdfClicked(o.offerId); }}
                            onLastIconClicked={(e) => {e.stopPropagation(); onDownloadOfferPdfClicked(o.offerId, o.offerName);}}
                        />
                        {selectedPdf && selectedPdf.offerId === o.offerId ? <div style={{textAlign: 'center', background: 'black'}}>
                            <object style={{width: 714, height: 1010}} data={selectedPdf.url} type="application/pdf">
                                <iframe src={selectedPdf.url}></iframe>
                            </object>
                        </div> : null}
                    </React.Fragment>
                )}
            </CollapsibleSection>
        </div>
    );
};

interface GenerateOfferFieldsProps {
    onOfferGenerated: (offerGenerated) => void;
    selectedCalculations: any[];
    bankConnections: any[];
    numberOfOffers: number;
    userId: number;
    applicationId: number;
    userName: string;
    salesPersons: SalesPerson[]
}

export const GenerateOfferFields = (props: GenerateOfferFieldsProps) => {
    const store: Store = useStore()
    
    const vendors: Vendor[] = store.data?.settings?.vendors || []

    
    const [offerFields, setOfferFields] = useState({
        fee: 0,
        distributionChannel: '',
        fundingProgram: false,
        specialCondition: false,
        guarantorAssociatedWithBorrower: false,
        guarantorIndependent: false,
        guarantorId: undefined,
        calculationType: '',
        commissionRecipientId: undefined,
        salesRepresentitive: '',
        vendorId: '',
        printObjectDescription: false,
        bankConnectionId: undefined,
        creditInstitutionId: null,
        accountOwner: '',
        iban: '',
        newBankAccount: false
    });
    const [uiData, setUiData] = useState({
        selectedFee: 4,
        andereFeeString: '0',
        selectedDistributionChannel: 0,
        calculationTypeOption: '',
        comissionRecipientsSearchTerm: '',
        searchingComissionRecipient: false,
        comissionRecipientsResult: [],
        guarantorSearchTerm: '',
        searchingGurantor: false,
        guarantorsResult: [],
        searchPeopleModalKey: '',
        commissionRecipientPhrase: '',
        guarantorPhrase: '',
        selectedGuarantor: 'noGuarantor',
        isSendingData: false
    })

    const fetchComissionRecipientsBySearch = async () => {
        try {
            const usersResult = await ApiManager.UserService.getUsers(1, 10, [], uiData.comissionRecipientsSearchTerm);
            setUiData({...uiData, searchingComissionRecipient: false, comissionRecipientsResult: usersResult.data})
        } catch (error) {
            setUiData({...uiData, searchingComissionRecipient: false})
            console.error(error);
        }
    };

    useEffect(() => {
        if (uiData.comissionRecipientsSearchTerm != null && uiData.searchingComissionRecipient) {
            fetchComissionRecipientsBySearch()
        }
    }, [uiData.comissionRecipientsSearchTerm])

    const fetchGuarantorsBySearch = async () => {
        try {
            const usersResult = await ApiManager.UserService.getUsers(1, 10, [UserType.Lieferant], uiData.guarantorSearchTerm);
            setUiData({...uiData, searchingGurantor: false, guarantorsResult: usersResult.data})
        } catch (error) {
            setUiData({...uiData, searchingGurantor: false})
            console.error(error);
        }
    };

    useEffect(() => {
        if (uiData.guarantorSearchTerm != null && uiData.searchingGurantor) {
            fetchGuarantorsBySearch()
        }
    }, [uiData.guarantorSearchTerm])

    async function onOfferClicked() {
        try {
            if (isDataInvalid().length > 0) {
                return message.error('Data invalid, please fill in the form sections ' + isDataInvalid().join(', '), 4);
            }

            if(uiData.isSendingData) {
                return;
            }
            setUiData({...uiData, isSendingData: true})


            const data = {
                includedCalculations: props.selectedCalculations,
                userId: props.userId,
                applicationId: props.applicationId,
                ...offerFields
            };

            const response = await ApiManager.ApplicationService.createOffer(data);
            setUiData({...uiData, isSendingData: false})

            props.onOfferGenerated(response.data);
            message.success('Daten Gespeichert', 2)
        } catch (e) {
            console.error(e)
            message.error('Konnte nicht Speichern', 2)
            setUiData({...uiData, isSendingData: false})
        }
    }

    function isDataInvalid() {
        return [
            feeInvalid() ? 'Antragsprüfungsgebühr' : null,
            bankAccountInvalid() ? "Bankverbindungen" : null
        ].filter(e => !!e);
    }

    function feeInvalid() {
        const isInvalid = uiData.selectedFee === 4 && (parseFloat(uiData.andereFeeString) < 0 || (offerFields.fee !== 0 && uiData.andereFeeString !== (offerFields.fee || '').toString()));
        return isInvalid;
    }

    function bankAccountInvalid() {
        if (offerFields.newBankAccount) {
            return !offerFields.creditInstitutionId || !offerFields.iban || !offerFields.accountOwner;
        } else {
            return !offerFields.bankConnectionId;
        }
    }

    return (
        <Form className={styles.form}
            layout="vertical"
            name="basic"
            initialValues={{remember: true}}
        >
            <div className={styles.offerFieldsInner}>
                <h3>Antragsprüfungsgebühr</h3>
                <div className={styles.centerRadioButtons}>
                    <Radio.Group onChange={(e) => {
                        setUiData({...uiData, selectedFee: e.target.value});
                        setOfferFields({...offerFields, fee: [0, 120, 140, 175, 0][e.target.value]})
                    }} value={uiData.selectedFee} >
                        <Radio value={1}>120 EUR</Radio>
                        <Radio value={2}>140 EUR</Radio>
                        <Radio value={3}>175 EUR</Radio>
                        <Radio value={4}>Anderer Betrag:</Radio>
                    </Radio.Group>
                    <Form.Item
                        validateStatus={feeInvalid() ? "error" : ''}
                        className={styles.formItem}
                    >
                        <Input size='small' className={styles.andereFee} value={uiData.selectedFee === 4 ? uiData.andereFeeString : ''} placeholder="" onChange={(e) => {
                            setUiData({...uiData, andereFeeString: e.target.value});
                            setOfferFields({...offerFields, fee: parseFloat(e.target.value)});
                        }}></Input>
                    </Form.Item>
                </div>
                <br />
                <h3>Kalkulationsart</h3>
                <AnchoredSelect data-cy="dropdown_type"
                    size="large"
                    placeholder="Lorem ipsum"
                    className={styles.dropdown}
                    defaultValue={uiData.calculationTypeOption}
                    onChange={(v) => {
                        setUiData({...uiData, calculationTypeOption: v})
                        setOfferFields({
                            ...offerFields,
                            calculationType: ['', 'Angebot Kredit (mit Znsvorgabe)', 'Angebot Kredit (mit EURO)'][v]
                        })
                    }}>
                    <Select.Option data-cy="option_1" value={1}>Angebot Kredit (mit Znsvorgabe)</Select.Option>
                    <Select.Option data-cy="option_2" value={2}>Angebot Kredit (mit EURO)</Select.Option>
                </AnchoredSelect>
                <br />
                <br />
                <h3>Vertriebsweg</h3>
                <Radio.Group onChange={(e) => {
                    setOfferFields({
                        ...offerFields,
                        distributionChannel: ['', 'Mobilien Absatz Agrar', 'Mobilien Agrar Vermittler'][e.target.value]
                    });
                    setUiData({
                        ...uiData,
                        selectedDistributionChannel: e.target.value
                    })
                }} value={uiData.selectedDistributionChannel} >
                    <Radio value={1}>Mobilien Absatz Agrar</Radio>
                    <Radio value={2}>Mobilien Agrar Vermittler</Radio>
                </Radio.Group>
                <br />
                <br />
                <h3>Kondition</h3>
                <div>
                    <Checkbox checked={offerFields.fundingProgram} onChange={(e) => setOfferFields({...offerFields, fundingProgram: e.target.checked})}>Förderprogram</Checkbox>
                    <Checkbox checked={offerFields.specialCondition} onChange={(e) => setOfferFields({...offerFields, specialCondition: e.target.checked})}>Sonderkondition</Checkbox>
                </div>
                <br />
                <h3>Bürge</h3>
                <div>
                    <Radio.Group onChange={(e) => {
                        setUiData({...uiData, selectedGuarantor: e.target.value});
                        const offerFieldsSelected = {
                            guarantorIndependent: false,
                            guarantorAssociatedWithBorrower: false
                        };

                        if( e.target.value === 'guarantorIndependent') {
                            offerFieldsSelected.guarantorIndependent = true;
                        } else if( e.target.value === 'guarantorAssociatedWithBorrower') {
                            offerFieldsSelected.guarantorAssociatedWithBorrower = true;
                        }
                        setOfferFields({...offerFields, ...offerFieldsSelected })
                    }} value={uiData.selectedGuarantor} >
                        <Radio value={'noGuarantor'}>Kein Bürge</Radio>
                        <br />
                        <br />
                        <Radio value={'guarantorAssociatedWithBorrower'}>Mit Kreditnehmer verbund</Radio>
                        <br />
                        <br />
                        <Radio value={'guarantorIndependent'}>Unabhängige dritte Person</Radio>
                    </Radio.Group>
                    <br />
                    <br />
                    <div>
                        <Input data-cy="input_guarantor"
                            className={styles.shortInput}
                            value={uiData.guarantorPhrase}
                            disabled={!(offerFields.guarantorIndependent || offerFields.guarantorAssociatedWithBorrower)}
                            onClick={() => {
                                setUiData({...uiData, searchPeopleModalKey: 'guarantor'})
                            }}>
                        </Input>
                    </div>

                </div>
                <br />
                
                <br />
                <h3>Provisionsempfänger</h3>
                <Input data-cy="input_commission"
                        className={styles.shortInput}
                        value={uiData.commissionRecipientPhrase}
                        onClick={() => {
                            setUiData({...uiData, searchPeopleModalKey: 'commissionRecipient'})
                        }}>
                    </Input>

                <br />
                <br />
                <h3>Vendor</h3>
                <AnchoredSelect data-cy="dropdown_vendor_id"
                                value={offerFields.vendorId}
                                onChange={(e) => {
                                    setOfferFields({...offerFields, vendorId: e});
                                }}
                                showSearch={true}
                                optionFilterProp={"children"}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    vendors.map((p, i) =>
                                        <Select.Option data-cy={`dropdown_vendor_${i}`} key={p.Code} value={p.Code}>{p.Bezeichnung}</Select.Option>
                                    )
                                }
                </AnchoredSelect>

                <br />
                <br />
                <h3>Aussendienstmitarbeiter</h3>
                {/* <AnchoredSelect data-cy="dropdown_sales_representitive"
                    size="large"
                    placeholder="Lorem ipsum"
                    className={styles.dropdown}
                    defaultValue={offerFields.salesRepresentitive}
                    onChange={(v) => {setOfferFields({...offerFields, salesRepresentitive: v})}}>
                    <Select.Option data-cy="option_1" key={0} value="type1">Values 1</Select.Option>
                    <Select.Option data-cy="option_2" key={1} value="type2">Values 2</Select.Option>
                </AnchoredSelect> */}
                <AnchoredSelect data-cy="dropdown_sales_representitive"
                                value={offerFields.salesRepresentitive}
                                onChange={(e) => {
                                    setOfferFields({...offerFields, salesRepresentitive: e});
                                }}
                                showSearch={true}
                                optionFilterProp={"children"}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    props.salesPersons.map((p, i) =>
                                        <Select.Option data-cy={`dropdown_sales_person_${i}`} key={p.id} value={p.id.toString()}>{p.name}</Select.Option>
                                    )
                                }
                </AnchoredSelect>
                <br />
                <br />
                <BankConnection
                    bankConnections={props.bankConnections || []}
                    onDataChanged={(data) => setOfferFields({...offerFields, ...data})}
                    userName={props.userName}
                />
                <br />
                <br />
                <div className={styles.buttonsGroup} >
                    <Button data-cy="button_save"
                        style={{marginLeft: 'auto', display: 'block'}}
                        type="primary"
                        size="small"
                        htmlType="submit"
                        onClick={onOfferClicked}>
                        Direkt zum Vertrag
				</Button>
                    <Button data-cy="button_save"
                        style={{marginLeft: '20px', display: 'block'}}
                        type="primary"
                        size="small"
                        htmlType="submit"
                        onClick={onOfferClicked}>
                        Angebot Drucken
				</Button>
                </div>
                <SearchPeopleModal 
            	isOpen={!!uiData.searchPeopleModalKey}
                onOk={(row) => {
                    const phrase = `${row.firstName || ""} ${row.lastName || ""}`;
                    
                    const idKey = `${uiData.searchPeopleModalKey}Id`;
                    const phraseKey = `${uiData.searchPeopleModalKey}Phrase`;
     
                    setOfferFields({...offerFields, [idKey]: row.userId })
                    setUiData({...uiData, searchPeopleModalKey: '', [phraseKey]: phrase})
                }}
                onCancel={() => {
                    setUiData({...uiData, searchPeopleModalKey: '' })
                }}
            />
            </div>
        </Form>
    );
}


interface GenerateOfferPdfProps {
    onOfferGenerated: (offerGenerated) => void;
    selectedOffers: OffersDataExtened[];
    userData: UserData;
}

export const GenerateOfferPdf = (props: GenerateOfferPdfProps) => {
    const [pdfFields, setPdfFields] = useState({
        ...(replaceDataFromTemplate({userData: props.userData, calculations: props.selectedOffers.map(o => o.calculation), offers: props.selectedOffers, objects: props.selectedOffers[0].objects}))
    });

    useEffect(() => {
        const data = {...(replaceDataFromTemplate({userData: props.userData, calculations: props.selectedOffers.map(o => o.calculation), offers: props.selectedOffers, objects: props.selectedOffers[0].objects}))};
        for (let index = 3; index > props.selectedOffers.length; index--) {
            delete data[`offer${index}Left`];
            delete data[`offer${index}Right`];
        }
        setPdfFields({...data});
    }, [props.selectedOffers]);

    function replaceFields(data, template) {
        const replacedInitialValues = {};
        for (const key in template) {
            if (Object.prototype.hasOwnProperty.call(template, key)) {
                const section = template[key];
                if (Array.isArray(data)) {
                    replacedInitialValues[key] = Object.keys(data).reduce((p, c) => {
                        const objectOfArray = data[c];
                        let replaceResult = p;
                        Object.keys(objectOfArray).forEach(g => {
                            replaceResult = replaceResult.replace(new RegExp(`\{\\[${c}\\]\.${g}\}`, 'g'), objectOfArray[g]);
                        });
                        return replaceResult;
                    }, section)
                } else {
                    replacedInitialValues[key] = Object.keys(data).reduce((p, c) => {
                        return p.replace(new RegExp(`\{${c}\}`, 'g'), data[c]);
                    }, section)
                }
            }
        }
        return replacedInitialValues as typeof initialValues;
    }

    function replaceDataFromTemplate({userData, calculations, objects, offers}) {
        let modifiedTemplate = replaceFields({addon: '', ...userData}, initialValues);
        modifiedTemplate = replaceFields({...objects.find(o => o.type === ObjectType.MAIN)}, modifiedTemplate);
        modifiedTemplate = replaceFields(calculationsWithCorrectFormat(calculations), modifiedTemplate);
        modifiedTemplate = replaceFields(offers.map(o => { return {...o, referenceId: o.akfOfferId || o.internalId || ''}}), modifiedTemplate);

        return modifiedTemplate;
    }

    function calculationsWithCorrectFormat(calculations) {
        return calculations.map(c => {
            return {
                ...c,
                loanAmount: c.loanAmount ? parseFloat((c.loanAmount).toFixed(2)).toLocaleString('de-DE', {style: 'currency', currency: 'EUR'}) : '',
                downpayment: c.downpayment ? parseFloat((c.downpayment).toFixed(2)).toLocaleString('de-DE', {style: 'currency', currency: 'EUR'}) : '',
                customMonthlyRate: parseFloat((getMonthlyRate(c)).toFixed(2)).toLocaleString('de-DE', {style: 'currency', currency: 'EUR'}),
                paymentsPerYear: mapPaymentsPerYearToString(c.paymentsPerYear),
                discountLabel: c.discount ? 'Auszahlungskurs:' : '',
                    discountText: c.discount ? `${100 - getDiscountPercentage(c)}%` : ''
            }
        })
    }

    async function onGeneratePdfOfferClicked() {
        try {
            const data = {
                ...pdfFields,
                selectedOffers: props.selectedOffers.map(o => o.offerId)
            };
            const response = await ApiManager.ApplicationService.generatePdfOffer(data);

            props.onOfferGenerated(response.data);
            message.success('Daten Gespeichert', 2)
        } catch (e) {
            console.error(e)
            message.error('Konnte nicht Speichern', 2)
        }
    }

    return (
        <div className={styles.offerFieldsInner}>
            <h3>Anschrift des Kunden</h3>
            <Input.TextArea
                rows={6} value={pdfFields.customerAddress} placeholder="" onChange={
                    (e) => {setPdfFields({...pdfFields, customerAddress: e.target.value})}
                }></Input.TextArea>
            <br />
            <br />
            <h3>Begrüssung</h3>
            <Input.TextArea
                rows={6} value={pdfFields.greeting} placeholder="" onChange={
                    (e) => {setPdfFields({...pdfFields, greeting: e.target.value})}
                }></Input.TextArea>
            <br />
            <br />
            {props.selectedOffers.map((c, i) => <React.Fragment>
                <h3>Angebot {i + 1}</h3>
                <div>
                    <Input.TextArea
                        className={styles.textArea + ' ' + styles.margintRight10}
                        rows={9} value={pdfFields[`offer${i + 1}Left`]} placeholder="" onChange={
                            (e) => {setPdfFields({...pdfFields, [`offer${i + 1}Left`]: e.target.value})}
                        }></Input.TextArea>
                    <Input.TextArea
                        className={styles.textArea}
                        rows={9} value={pdfFields[`offer${i + 1}Right`]} placeholder="" onChange={
                            (e) => {setPdfFields({...pdfFields, [`offer${i + 1}Right`]: e.target.value})}
                        }></Input.TextArea>
                </div>
                <br />
            </React.Fragment>)}
            <h3>Zusatzkommentar</h3>
            <div>
                <Input.TextArea
                    rows={6} value={pdfFields.additionalComment} placeholder="" onChange={
                        (e) => {setPdfFields({...pdfFields, additionalComment: e.target.value})}
                    }></Input.TextArea>
            </div>
            <br />
            <h3>Rückfrage</h3>
            <div>
                <Input.TextArea
                    rows={3} value={pdfFields.inquire} placeholder="" onChange={
                        (e) => {setPdfFields({...pdfFields, inquire: e.target.value})}
                    }></Input.TextArea>
            </div>
            <br />
            <h3>Abschied</h3>
            <div>
                <Input.TextArea
                    rows={3} value={pdfFields.farewell} placeholder="" onChange={
                        (e) => {setPdfFields({...pdfFields, farewell: e.target.value})}
                    }></Input.TextArea>
            </div>
            <br />
            <div className={styles.buttonsGroup} >
                <Button data-cy="button_save"
                    style={{marginLeft: 'auto', display: 'block'}}
                    type="primary"
                    size="small"
                    htmlType="submit"
                    onClick={() => setPdfFields({...initialValues})}
                >
                    Zurücksetzen
				</Button>
                <Button data-cy="button_save"
                    style={{marginLeft: '20px', display: 'block'}}
                    type="primary"
                    size="small"
                    htmlType="submit"
                    onClick={() => onGeneratePdfOfferClicked()}>
                    Speichern
				</Button>
            </div>
        </div>
    );
}

const RenderLabel = (label) => {
    return (
        <div>
            <span>
                {label}
            </span>
        </div>
    );
}

const options = [
    {
        label: RenderLabel('Test name 1'),
        value: '1'
    },
    {
        label: RenderLabel('Test name 2'),
        value: '2'
    },
    {
        label: RenderLabel('Test name 3'),
        value: '3'
    }
];

// const Guarantors = (props) => {
//     const [selectedContacts, setSelectedContacts] = useState({} as any);
//     const contacts = [
//         {
//             contactId: 1,
//             contactName: 'Test name 1'
//         },
//         {
//             contactId: 2,
//             contactName: 'Test name 2'
//         },
//         {
//             contactId: 3,
//             contactName: 'Test name 3'
//         }
//     ];
//     return (
//         <React.Fragment>
//             <AutoComplete
//                 options={options}
//             >
//                 <Input.Search size="large" placeholder="Person Suchen" />
//             </AutoComplete>
//             <br />
//             <br />
//             <ListContacts
//                 contacts={contacts.map(o => {return {...o, selected: o.contactId === selectedContacts.contactId}})}
//                 onClick={(o) => setSelectedContacts(o)}
//             />
//         </React.Fragment>
//     );
// }


const toCalculationLabel = (c) => {
    const parsed = parseLocaleFormattedFloat(c.loanAmount.toString())
    const internalWithPrecision = currencyLocaleFormatter(parsed, 2)

	let text = `${c.loanDuration} Lfz. - ${internalWithPrecision} € - ${c.annualInterestRate}%${c.discountPercentage > 0 ? ` - ${c.discountPercentage}% Dis` : ''}
    ${(c.repaymentPlan).some(p => p.isCustomAmount) ? ' - inkl. Staffel' : ''}`

    if(c.isBoundToAnyContract) {
        text = `${text} - VT${c?.offer?.akfOfferId ? ` - <span style="font-weight: 900;">${c?.offer?.akfOfferId}-A</span>` : ''}`
    } else if(c.isBoundToAnyOffer) {
        text = `${text} - ANG${c?.offer?.akfOfferId ? ` - <span style="font-weight: 900;">${c?.offer?.akfOfferId}-A</span>` : ''}`
    }

	return text;
}

// function toCalculationLabel(data) {
//     let text = data.calculationName;

//     if (data.interestRate) {
//         text = text + ` - ${parseFloat(data.interestRate).toFixed(2)}%`;
//     }

//     if (data.loanDuration) {
//         text = text + ` - ${data.loanDuration} Monate`;
//     }

//     if (data.loanAmount) {
//         text = text + ` - €${data.loanAmount}`;
//     }

//     return text;
// }

const customerAddressFragmentValue = `{title} {firstName} {lastName}
{addon}
{addressLine1} {addressStreetNumber}
{addressPostalCode} {addressCity}
`;

const greetingFragmentValue = `Sehr geehrter {title} {lastName},

wie besprochen unterbreiten wir Ihnen auf Basis der aktuellen Geld- und
Kapitalmarktverhältnisse unser freibleibendes Kreditangebot:

`;

const offer1LeftFragmentValue = `Investitionsobjekt:
Investitionssumme:
abzüglich Anzahlung:

Laufzeit:
Monatliche Rate:

Blockrate:
Einzug:
Zinssatz:
{[0].discountLabel}`;


const offer1RightFragmentValue = `{objectName}
{[0].loanAmount}
{[0].downpayment}

{[0].loanDuration} Monate
{[0].customMonthlyRate}

0,00
{[0].paymentsPerYear}
{[0].annualInterestRate} %p.a.
{[0].discountText}`;

const offer2LeftFragmentValue = `Investitionsobjekt:
Investitionssumme:
abzüglich Anzahlung:

Laufzeit:
Monatliche Rate:

Blockrate:
Einzug:
Zinssatz:
{[1].discountLabel}`;


const offer2RightFragmentValue = `{objectName}
{[1].loanAmount}
{[1].downpayment}

{[1].loanDuration} Monate
{[1].customMonthlyRate}

0,00
{[1].paymentsPerYear}
{[1].annualInterestRate} %p.a.
{[1].discountText}`;


const offer3LeftFragmentValue = `Investitionsobjekt:
Investitionssumme:
abzüglich Anzahlung:

Laufzeit:
Monatliche Rate:

Blockrate:
Einzug:
Zinssatz:
{[1].discountLabel}`;


const offer3RightFragmentValue = `{objectName}
{[2].loanAmount}
{[2].downpayment}

{[2].loanDuration} Monate
{[2].customMonthlyRate}

0,00
{[2].paymentsPerYear}
{[2].annualInterestRate}
{[2].discountText}`;

const inquireFragmentValue = `Bei Rückfragen stehen wir Ihnen gerne unter Angabe Ihrer
Angebotsnummer {[0].referenceId} zur Verfügung.`;

const farewellFragmentValue = `Mit freundlichen Grüßen

akf bank GmbH & Co KG`;

const initialValues = {
    customerAddress: customerAddressFragmentValue,
    greeting: greetingFragmentValue,
    offer1Left: offer1LeftFragmentValue,
    offer1Right: offer1RightFragmentValue,
    offer2Left: offer2LeftFragmentValue,
    offer2Right: offer2RightFragmentValue,
    offer3Left: offer3LeftFragmentValue,
    offer3Right: offer3RightFragmentValue,
    additionalComment: '',
    inquire: inquireFragmentValue,
    farewell: farewellFragmentValue
};

const distinctOffersByDocumentUrl = (offers: any[]) => {
    if (!offers) {
        return [];
    }

    const f = offers.reduce((acc, x) => {
        const foundElement = acc.find(o => o.offerDocumentUrl === x.offerDocumentUrl)
        if (foundElement) {
            return acc;
        }
        return [...acc, x];
    }, [])

    return f;
}
