import * as React from "react";
import styles from "components/common/pages/example-calculator-page/ExampleCalculatorPage.module.scss";
import {SideMenu} from "components/common/presenters/side-menu/SideMenu";
import {Calculator} from "../../presenters/calculator/Calculator";
import {useTranslation} from "react-i18next";

export const ExampleCalculatorPage = () => {
	const { t } = useTranslation()

	return (
		<div className={styles.page}>

			<SideMenu />

			<div className={styles.sectionRight}>
				<div className={styles.calcContainer}>
					<h3>{t('example_calculator')}</h3>
					<Calculator isExampleCalculator={true} />
				</div>
			</div>

		</div>
	);
};

