class UiHelper {

    public readonly SCALED_DOWN_VERSION_BREAKPOINT_WIDTH_PX = 1100;
    public readonly MOBILE_VERSION_BREAKPOINT_WIDTH_PX = 650;
    public readonly MOBILE_MENU_BREAKPOINT_WIDTH_PX = 1350;


    public downloadPDFFromBase64(pdf: string, fileName) {
        const linkSource = `data:application/pdf;base64,${pdf}`;

        const downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    public downloadJsonFile(data: object, fileName) {
        const linkSource = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));

        const downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    public viewPDFFromBase64(pdf: string) {
        const linkSource = `data:application/pdf;base64,${pdf}`;

        const w = window.open('about:blank');
        // FireFox seems to require a setTimeout for this to work.
        setTimeout(function () {
            const pdfTab = w.document.body.appendChild(w.document.createElement('iframe'));
            pdfTab.width = '100%';
            pdfTab.height = '100%';
            pdfTab.src = linkSource;
        }, 0);
    }

    public downloadPDFFromUrl(url: string, fileName: string) {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent('click'));
    }

    public downloadPDFFromOtherDomain(url, fileName) {
        return new Promise((resolve, reject) => {
            var oReq = new XMLHttpRequest();

            // Configure XMLHttpRequest
            oReq.open("GET", url, true);

            // Important to use the blob response type
            oReq.responseType = "blob";
            // oReq.setRequestHeader('Origin', undefined);

            // When the file request finishes
            // Is up to you, the configuration for error events etc.
            oReq.onload = function () {
                // Once the file is downloaded, open a new window with the PDF
                // Remember to allow the POP-UPS in your browser

                var file = new Blob([oReq.response], {
                    type: 'application/pdf'
                });

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function () {
                    var base64String = reader.result;

                    const link = document.createElement('a');
                    link.href = base64String as string;
                    link.download = fileName;
                    link.dispatchEvent(new MouseEvent('click'));
                }

            };

            oReq.send();
        });
    }

    public viewPDFFromOtherDomain(url) {
        return new Promise((resolve, reject) => {
            var oReq = new XMLHttpRequest();

            // Configure XMLHttpRequest
            oReq.open("GET", url, true);

            // Important to use the blob response type
            oReq.responseType = "blob";

            // When the file request finishes
            // Is up to you, the configuration for error events etc.
            oReq.onload = function () {
                // Once the file is downloaded, open a new window with the PDF
                // Remember to allow the POP-UPS in your browser

                var file = new Blob([oReq.response], {
                    type: 'application/pdf'
                });

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function () {
                    var base64String = reader.result;

                    const w = window.open('about:blank');
                    // FireFox seems to require a setTimeout for this to work.
                    setTimeout(function () {
                        const pdfTab = w.document.body.appendChild(w.document.createElement('iframe'));
                        pdfTab.width = '100%';
                        pdfTab.height = '100%';
                        pdfTab.src = base64String as any;
                    }, 0);
                }

            };

            oReq.send();
        });
    }

    public openUrlInNewTab(url: string) {
        try {
            const fileTab = window.open(url, '_blank');
            fileTab.focus();
        }
        catch (e) {
            console.error(e);
        }
    }

    public downloadFileFromUrl(url: string, fileName: string) {
        const linkSource = url;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }

    public timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}

export default new UiHelper();
