import {SearchFields} from "services/api/akfIntegrationService";
import ApiManager from "./ApiManager";
import {ApiWrapper} from "./ApiWrapper";
import {BankConnection, BoUserProfile, ContactPerson, CreditworthinessReportType, DocumentOrigin, SelfDeclaration, UserData, UserDocumentCategory} from "./types";
import throttle from 'lodash.throttle';

enum UserEmbedEnum {
    ContactPerson = 'contact_persons',
    BankConnections = 'bank_connections',
    Applications = 'applications',
    Calculations = 'calculations',
    ApplicationDocuments = 'application_documents',
    UserDocuments = 'user_documents',
    Objects = 'objects',
    Offers = 'offers',
    Contracts = 'contracts',
    MarketVotes = 'market_votes',
    Creditworthiness = 'creditworthiness'
}

const uploadUserDocument = async (file: any, userId: number, documentCategory: UserDocumentCategory, documentOrigin: DocumentOrigin, documentName: string) => {
    const path = '/user_documents';

    let formData = new FormData();
    formData.append("file", file);
    formData.append("userId", userId.toString());
    formData.append("documentCategory", documentCategory.toString());
    formData.append("documentOrigin", documentOrigin.toString());
    formData.append("documentName", documentName);

    const apiCall = ApiWrapper.postFormWithFile(path, formData);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getUserDocuments = async (userId: number) => {
    const path = `/users/${userId}/documents`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getUserContacts = async (userId: number) => {
    const path = `/users/${userId}/contacts`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getUserDocumentSignedUrl = async (userDocumentId: number) => {
    const path = `/user_documents/${userDocumentId}/signed_url`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const deleteFile = async (fileId) => {
    const path = `/user_documents/${fileId}`;
    const body = {
    };

    const apiCall = ApiWrapper.del(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const addUser = async (data: UserData, newBankConnection?: BankConnection) => {
    const path = '/users';
    const body = {
        ...data,
    };

    if (newBankConnection) {
        body["newBankConnection"] = newBankConnection
    }

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const syncAkfUser = async (data: UserData, newBankConnection?: BankConnection) => {
    const path = '/users/sync_akf_user';
    const body = {
        ...data,
    };

    if (newBankConnection) {
        body["newBankConnection"] = newBankConnection
    }

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};


export interface AutoSyncAkfUser {
    akfApiCustomerId: number;
    userId: number;
    bankUserId: number;
}


const autoSyncAkfUser = async (data: AutoSyncAkfUser,) => {
    const path = '/users/auto_sync_akf_user';
    const body = {
        akfApiCustomerId: data.akfApiCustomerId,
        userId: data.userId,
        bankUserId: data.bankUserId
    };

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

export interface SearchAddressResponse {
    Description: string,
    Highlight: string,
    Id: string,
    Text: string,
    Type: string
}

const searchAddress = async (addressText: string, container: string) => {
    const path = `/address_search`;
    const body = {
        text: addressText,
        container 
    };

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};


export interface RietreveAddressResponse {
    Id: string,
    BuildingNumber: string,
    Street: string,
    Block: string,
    City: string,
    PostalCode: string,
    CountryName: string
}

const getAddress = async (addressId: string) => {
    const path = `/address_search/${addressId}`;
    const body = {
    };

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const updateUser = async (userId: number, data: UserData) => {
    const path = `/users/${userId}`;
    const body = {
        ...data
    };
    Reflect.deleteProperty(body, 'userId')
    Reflect.deleteProperty(body, 'createdAt')
    Reflect.deleteProperty(body, 'offers')
    Reflect.deleteProperty(body, 'userDocuments')

    const apiCall = ApiWrapper.patch(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const saveSelfDeclaration = async (userId: number, data: SelfDeclaration) => {
    const path = `/self_declarations`;
    const body = {
        ...data,
        userId
    };

    delete body['selfDeclarationId']
    delete body['createdAt']
    delete body['submittedAt']
    body.persons?.forEach(p => {
        delete p['selfDeclarationId']
        delete p['selfDeclarationPersonId']
        delete p['createdAt']
    })

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const submitSelfDeclaration = async (userId: number, data: SelfDeclaration) => {
    const path = `/self_declarations/submit`;
    const body = {
        ...data,
        userId
    };

    delete body['selfDeclarationId']
    delete body['createdAt']
    delete body['submittedAt']
    body.persons?.forEach(p => {
        delete p['selfDeclarationId']
        delete p['selfDeclarationPersonId']
        delete p['createdAt']
    })

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const saveSelfDeclarationByShareToken = async (shareToken: string, data: SelfDeclaration) => {
    const path = `/self_declarations/share/${shareToken}`;
    const body = {
        ...data
    };

    delete body['userId']
    delete body['selfDeclarationId']
    delete body['createdAt']
    delete body['submittedAt']
    body.persons?.forEach(p => {
        delete p['selfDeclarationId']
        delete p['selfDeclarationPersonId']
        delete p['createdAt']
    })

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const submitSelfDeclarationByShareToken = async (shareToken: string, data: SelfDeclaration) => {
    const path = `/self_declarations/share/${shareToken}/submit`;
    const body = {
        ...data
    };

    delete body['userId']
    delete body['selfDeclarationId']
    delete body['createdAt']
    delete body['submittedAt']
    body.persons?.forEach(p => {
        delete p['selfDeclarationId']
        delete p['selfDeclarationPersonId']
        delete p['createdAt']
    })

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getUser = async (userId, embed) => {
    let path = `/users/${userId}`;
    if (embed && embed.length) {
        path = `${path}?_embed=${embed.join(',')}`
    }

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const shareSelfDeclaration = async (userId: number, email?: string) => {
    const path = `/users/${userId}/share/self_declaration`;
    let body = {}

    if (email) {
        body = {
            email: email
        }
    }

    const apiCall = ApiWrapper.post(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
}

const getLatestSelfDeclarationForUser = async (userId: number) => {
    const path = `/users/${userId}/self_declarations/latest`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getLatestSelfDeclarationByShareToken = async (shareToken: string) => {
    const path = `/self_declarations/share/${shareToken}/latest`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getUsers = async (pageIndex, perPage, userTypes, searchTerm, searchFields?: SearchFields, userIds?: number[]) => {
    let path = `/users?page=${pageIndex}&per_page=${perPage || 10}`;
    if (userTypes.length) {
        path = `${path}&user_type=${userTypes.join(',')}`;
    }
    if (searchTerm) {
        path = `${path}&q=${encodeURIComponent(searchTerm)}`;
    }
    if (userIds) {
        path=`${path}&user_ids=${userIds.join(',')}`
    }

    if (searchFields) {
        for (const field in searchFields) {
            if (searchFields[field]) {
                path = `${path}&${field}=${encodeURIComponent(searchFields[field])}`;
            }
        }
    }

    const apiCall = ApiWrapper.get(path);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const checkBoUserAuth = async () => {
    const apiCall = ApiWrapper.get('/bo_users/testauth');

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, null);
};

const refreshSession = async () => {
    const path = '/refresh_session';

    const apiCall = ApiWrapper.post(path, {});

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "");
};

const loginBoUser = async (email: string, password: string) => {
    const path = '/bo_users/login';
    const body = {
        email: email,
        password: password
    };

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "user");
};


const registerBoUser = async (token: string, password: string) => {
    const path = '/bo_users/register';

    const body = {
        invite_token: token,
        password: password
    };

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, null);
};

const logoutBoUser = async () => {
    const path = '/bo_users/logout';
    const body = {};

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);


    return networkCall;
    // return ApiManager.handleNetworkCallResult(networkCall, "user");
};

const getSalesPersons = async () => {
    const path = '/bo_users/sales_persons';

    const apiCall = ApiWrapper.get(path);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const createContactPerson = async (contactPerson: ContactPerson) => {
    const path = `/contact_persons`;
    const body = {
        ...contactPerson,
    }

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const updateContactPerson = async (contactPerson: ContactPerson) => {
    const path = `/contact_persons/${contactPerson.contactPersonId}`;
    const body = {
        ...contactPerson
    }
    delete body.contactPersonId
    delete body.createdAt

    const apiCall = ApiWrapper.patch(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const createCreditworthiness = async (creditworthiness: {userId: number, type: CreditworthinessReportType}) => {
    const path = `/creditworthiness`;
    const body = {
        ...creditworthiness,
    }

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const checkDocumentScore = async (file: any, documentCategory: UserDocumentCategory) => {
    const path = '/creditworthiness/check_score';

    let formData = new FormData();
    formData.append("file", file);
    formData.append("documentCategory", documentCategory.toString());

    const apiCall = ApiWrapper.postFormWithFile(path, formData);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const updateScore = async (data: {userId: number, userDocumentId: number, score: string, documentCategory: UserDocumentCategory}) => {
    const path = '/creditworthiness/update_score';

    const body = {
        ...data
    }

    const apiCall = ApiWrapper.patch(path, body);


    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getProfileInfo = async () => {
    let path = `/bo_users/profile`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getBOUsers = async () => {
    let path = `/bo_users`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const changePassword = async (oldPassword: string, newPassword: string) => {
    let path = `/bo_users/change_password`;

    const body = {
        old_password: oldPassword,
        new_password: newPassword,
    }

    const apiCall = ApiWrapper.post(path, body);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};


const getCreditworthiness = async (userId: number) => {
    let path = `/creditworthiness?userId=${userId}`;

    const apiCall = ApiWrapper.get(path);

    // One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const createBOUser = async (profileData: BoUserProfile) => {
    let path = `/bo_users`;

    const body = {
        ...profileData,
    }
    delete body.bo_user_id
    delete body.created_at

    const apiCall = ApiWrapper.post(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};


const getCreditworthinessPdf = async (userDocumentId) => {
    const path = `/user_documents/${userDocumentId}/signed_url`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const updateBOUser = async (profileData: BoUserProfile) => {
    let path = `/bo_users/${profileData.bo_user_id}`;

    const body = {
        ...profileData,
    }
    delete body.bo_user_id
    delete body.created_at
    delete body.is_available

    const apiCall = ApiWrapper.patch(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const disableBOUser = async (boUserId: number) => {
    let path = `/bo_users/${boUserId}/disable`;

    const apiCall = ApiWrapper.patch(path, {});

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const enableBOUser = async (boUserId: number) => {
    let path = `/bo_users/${boUserId}/enable`;

    const apiCall = ApiWrapper.patch(path, {});

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getSettings = async () => {
    let path = `/settings`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const updateSettings = async (subPath: string, file: any) => {
    const path = `/settings/${subPath}`;

    let formData = new FormData();
    formData.append("file", file);

    const apiCall = ApiWrapper.patchFormWithFile(path, formData);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getDealershipConditions = async () => {
    const path = `/dealership_conditions`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const getDealershipConditionGroups = async () => {
    const path = `/dealership_conditions/groups`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const deleteDealershipConditionGroup = async (dealerName: string, conditionType: string) => {
    const path = `/dealership_conditions/group`;
    const body = {
        dealerName,
        conditionType
    }

    const apiCall = ApiWrapper.del(path, body);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

const uploadDealershipConditions = async (file: any, dealerName: string, conditionType: string) => {
    const path = `/dealership_conditions`;

    let formData = new FormData();
    formData.append("file", file);
    formData.append("dealerName", dealerName);
    formData.append("conditionType", conditionType);

    const apiCall = ApiWrapper.postFormWithFile(path, formData);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    return ApiManager.handleNetworkCallResult(networkCall, "data");
};

export default {
    UserEmbedEnum,
    uploadUserDocument,
    getUserDocuments,
    getUserContacts,
    getUserDocumentSignedUrl,
    deleteFile,
    addUser: throttle(addUser, 2000, {trailing: false}),
    syncAkfUser,
    autoSyncAkfUser,
    searchAddress,
    getAddress,
    getUser,
    getUsers,
    checkBoUserAuth,
    refreshSession,
    loginBoUser,
    registerBoUser,
    logoutBoUser,
    updateUser,
    saveSelfDeclaration,
    submitSelfDeclaration: throttle(submitSelfDeclaration, 2000, {trailing: false}),
    saveSelfDeclarationByShareToken,
    submitSelfDeclarationByShareToken: throttle(submitSelfDeclarationByShareToken, 2000, {trailing: false}),
    getLatestSelfDeclarationByShareToken,
    shareSelfDeclaration,
    getSalesPersons,
    createContactPerson: throttle(createContactPerson, 2000, {trailing: false}),
    updateContactPerson,
    createCreditworthiness: throttle(createCreditworthiness, 2000, {trailing: false}),
    checkDocumentScore,
    updateScore,
    getCreditworthiness,
    getCreditworthinessPdf,
    getProfileInfo,
    getBOUsers,
    changePassword,
    createBOUser: throttle(createBOUser, 2000, {trailing: false}),
    updateBOUser,
    disableBOUser,
    enableBOUser,
    getSettings,
    updateSettings,
    getDealershipConditions,
    getDealershipConditionGroups,
    deleteDealershipConditionGroup,
    uploadDealershipConditions,
};
