import {PostMarketVoteResponse} from "./response/marketVote";
import {PaymentsPerYear} from "../calculator/types";
import { ContractType as DocumentContractType } from "components/common/presenters/application-page-tabs/contracts-tab/PdfComponents";

export interface ApplicationAndLeadsFilterParams {
    riskFilter?: boolean;
    salesFilter?: boolean;
}

export enum UserType {
    Kunde = 'kunde',
    Person = 'person',
    Lieferant = 'lieferant',
    Handler = 'handler',
    HandlerLieferant = 'handler-lieferant',
    Vermittler = 'vermittler',
    Hersteller = 'hersteller',
}

export enum DistributionChannel {
    Direct = 'Direkt',
    Sales = 'Absatz'
}

export enum ContractType {
    CREDIT = 'credit',
    RENTAL_PURCHASE = 'rental_purchase'
}

export enum ClientType {
    COMMERCIAL = 'commercial',
    PRIVATE = 'private'
}

export enum DocumentType {
    IdCard = 'IdCard',
    Passport = 'Reisepass'
}

export enum BoUserRole {
    ADMIN = "admin",
    SALES_REPRESENTATIVE = "sales_rep",
}

export interface BoUserProfile {
    bo_user_id: number
    email: string
    name?: string
    salutation?: string
    phone_number?: string
    location?: string
    is_available: boolean
    role: BoUserRole
    created_at?: string
}

export interface ApplicationData {
    applicationId: number;
    bankId?: string;
    createdBy: number;
    userId: number;
    isLocked: boolean;
    submittedAt?: Date;
    selectedCalculation?: number;
    selectedOffer?: number;
    commissionRecipientId?: number;
    commissionRecipientName?: string;
    rewardShopRecipientId?: number;
    vendorId?: string;
    isUserChecked?: boolean;
    isOfferChecked?: boolean;
    isMarketVoteChecked?: boolean;
    isSpecialPermitChecked?: boolean;
    branch?: Branch;
    employeeCreditRating?: EmployeeCreditRating;
    employeeCompletion?: EmployeeCompletion;
    createdAt?: Date;
    // user relation properties
    email?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    // object relation properties
    mainObjectName?: string;
    // embedded objects //TODO add these types
    objects?: ObjectData[];
    calculations?: Calculation[];
    offers?: OffersData[];
    contracts?: ContractData[];
    contractDrafts?: any[];
    applicationDocuments?: ApplicationDocument[];
    marketVotes?: PostMarketVoteResponse[];
}

export interface ApplicationDocument {
    applicationDocumentId: number
    applicationId: number
    userId: number
    documentCategory?: ApplicationDocumentCategory
    documentName?: string
    sourceUrl?: string
    createdAt: Date
}

export enum ApplicationDocumentCategory {
    MARKTVOTUM = 'marktvotum',
    PURCHASE_OFFER = 'purchase_offer', // the offer document that the customer receives when they want to buy e.g. a tractor
    ORDER_CONFIRMATION = 'order_confirmation',
    INVOICE = 'invoice',
    PROOF_OF_PAYMENT = 'proof_of_payment',
    OTHER_DOCUMENTS = 'other_documents',
}

export enum Branch {
    Berlin = 'berlin',
    Mittweida = 'mittweida',
    Wuppertal = 'wuppertal',
    Stuttgart = 'stuttgart'
}

export enum EmployeeCreditRating {
    LOREM1 = 'lorem1',
    LOREM2 = 'lorem2',
}

export enum EmployeeCompletion {
    IPSUM1 = 'ipsum1',
    IPSUM2 = 'ipsum2',
}

export interface UserData {
    userId?: number
    isAkf?: boolean
    salesRepresentativeId?: string
    bankUserId?: number
    akfApiCustomerId?: number
    userType?: string[]
    email?: string
    firstName?: string
    lastName?: string
    password?: string
    title?: string
    salutation?: string
    addressLine1?: string
    addressStreetNumber?: string
    addon?: string
    addressCity?: string
    addressPostalCode?: string
    addressCountryId?: number
    country?: string
    oldschoolphoneNumber?: string
    mobileNumber?: string
    faxNumber?: string
    birthDate?: string // Date
    birthCity?: string
    nationalityCountryId?: number
    birthCountryId?: number
    birthCountry?: string
    nationality?: string
    contactLanguageId?: number
    taxId?: string
    vatTaxId?: string
    taxNumber?: string
    isPrivate?: boolean
    distributionChannel?: string
    gender?: string
    matchCode?: string

    // Company-related
    industryTypeId?: number
    companyTypeId?: number
    companyName?: string
    companyRegisterNumber?: string
    companyUrl?: string
    foundedOn?: string // Date

    // User Identification
    idOn?: string // Date
    idBy?: string
    idDoc?: string
    idNumber?: string
    idIssuedBy?: string
    createdAt?: string // Date

    // embedded fields
    iban?: string
    blz?: string
    userDocuments?: UserDocument[]
    applications?: ApplicationData[]
    calculations?: Calculation[]
    offers?: OffersData[]
    bankConnections?: BankConnection[]
    contactPersons?: ContactPerson[]
    objects?: ObjectData[]
    contracts?: ContractData[]
    latestSelfDeclaration?: SelfDeclaration
    creditworthiness?: Creditworthiness[]
}

export interface BankConnection {
    bankConnectionId: number
    creditInstitutionId: number
    userId: number
    accountOwner: string
    iban: string
    createdAt?: string
}

export interface UserDocument {
    userDocumentId: number
    userId: number
    documentCategory: UserDocumentCategory
    documentName: string
    sourceUrl: string
    createdAt?: Date
    validUntil: Date
}

export interface ContactPerson {
    contactPersonId?: number;
    contactPersonFunctionId?: number;
    userId: number;
    roles?: ContactPersonRole[];
    salutation?: string;
    title?: string;
    firstName?: string;
    lastName?: string;
    addon?: string;
    streetAndHouseNumber?: string;
    addressLine1?: string;
    addressStreetNumber?: string;
    addressPostalCode?: string;
    addressLine2?: string;
    addressCity?: string;
    addressCountryId?: number;
    addressCountry?: string;
    phoneNumber?: string;
    fax?: string;
    mobilePhone?: string;
    email?: string;
    contactUrl?: string;
    taxNumber?: string;
    taxId?: string;
    dateOfBirth?: string; // Date
    placeOfBirth?: string;
    gender?: string;
    others?: string;
    finishedAt?: string; // Date
    active?: boolean;
    createdAt?: Date;
    function?: string;
    isManagingDirector: boolean;
    isBeneficialOwner: boolean;
    isShareholder: boolean;
    companyPosition: string
}

export enum ContactPersonRole {
    MANAGING_DIRECTOR = 'managing_director',
    BENEFICIAL_OWNER = 'beneficial_owner',
    SHAREHOLDER = 'shareholder',
}

export interface ObjectData {
    objectId: number
    userId: number;
    applicationId?: number;
    type: ObjectType;
    objectName: string;
    description?: string;
    manufacturer?: string;
    serialNumber?: string;
    objectState?: string;
    yearBuilt?: Date;
    location?: string;
    deliveryDate: Date;
    isTitleAvailable?: boolean;
    lieferantId?: number;
    lieferantPhrase?: string;
    handlerId?: number;
    objectCurrency: string;
    price?: number;
    createdAt: Date;
    objectTypeId?: string;
    machineType?: string;
    priceType?: string;
    vehicleLetterAvailable?: boolean;
    deductible?: string;
    features?: string;
    owner?: string;
    payoutAmount?: number;
    firstRegistration?: string;
    insuranceQuote?: boolean;
}

export interface OffersData {
    offerId: number;
    internalId?: string;
    akfOfferId?: string;
    calculationId: number;
    calculation?: Calculation;
    userId: number;
    applicationId: number;
    offerName?: string;
    offerDocumentUrl?: string;
    offerCurrency: string;
    createdAt?: Date;
    fee?: number;
    distributionChannel?: string;
    guarantorAssociatedWithBorrower?: boolean;
    guarantorIndependent?: boolean;
    calculationType?: string;
    commissionRecipientId?: number;
    salesRepresentitive?: string;
    comment1?: string;
    comment2?: string;
    comment3?: string;
    comment4?: string;
    printObjectDescription?: boolean;
    guarantors: [{
        userid: number;
        worth: number;
        securityType: string;
        name: string;
    }],
    guarantorId?: number;
    guarantorName?: string;
    vendorId?: string;
}

export interface OffersDataExtened extends OffersData {
    calculation: Calculation;
    objects: ObjectData[];

    firstName?: string;
    lastName?: string;
    companyName?: string;
    loanAmount?: number;
    loanDuration?: number;
    annualInterestRate?: number;
    mainObjectName?: string;
}


export enum ObjectType {
    MAIN = 'main',
    CHILD = 'child',
}

export interface SalesPerson {
    id: string
    name: string
}

export enum UserDocumentCategoryGroup {
    GENERAL_CORRESPONDENCE = 'general_correspondence',
    INFORMATION = 'information',
    CREDIT_RECORDS = 'credit_records',
    PERMITS = 'permits',
    NOTES_FOR_PERSON = 'notes_for_person',
    COLLATERAL = 'collateral',
}

export enum UserDocumentCategory {
    SELF_DECLARATION_GENERATED = 'self_declaration_generated',

    // Doxis tab categories
    GENERAL_CORRESPONDENCE_PERSON = 'general_correspondence_person',
    HEK_DEBIT_NOTE = 'hek_debit_note',
    PROOF_OF_IDENTITY = 'proof_of_identity',
    BALANCE_CONFIRMATION = 'balance_confirmation',
    SERVICE_ACCOUNT_DOCUMENTS = 'service_account_documents',
    BANK_INFO_POWER_OF_ATTORNEY = 'bank_info_power_of_attorney',
    SA = 'sa',
    SCHUFA = 'schufa',
    VC = 'vc',
    BALANCE_FINANCIAL_STATEMENT = 'balance_financial_statement',
    BALANCE_SHEET_EVALUATIONS = 'balance_sheet_evaluations',
    BWA = 'bwa',
    INCOME_RECORDS = 'income_records',
    FUSER_RATING = 'fuser_rating',
    COMMERCIAL_HR = 'commercial_hr',
    COMMITMENT = 'commitment',
    IDENTITY_CARD = 'identity_card',
    OTHER_CREDIT_DOCUMENTS = 'other_credit_documents',
    CALCULATION_OFFER = 'calculation_offer',
    ASSET_RECORDS = 'asset_records',
    APPROVAL_REJECTED_OE = 'approval_rejected_oe',
    APPROVAL_OF_ENGAGEMENT = 'approval_of_engagement',
    VOTES = 'votes',
    AN_OF_PERSON = 'an_of_person',
    APPLICATION_SCAN = 'application_scan',
    VISIT_NOTES = 'visit_notes',
    REMINDERS_P = 'reminders_p',
    CONNECTION_SECURITY_TRANSFER = 'connection_security_transfer',
    BANK_GUARANTEES = 'bank_guarantees',
    GUARANTEES = 'guarantees',
    LAND_CHARGE = 'land_charge',
    DEPOSIT = 'deposit',
    OTHER_ADDITIONAL_SECURITIES = 'other_additional_securities',

    // TODO find duplications, Marktvotum tab categories
    PROPERTY_INFO_OR_AAW_OA_1401 = 'property_info_or_aaw_oa_1401',
    VISIT_REPORT = 'visit_report',
    PROPERTY_VALUATION_BY_AKF = 'property_valuation_by_akf',
    CREDITREFORM_INFO = 'creditreform_info',
    MAIN_LENDERS_BANK_INFO = 'main_lenders_bank_info',
    SELF_DECLARATION_WITH_SCHUFA_APPROVAL = 'self_declaration_with_schufa_approval',
    BUSINESS_EVALUATION_OF_CUSTOMER = 'business_evaluation_of_customer',
    LAST_ANNUAL_FINANCIAL_STATEMENTS_OF_CUSTOMER = 'last_annual_financial_statements_of_customer',
    BALANCE_SHEET_AND_LAST_EST_NOTICE_OF_CUSTOMER = 'balance_sheet_and_last_est_notice_of_customer',
    BUSINESS_EVALUATION_OF_HOLDING_COMPANY = 'business_evaluation_of_holding_company',
    LAST_ANNUAL_FINANCIAL_STATEMENTS_OF_HOLDING_COMPANY = 'last_annual_financial_statements_of_holding_company',
    ORGANIZATION_CHART_OF_GROUP = 'organization_chart_of_group',
    LAST_CONSOLIDATED_FINANCIAL_STATEMENT_OF_GROUP = 'last_consolidated_financial_statement_of_group',
    ASSETS_LIABILITIES_INCOME_STATEMENT_OF_LAST_CONSOLIDATED_FINANCIAL_STATEMENTS = 'assets_liabilities_income_statement_of_last_consolidated_financial_statements',
    BUSINESS_EVALUATION_OF_GROUP = 'business_evaluation_of_group',
    ANNUAL_FINANCIAL_STATEMENTS_OF_MAJOR_COMPANIES_OF_GROUP = 'annual_financial_statements_of_major_companies_of_group',
    ANNUAL_FINANCIAL_STATEMENTS_OF_PARENT_COMPANY = 'annual_financial_statements_of_parent_company',
    BUSINESS_EVALUATION_OF_PARENT_COMPANY = 'business_evaluation_of_parent_company',
    LAST_FINANCIAL_STATEMENTS_OF_GUARANTOR = 'last_financial_statements_of_guarantor',
    INCOME_TAX_ASSESSMENT_AND_DECLARATION_OF_GUARANTOR = 'income_tax_assessment_and_declaration_of_guarantor',
    BUSINESS_EVALUATION_OF_GUARANTOR = 'business_evaluation_of_guarantor',
    BALANCE_SHEET_OF_GUARANTOR = 'balance_sheet_of_guarantor',
    PLAN_CALCULATION_OF_CURRENT_AND_NEXT_FINANCIAL_YEAR = 'plan_calculation_of_current_and_next_financial_year',
    LIQUIDITY_PLANNING = 'liquidity_planning',
    PROFITABILITY_CALCULATION_OF_INVESTMENT = 'profitability_calculation_of_investment',
}

export enum DocumentOrigin {
    COLD = 'cold',
    FAX = 'fax',
    INTERNAL_DOCUMENTS = 'internal_documents',
    OUTBOX = 'outbox',
    INBOX = 'inbox',
    OTHER = 'other',
    UNDELIVERABLE = 'undeliverable',
}

export enum VehicleType {
    NEW = 'new',
    SECOND_HAND = 'second_hand',
    DEMO = 'demo'
}

export interface PublicSelfDeclarationInfo {
    companyName: string
    salutation: string
    name: string
    phoneNumber: string
    email: string
    latestSelfDeclaration?: SelfDeclaration
}

export interface SelfDeclaration {
    persons?: SelfDeclarationPerson[]
    companyName?: string
    address?: string
    phone?: string
    fax?: string
    mobilePhone?: string
    email?: string
    taxNumber?: string
    description?: string
    manufacturer?: string
    vehicleType?: VehicleType
    isVehicleLetterAvailable?: boolean
    type?: string
    yearOfConstruction?: number
    serialNumber?: string
    machineLocation?: string
    deliveryDate?: string
    dealerName?: string
    dealerAddress?: string
    dealerEmail?: string
    dealerPhone?: string
    dealerFax?: string
    dealerContactName?: string
    purchasePrice?: number
    purchasePriceType?: PriceType
    tradeInPrice?: number
    tradeInPriceType?: PriceType
    cashDeposit?: number
    netFinancingAmount?: number
    netBlockRate?: number
    contractType?: ContractType
    loanDurationMonths?: number
    paymentMethod?: PaymentMethod
    employmentType?: EmploymentType
    isDeductInputTax?: boolean
    isContractor?: boolean
    profession?: string
    employedSince?: string // Date
    employer?: string
    netIncome?: number
    companyLegalType?: string // other than CompanyLegalType enum
    foundedOn?: string // Date
    firstFoundedOn?: string // Date
    employee?: string
    equity?: number
    equityPer?: string
    turnover?: number
    turnoverPer?: string
    resultProfit?: number
    resultProfitPer?: string
    totalAssets?: number
    totalAssetsPer?: string

    ownedArea?: number
    leasedArea?: number
    cropArea?: number
    grainArea?: number
    cornArea?: number
    specialArea?: number
    numberMilkCows?: number
    numberBulls?: number
    numberBreedingPigs?: number
    numberEatingPigs?: number
    numberOtherAnimals?: number
    numberTractors?: number
    numberHarvesters?: number
    numberFoodHarvesters?: number
    numberOtherDrivingVehicles?: number
    numberCombines?: number
    numberManureTransporters?: number
    numberTillers?: number

    buildingMarketValue?: number
    buildingRemainingDebt?: number
    areaMarketValue?: number
    areaRemainingDebt?: number
    otherAssets?: string

    bankAccountOwner?: string
    creditInstitution?: string
    bankContactPerson?: string
    bankEmail?: string
    bankPhone?: string
    bankFax?: string
    bic?: string
    iban?: string

    isPrivacyNoticeAccepted?: boolean
    isPublicAgreementAccepted?: boolean
    signatoryName?: string
}

export interface Creditworthiness {
    userDocumentId: number;
    userId: number;
    type: CreditworthinessReportType;
    data: string;
    name: string;
    createdAt: Date;
}

export interface SelfDeclarationPerson {
    name?: string
    address?: string
    dateOfBirth?: string // Date
    placeOfBirth?: string
    nationality?: string
    yearsExperience?: number
}

export enum PriceType {
    GROSS = 'gross',
    NET = 'net'
}

export enum PaymentMethod {
    MONTHLY = 'monthly',
    QUARTERLY = 'quarterly',
    HALF_YEARLY = 'half_yearly',
    YEARLY = 'yearly',
}

export enum EmploymentType {
    FULL_EMPLOYMENT = 'full_employment',
    SIDE_EMPLOYMENT = 'side_employment',
}

export interface Calculation {
    calculationId: number
    akfCalculationId?: string
    applicationId: number
    userId: number
    calculationCurrency: CurrencyEnum
    calculationName?: string
    loanAmount: number
    loanDuration: number
    customMonthlyRate?: number
    acquisitionCost?: number
    annualInterestRate?: number
    discountKp?: number
    workGrant?: number
    downpayment?: number
    commission?: number
    discount?: number
    remainingValue?: number
    hedgedProceeds?: number
    additionalProceeds?: number;
    contractStartAt?: string; // Date
    dateOfFirstPayment: string // Date
    paidOutAt: string // Date
    paymentsPerYear: PaymentsPerYear
    runUpTime?: number
    prePay: boolean
    discountKpPercentage?: number
    discountPercentage?: number
    downpaymentPercentage?: number
    workGrantPercentage?: number
    commissionPercentage?: number
    remainingValuePercent?: number
    bgInternal?: number
    createdAt?: string // Date
    repaymentPlan?: CalculatorRepaymentPlan[]

    isBoundToAnyOffer?: boolean;
    isBoundToAnyContract?: boolean;

    offer?: OffersData;
}

export interface CalculatorRepaymentPlan {
    date: string;
    amount: number;
    isCustomAmount: boolean;
}

export enum CurrencyEnum {
    EUR = 'EUR'
}

export enum CreditworthinessReportType {
    CreditReform = 'CREDITREFORM',
    Schufa = 'SCHUFA',
    OBLIGO = 'OBLIGO',
    Other = 'OTHER'
}

export interface SettingsProperties {
    companyTypes: CompanyTypeSetting[]
    contactPersonFunctions: ContactPersonFunctionSetting[] // Funktionen für Kontaktpersonen
    countries: CountrySetting[]
    creditInstitutions: CreditInstitutionSetting[] // BLZ und Bankenliste
    industryTypes: IndustryTypeSetting[] // Branchenarten
    marketVoteAnalysts: MarketVoteAnalystSetting[] // Bonitätsmitarbeiter
    objectGroups: ObjectTypeSetting[] // Objekttypen
    rewardShopRecipients: RewardShopRecipient[] // Prämienshopempfänger
    vendors: Vendor[]
}

export interface CompanyTypeSetting {
    companyTypeId: number
    akfSysKey: string
    name: string
    isEnabled: boolean
}

export interface ContactPersonFunctionSetting {
    contactPersonFunctionId: number
    akfSysKey: string
    functionName: string
    isEnabled: boolean
}

export interface CountrySetting {
    countryId: number
    akfSysKey: string
    isoCode: string
    name: string
    isEnabled: boolean
}

export interface CreditInstitutionSetting {
    creditInstitutionId: number
    akfSysKey: string
    blz: string
    name: string
    bic: string
    city: string
    isEnabled: boolean
}

export interface IndustryTypeSetting {
    industryTypeId: number
    akfSysKey: string
    key: string
    description: string
    isEnabled: boolean
}

export interface MarketVoteAnalystSetting { // columns not 100% sure
    marketVoteAnalystId: number
    akfSysKey: string
    rpaCommand: string
    email: string
    name: string
    isEnabled: boolean
}

export interface ObjectTypeSetting {
    objectTypeId: number
    akfSysKey: string
    description: string
    isEnabled: boolean
    // objectCurve ??
}

export interface RewardShopRecipient {
    rewardShopRecipientId: number
    akfSysId: number;
    merchant: string;
    sellerLastName: string;
    sellerFirstName: string;
    isEnabled: boolean
}

export interface Vendor {
    Rang: string
    Bezeichnung: string
    Matchcode: string
    Zusatz: string
    Strasse: string
    PLZ: string
    Ort: string
    ["ID Person"]: string
    Code: string
}

export interface DealershipConditionGroup {
    dealerName: string
    conditionType: string
    createdAt?: Date
}

export interface DealershipCondition {
    dealershipConditionId: number
    dealerName: string
    conditionType: string
    loanDuration?: string
    rate?: string
    factor?: string
    interestRate?: string
    commission?: string
    internalInterestRate?: string
    discount?: string
    refinancingInterest?: string
    interestMargin?: string
    sampleRate?: string
    createdAt?: Date
}

export interface ContractDraftData {
    contractDraftId: number;
    applicationId: number;
    userId: number;
    bankConnectionId: number;
    contractTemplateName: string;
    createdAt: Date;
    fee: number;
    contractType: string;
    confirmationOfAcceptance: boolean;
    printDueDate: boolean;
    actInOwn: boolean;
    typeOfAddmission: number;
    sendNonBindingInsuranceOffer: boolean;
    insuranceToBeIncluded: boolean;
    insuranceOurselves: boolean;
    inExistingFrameworkInsurance: boolean;
    inIndividualContract: boolean;
    volkaskoInsurance: boolean;
    fullInsurance: boolean;
    theftInsurance: boolean;
    heilInsurance: boolean;
    nameOfInsuranceCompany: string;
    addressOfInsuranceCompany: string;
    insuranceNumber: string;
    confirmationOfAcceptances?: string;
    beneficiaries?: string;
    guarantors?: any[]
    offer?: OffersData[]
}

export type CustomerType = 'customer' | 'guarantor'

export interface ContractData {
    contractId: number
    offerId: number
    contractDraftId: number
    userId: number
    applicationDocumentId: number
    contractName?: string
    pdfName?: string
    signatories?: {
        id: number;
        signatoryName: string;
        base64Signature?: string;
        signatoryType: CustomerType,
    }[]
    isConfirmedSigned: boolean
    signedAt?: Date
    createdAt?: Date,
    contractType: DocumentContractType
}

export interface ContractDataExtended extends ContractData {
    contractDraft: ContractDraftData
}
