import * as React from "react";
import {useEffect, useState} from "react";
import styles from "./MarktVoteSection.module.scss";
import {Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, message, Radio, Row, Select} from "antd";
import {CheckSquareOutlined, SyncOutlined} from "@ant-design/icons";
import {ApplicationData, Calculation, ClientType, CreditworthinessReportType, MarketVoteAnalystSetting, UserData, UserDocumentCategory} from "../../../../../services/api/types";
import {DATE_FORMAT} from "../../../../../services/api/Globals";
import ApiManager from "../../../../../services/api/ApiManager";
import {Store, useStore} from "../../../../../hooks/Store";
import moment from "moment";
import {LocalizedNumberInput} from "../../localized-number-input/LocalizedNumberInput";
import {useTranslation} from "react-i18next";
import {RequiredDocumentsSectionTab} from "components/common/presenters/application-page-tabs/marketvote-tab/RequiredDocumentsSection";
import {AnchoredSelect} from "components/common/presenters/anchored-select/AnchoredSelect";
import {ListItem, ListItemTypes} from "components/common/presenters/list-item/ListItem";
import {disableIncrementalParsing} from "typescript";
import {toThousands} from "services/calculator/calculationHelper";

export interface MarktVoteSectionProps {
    applicationInformation?: ApplicationData
    userData?: UserData
    onApplicationUpdated: () => void
    selectedFromOfferCalculation?: Calculation
}

export interface MarktVoteSectionState {
    marketVoteAnalystId?: number;
    clientType?: ClientType
    customerVcIndex?: number
    vcStandDate?: string // Date
    openLeaseCommitment?: number
    openLeaseCommitmentStandDate?: string // Date
    upcomingInvestment?: number
    objectGroup?: string
    objectCurve?: number
    isOwnerManaged?: boolean
    isHoldingCompanyPresent?: boolean
    isCompanyWithFiftyPercentPresent?: boolean
    isInGroupAffiliation?: boolean
    isGuaranteePresent?: boolean
    isGuarantorPrivate?: boolean
    crossSelling?: string
    isCommitment?: boolean
    comment?: string
    documentCategoriesUploaded: UserDocumentCategory[]
    documentCategoriesInWu: UserDocumentCategory[]
}

/**
 * Copy this tab.
 * @param props
 */
export const MarktVoteSection = (props: MarktVoteSectionProps) => {
    const store: Store = useStore()
    const {t} = useTranslation()

    const getVcFromCreditworthiness = () => {
        const reports = props.userData.creditworthiness?.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)

        if (reports) {
            const report = (reports.filter(r => r.type == CreditworthinessReportType.CreditReform)[0] || {}) as any;
            return report;
        } else {
            return {}
        }
    }

    const defaultState = {
        marketVoteAnalystId: props.applicationInformation.marketVotes[0]?.marketVoteAnalystId ? props.applicationInformation.marketVotes[0].marketVoteAnalystId : null,
        clientType: props.userData.isPrivate ? ClientType.PRIVATE : ClientType.COMMERCIAL,
        customerVcIndex: props.applicationInformation.marketVotes[0]?.customerVcIndex ? props.applicationInformation.marketVotes[0].customerVcIndex : getVcFromCreditworthiness()?.data?.score,
        vcStandDate: props.applicationInformation.marketVotes[0]?.vcStandDate ? moment(props.applicationInformation.marketVotes[0].vcStandDate).format(DATE_FORMAT.API) : getVcFromCreditworthiness().createdAt ?
            moment(getVcFromCreditworthiness().createdAt).format(DATE_FORMAT.API) : null,
        openLeaseCommitment: props.applicationInformation.marketVotes[0]?.openLeaseCommitment ? props.applicationInformation.marketVotes[0].openLeaseCommitment : 0,
        openLeaseCommitmentStandDate: props.applicationInformation.marketVotes[0]?.openLeaseCommitmentStandDate ? moment(props.applicationInformation.marketVotes[0].openLeaseCommitmentStandDate).format(DATE_FORMAT.API) : null,
        upcomingInvestment: props.selectedFromOfferCalculation ? props.selectedFromOfferCalculation.loanAmount - props.selectedFromOfferCalculation.downpayment - props.selectedFromOfferCalculation.workGrant : 0, // props.applicationInformation.marketVotes[0]?.upcomingInvestment ? props.applicationInformation.marketVotes[0].upcomingInvestment : 0,
        isOwnerManaged: props.applicationInformation.marketVotes[0]?.isOwnerManaged ? props.applicationInformation.marketVotes[0].isOwnerManaged : false,
        isHoldingCompanyPresent: props.applicationInformation.marketVotes[0]?.isHoldingCompanyPresent ? props.applicationInformation.marketVotes[0].isHoldingCompanyPresent : false,
        isCompanyWithFiftyPercentPresent: props.applicationInformation.marketVotes[0]?.isCompanyWithFiftyPercentPresent ? props.applicationInformation.marketVotes[0].isCompanyWithFiftyPercentPresent : false,
        isInGroupAffiliation: props.applicationInformation.marketVotes[0]?.isInGroupAffiliation ? props.applicationInformation.marketVotes[0].isInGroupAffiliation : false,
        isGuaranteePresent: props.applicationInformation.marketVotes[0]?.isGuaranteePresent ? props.applicationInformation.marketVotes[0].isGuaranteePresent : false,
        isGuarantorPrivate: props.applicationInformation.marketVotes[0]?.isGuarantorPrivate ? props.applicationInformation.marketVotes[0].isGuarantorPrivate : false,
        crossSelling: props.applicationInformation.marketVotes[0]?.crossSelling ? props.applicationInformation.marketVotes[0].crossSelling : '',
        isCommitment: props.applicationInformation.marketVotes[0]?.isCommitment ? props.applicationInformation.marketVotes[0].isCommitment : false,
        comment: props.applicationInformation.marketVotes[0]?.comment ? props.applicationInformation.marketVotes[0].comment : '',
        documentCategoriesUploaded: props.applicationInformation.marketVotes[0]?.documentCategoriesUploaded ? props.applicationInformation.marketVotes[0]?.documentCategoriesUploaded : [],
        documentCategoriesInWu: props.applicationInformation.marketVotes[0]?.documentCategoriesInWu ? props.applicationInformation.marketVotes[0]?.documentCategoriesInWu : [],
    }

    const [data, setData] = useState({...defaultState} as MarktVoteSectionState)

    const [ui, setUi] = useState({
        sending: false,
        marketVoteAnalystIdError: false,
    })

    const enabledMarketVoteAnalysts: MarketVoteAnalystSetting[] = store.data?.settings?.marketVoteAnalysts?.filter(a => a.isEnabled) || []

    useEffect(() => {
        setData({...data,
            upcomingInvestment: props.selectedFromOfferCalculation ? props.selectedFromOfferCalculation.loanAmount - props.selectedFromOfferCalculation.downpayment - props.selectedFromOfferCalculation.workGrant : 0
        })
        refreshObligo()
    }, [props])

    const renderLineBreak = () => {
        return (
            <>
                <br />
                <br />
            </>
        );
    }

    const resetState = () => {
        setData(defaultState)
    }

    const handleDocumentUploadedChecked = (isChecked: boolean, documentCategory: UserDocumentCategory) => {
        const documentCategoriesUploaded = isChecked ?
            [...data.documentCategoriesUploaded, documentCategory] :
            data.documentCategoriesUploaded.filter(d => d !== documentCategory)

        const documentCategoriesInWu = isChecked ?
            data.documentCategoriesInWu.filter(d => d !== documentCategory) :
            [...data.documentCategoriesInWu]

        setData({
            ...data,
            documentCategoriesUploaded: documentCategoriesUploaded,
            documentCategoriesInWu: documentCategoriesInWu
        })
    }

    const handleDocumentUploadedInWUChecked = (isChecked: boolean, documentCategory: UserDocumentCategory) => {
        const documentCategoriesInWu = isChecked ?
            [...data.documentCategoriesInWu, documentCategory] :
            data.documentCategoriesInWu.filter(d => d !== documentCategory)

        const documentCategoriesUploaded = isChecked ?
            data.documentCategoriesUploaded.filter(d => d !== documentCategory) :
            [...data.documentCategoriesUploaded]

        setData({
            ...data,
            documentCategoriesInWu: documentCategoriesInWu,
            documentCategoriesUploaded: documentCategoriesUploaded
        })
    }

    const renderRequiredDocuments = () => {
        return <RequiredDocumentsSectionTab
            applicationInformation={props.applicationInformation}
            userData={props.userData}
            marketVoteTabState={data}
            documentCategoriesUploaded={data.documentCategoriesUploaded}
            documentCategoriesInWu={data.documentCategoriesInWu}
            handleDocumentUploadedChecked={handleDocumentUploadedChecked}
            handleDocumentUploadedInWUChecked={handleDocumentUploadedInWUChecked}
        />
    }

    const handleSave = async () => {
        const reqBody = {...data};
        if (reqBody.marketVoteAnalystId == null) {
            message.error('Bonitätsmitarbeiter ist falsch!', 2)
            setUi({...ui, marketVoteAnalystIdError: true})
            return;
        }
        setUi({...ui, marketVoteAnalystIdError: false, sending: true})
        if (ui.sending) {
            return;
        }
        try {
            await ApiManager.ApplicationService.upsertMarketVote(props.applicationInformation.applicationId, reqBody)
            message.success('Daten Gespeichert', 2)
            props.onApplicationUpdated()
        } catch (e) {
            console.log(e)
            message.error('Konnte nicht Speichern', 2)
        }
        setUi({...ui, sending: false})
    }

    // TODO
    const refreshObligo = async () => {
        setData(prevState => {
            return {
                ...prevState,
                openLeaseCommitment: 0,
                openLeaseCommitmentStandDate: moment().format(DATE_FORMAT.API),
            }
        })
    }

    return (
        <div>
            <div className={styles.marktVoteSection}>
                <h4>Bonitätsmitarbeiter</h4>
                <Form.Item
                    validateStatus={ui.marketVoteAnalystIdError ? "error" : ''}
                    help={ui.marketVoteAnalystIdError ? "Bonitätsmitarbeiter ist falsch!" : ''}
                >
                    <AnchoredSelect data-cy="dropdown_analyst"
                        value={data.marketVoteAnalystId}
                        disabled={props.applicationInformation.isLocked}
                        onChange={(e) => {
                            setData({...data, marketVoteAnalystId: e})
                        }}
                        showSearch={true}
                        optionFilterProp={"children"}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            enabledMarketVoteAnalysts.map((analyst, i) =>
                                <Select.Option key={i} data-cy={`dropdown_analyst_option_${i}`} value={analyst.marketVoteAnalystId}>{analyst.name}</Select.Option>
                            )
                        }
                    </AnchoredSelect>
                </Form.Item>
                <br />

                <h4>Art des Geschäfts</h4>
                <Radio.Group data-cy="radio_client_type"
                    value={data.clientType}
                    onChange={(e) => {
                        setData({...data, clientType: e.target.value});
                    }}
                >
                    <Radio data-cy="option_commercial" value={ClientType.COMMERCIAL}>Standard</Radio>
                    <Radio data-cy="option_private" value={ClientType.PRIVATE}>Individuell</Radio>
                </Radio.Group>
                {renderLineBreak()}

                <Row gutter={16} align={'bottom'}>
                    <Col>
                        <Row justify={"start"}
                            gutter={24}
                        >
                            <Col>
                                <h4>VC Index</h4>
                                <InputNumber data-cy="input_vc_index"
                                    className={styles.inputsMinWidth}
                                    value={data.customerVcIndex}
                                    onChange={(e) => {
                                        setData({...data, customerVcIndex: e as number})
                                    }}
                                />
                            </Col>
                            <Col>
                                <h4>Stand</h4>
                                <DatePicker data-cy="picker_vc_stand_date"
                                    format={DATE_FORMAT.DE}
                                    value={data.vcStandDate ? moment(data.vcStandDate, DATE_FORMAT.API) : null}
                                    onChange={(e) => {
                                        setData({...data, vcStandDate: e?.format(DATE_FORMAT.API)})
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <br />

                <Row gutter={16} align={'bottom'}>
                    <Col>
                        <Row justify={'start'}
                            gutter={24}
                        >
                            <Col>
                                <h4>Aktuelles Obligo</h4>
                                <LocalizedNumberInput data-cy="input_open_lease_commitment"
                                    className={styles.inputsMinWidth}
                                    disabled={true}
                                    value={data.openLeaseCommitment}
                                    prefix={t('currency_sign')}
                                    precision={2}
                                />
                            </Col>
                            <Col>
                                <h4>Stand</h4>
                                <DatePicker data-cy="picker_obligo_stand_date"
                                    disabled={true}
                                    format={DATE_FORMAT.DE}
                                    value={data.openLeaseCommitmentStandDate ? moment(data.openLeaseCommitmentStandDate, DATE_FORMAT.API) : null}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Button type={"primary"}
                            data-cy={"button_refresh_obligo"}
                            className={styles.refreshButton}
                            onClick={() => {
                                refreshObligo()
                            }}>
                            <SyncOutlined /> Aktualisieren
							</Button>
                    </Col>
                </Row>

                <br />


                <h4>Anstehende Investition in T€</h4>
                <InputNumber data-cy="input_upcoming_investment"
                    min={0}
                    disabled={true}
                    value={toThousands(data.upcomingInvestment)}
                    // onChange={(e) => {
                    //     setData({...data, upcomingInvestment: e as number})
                    // }}
                    >
                </InputNumber>
                {renderLineBreak()}

                <h4>Handelt es sich um eine inhabergeführte GmbH, GbR, KG, OHG, Einzelfirma, Gewerbebetrieb?</h4>
                <Radio.Group data-cy="radio_is_owner_managed"
                    value={data.isOwnerManaged}
                    onChange={(e) => {
                        setData({...data, isOwnerManaged: e.target.value as boolean});
                    }}
                >
                    <Radio data-cy="option_is_owner_managed_true" value={true}>Ja</Radio>
                    <Radio data-cy="option_is_owner_managed_false" value={false}>Nein</Radio>
                </Radio.Group>
                {renderLineBreak()}

                <h4>Besteht Besitzgesellschaft?</h4>
                <Radio.Group data-cy="radio_is_holding_company"
                    value={data.isHoldingCompanyPresent}
                    onChange={(e) => {
                        setData({...data, isHoldingCompanyPresent: e.target.value as boolean});
                    }}
                >
                    <Radio data-cy="option_is_holding_company_true" value={true}>Ja</Radio>
                    <Radio data-cy="option_is_holding_company_false" value={false}>Nein</Radio>
                </Radio.Group>
                {renderLineBreak()}

                <h4>Besteht eine Gesellschaft deren Anteil an unserem Kunden &gt; 50% ist? </h4>
                <Radio.Group data-cy="radio_is_company_with_50_percent"
                    value={data.isCompanyWithFiftyPercentPresent}
                    onChange={(e) => {
                        setData({...data, isCompanyWithFiftyPercentPresent: e.target.value as boolean});
                    }}
                >
                    <Radio data-cy="option_is_company_with_50_percent_true" value={true}>Ja</Radio>
                    <Radio data-cy="option_is_company_with_50_percent_false" value={false}>Nein</Radio>
                </Radio.Group>
                {renderLineBreak()}

                <h4>Besteht eine Konzernzugehörigkeit?</h4>
                <Radio.Group data-cy="radio_is_group_affiliation"
                    value={data.isInGroupAffiliation}
                    onChange={(e) => {
                        setData({...data, isInGroupAffiliation: e.target.value as boolean});
                    }}
                >
                    <Radio data-cy="option_is_group_affiliation_true" value={true}>Ja</Radio>
                    <Radio data-cy="option_is_group_affiliation_false" value={false}>Nein</Radio>
                </Radio.Group>
                {renderLineBreak()}

                <h4>Erhalten wir eine Bürgschaft auf die abgestellt wird?</h4>
                <Radio.Group data-cy="radio_is_guarantee"
                    value={data.isGuaranteePresent}
                    onChange={(e) => {
                        setData({...data, isGuaranteePresent: e.target.value as boolean});
                    }}
                >
                    <Radio data-cy="option_is_guarantee_true" value={true}>Ja</Radio>
                    <Radio data-cy="option_is_guarantee_false" value={false}>Nein</Radio>
                </Radio.Group>
                {renderLineBreak()}

                {
                    data.isGuaranteePresent ?
                        <>
                            <h4>Ist der Bürge Unternehmen oder Privatperson?</h4>
                            <Radio.Group data-cy="radio_is_guarantor_private"
                                value={data.isGuarantorPrivate}
                                onChange={(e) => {
                                    setData({...data, isGuarantorPrivate: e.target.value as boolean});
                                }}
                            >
                                <Radio data-cy="option_is_guarantor_private_true" value={true}>Privatperson</Radio>
                                <Radio data-cy="option_is_guarantor_private_false" value={false}>Unternehmen</Radio>
                            </Radio.Group>
                        </> : null
                }

                {renderRequiredDocuments()}

                {
                    data.openLeaseCommitment > 100000 ?
                        <>
                            <h4>Cross Selling (ab T€ 100 Gesamtobligo) wurde mit folgendem Ergebnis mit dem Kunden besprochen </h4>
                            <Input data-cy="input_cross_selling"
                                value={data.crossSelling}
                                onChange={(e) => {
                                    setData({...data, crossSelling: e.target.value})
                                }}
                            />
                            <br />
                            <br />
                        </>
                        : null
                }


                <h4>Weitere Kommentare</h4>
                <Input.TextArea style={{maxWidth: 500}}
                    autoSize={{minRows: 4, maxRows: 12}}
                    value={data.comment}
                    onChange={(e) => {
                        setData({...data, comment: e.target.value})
                    }}
                />
                {renderLineBreak()}

                <Checkbox data-cy="checkbox_3" className={styles.checkbox}
                    defaultChecked={data.isCommitment}
                    onChange={(e) => {
                        setData({...data, isCommitment: e.target.checked})
                    }}
                >
                    Mit Einreichung der o.g. Unterlagen befürworte ich die Begleitung des Engagements.
					</Checkbox>
                {renderLineBreak()}

                <Row gutter={24}>
                    <Col>
                        <Button data-cy="button_save" className={styles.button} type="primary"
                        disabled={ui.sending}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleSave();
                            }}>
                            <CheckSquareOutlined />
								Speichern
							</Button>
                    </Col>
                    <Col>
                        <Button data-cy="button_save" className={styles.button} type="default"
                            onClick={() => {
                                resetState()
                            }}>
                            Zurücksetzen
							</Button>
                    </Col>
                </Row>
                <br />
            </div>
        </div>
    );
};
