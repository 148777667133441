import * as React from "react";
import throttle from 'lodash.throttle';
import styles from "components/common/presenters/application-page-tabs/calculator-tab/CalculatorTab.module.scss";
import {ListItem, ListItemTypes} from "components/common/presenters/list-item/ListItem";
import {useTranslation} from "react-i18next";
import {Calculation} from "../../../../../services/api/types";
import ApiManager from "../../../../../services/api/ApiManager";
import CollapsibleSection from "../../collapsible-section/CollapsibleSection";
import {Button, message} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {initCalculatorState} from "../../../../../services/calculator/defaults";
import {DATE_FORMAT} from "../../../../../services/api/Globals";
import {calculateRepaymentPlan} from "../../../../../services/calculator/calculation-actions/calculateRepaymentPlan";
import {parseLocaleFormattedFloat} from "helpers/parsers";
import {currencyLocaleFormatter} from "helpers/formatters";
import {useCallback} from "react";

export interface CalculationsListProps {
    applicationId: number;
    userId: number;
	calculations: Calculation[];
	selectedCalculationId: number;
	onCalculationSelected: (calculationId: number) => void;
	onDataChanged: () => void;
}

export const CalculationsList = (props: CalculationsListProps) => {
	const { t } = useTranslation();

	const onDeleteCalculation = async (calculationId: number) => {
		try {
			await ApiManager.ApplicationService.deleteCalculation(calculationId);
			props.onDataChanged();
		} catch (error) {
			console.error(error);
		}
	}

	const onDuplicateCalculation = useCallback(throttle(
        async (calculationId: number) => {
		try {

			await ApiManager.ApplicationService.duplicateCalculation(calculationId);
			props.onDataChanged();
            message.success('Success', 2)
		} catch (error) {
			console.error(error);
            message.error('Unexpected error')
		}
	}, 2000, {trailing: false}), []);

    const onNewCalculation = useCallback(throttle(async () => {
	    const blankCalculation = initCalculatorState()
        blankCalculation.repaymentPlan = calculateRepaymentPlan(blankCalculation.loanObject)

        const reqPayload = {
            ...blankCalculation.loanObject,
            applicationId: props.applicationId,
            userId: props.userId,
            repaymentPlan: blankCalculation.repaymentPlan.map(p => {
                return {
                    date: p.date.format(DATE_FORMAT.API),
                    amount: p.amount,
                    isCustomAmount: p.amount == 0 ? true : p.isCustomAmount
                }})
        }

        try {
            await ApiManager.ApplicationService.createCalculation(reqPayload)
            props.onDataChanged()
        } catch (error) {
            console.error(error)
        }
    }, 2000, {trailing: false}), []);

	return (
		<CollapsibleSection text={t('saved_calculations')}>
			{
				props.calculations.map((c, i) =>
					<ListItem key={i}
							  dataCy={`saved_calculation_${i}`}
							  dataCyNextToLastIcon={c.isBoundToAnyOffer ? '' : `saved_calculation_${i}_duplicate`}
							  dataCyLastIcon={c.isBoundToAnyOffer ? `saved_calculation_${i}_duplicate` : `saved_calculation_${i}_delete`}
							  type={
								  c.isBoundToAnyOffer ?
									  ListItemTypes.CalculationCopiable :
									  ListItemTypes.CalculationCopiableDeletable
							  }
							  selected={c.calculationId === props.selectedCalculationId}
							  htmlText={toCalculationLabel(c)}
							  onClick={() => {
							  		const isAlreadySelected = c.calculationId === props.selectedCalculationId
							  		const selectedCalculationId = isAlreadySelected ? null : c.calculationId
								  	props.onCalculationSelected(selectedCalculationId)
							  }}
							  onNextToLastIconClicked={() => onDuplicateCalculation(c.calculationId)
							  }
							  onLastIconClicked={
								  c.isBoundToAnyOffer ?
                                  (() => {}) :
									  (() => onDeleteCalculation(c.calculationId))
							  }
					/>)
			}
			<Button data-cy={"button-add-calculation"}
                    className={styles.addCalcButton}
                    type={"primary"}
                    onClick={() => {
                        onNewCalculation()
                    }}
            >
                <PlusOutlined />
                {t('calculation')}
            </Button>
		</CollapsibleSection>
	);
};

const toCalculationLabel = (c) => {
    const parsed = parseLocaleFormattedFloat(c.loanAmount.toString())
    const internalWithPrecision = currencyLocaleFormatter(parsed, 2)

	let text = `${c.loanDuration} Lfz. - ${internalWithPrecision} € - ${c.annualInterestRate}%${c.discountPercentage > 0 ? ` - ${c.discountPercentage}% Dis` : ''}
    ${(c.repaymentPlan).some(p => p.isCustomAmount) ? ' - inkl. Staffel' : ''}`

    if(c.isBoundToAnyContract) {
        text = `${text} - VT${c?.offer?.akfOfferId ? ` -  <span style="font-weight: 900;">${c?.offer?.akfOfferId}-A<span>` : ''}`
    } else if(c.isBoundToAnyOffer) {
        text = `${text} - ANG${c?.offer?.akfOfferId ? ` -  <span style="font-weight: 900;">${c?.offer?.akfOfferId}-A<span>` : ''}`
    }

	return text;
}
