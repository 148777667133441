import {ArrowLeftOutlined, EditOutlined, PlusOutlined, UndoOutlined} from "@ant-design/icons";
import {Button, Col, message, Row} from "antd";
import {useHistory, useParams, useLocation} from "react-router-dom";
import styles from "components/common/pages/account-information-page/AccountInformationPage.module.scss";
import {SideMenu} from "components/common/presenters/side-menu/SideMenu";
import * as React from "react";
import {useEffect, useState} from "react";
import {TabMenu, TabMenuContent} from "components/common/presenters/tab-menu/TabMenu";
import {CalculationsTab} from "components/common/presenters/account-information-page-tabs/CalculationsTab";
import ApiManager from "services/api/ApiManager";
import {ApplicationsTab} from "components/common/presenters/account-information-page-tabs/ApplicationsTab";
import {RouteStrings} from "routing/Routes";
import {DocumentsTab} from "components/common/presenters/account-information-page-tabs/documents-tab/DocumentsTab";
import {CountrySetting, IndustryTypeSetting, SalesPerson, UserData, UserDocumentCategory} from "../../../../services/api/types";
import {SelfDeclarationTab} from "../../presenters/account-information-page-tabs/self-declaration-tab/SelfDeclarationTab";
import {ContactPersonsTab} from "../../presenters/account-information-page-tabs/contact-persons-tab/ContactPersonsTab";
import {OffersTab} from "../../presenters/account-information-page-tabs/OffersTab";
import {ContractsTab} from "../../presenters/account-information-page-tabs/ContractsTab";
import {CreditScoringTab} from "../../presenters/account-information-page-tabs/CreditScoringTab";
import {useTranslation} from "react-i18next";
import {UserModal} from "../../presenters/user-modal/UserModal";
import {getUserFromAkf} from "services/api/akfIntegrationService";
import {Store, useStore} from "hooks/Store";

export interface AccountInformationPageProps {
    tabName?: string
}

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function isItUserOnAkfSideOnly(userid, akfApiCustomerId) {
    return userid === "undefined" && akfApiCustomerId;
}

// TODO: extract add user modal to a component
export const AccountInformationPage = (props: AccountInformationPageProps) => {
    const {t} = useTranslation()
    const store: Store = useStore()
    const history = useHistory();
    let {userid} = useParams();

    const query = useQuery();

    const akfApiCustomerId = query.get("akfApiCustomerId")

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [salesPersons, setSalesPersons] = useState([] as SalesPerson[]);
    const [ui, setUi] = useState({
        isSendingData: false
    });

    const [defaultTabKey, setDefaultTabKey] = useState(props.tabName || 'calculations');
    const [accountInformation, setAccountInformation] = useState({} as UserData);

    const enabledCountries: CountrySetting[] = store.data?.settings?.countries?.filter(c => c.isEnabled) || []

    const fetchData = async () => {
        try {
            if(isItUserOnAkfSideOnly(userid, akfApiCustomerId)) {
                const user = await getUserFromAkf(akfApiCustomerId);
                // const user = mockedAkfUsersData[0]; 
                setAccountInformation(user);
            } else {
                const result = await ApiManager.UserService.getUser(userid, ['applications', 'calculations', 'offers', 'user_documents', 'self_declaration', 'contact_persons',
                'objects', 'bank_connections', 'contracts']);
                setAccountInformation({...result.data});
                if(result?.data?.akfApiCustomerId || result?.data?.bankUserId) {
                    const syncResponse = await ApiManager.UserService.autoSyncAkfUser({
                        userId: result?.data?.userId,
                        akfApiCustomerId: result?.data?.akfApiCustomerId,
                        bankUserId: result?.data?.bankUserId
                    })
                    setAccountInformation({...result.data, ...syncResponse.data});
                }
            }

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [userid]);

    useEffect(() => {
        if (tabMenuItems.map(item => item.key).includes(props.tabName)) {
            setDefaultTabKey(props.tabName)
        }
    }, [props.tabName]);

    const fetchSalesPersonInfo = async () => {
        const fetchResult = await ApiManager.UserService.getSalesPersons()
        let salesPersons: SalesPerson[] = fetchResult.data;
        setSalesPersons(salesPersons)
    }

    async function onNewApplicationClicked() {
        try {
            if(ui.isSendingData) {
                return;
            }
            setUi({...ui, isSendingData: true})

            const result = await ApiManager.ApplicationService.createApplication(userid);
            setUi({...ui, isSendingData: false})
            history.push(RouteStrings.AdminApplicationDrilldown.replace(':applicationid', result.data.applicationId));
        } catch (error) {
            console.error(error);
        }
        setUi({...ui, isSendingData: false})
    }

    const refreshUserDocumentList = async () => {
        try {
            const docsResponse = await ApiManager.UserService.getUserDocuments(userid)
            setAccountInformation((prevState: UserData) => {
                return {...prevState, userDocuments: docsResponse.data}
            })
        } catch (e) {
            console.error(e)
        }
    }

    const syncUserData = async () => {
        try {
            if(ui.isSendingData) {
                return;
            }
            setUi({...ui, isSendingData: true})
            const fetchResult = await ApiManager.UserService.getSalesPersons()
            let salesPersons: SalesPerson[] = fetchResult.data;

            const addressCountryId = (enabledCountries.filter(k => k.name.toLowerCase() === accountInformation?.country?.toLowerCase())[0]||{}).countryId;
            const birthCountryId = (enabledCountries.filter(k => k.name.toLowerCase() === accountInformation?.birthCountry?.toLowerCase())[0]||{}).countryId;
            const sendingData = {
                salesRepresentativeId: salesPersons[0].id,
                addressCountryId,
                birthCountryId,
                ...accountInformation,
                firstName: accountInformation.firstName || '',
                email: accountInformation.email || '',
                title: accountInformation.title || '',
                addressLine1: accountInformation.addressLine1 || '',
                addressStreetNumber: accountInformation.addressStreetNumber || '',
                addressCity: accountInformation.addressCity || '',
                addressPostalCode: accountInformation.addressPostalCode || '',
                mobileNumber: accountInformation.mobileNumber || '',
                salutation: accountInformation.salutation || '',
            }
            delete sendingData.isAkf;
            delete sendingData.country;
            delete sendingData.birthCountry;
            const result = await ApiManager.UserService.syncAkfUser(sendingData)
            setAccountInformation(result.data);
            setUi({...ui, isSendingData: false})
            history.push(RouteStrings.AdminRouteBase + "/users/"+result.data.userId)
            message.success('Success', 2)
        } catch (e) {
            console.error(e)
            message.error('Unexpected error')
            setUi({...ui, isSendingData: false})
        }
    }

    const getFullAddress = (): string => {
        let address = ''

        if (accountInformation.addressLine1?.length > 0) {
            address += `${accountInformation.addressLine1}`
        }

        if (accountInformation.addressLine1?.length > 0 && (accountInformation.addressPostalCode?.length > 0 || accountInformation.addressCity?.length > 0)) {
            address += ', '
        }

        if (accountInformation.addressPostalCode?.length > 0) {
            address += `${accountInformation.addressPostalCode} `
        }

        if (accountInformation.addressCity?.length > 0) {
            address += `${accountInformation.addressCity}`
        }

        return address.length > 0 ? address : '-'
    }

    const getUserTypes = (): string => {
        return accountInformation.userType?.length > 0 ?
            accountInformation.userType
                .map(userType => t(`user_types:${userType}`))
                .join(', ')
            : '-'
    }

    const tabMenuItems: TabMenuContent[] = [
        {
            title: t('tabs:applications'),
            key: "applications",
            content: <ApplicationsTab applications={accountInformation.applications || []} />,
        },
        {
            title: t('tabs:calculations'),
            key: "calculations",
            content: <CalculationsTab calculations={accountInformation.calculations || []}
                objects={accountInformation.objects || []} />,
        },
        {
            title: t('tabs:offers'),
            key: "offers",
            content: <OffersTab offers={accountInformation.offers || []}
                calculations={accountInformation.calculations || []}
                objects={accountInformation.objects || []} />,
        },
        {
            title: t('tabs:contracts'),
            key: "contracts",
            content: <ContractsTab contracts={accountInformation.contracts || []}
                user={accountInformation} />,
        },
        {
            title: t('tabs:contact_persons'),
            key: "contact_persons",
            content: <ContactPersonsTab userId={accountInformation.userId}
                akfApiCustomerId={accountInformation.akfApiCustomerId}
                contactPersons={accountInformation.contactPersons || []}
                onListChanged={() => fetchData()} />,
        },
        {
            title: t('tabs:credit_scoring'),
            key: "credit_scoring",
            content: <CreditScoringTab userId={userid} userDocuments={accountInformation.userDocuments || []}
                onUserFilesChanged={() => {
                    fetchData()
                }} />
        },
        {
            title: t('tabs:self_declaration'),
            key: "self_declaration",
            content: <SelfDeclarationTab isPublicPage={false}
                userId={accountInformation.userId}
                accountInformation={accountInformation}
                latestSelfDeclaration={accountInformation.latestSelfDeclaration}
                offers={accountInformation.offers || []}
                generatedSelfDeclarationDocuments={
                    accountInformation.userDocuments?.filter(d => d.documentCategory === UserDocumentCategory.SELF_DECLARATION_GENERATED)
                }
                uploadedSelfDeclarationDocuments={
                    accountInformation.userDocuments?.filter(d => d.documentCategory === UserDocumentCategory.SA)
                }
                onSubmitSuccess={() => {
                    fetchData()
                }} />,
        },
        // {
        //     title: t('tabs:documents'),
        //     key: "documents",
        //     content: <DocumentsTab userDocuments={accountInformation.userDocuments || []}
        //         userId={accountInformation.userId}
        //         userContactPersons={accountInformation.contactPersons || []}
        //         onUserFilesChanged={() => {
        //             refreshUserDocumentList()
        //         }} />
        // },
    ];

    return (
        <div className={styles.page}>

            <SideMenu />

            <div className={styles.sectionRight}>

                <div className={styles.rowInfoTop}>
                    <Button className={styles.buttonBack}
                        onClick={() => {
                            history?.push(RouteStrings.AdminPeople)
                        }}
                    >
                        <ArrowLeftOutlined />
                        Back
                    </Button>

                    <Row className={styles.rowInfoTopDetails}
                        gutter={24}
                        justify={'space-between'}
                    >
                        <Col flex={"1 0 auto"}>
                            <div className={styles.colTopRight}>
                                <div className={styles.containerTopInnerPinTop}>
                                    <h2>{t('personal_information')}</h2>
                                    <Row gutter={24}>
                                        <Col>
                                            <div className={styles.accountDataRow}>
                                                <div className={styles.accountDataFirstCol}>{t('data:name')}:</div>
                                                <div className={styles.accountDataSecondCol}>{accountInformation.firstName} {accountInformation.lastName}</div>
                                            </div>
                                            <div className={styles.accountDataRow}>
                                                <div className={styles.accountDataFirstCol}>{t('data:email')}:</div>
                                                <div className={styles.accountDataSecondCol}>{accountInformation.email}</div>
                                            </div>
                                            <div className={styles.accountDataRow}>
                                                <div className={styles.accountDataFirstCol}>{t('data:id_number')}:</div>
                                                <div className={styles.accountDataSecondCol}>{accountInformation.bankUserId}</div>
                                            </div>
                                            <div className={styles.accountDataRow}>
                                                <div className={styles.accountDataFirstCol}>{t('data:address_2')}:</div>
                                                <div className={styles.accountDataSecondCol}> {`${getFullAddress()}`}</div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className={styles.accountDataRow}>
                                                <div className={styles.accountDataFirstCol}>{t('data:person_types')}:</div>
                                                <div className={styles.accountDataSecondCol}>{getUserTypes()}</div>
                                            </div>
                                            <div className={styles.accountDataRow}>
                                                <div className={styles.accountDataFirstCol}>{t('data:telephone_number')}:</div>
                                                <div className={styles.accountDataSecondCol}>{accountInformation.oldschoolphoneNumber}</div>
                                            </div>
                                            <div className={styles.accountDataRow}>
                                                <div className={styles.accountDataFirstCol}>{t('data:mobile_number')}:</div>
                                                <div className={styles.accountDataSecondCol}>{accountInformation.mobileNumber}</div>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </Col>
                        <Col flex={"0 0 auto"}>
                            <Button data-cy="button_new_application"
                                className={styles.topRightButton}
                                type="primary"
                                disabled={isItUserOnAkfSideOnly(userid,akfApiCustomerId)}
                                onClick={isItUserOnAkfSideOnly(userid,akfApiCustomerId) ? ()=> {} : onNewApplicationClicked}>
                                <PlusOutlined /> {t('new_application')}
                            </Button>
                            <br />
                            <br />

                            <Button data-cy="button_edit_data"
                                className={styles.topRightButton}
                                type="default"
                                disabled={isItUserOnAkfSideOnly(userid,akfApiCustomerId)}
                                onClick={async () => {
                                    if(isItUserOnAkfSideOnly(userid,akfApiCustomerId) ) {
                                        return;
                                    }
                                    await fetchSalesPersonInfo()
                                    setEditModalOpen(true)
                                }}>
                                <EditOutlined /> {t('edit_data')}
                            </Button>
                            {akfApiCustomerId ? <>
                                <br />
                            <br />
                            <Button data-cy="button_new_application"
                                className={styles.topRightButton}
                                type="primary"
                                disabled={!akfApiCustomerId}
                                onClick={akfApiCustomerId ? syncUserData : ()=> {}}>
                                <UndoOutlined  /> Sync data
                            </Button>
                            </> : null}
                        
                        </Col>
                    </Row>

                </div>

                <div className={styles.rowMainContent}>
                    <TabMenu items={tabMenuItems}
                        defaultItem={tabMenuItems.find(item => item.key === defaultTabKey)}
                        onTabChange={(activeKey) => setDefaultTabKey(activeKey)}
                    />
                </div>

                <UserModal modalOpen={editModalOpen}
                    setModalOpen={setEditModalOpen}
                    userData={accountInformation}
                    onDataSaved={() => {
                        fetchData()
                    }}
                    salesPersons={salesPersons}
                />
            </div>
        </div>
    );
};
