import * as React from "react";
import styles from "components/common/pages/jobs-page/JobsPage.module.scss";
import UiHelper from "../../../../helpers/uiHelpers";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Button, Checkbox, Dropdown, Input, Menu, message, Table} from "antd";
import {ArrowRightOutlined, CheckCircleFilled, CheckCircleOutlined, DownloadOutlined, DownOutlined, FileZipOutlined, PlusOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import {RouteStrings} from "routing/Routes";
import ApiManager from "services/api/ApiManager";
import {join} from "path";

const statuses = ['Completed', 'Failed', 'In Progress', 'Open', 'In Progress Queue'];

function toPascelCase(s) {
    if (!s) {
        return null
    }
    return s.toLowerCase()
        .replace(/^([a-z]|[A-Z])/g,
            (g0, g1) => `${g1.toUpperCase()}`)
        .replace(/_([a-z]|[A-Z])/g,
            (g0, g1) => ` ${g1.toUpperCase()}`)
}

function toDbCase(s) {
    if (!s) {
        return null
    }
    return s.toUpperCase()
        .replace(/ /g, '_')
}


export interface JobsTableSectionProps {
    onRowClicked?: (recodr) => {}
}

export const JobsTableSection = (props: JobsTableSectionProps) => {

    const [statusFilters, setStatusFilters] = useState([...(statuses.map(s => {return {text: s, checked: false, key: s.toUpperCase().replace(/ /g, '_')};}))]);

    const [personSelected, setPersonSelected] = useState(false);
    const [handlerSelected, setHandlerSelected] = useState(false);
    const [lieferantSelected, setLieferantSelected] = useState(false);

    const [jobs, setJobs] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);

    const fetchData = async () => {
        try {
            const jobStatuses = statusFilters.filter(s => s.checked).map(s => toDbCase(s.key));

            const jobsResult = await ApiManager.ApplicationService.getJobs(pageIndex, jobStatuses);
            setJobs(jobsResult.data.map(j => {
                return {
                    ...j, boUserName: j.boUser.name,
                    userName: `${j.user.firstName} ${j.user.lastName}`,
                    bankUserId: j.user.bankUserId
                };
            }));
            setTotalUsers(jobsResult.pagination.numberOfRows);
            setItemsPerPage(jobsResult.pagination.itemsPerPage);


        } catch (error) {
            console.error(error);
            message.error('Unexpected error')
        }
    };

    useEffect(() => {
        fetchData();
    }, [pageIndex, statusFilters]);

    const retry = async (jobId) => {
        try {

            await ApiManager.ApplicationService.retryJob(jobId);
            await fetchData();

            message.success('Success', 2)
        } catch (error) {
            console.error(error);
            message.error('Unexpected error')
        }
    };

    const getDetails = async (jobId) => {
        try {

            const result = await ApiManager.ApplicationService.getJobFiles(jobId);

            UiHelper.downloadFileFromUrl(result.data.file, 'zippedFiles.zip');
        } catch (error) {
            console.error(error);
            message.error('Unexpected error')
        }
    };

    const menu = (jobId) => (
        <Menu onClick={(e) => {
            switch (e.key) {
                case "1":
                    (jobs.find(j => j.jobId === jobId) || {}).finishedAt ? getDetails(jobId) : (() => { })()
                    break;
                case "2":
                    retry(jobId)
                    break;
                case "3":
                    const logs = (jobs.find(j => j.jobId === jobId) || {}).logs || [];
                    UiHelper.downloadJsonFile(logs, 'logs.json')
                    break;
                default:
                    break;
            }
        }}>
            <Menu.Item key="1" icon={<DownloadOutlined />}>
                Download details
          </Menu.Item>
            <Menu.Item key="2" icon={<ReloadOutlined />}>
                Retry
          </Menu.Item>
            <Menu.Item key="3" icon={<FileZipOutlined />}>
                View Logs
          </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: 'Started',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Finished',
            dataIndex: 'finishedAt',
            key: 'finishedAt',
        },
        {
            title: 'Operation',
            dataIndex: 'action',
            key: 'action',
            render: (value) => toPascelCase(value)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value) => toPascelCase(value)
        },
        {
            title: 'User Name',
            dataIndex: 'boUserName',
            key: 'boUserName',
        },
        {
            title: 'Personname',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Personnumber',
            dataIndex: 'bankUserId',
            key: 'bankUserId',
        },
        {
            title: 'Job ID',
            dataIndex: 'jobId',
            key: 'jobId',
        },
        {
            title: '',
            dataIndex: 'jobId',
            key: 'jobId',
            render: (value) => <Dropdown overlay={menu(value)}>
                <Link className={styles.actions} to={`#`}>Actions <DownOutlined /></Link>
            </Dropdown>
        }
    ];

    return (
        <div className={styles.jobsTable}>
            <div className={styles.rowTop}>
                <h1>RPA Dashboard</h1>
            </div>
            <div className={styles.rowSecond}>
                {statusFilters.map(s =>
                    <Checkbox data-cy={`checkbox_${s.key}`}
                        checked={s.checked} className={styles.checkbox}
                        onChange={(e) => {setStatusFilters([...(statusFilters.map(c => s === c ? {...c, checked: !c.checked} : c))])}}>
                        {s.text}
                    </Checkbox>)
                }
            </div>

            <div className={styles.rowMainContent}>
                <Table
                    pagination={{position: ["bottomCenter"], showSizeChanger: false, current: pageIndex, pageSize: itemsPerPage, total: totalUsers, }}
                    columns={columns}
                    dataSource={jobs}
                    onChange={(pagination) => {setPageIndex(pagination.current)}}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => props.onRowClicked ? props.onRowClicked(record) : (() => { })()
                        };
                    }}
                />
            </div>
        </div>
    );
}
