import * as React from "react";
import { useEffect, useState } from "react";
import classnames from "classnames";
import styles from "components/common/presenters/share-section/ShareSection.module.scss";
import { PlusCircleOutlined, MinusCircleOutlined, MailOutlined, MailTwoTone } from "@ant-design/icons";
import { Row, Col, Input, Button } from "antd";

export interface ShareSectionProps {
	className?: string;
	onClick?: (email: string) => void
}

export interface ShareSectionState {
	email: string
}

export const ShareSection = (props: ShareSectionProps) => {
	const [data, setData] = useState({
		email: null,
	} as ShareSectionState);
	const [isCollapsed, setIsCollapsed] = useState(false);

	let dynamicClasses = classnames(styles.section, { 
		[styles.sectionCollapsed]: isCollapsed,
	});

	return (
		<div className={dynamicClasses}>

			<Row>
				<Col className={styles.colLeft} span={8}>
					<MailTwoTone className={styles.icon} twoToneColor="#52c41a"/>
				</Col>

				<Col span={16}>
					<h2 className={styles.h2}>Diese Seite Teilen</h2>
					<p>Falls Sie diese Seite mit einer Person teilen wollen, geben Sie bitte unten die E-mail Adresse des Empfängers an. Die Person erhält dann einen speziellen Anmelde-Link per E-Mail. Der Empfänger hat keinen Zugriff auf andere Anträge in Ihrem Benutzerkonto.</p>
					<br />
					<h4>E-Mail-Adresse</h4>
					<Input className={styles.input}
						data-cy="input_share_email"
						placeholder="Beispiel: muster@muster.de"
						onChange={(e) => {
							setData({...data, email: e.target.value})
						}} />
					<br />
					<br />
					<Button data-cy="button_share"
						className={styles.button}
						type="primary"
						htmlType="submit"
						onClick={ () => {
							if(props.onClick) {
								props.onClick(data.email)
							}
						}}>
						Teilen
					</Button>
				</Col>
			</Row>
		</div>
	);
};

export default ShareSection;
