import * as React from "react";
import {useEffect, useState} from "react";
import styles from "components/common/presenters/account-information-page-tabs/AccountInformationPageTabs.module.scss";
import ShareSection from "components/common/presenters/share-section/ShareSection";
import {Button, Checkbox, Col, DatePicker, Descriptions, Divider, Input, InputNumber, message, Radio, Row, Select} from "antd";
import {CheckSquareOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {ContractType, CreditInstitutionSetting, EmploymentType, ObjectType, OffersData, PaymentMethod, PriceType, PublicSelfDeclarationInfo, SelfDeclaration, SelfDeclarationPerson, UserData, UserDocument, VehicleType} from "../../../../../services/api/types";

import ApiManager from "../../../../../services/api/ApiManager";
import CollapsibleSection from "../../collapsible-section/CollapsibleSection";
import {useTranslation} from "react-i18next";
import {ListItem, ListItemTypes} from "../../list-item/ListItem";
import UiHelper from "../../../../../helpers/uiHelpers";
import {DATE_FORMAT} from "../../../../../services/api/Globals";
import moment from "moment";
import {getMonthlyRate} from "services/calculator/calculation-actions/loanObjectGetters";
import {mapPaymentsPerYearToPaymentMethod} from "services/calculator/types";
import {Store, useStore} from "hooks/Store";
import {SelectContactPersonModal} from "components/common/presenters/account-information-page-tabs/self-declaration-tab/SelectContactPersonModal";

export interface SelfDeclarationTabProps {
    isPublicPage: boolean
    latestSelfDeclaration?: SelfDeclaration
    offers?: OffersData[]
    accountInformation?: UserData

    // non-public
    userId?: number
    generatedSelfDeclarationDocuments?: UserDocument[]
    uploadedSelfDeclarationDocuments?: UserDocument[]
    onSubmitSuccess?: () => void

    // public
    shareToken?: string
    publicInfo?: PublicSelfDeclarationInfo
}

export interface SelfDeclarationTabState extends SelfDeclaration {
}

export const SelfDeclarationTab = (props: SelfDeclarationTabProps) => {
    const {t} = useTranslation()
    const store: Store = useStore()

    const defaultState = {
        vehicleType: VehicleType.SECOND_HAND,
        isVehicleLetterAvailable: false,
        purchasePriceType: PriceType.NET,
        purchasePrice: 0,
        tradeInPriceType: PriceType.NET,
        tradeInPrice: 0,
        cashDeposit: 0,
        netFinancingAmount: 0,
        netBlockRate: 0,
        contractType: ContractType.RENTAL_PURCHASE,
        loanDurationMonths: 24,
        paymentMethod: PaymentMethod.QUARTERLY,
        employmentType: EmploymentType.SIDE_EMPLOYMENT,
        isDeductInputTax: false,
        isContractor: false,
        netIncome: 0,
    };

    const [data, setData] = useState(defaultState as SelfDeclarationTabState)
    const [ui, setUi] = useState({
        selectedOfferId: null,
        selectedBankAccountId: null,
        selectContactPersonModal: false
    })

    const [persons, setPersons] = useState([{yearsExperience: 0}] as SelfDeclarationPerson[])

    const [companyLegalTypeRadio, setCompanyLegalTypeRadio] = useState('other')
    const [companyLegalTypeOther, setCompanyLegalTypeOther] = useState('')

    useEffect(() => {
        if (props.latestSelfDeclaration) {
            setData(props.latestSelfDeclaration)
            setPersons(props.latestSelfDeclaration.persons)
            switch (props.latestSelfDeclaration.companyLegalType) {
                case 'Gewerbebetrieb':
                    setCompanyLegalTypeRadio('Gewerbebetrieb')
                    break
                case 'GbR':
                    setCompanyLegalTypeRadio('GbR')
                    break
                case 'GmbH':
                    setCompanyLegalTypeRadio('GmbH')
                    break
                default:
                    setCompanyLegalTypeRadio('other')
                    setCompanyLegalTypeOther(props.latestSelfDeclaration.companyLegalType)
            }
        } else {
            setData(defaultState)
        }
    }, [props.latestSelfDeclaration, props.publicInfo])

    const onSave = async () => {
        try {
            if (props.isPublicPage) {
                try {
                    await ApiManager.UserService.saveSelfDeclarationByShareToken(props.shareToken, {...data, persons: persons})
                    message.success('Daten Gespeichert', 2)
                } catch (e) {
                    console.error(e)
                    switch (e.response?.status) {
                        case 400:
                            message.error(`Ungültige Daten: ${e.response.data?.errors[0]}`, 2)
                        case 403:
                            if (e.response.data?.errors[0] === 'ShareRequestUsed') {
                                message.error('Token bereits verwendet.', 2)
                            } else if (e.response.data?.errors[0] === 'ShareRequestExpired') {
                                message.error('Token abgelaufen.', 2)
                            }
                            break
                        case 404:
                            message.error('Benutzer wurde nicht gefunden', 2)
                    }
                }
            } else {
                try {
                    await ApiManager.UserService.saveSelfDeclaration(props.userId, {...data, persons: persons})
                    message.success('Daten Gespeichert', 2)
                } catch (e) {
                    console.error(e)
                    message.error('Konnte nicht Speichern', 2)
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const onSubmit = async () => {
        try {
            if (props.isPublicPage) {
                try {
                    await ApiManager.UserService.submitSelfDeclarationByShareToken(props.shareToken, {...data, persons: persons})
                    message.success('Selbstauskunft verschickt', 2)
                } catch (e) {
                    console.error(e)
                    switch (e.response?.status) {
                        case 400:
                            message.error(`Ungültige Daten: ${e.response.data?.errors[0]}`, 2)
                        case 403:
                            if (e.response.data?.errors[0] === 'ShareRequestUsed') {
                                message.error('Token bereits verwendet.', 2)
                            } else if (e.response.data?.errors[0] === 'ShareRequestExpired') {
                                message.error('Token abgelaufen.', 2)
                            }
                            break
                        case 404:
                            message.error('Benutzer wurde nicht gefunden', 2)
                    }
                }
            } else {
                try {
                    await ApiManager.UserService.submitSelfDeclaration(props.userId, {...data, persons: persons})
                    await props.onSubmitSuccess()
                    message.success('Selbstauskunft verschickt', 2)
                } catch (e) {
                    console.error(e)
                    message.error('Konnte nicht Speichern', 2)
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const resetState = () => {
        setData(defaultState)
        setPersons([{yearsExperience: 0}] as SelfDeclarationPerson[])
        setCompanyLegalTypeRadio('other')
        setCompanyLegalTypeOther('')
    }

    const onViewUserDocClicked = async (userDocId: number) => {
        try {
            const signedUrlResponse = await ApiManager.UserService.getUserDocumentSignedUrl(userDocId)
            const signedUrl = signedUrlResponse.data
            UiHelper.openUrlInNewTab(signedUrl)
        } catch (e) {
            console.error(e)
        }
    }

    const onDownloadUserDocClicked = async (userDocId: number, fileName: string) => {
        try {
            const signedUrlResponse = await ApiManager.UserService.getUserDocumentSignedUrl(userDocId)
            const signedUrl = signedUrlResponse.data
            UiHelper.downloadPDFFromOtherDomain(signedUrl, fileName) // TODO: cors.............
        } catch (e) {
            console.error(e)
        }
    }

    const renderExistingDocuments = () => (
        <>
            {
                props.generatedSelfDeclarationDocuments?.length > 0 ?
                    <>
                        <h2 className={styles.textAlignStart}>{t(`generated_self_declarations`)}</h2>
                        {
                            props.generatedSelfDeclarationDocuments.map((d, i) =>
                                <ListItem key={i}
                                    text={d.documentName}
                                    type={ListItemTypes.SelfDeclaration}
                                    onNextToLastIconClicked={() => {
                                        onViewUserDocClicked(d.userDocumentId)
                                    }}
                                    onLastIconClicked={() =>
                                        onDownloadUserDocClicked(d.userDocumentId, d.documentName)
                                    }
                                />
                            )
                        }
                        <br />
                        <br />
                    </> : null
            }
            {
                props.uploadedSelfDeclarationDocuments?.length > 0 ?
                    <>
                        <h2 className={styles.textAlignStart}>{t(`uploaded_self_declarations`)}</h2>
                        {
                            props.uploadedSelfDeclarationDocuments.map((d, i) =>
                                <ListItem key={i}
                                    text={d.documentName}
                                    type={ListItemTypes.SelfDeclaration}
                                    onNextToLastIconClicked={() => {
                                        onViewUserDocClicked(d.userDocumentId)
                                    }}
                                    onLastIconClicked={() =>
                                        onDownloadUserDocClicked(d.userDocumentId, d.documentName)
                                    }
                                />
                            )
                        }
                        <br />
                        <br />
                    </> : null
            }
        </>
    )

    const renderPublicInfo = () => (
        <>
            <h2 className={styles.textAlignStart}>{`${t('company_name')}: ${props.publicInfo.companyName || ''}`}</h2>
            <div className={styles.tabInner}>
                <p>{t('public_self_declaration_header_1')}</p>
                <Descriptions title={t('public_self_declaration_header_2')}
                    layout={'vertical'}
                    column={3}
                >
                    <Descriptions.Item label={t('data:name')}>{props.publicInfo.name}</Descriptions.Item>
                    <Descriptions.Item label={t('data:telephone')}>{props.publicInfo.phoneNumber}</Descriptions.Item>
                    <Descriptions.Item label={t('data:email')}>{props.publicInfo.email}</Descriptions.Item>
                </Descriptions>
            </div>
            <br />
        </>
    )

    const renderSaveButton = () => {
        return (
            <Button data-cy="button_save"
                type="primary"
                onClick={() => {
                    onSave()
                }}>
                <CheckSquareOutlined />
				Speichern
            </Button>
        )
    }

    return (
        <div className={styles.tab}>
            {
                props.isPublicPage ?
                    renderPublicInfo() :
                    renderExistingDocuments()
            }
            <div>
                <h2 className="text-left">Antrag für die Selbstauskunft auswählen</h2>
                <h3 className="text-left">Erstellte Angebote</h3>
                <Row gutter={[24, 24]} justify={'space-between'}>
                    <Col span={18}>
                        <Select data-cy="dropdown_offer_id"
                            style={{maxWidth: '700px'}}
                            value={ui.selectedOfferId}
                            onChange={(e) => setUi({...ui, selectedOfferId: e})}>
                            {(props.offers || []).map((l, i) => <Select.Option data-cy={`offer_option_${i}`} key={i} value={l.offerId}>{`${l.offerName.split('-')[0]}`}</Select.Option>)}
                        </Select>
                    </Col>
                </Row>
            </div>
            <CollapsibleSection text={t('details_of_applicant_company')}
                initialCollapsedState={true}
                buttonsTop={<Button data-cy="button_save"
                    type="primary"
                    onClick={() => {
                        let newObject = {};
                        if (props.accountInformation.isPrivate) {
                            newObject = {
                                companyName: props.accountInformation.firstName + ' ' + props.accountInformation.lastName,
                            }
                        } else {
                            newObject = {
                                companyName: props.accountInformation.companyName,
                            }
                        }
                        newObject = {
                            ...newObject,
                            address: props.accountInformation.addressLine1 || '' + ' ' + props.accountInformation.addressStreetNumber || '' + '\n' +
                                props.accountInformation.addressPostalCode || '' + ' ' + props.accountInformation.addressCity || '',
                            phone: props.accountInformation.oldschoolphoneNumber,
                            mobilePhone: props.accountInformation.mobileNumber,
                            fax: props.accountInformation.faxNumber,
                            taxNumber: props.accountInformation.taxNumber,
                            email: props.accountInformation.email
                        }
                        setData({
                            ...data,
                            ...newObject
                        })
                    }}>
                    Personendaten übernehmen
                </Button>}
            >
                <div className={styles.tabInner}>
                    <h4>{t('data:name_company_name')}</h4>
                    <Input data-cy={"input_company_name"}
                        placeholder={'Example GmbH'}
                        value={data.companyName}
                        onChange={(e) => {
                            setData({...data, companyName: e.target.value})
                        }}
                    />
                    <br />
                    <br />

                    <h4>{t('data:address')}</h4>
                    <Input.TextArea data-cy={"input_address"}
                        autoSize={{minRows: 2, maxRows: 2}}
                        placeholder={t('placeholders:address_line_1_2')}
                        value={data.address}
                        onChange={(e) => {
                            setData({...data, address: e.target.value})
                        }}
                    />
                    <br />
                    <br />

                    <Row gutter={24}>
                        <Col span={12}>
                            <h4>{t('data:telephone')}</h4>
                            <Input data-cy={"input_telephone"}
                                placeholder={'+491234567'}
                                value={data.phone}
                                onChange={(e) => {
                                    setData({...data, phone: e.target.value})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{t('data:mobile')}</h4>
                            <Input data-cy={"input_mobile"}
                                placeholder={'+491234567'}
                                value={data.mobilePhone}
                                onChange={(e) => {
                                    setData({...data, mobilePhone: e.target.value})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{t('data:tax_number')}</h4>
                            <Input data-cy={"input_tax_number"}
                                placeholder={'12/345/67892'}
                                value={data.taxNumber}
                                onChange={(e) => {
                                    setData({...data, taxNumber: e.target.value})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                        <Col span={12}>
                            <h4>{t('data:fax')}</h4>
                            <Input data-cy={"input_fax"}
                                placeholder={'+491234567'}
                                value={data.fax}
                                onChange={(e) => {
                                    setData({...data, fax: e.target.value})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{t('data:email')}</h4>
                            <Input data-cy={"input_email"}
                                placeholder={'example@email.com'}
                                value={data.email}
                                onChange={(e) => {
                                    setData({...data, email: e.target.value})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                    </Row>
                    {renderSaveButton()}
                </div>
            </CollapsibleSection>

            <CollapsibleSection text={t('financing_object')} initialCollapsedState={true}
                buttonsTop={<Button data-cy="button_save"
                    disabled={!ui.selectedOfferId}
                    type="primary"
                    onClick={() => {
                        const selectedOffer = (props.offers || []).find((o) => o.offerId === ui.selectedOfferId) || {applicationId: ''}
                        const object = props.accountInformation.objects
                            .find(o => o.applicationId === selectedOffer.applicationId && o.type === ObjectType.MAIN);
                        if (object) {
                            setData({
                                ...data,
                                description: object.objectName,
                                manufacturer: object.manufacturer,
                                vehicleType: object.machineType as VehicleType,
                                yearOfConstruction: object.yearBuilt ? parseInt(moment(object.yearBuilt, DATE_FORMAT.API).format('YYYY')) : null,
                                serialNumber: object.serialNumber,
                                deliveryDate: object.deliveryDate ? moment(object.deliveryDate, DATE_FORMAT.API).format('YYYY') : ''
                            })
                        }

                    }}>
                    Objektdaten übernehmen
                        </Button>}
            >
                <div className={styles.tabInner}>
                    <h4>{t('data:description')}</h4>
                    <Input data-cy={"input_description"}
                        placeholder={'Lorem Ipsum'}
                        value={data.description}
                        onChange={(e) => {
                            setData({...data, description: e.target.value})
                        }}
                    />
                    <br />
                    <br />

                    <h4>{t('data:manufacturer')}</h4>
                    <Input data-cy={"input_manufacturer"}
                        placeholder={'Max Holder GMBH'}
                        value={data.manufacturer}
                        onChange={(e) => {
                            setData({...data, manufacturer: e.target.value})
                        }}
                    />
                    <br />
                    <br />

                    <h4>{t('data:vehicle_type')}</h4>
                    <Radio.Group data-cy={"radio_vehicle_type"}
                        value={data.vehicleType}
                        onChange={(e) => {
                            setData({...data, vehicleType: e.target.value})
                        }}
                    >
                        <Radio data-cy={"radio_vehicle_type_0"}
                            value={VehicleType.NEW}
                        >
                            {t('vehicle_types:new')}
                        </Radio>
                        <Radio data-cy={"radio_vehicle_type_1"}
                            value={VehicleType.SECOND_HAND}
                        >
                            {t('vehicle_types:second_hand')}
                        </Radio>
                        <Radio data-cy={"radio_vehicle_type_2"}
                            value={VehicleType.DEMO}
                        >
                            {t('vehicle_types:demo')}
                        </Radio>
                    </Radio.Group>
                    <br />
                    <br />

                    <h4>{t('vehicle_letter_available')}</h4>
                    <Radio.Group data-cy={"radio_vehicle_letter_available"}
                        value={data.isVehicleLetterAvailable}
                        onChange={(e) => {
                            setData({...data, isVehicleLetterAvailable: e.target.value})
                        }}
                    >
                        <Radio data-cy={"radio_vehicle_letter_available_0"}
                            value={true}
                        >
                            {t('yes')}
                        </Radio>
                        <Radio data-cy={"radio_vehicle_letter_available_1"}
                            value={false}
                        >
                            {t('no')}
                        </Radio>
                    </Radio.Group>
                    <br />
                    <br />

                    <h4>{t('data:type')}</h4>
                    <Input data-cy={"input_type"}
                        placeholder={'Type'}
                        value={data.type}
                        onChange={(e) => {
                            setData({...data, type: e.target.value})
                        }}
                    />
                    <br />
                    <br />

                    <Row gutter={24}>
                        <Col span={12}>
                            <h4>{t('data:year_of_construction')}</h4>
                            <DatePicker data-cy={"input_year_of_construction"}
                                value={data.yearOfConstruction ? moment().year(data.yearOfConstruction) : null}
                                picker={'year'}
                                onChange={(e) => {
                                    setData({...data, yearOfConstruction: parseInt(e?.format('YYYY'))})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{t('data:location')}</h4>
                            <Input data-cy={"input_machine_location"}
                                placeholder={'Berlin'}
                                value={data.machineLocation}
                                onChange={(e) => {
                                    setData({...data, machineLocation: e.target.value})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                        <Col span={12}>
                            <h4>{t('data:serial_number')}</h4>
                            <Input data-cy={"input_serial_number"}
                                value={data.serialNumber}
                                onChange={(e) => {
                                    setData({...data, serialNumber: e.target.value})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{t('data:delivery_date')}</h4>
                            <DatePicker data-cy={"input_delivery_date"}
                                defaultValue={null}
                                format={DATE_FORMAT.DE}
                                value={data.deliveryDate ? moment(data.deliveryDate) : null}
                                onChange={(e) => {
                                    setData({...data, deliveryDate: e?.format(DATE_FORMAT.API)})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                    </Row>
                    {renderSaveButton()}
                </div>
            </CollapsibleSection>

            <CollapsibleSection text={t('dealer_data')}
                initialCollapsedState={true}
                buttonsTop={<Button data-cy="button_save"
                    disabled={!ui.selectedOfferId}
                    type="primary"
                    onClick={async () => {
                        const selectedOffer = (props.offers || []).find((o) => o.offerId === ui.selectedOfferId) || {applicationId: ''};
                        const object = props.accountInformation.objects
                            .find(o => o.applicationId === selectedOffer.applicationId && o.type === ObjectType.MAIN);
                        if (object && object.lieferantId) {
                            const result = await ApiManager.UserService.getUser(object.lieferantId, []);
                            const lieferant = result.data;
                            if (lieferant) {
                                setData({
                                    ...data,
                                    dealerName: lieferant.companyName || lieferant.firstName + ' ' + lieferant.lastName,
                                    dealerAddress: lieferant.addressLine1 + ' ' + lieferant.addressStreetNumber + '\n' +
                                        lieferant.addressPostalCode + ' ' + lieferant.addressCity,
                                    dealerPhone: lieferant.mobileNumber || lieferant.oldschoolphoneNumber,
                                    dealerEmail: lieferant.email,
                                    dealerFax: lieferant.faxNumber,
                                    dealerContactName: lieferant.firstName + ' ' + lieferant.lastName
                                })
                            }
                        }

                    }}>
                    Händlerdaten übernehmen
                </Button>}
            >
                <div className={styles.tabInner}>
                    <h4>{t('data:name')}</h4>
                    <Input data-cy={"input_dealer_name"}
                        placeholder={'Example GmbH'}
                        value={data.dealerName}
                        onChange={(e) => {
                            setData({...data, dealerName: e.target.value})
                        }}
                    />
                    <br />
                    <br />

                    <h4>{t('data:address')}</h4>
                    <Input.TextArea data-cy={"input_dealer_address"}
                        autoSize={{minRows: 2, maxRows: 2}}
                        placeholder={t('placeholders:address_line_1_2')}
                        value={data.dealerAddress}
                        onChange={(e) => {
                            setData({...data, dealerAddress: e.target.value})
                        }}
                    />
                    <br />
                    <br />

                    <Row gutter={24}>
                        <Col span={12}>
                            <h4>{t('data:telephone')}</h4>
                            <Input data-cy={"input_dealer_telephone"}
                                placeholder={'+491234567'}
                                value={data.dealerPhone}
                                onChange={(e) => {
                                    setData({...data, dealerPhone: e.target.value})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{t('data:email')}</h4>
                            <Input data-cy={"input_dealer_email"}
                                placeholder={'example@email.com'}
                                value={data.dealerEmail}
                                onChange={(e) => {
                                    setData({...data, dealerEmail: e.target.value})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                        <Col span={12}>
                            <h4>{t('data:fax')}</h4>
                            <Input data-cy={"input_dealer_fax"}
                                placeholder={'+491234567'}
                                value={data.dealerFax}
                                onChange={(e) => {
                                    setData({...data, dealerFax: e.target.value})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{t('data:contact_person')}</h4>
                            <Input data-cy={"input_dealer_contact"}
                                placeholder={''}
                                value={data.dealerContactName}
                                onChange={(e) => {
                                    setData({...data, dealerContactName: e.target.value})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                    </Row>
                    {renderSaveButton()}
                </div>
            </CollapsibleSection>

            <CollapsibleSection text={t('offer_data')}
                initialCollapsedState={true}
                buttonsTop={<Button data-cy="button_save"
                    disabled={!ui.selectedOfferId}
                    type="primary"
                    onClick={async () => {
                        const selectedOffer = (props.offers || []).find((o) => o.offerId === ui.selectedOfferId) || {calculationId: ''};
                        const calculation = props.accountInformation.calculations
                            .find(o => o.calculationId === selectedOffer.calculationId);
                        if (calculation) {
                            setData({
                                ...data,
                                purchasePriceType: PriceType.GROSS,
                                purchasePrice: calculation.loanAmount,
                                cashDeposit: calculation.downpayment,
                                netFinancingAmount: calculation.loanAmount - calculation.downpayment,
                                netBlockRate: parseInt(getMonthlyRate(calculation).toString()),
                                contractType: ContractType.CREDIT,
                                loanDurationMonths: calculation.loanDuration,
                                paymentMethod: mapPaymentsPerYearToPaymentMethod(calculation.paymentsPerYear)
                            })
                        }

                    }}>
                    Angebotsdaten übernehmen
                        </Button>}
            >
                <div className={styles.tabInner}>
                    <h4>{t('data:purchase_price')}</h4>
                    <Radio.Group data-cy={"radio_purchase_price_type"}
                        value={data.purchasePriceType}
                        onChange={(e) => {
                            setData({...data, purchasePriceType: e.target.value})
                        }}
                    >
                        <Radio data-cy={"radio_purchase_price_type_0"}
                            value={PriceType.GROSS}
                        >
                            {t('gross')}
                        </Radio>
                        <Radio data-cy={"radio_purchase_price_type_1"}
                            value={PriceType.NET}
                        >
                            {t('net')}
                        </Radio>
                    </Radio.Group>
                    <br />
                    <br />

                    <InputNumber data-cy={"input_purchase_price"}
                        formatter={(e) => `€ ${e}`}
                        min={0}
                        value={data.purchasePrice}
                        onChange={(e) => {
                            setData({...data, purchasePrice: e as number})
                        }}
                    />
                    <br />
                    <br />

                    <h4>{t('data:trade_in')}</h4>
                    <Radio.Group data-cy={"radio_trade_in"}
                        value={data.tradeInPriceType}
                        onChange={(e) => {
                            setData({...data, tradeInPriceType: e.target.value})
                        }}
                    >
                        <Radio data-cy={"radio_trade_in_0"}
                            value={PriceType.GROSS}
                        >
                            {t('gross')}
                        </Radio>
                        <Radio data-cy={"radio_trade_in_1"}
                            value={PriceType.NET}
                        >
                            {t('net')}
                        </Radio>
                    </Radio.Group>
                    <br />
                    <br />

                    <InputNumber data-cy={"input_trade_in"}
                        formatter={(e) => `€ ${e}`}
                        min={0}
                        value={data.tradeInPrice}
                        onChange={(e) => {
                            setData({...data, tradeInPrice: e as number})
                        }}
                    />
                    <br />
                    <br />

                    <h4>{t('data:cash_deposit')}</h4>
                    <InputNumber data-cy={"input_cash_deposit"}
                        formatter={(e) => `€ ${e}`}
                        min={0}
                        value={data.cashDeposit}
                        onChange={(e) => {
                            setData({...data, cashDeposit: e as number})
                        }}
                    />
                    <br />
                    <br />

                    <h4>{'= ' + t('data:net_financing_amount')}</h4>
                    <InputNumber data-cy={"input_net_financing_amount"}
                        formatter={(e) => `€ ${e}`}
                        min={0}
                        value={data.netFinancingAmount}
                        onChange={(e) => {
                            setData({...data, netFinancingAmount: e as number})
                        }}
                    />
                    <br />
                    <br />

                    <h4>{t('data:net_block_rate')}</h4>
                    <InputNumber data-cy={"input_net_block_rate"}
                        formatter={(e) => `€ ${e}`}
                        min={0}
                        value={data.netBlockRate}
                        onChange={(e) => {
                            setData({...data, netBlockRate: e as number})
                        }}
                    />
                    <br />
                    <br />

                    <h4>{t('data:contract_type')}</h4>
                    <Radio.Group data-cy={"radio_contract_type"}
                        value={data.contractType}
                        onChange={(e) => {
                            setData({...data, contractType: e.target.value})
                        }}
                    >
                        <Radio data-cy={"radio_contract_type_0"}
                            value={ContractType.CREDIT}
                        >
                            {t('credit')}
                        </Radio>
                        <Radio data-cy={"radio_contract_type_1"}
                            value={ContractType.RENTAL_PURCHASE}
                        >
                            {t('rental_purchase')}
                        </Radio>
                    </Radio.Group>
                    <br />
                    <br />

                    <h4>{t('data:loan_duration')}</h4>
                    <Row gutter={16}>
                        <Col>
                            <Radio.Group data-cy={"radio_loan_duration"}
                                value={data.loanDurationMonths}
                                onChange={(e) => {
                                    setData({...data, loanDurationMonths: e.target.value})
                                }}
                            >
                                <Radio data-cy={"radio_loan_duration_0"} value={12}>12</Radio>
                                <Radio data-cy={"radio_loan_duration_1"} value={24}>24</Radio>
                                <Radio data-cy={"radio_loan_duration_1"} value={36}>36</Radio>
                                <Radio data-cy={"radio_loan_duration_1"} value={48}>48</Radio>
                                <Radio data-cy={"radio_loan_duration_1"} value={60}>60</Radio>
                                <Radio data-cy={"radio_loan_duration_1"} value={72}>72</Radio>
                                <Radio data-cy={"radio_loan_duration_1"} value={84}>84</Radio>
                            </Radio.Group>
                        </Col>
                        <Col>
                            {t('months')}
                        </Col>
                    </Row>
                    <br />

                    <h4>{t('data:payment_method')}</h4>
                    <Radio.Group data-cy={"radio_payment_method"}
                        value={data.paymentMethod}
                        onChange={(e) => {
                            setData({...data, paymentMethod: e.target.value})
                        }}
                    >
                        <Radio data-cy={"radio_paymentMethod_0"}
                            value={PaymentMethod.MONTHLY}
                        >
                            {t(`payment_methods:${PaymentMethod.MONTHLY}`)}
                        </Radio>
                        <Radio data-cy={"radio_paymentMethod_1"}
                            value={PaymentMethod.QUARTERLY}
                        >
                            {t(`payment_methods:${PaymentMethod.QUARTERLY}`)}
                        </Radio>
                        <Radio data-cy={"radio_paymentMethod_2"}
                            value={PaymentMethod.HALF_YEARLY}
                        >
                            {t(`payment_methods:${PaymentMethod.HALF_YEARLY}`)}
                        </Radio>
                        <Radio data-cy={"radio_paymentMethod_3"}
                            value={PaymentMethod.YEARLY}
                        >
                            {t(`payment_methods:${PaymentMethod.YEARLY}`)}
                        </Radio>
                    </Radio.Group>
                    <br />
                    <br />
                    <br />

                    {renderSaveButton()}
                </div>
            </CollapsibleSection>

            <CollapsibleSection initialCollapsedState={true} text={t('company_briefs')}>
                <div className={styles.tabInner}>
                    <h4>{t('agriculture_in')}</h4>
                    <Radio.Group data-cy={"radio_employment_type"}
                        value={data.employmentType}
                        onChange={(e) => {
                            setData({...data, employmentType: e.target.value})
                        }}
                    >
                        <Radio data-cy={"radio_employment_type_0"}
                            value={EmploymentType.FULL_EMPLOYMENT}
                        >
                            {t('employment_types:full_employment')}
                        </Radio>
                        <Radio data-cy={"radio_employment_type_1"}
                            value={EmploymentType.SIDE_EMPLOYMENT}
                        >
                            {t('employment_types:side_employment')}
                        </Radio>
                    </Radio.Group>
                    <br />
                    <br />

                    <h4>{t('company_may_deduct_input_tax')}</h4>
                    <Radio.Group data-cy={"radio_company_may_deduct_input_tax"}
                        value={data.isDeductInputTax}
                        onChange={(e) => {
                            setData({...data, isDeductInputTax: e.target.value})
                        }}
                    >
                        <Radio data-cy={"radio_company_may_deduct_input_tax_0"}
                            value={true}
                        >
                            {t('yes')}
                        </Radio>
                        <Radio data-cy={"radio_company_may_deduct_input_tax_1"}
                            value={false}
                        >
                            {t('no')}
                        </Radio>
                    </Radio.Group>
                    <br />
                    <br />

                    <h4>{t('contractor')}</h4>
                    <Radio.Group data-cy={"radio_is_contractor"}
                        value={data.isContractor}
                        onChange={(e) => {
                            setData({...data, isContractor: e.target.value})
                        }}
                    >
                        <Radio data-cy={"radio_is_contractor_0"}
                            value={true}
                        >
                            {t('yes')}
                        </Radio>
                        <Radio data-cy={"radio_is_contractor_1"}
                            value={false}
                        >
                            {t('no')}
                        </Radio>
                    </Radio.Group>
                    <br />
                    <br />

                    <h4>{`${t('with_sideline')}:`}</h4>
                    <Row gutter={24}>
                        <Col span={12}>
                            <h4>{t('profession')}</h4>
                            <Input data-cy={"input_profession"}
                                placeholder={''}
                                value={data.profession}
                                onChange={(e) => {
                                    setData({...data, profession: e.target.value})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{t('data:employed_since')}</h4>
                            <DatePicker data-cy={"input_employed_since"}
                                value={data.employedSince ? moment(data.employedSince) : null}
                                format={DATE_FORMAT.DE}
                                onChange={(e) => {
                                    setData({...data, employedSince: e?.format(DATE_FORMAT.API)})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                        <Col span={12}>
                            <h4>{t('data:employer')}</h4>
                            <Input data-cy={"input_employer"}
                                value={data.employer}
                                onChange={(e) => {
                                    setData({...data, employer: e.target.value})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{t('data:net_income')}</h4>
                            <InputNumber data-cy={"input_net_income"}
                                formatter={(e) => `€ ${e}`}
                                min={0}
                                value={data.netIncome}
                                onChange={(e) => {
                                    setData({...data, netIncome: e as number})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                    </Row>

                    <Radio.Group data-cy={"radio_company_legal_type"}
                        value={companyLegalTypeRadio}
                        onChange={(e) => {
                            setCompanyLegalTypeRadio(e.target.value)

                            if (e.target.value === 'other') {
                                setData({...data, companyLegalType: companyLegalTypeOther})
                            } else {
                                setData({...data, companyLegalType: e.target.value})
                            }
                        }}
                    >
                        <Radio data-cy={"radio_company_legal_type_0"}
                            value={'Gewerbebetrieb'}
                        >
                            Gewerbebetrieb
						</Radio>
                        <Radio data-cy={"radio_company_legal_type_1"}
                            value={'GbR'}
                        >
                            GbR
						</Radio>
                        <Radio data-cy={"radio_company_legal_type_2"}
                            value={'GmbH'}
                        >
                            GmbH
						</Radio>
                        <br />
                        <br />
                        <Radio data-cy={"radio_company_legal_type_3"}
                            value={'other'}
                        >
                            Sonstige &nbsp;&nbsp;&nbsp;
							<Input data-cy={"input_company_legal_type_other"}
                                disabled={companyLegalTypeRadio !== 'other'}
                                placeholder={'AG'}
                                value={companyLegalTypeOther}
                                onChange={(e) => {
                                    setCompanyLegalTypeOther(e.target.value)
                                    setData({...data, companyLegalType: e.target.value})
                                }}
                            />
                        </Radio>
                    </Radio.Group>
                    <br />
                    <br />

                    <Row gutter={24}>
                        <Col span={12}>
                            <h4>{t('data:founded_on')}</h4>
                            <DatePicker data-cy={"input_founded_on"}
                                value={data.foundedOn ? moment(data.foundedOn) : null}
                                format={DATE_FORMAT.DE}
                                onChange={(e) => {
                                    setData({...data, foundedOn: e?.format(DATE_FORMAT.API)})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{t('data:employee')}</h4>
                            <Input data-cy={"input_employee"}
                                placeholder={''}
                                value={data.employee}
                                onChange={(e) => {
                                    setData({...data, employee: e.target.value})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                        <Col span={12}>
                            <h4>{t('data:first_founded_on')}</h4>
                            <DatePicker data-cy={"input_first_founded_on"}
                                value={data.firstFoundedOn ? moment(data.firstFoundedOn) : null}
                                format={DATE_FORMAT.DE}
                                onChange={(e) => {
                                    setData({...data, firstFoundedOn: e?.format(DATE_FORMAT.API)})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                    </Row>

                    <h4>{t('data:equity')}</h4>
                    <div className={styles.row}>
                        <InputNumber data-cy={"input_equity"}
                            formatter={(e) => `€ ${e}`}
                            min={0}
                            value={data.equity}
                            onChange={(e) => {
                                setData({...data, equity: e as number})
                            }}
                        />
						&nbsp;&nbsp; per &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Input data-cy={"input_equity_per"}
                            className={styles.shortInput}
                            placeholder={''}
                            value={data.equityPer}
                            onChange={(e) => {
                                setData({...data, equityPer: e.target.value})
                            }}
                        />
                    </div>
                    <br />

                    <h4>{t('data:turnover')}</h4>
                    <div className={styles.row}>
                        <InputNumber data-cy={"input_turnover"}
                            formatter={(e) => `€ ${e}`}
                            min={0}
                            value={data.turnover}
                            onChange={(e) => {
                                setData({...data, turnover: e as number})
                            }}
                        />
						&nbsp;&nbsp; per &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Input data-cy={"input_turnover_per"}
                            className={styles.shortInput}
                            placeholder={''}
                            value={data.turnoverPer}
                            onChange={(e) => {
                                setData({...data, turnoverPer: e.target.value})
                            }}
                        />
                    </div>
                    <br />

                    <h4>{t('data:result_profit')}</h4>
                    <div className={styles.row}>
                        <InputNumber data-cy={"input_result_profit"}
                            formatter={(e) => `€ ${e}`}
                            min={0}
                            value={data.resultProfit}
                            onChange={(e) => {
                                setData({...data, resultProfit: e as number})
                            }}
                        />
						&nbsp;&nbsp; per &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Input data-cy={"input_result_profit_per"}
                            className={styles.shortInput}
                            placeholder={''}
                            value={data.resultProfitPer}
                            onChange={(e) => {
                                setData({...data, resultProfitPer: e.target.value})
                            }}
                        />
                    </div>
                    <br />

                    <h4>{t('data:total_assets')}</h4>
                    <div className={styles.row}>
                        <InputNumber data-cy={"input_total_assets"}
                            formatter={(e) => `€ ${e}`}
                            min={0}
                            value={data.totalAssets}
                            onChange={(e) => {
                                setData({...data, totalAssets: e as number})
                            }}
                        />
						&nbsp;&nbsp; per &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Input data-cy={"input_total_assets_per"}
                            className={styles.shortInput}
                            placeholder={''}
                            value={data.totalAssetsPer}
                            onChange={(e) => {
                                setData({...data, totalAssetsPer: e.target.value})
                            }}
                        />
                    </div>
                    <br />
                    {renderSaveButton()}
                </div>
            </CollapsibleSection>

            <CollapsibleSection initialCollapsedState={true} text={t('applicant_personal_data')}
                buttonsTop={<Button data-cy="button_save"
                    type="primary"
                    onClick={async () => {
                        setUi({...ui, selectContactPersonModal: true})
                    }}>
                    Angebotsdaten übernehmen
                            </Button>}
            >
                <div>
                    <SelectContactPersonModal
                        isOpen={ui.selectContactPersonModal}
                        contactPersons={props.accountInformation.contactPersons || []}
                        onOk={(selectedPersons) => {
                            const persons = [];
                            selectedPersons.map(s => persons.push({
                                name: s.firstName + ' ' + s.lastName,
                                address: s.addressLine1 || '' + ' ' + s.addressStreetNumber || '' + '\n' +
                                    s.addressPostalCode || '' + ' ' + s.addressCity || '',
                                dateOfBirth: s.dateOfBirth,
                                placeOfBirth: s.placeOfBirth,
                            }))
                            setPersons(persons)
                            setUi({...ui, selectContactPersonModal: false})
                        }}
                        onCancel={() => setUi({...ui, selectContactPersonModal: false})}
                    />
                    {
                        persons.map((sdPerson, i) =>
                            <div key={i}>
                                <div className={styles.tabInner}>
                                    {
                                        i === 0 ?
                                            <>
                                                <h4>{t('sa_person_header')}</h4>
                                                <br />
                                            </>
                                            : null
                                    }

                                    <h4>{t('data:name')}</h4>
                                    <Input data-cy={`input_sd_person_${i}_name`}
                                        placeholder={''}
                                        value={persons[i].name}
                                        onChange={(e) => {
                                            const newVal = e.target.value
                                            setPersons((prevState: SelfDeclarationPerson[]) => {
                                                const newState: SelfDeclarationPerson[] = [...prevState]
                                                newState[i].name = newVal
                                                return newState
                                            })
                                        }}
                                    />
                                    <br />
                                    <br />

                                    <h4>{t('data:address')}</h4>
                                    <Input.TextArea data-cy={`input_sd_person_${i}_address`}
                                        autoSize={{minRows: 2, maxRows: 2}}
                                        placeholder={t('placeholders:address_line_1_2')}
                                        value={persons[i].address}
                                        onChange={(e) => {
                                            const newVal = e.target.value
                                            setPersons((prevState: SelfDeclarationPerson[]) => {
                                                const newState: SelfDeclarationPerson[] = [...prevState]
                                                newState[i].address = newVal
                                                return newState
                                            })
                                        }}
                                    />
                                    <br />
                                    <br />

                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <h4>{t('data:date_of_birth')}</h4>
                                            <DatePicker data-cy={`input_sd_person_${i}_date_of_birth`}
                                                format={DATE_FORMAT.DE}
                                                value={persons[i].dateOfBirth ? moment(persons[i].dateOfBirth) : null}
                                                onChange={(e) => {
                                                    const newVal = e?.format(DATE_FORMAT.API)
                                                    setPersons((prevState: SelfDeclarationPerson[]) => {
                                                        const newState: SelfDeclarationPerson[] = [...prevState]
                                                        newState[i].dateOfBirth = newVal
                                                        return newState
                                                    })
                                                }}
                                            />
                                            <br />
                                            <br />

                                            <h4>{t('data:nationality')}</h4>
                                            <Input data-cy={`input_sd_person_${i}_nationality`}
                                                placeholder={'Deutsch'}
                                                value={persons[i].nationality}
                                                onChange={(e) => {
                                                    const newVal = e.target.value
                                                    setPersons((prevState: SelfDeclarationPerson[]) => {
                                                        const newState: SelfDeclarationPerson[] = [...prevState]
                                                        newState[i].nationality = newVal
                                                        return newState
                                                    })
                                                }}
                                            />
                                            <br />
                                            <br />
                                        </Col>
                                        <Col span={12}>
                                            <h4>{t('data:place_of_birth')}</h4>
                                            <Input data-cy={`input_sd_person_${i}_place_of_birth`}
                                                placeholder={'Berlin'}
                                                value={persons[i].placeOfBirth}
                                                onChange={(e) => {
                                                    const newVal = e.target.value
                                                    setPersons((prevState: SelfDeclarationPerson[]) => {
                                                        const newState: SelfDeclarationPerson[] = [...prevState]
                                                        newState[i].placeOfBirth = newVal
                                                        return newState
                                                    })
                                                }}
                                            />
                                            <br />
                                            <br />

                                            <h4>{t('data:years_of_experience')}</h4>
                                            <InputNumber data-cy={`input_sd_person_${i}_years_of_experience`}
                                                min={0}
                                                value={persons[i].yearsExperience}
                                                onChange={(e) => {
                                                    const newVal = e as number
                                                    setPersons((prevState: SelfDeclarationPerson[]) => {
                                                        const newState: SelfDeclarationPerson[] = [...prevState]
                                                        newState[i].yearsExperience = newVal
                                                        return newState
                                                    })
                                                }}
                                            />
                                            <br />
                                            <br />
                                        </Col>
                                    </Row>

                                    {
                                        persons.length > 1 ?
                                            <Button type={'default'}
                                                onClick={() => {
                                                    setPersons((prevState: SelfDeclarationPerson[]) => {
                                                        const newState: SelfDeclarationPerson[] = [...prevState]
                                                        newState.splice(i, 1)
                                                        return newState
                                                    })
                                                }}
                                            >
                                                <DeleteOutlined />
                                                {t('remove')}
                                            </Button>
                                            : null
                                    }

                                    {
                                        persons.length - 1 === i ?
                                            <>
                                                <Divider />
                                                <Button type={'primary'}
                                                    onClick={() => {
                                                        setPersons((prevState: SelfDeclarationPerson[]) => {
                                                            const newState: SelfDeclarationPerson[] = [...prevState]
                                                            newState.push({yearsExperience: 0} as SelfDeclarationPerson)
                                                            return newState
                                                        })
                                                    }}
                                                >
                                                    <PlusOutlined />
                                                    {t('add_person')}
                                                </Button>
                                                <br />
                                                <br />

                                                {renderSaveButton()}
                                            </>
                                            : null
                                    }
                                </div>
                                {persons.length > 1 && persons.length - 1 !== i ? <br /> : null}
                            </div>
                        )
                    }
                </div>
            </CollapsibleSection>

            <CollapsibleSection initialCollapsedState={true} text={t('operational_information')}>
                <div className={styles.tabInner}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <h4>{`${t('data:owned_area')} (${t('hectare')})`}</h4>
                            <InputNumber data-cy={"input_owned_area"}
                                min={0}
                                value={data.ownedArea}
                                onChange={(e) => {
                                    setData({...data, ownedArea: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:leased_area')} (${t('hectare')})`}</h4>
                            <InputNumber data-cy={"input_leased_area"}
                                min={0}
                                value={data.leasedArea}
                                onChange={(e) => {
                                    setData({...data, leasedArea: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:crop_area')} (${t('hectare')})`}</h4>
                            <InputNumber data-cy={"input_crop_area"}
                                min={0}
                                value={data.cropArea}
                                onChange={(e) => {
                                    setData({...data, cropArea: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:number_milk_cows')} (${t('piece')})`}</h4>
                            <InputNumber data-cy={"input_number_milk_cows"}
                                min={0}
                                value={data.numberMilkCows}
                                onChange={(e) => {
                                    setData({...data, numberMilkCows: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:number_bulls')} (${t('piece')})`}</h4>
                            <InputNumber data-cy={"input_number_bulls"}
                                min={0}
                                value={data.numberBulls}
                                onChange={(e) => {
                                    setData({...data, numberBulls: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:number_breeding_pigs')} (${t('piece')})`}</h4>
                            <InputNumber data-cy={"input_number_breeding_pigs"}
                                min={0}
                                value={data.numberBreedingPigs}
                                onChange={(e) => {
                                    setData({...data, numberBreedingPigs: e as number})
                                }}
                            />
                            <br />
                            <br />

                        </Col>
                        <Col span={12}>
                            <h4>{`${t('data:grain_area')} (${t('hectare')})`}</h4>
                            <InputNumber data-cy={"input_grain_area"}
                                min={0}
                                value={data.grainArea}
                                onChange={(e) => {
                                    setData({...data, grainArea: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:special_area')} (${t('hectare')})`}</h4>
                            <InputNumber data-cy={"input_special_area"}
                                min={0}
                                value={data.specialArea}
                                onChange={(e) => {
                                    setData({...data, specialArea: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:corn_area')} (${t('hectare')})`}</h4>
                            <InputNumber data-cy={"input_corn_area"}
                                min={0}
                                value={data.cornArea}
                                onChange={(e) => {
                                    setData({...data, cornArea: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:number_eating_pigs')} (${t('piece')})`}</h4>
                            <InputNumber data-cy={"input_number_eating_pigs"}
                                min={0}
                                value={data.numberEatingPigs}
                                onChange={(e) => {
                                    setData({...data, numberEatingPigs: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:number_other_animals')} (${t('piece')})`}</h4>
                            <InputNumber data-cy={"input_number_other_animals"}
                                min={0}
                                value={data.numberOtherAnimals}
                                onChange={(e) => {
                                    setData({...data, numberOtherAnimals: e as number})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                    </Row>
                    <br />

                    <h4 className={styles.bold}>{t('details_of_real_estate')}</h4>
                    <Row gutter={24}>
                        <Col span={12}>
                            <h4 className={styles.bold}>{t('building')}</h4>
                            <h4>{t('data:market_value')}</h4>
                            <InputNumber data-cy={"input_building_market_value"}
                                formatter={(e) => `€ ${e}`}
                                min={0}
                                value={data.buildingMarketValue}
                                onChange={(e) => {
                                    setData({...data, buildingMarketValue: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{t('data:thereof_remaining_debt')}</h4>
                            <InputNumber data-cy={"input_building_remaining_debt"}
                                formatter={(e) => `€ ${e}`}
                                min={0}
                                value={data.buildingRemainingDebt}
                                onChange={(e) => {
                                    setData({...data, buildingRemainingDebt: e as number})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                        <Col span={12}>
                            <h4 className={styles.bold}>{t('area')}</h4>
                            <h4>{t('data:market_value')}</h4>
                            <InputNumber data-cy={"input_area_market_value"}
                                formatter={(e) => `€ ${e}`}
                                min={0}
                                value={data.areaMarketValue}
                                onChange={(e) => {
                                    setData({...data, areaMarketValue: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{t('data:thereof_remaining_debt')}</h4>
                            <InputNumber data-cy={"input_area_remaining_debt"}
                                formatter={(e) => `€ ${e}`}
                                min={0}
                                value={data.areaRemainingDebt}
                                onChange={(e) => {
                                    setData({...data, areaRemainingDebt: e as number})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                    </Row>
                    <br />

                    <h4 className={styles.bold}>{t('data:other_assets')}</h4>
                    <Input.TextArea data-cy={"input_other_assets"}
                        autoSize={{minRows: 4, maxRows: 4}}
                        value={data.otherAssets}
                        onChange={(e) => {
                            setData({...data, otherAssets: e.target.value})
                        }}
                    />
                    <br />
                    <br />
                    <br />

                    <h4 className={styles.bold}>{t('technical_information')}</h4>
                    <Row gutter={24}>
                        <Col span={12}>
                            <h4>{`${t('data:number_tractors')} (${t('piece')})`}</h4>
                            <InputNumber data-cy={"input_number_tractors"}
                                min={0}
                                value={data.numberTractors}
                                onChange={(e) => {
                                    setData({...data, numberTractors: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:number_harvesters')} (${t('piece')})`}</h4>
                            <InputNumber data-cy={"input_number_harvesters"}
                                min={0}
                                value={data.numberHarvesters}
                                onChange={(e) => {
                                    setData({...data, numberHarvesters: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:number_food_harvesters')} (${t('piece')})`}</h4>
                            <InputNumber data-cy={"input_number_food_harvesters"}
                                min={0}
                                value={data.numberFoodHarvesters}
                                onChange={(e) => {
                                    setData({...data, numberFoodHarvesters: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:number_other_driving_vehicles')} (${t('piece')})`}</h4>
                            <InputNumber data-cy={"input_number_other_driving_vehicles"}
                                min={0}
                                value={data.numberOtherDrivingVehicles}
                                onChange={(e) => {
                                    setData({...data, numberOtherDrivingVehicles: e as number})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                        <Col span={12}>
                            <h4>{`${t('data:number_combines')} (${t('piece')})`}</h4>
                            <InputNumber data-cy={"input_number_combines"}
                                min={0}
                                value={data.numberCombines}
                                onChange={(e) => {
                                    setData({...data, numberCombines: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:number_manure_transporters')} (${t('piece')})`}</h4>
                            <InputNumber data-cy={"input_number_manure_transporters"}
                                min={0}
                                value={data.numberManureTransporters}
                                onChange={(e) => {
                                    setData({...data, numberManureTransporters: e as number})
                                }}
                            />
                            <br />
                            <br />

                            <h4>{`${t('data:number_tillers')} (${t('piece')})`}</h4>
                            <InputNumber data-cy={"input_number_tillers"}
                                min={0}
                                value={data.numberTillers}
                                onChange={(e) => {
                                    setData({...data, numberTillers: e as number})
                                }}
                            />
                            <br />
                            <br />
                        </Col>
                    </Row>
                    {renderSaveButton()}
                </div>
            </CollapsibleSection>

            <CollapsibleSection initialCollapsedState={true} text={t('data_protection_notices')}>
                <div className={styles.tabInner}>
                    <pre className={styles.regularFont}>{t('sd_public_data_protection_notice')}</pre>
                </div>
            </CollapsibleSection>

            <CollapsibleSection initialCollapsedState={true} text={t('obtaining_bank_report')}>
                <React.Fragment>
                    <div>
                        <h3 className="text-left">Bestehende Bankverbindungen</h3>
                        <Row gutter={[24, 24]} justify={'space-between'}>
                            <Col span={12}>
                                <Select data-cy="dropdown_offer_id"
                                    style={{maxWidth: '700px'}}
                                    value={ui.selectedBankAccountId}
                                    onChange={(e) => setUi({...ui, selectedBankAccountId: e})}>
                                    {(props.accountInformation.bankConnections || []).map((l, i) => <Select.Option data-cy={`offer_option_${i}`} key={i} value={l.bankConnectionId}>{`${l.iban}`}</Select.Option>)}
                                </Select>
                            </Col>
                            <Col span={7}>
                                <Button data-cy="button_save"
                                    disabled={!ui.selectedBankAccountId}
                                    type="primary"
                                    onClick={() => {
                                        const selectedBankConnection = (props.accountInformation.bankConnections || []).find((o) => o.bankConnectionId === ui.selectedBankAccountId)

                                        if (selectedBankConnection) {
                                            const enabledCreditInstitutions: CreditInstitutionSetting[] = store.data?.settings?.creditInstitutions?.filter(ci => ci.isEnabled) || []

                                            const creditInstitution = enabledCreditInstitutions.find(ci => ci.creditInstitutionId === selectedBankConnection.creditInstitutionId)
                                            setData({
                                                ...data,
                                                bankAccountOwner: selectedBankConnection.accountOwner,
                                                creditInstitution: creditInstitution?.name,
                                                bic: creditInstitution?.bic,
                                                iban: selectedBankConnection.iban,
                                            })
                                        }
                                    }}>
                                    Bankverbindung auswählen
                        </Button>
                            </Col>
                        </Row>
                    </div>
                    <div className={styles.tabInner}>
                        <h4>{t('data:account_owner')}</h4>
                        <Input data-cy={"input_bank_account_owner"}
                            placeholder={''}
                            value={data.bankAccountOwner}
                            onChange={(e) => {
                                setData({...data, bankAccountOwner: e.target.value})
                            }}
                        />
                        <br />
                        <br />

                        <h4>{t('data:credit_institution')}</h4>
                        <Input data-cy={"input_credit_institution"}
                            placeholder={''}
                            value={data.creditInstitution}
                            onChange={(e) => {
                                setData({...data, creditInstitution: e.target.value})
                            }}
                        />
                        <br />
                        <br />

                        <Row gutter={24}>
                            <Col span={12}>
                                <h4>{'BIC'}</h4>
                                <Input data-cy={"input_bic"}
                                    placeholder={''}
                                    value={data.bic}
                                    onChange={(e) => {
                                        setData({...data, bic: e.target.value})
                                    }}
                                />
                                <br />
                                <br />
                            </Col>
                            <Col span={12}>
                                <h4>{'IBAN'}</h4>
                                <Input data-cy={"input_iban"}
                                    placeholder={''}
                                    value={data.iban}
                                    onChange={(e) => {
                                        setData({...data, iban: e.target.value})
                                    }}
                                />
                                <br />
                                <br />
                            </Col>
                        </Row>

                        <h4>{t('data:bank_contact_person')}</h4>
                        <Input data-cy={"input_bank_contact_person"}
                            placeholder={''}
                            value={data.bankContactPerson}
                            onChange={(e) => {
                                setData({...data, bankContactPerson: e.target.value})
                            }}
                        />
                        <br />
                        <br />

                        <Row gutter={24}>
                            <Col span={12}>
                                <h4>{t('data:telephone')}</h4>
                                <Input data-cy={"input_bank_telephone"}
                                    placeholder={'+491234567'}
                                    value={data.bankPhone}
                                    onChange={(e) => {
                                        setData({...data, bankPhone: e.target.value})
                                    }}
                                />
                                <br />
                                <br />
                            </Col>
                            <Col span={12}>
                                <h4>{t('data:fax')}</h4>
                                <Input data-cy={"input_bank_fax"}
                                    placeholder={'+491234567'}
                                    value={data.bankFax}
                                    onChange={(e) => {
                                        setData({...data, bankFax: e.target.value})
                                    }}
                                />
                                <br />
                                <br />
                            </Col>
                        </Row>

                        <h4>{t('data:email')}</h4>
                        <Input data-cy={"input_bank_email"}
                            placeholder={'example@email.com'}
                            value={data.bankEmail}
                            onChange={(e) => {
                                setData({...data, bankEmail: e.target.value})
                            }}
                        />
                        <br />
                        <br />

                        {renderSaveButton()}
                        <br />
                        <br />
                        <Divider />
                        <br />

                        <Row gutter={24} align={'top'}>
                            <Col span={1}>
                                <Checkbox
                                    checked={data.isPrivacyNoticeAccepted}
                                    onChange={e => {
                                        setData({...data, isPrivacyNoticeAccepted: e.target.checked})
                                    }}
                                />
                            </Col>
                            <Col span={23}>
                                <pre className={styles.regularFont}>{t('sd_check_1')}</pre>
                            </Col>
                        </Row>
                        <br />

                        <Row gutter={24} align={'top'}>
                            <Col span={1}>
                                <Checkbox
                                    checked={data.isPublicAgreementAccepted}
                                    onChange={e => {
                                        setData({...data, isPublicAgreementAccepted: e.target.checked})
                                    }}
                                />
                            </Col>
                            <Col span={23}>
                                <pre className={styles.regularFont}>{t('sd_check_2')}</pre>
                            </Col>
                        </Row>

                        <h4>{t('data:name')}</h4>
                        <Input data-cy={"input_signatory_name"}
                            placeholder={t('signatory_name')}
                            value={data.signatoryName}
                            onChange={(e) => {
                                setData({...data, signatoryName: e.target.value})
                            }}
                        />
                        <br />
                        <br />

                        <Row gutter={24}>
                            <Col>
                                <Button data-cy="button_submit"
                                    type="primary"
                                    disabled={!data.isPrivacyNoticeAccepted || !data.isPublicAgreementAccepted}
                                    onClick={() => {
                                        onSubmit()
                                    }}>
                                    <CheckSquareOutlined />
                                    {t('submit')}
                                </Button>
                            </Col>
                            <Col>
                                <Button data-cy="button_reset"
                                    type="default"
                                    onClick={() => {
                                        resetState()
                                    }}>
                                    {t('reset')}
                                </Button>
                            </Col>
                        </Row>

                    </div>
                </React.Fragment>
            </CollapsibleSection>

            {
                !props.isPublicPage ?
                    <ShareSection onClick={(email: string) => {
                        try {
                            (async function () {
                                await ApiManager.UserService.shareSelfDeclaration(props.userId, email);
                                message.success('Seite geteilt', 2)
                            }())
                        } catch (e) {
                            console.log(e)
                        }
                    }} /> :
                    null
            }
        </div>
    );
};
