import * as React from "react";
import styles from "./Calculator.module.scss";
import {Checkbox, Col, DatePicker, Row} from "antd";
import {DATE_FORMAT} from "../../../../services/api/Globals";
import {LocalizedNumberInput} from "../localized-number-input/LocalizedNumberInput";
import {RepaymentPlanInstallment} from "../../../../services/calculator/types";
import {useTranslation} from "react-i18next";

export interface RateAdjustmentRowProps {
    id: number
    isLocked: boolean
    isLast: boolean
    repaymentPlanInstallment: RepaymentPlanInstallment
    onChange: (paymentValueValue: number) => void
    onCheckBoxToggle: (isChecked: boolean) => void
    onDateClick: () => void
}

export const RateAdjustmentRow = (props: RateAdjustmentRowProps) => {
    const {t} = useTranslation()

    return (
        <div className={styles.rateItem}>
            <Row gutter={24} align={"middle"}>
                <Col span={2}>
                    <Checkbox data-cy={`checkbox_${props.id}`}
                        className={styles.checkbox}
                        checked={props.repaymentPlanInstallment.isChecked}
                        disabled={props.isLast || props.isLocked}
                        onChange={(e) => {
                            props.onCheckBoxToggle(e?.target?.checked)
                        }}>
                    </Checkbox>
                </Col>
                <Col span={2}>
                    <div className={styles.labelRateItem}>{props.id}</div>
                </Col>
                <Col span={9}>
                    <DatePicker data-cy={`input_installment_date_${props.id}`}
                        format={DATE_FORMAT.DE}
                        value={props.repaymentPlanInstallment.date}
                        disabled={true}
                        className={styles.clickable}
                        onClick={() => {
                            if (!props.isLocked) {
                                props.onDateClick()
                            }
                        }}
                    />
                </Col>
                <Col span={9}>
                    <LocalizedNumberInput data-cy={`input_installment_amount_${props.id}`}
                        value={props.repaymentPlanInstallment.amount}
                        prefix={t('currency_sign')}
                        precision={2}
                        disabled={props.isLast || props.isLocked || !props.repaymentPlanInstallment.isChecked}
                        onChange={(e) => {
                            if (e === 0) {
                                props.onCheckBoxToggle(false)
                            } else {
                                props.onChange(e)
                            }
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
};
