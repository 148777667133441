import * as React from "react";
import styles from "./AccountInformationPageTabs.module.scss";
import {Button, Col, Empty, Input, message, Row, Space, Table} from "antd";
import ApiManager from "services/api/ApiManager";
import {CreditworthinessReportType, DocumentOrigin, UserDocument, UserDocumentCategory} from "services/api/types";
import {useEffect, useState} from "react";
import {ListItem, ListItemTypes} from "components/common/presenters/list-item/ListItem";
import UiHelper from "../../../../helpers/uiHelpers";
import {CameraOutlined} from "@ant-design/icons";
import {DropzoneUpload} from "components/common/presenters/add-document-strip/DocumentUploadButton";
import {fileNameExtension, fileNameWithoutExtension} from "helpers/stringHelpers";

export interface CreditScoringTabProps {
    userId: number;
    userDocuments: UserDocument[];
    onUserFilesChanged: () => void;
}

export const CreditScoringTab = (props: CreditScoringTabProps) => {
    const [creditworthinesses, setCreditworthinesses] = useState([]);
    const [selectedCreditworthiness, setSelectedCreditworthiness] = useState(null);
    const [creditworthinessPdf, setCreditworthinessPdf] = useState(null);
    const [ui, setUi] = useState({
        gettingObligo: false,
        gettingCredireform: false,
        gettingSchufa: false,
    })
    const [selectedFile, setSelectedFile] = useState(null);

    const fetchData = async () => {
        try {
            const creditworthinessResult = await ApiManager.UserService.getCreditworthiness(props.userId);
            setCreditworthinesses(creditworthinessResult.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [props.userId]);

    const onNewReportClicked = async (type: CreditworthinessReportType) => {
        try {
            setUi({...ui, gettingCredireform: type === CreditworthinessReportType.CreditReform, gettingSchufa: type === CreditworthinessReportType.Schufa});
            await ApiManager.UserService.createCreditworthiness({userId: props.userId, type})
            message.success('Erfolg.');
            await fetchData();
        } catch (e) {
            message.error('Fehler.')
        }
        setUi({...ui, gettingCredireform: false});
    }

    const onViewUserDocClicked = async (userDocId: number) => {
        try {
            const signedUrlResponse = await ApiManager.UserService.getUserDocumentSignedUrl(userDocId)
            const signedUrl = signedUrlResponse.data
            UiHelper.openUrlInNewTab(signedUrl)
        } catch (e) {
            console.error(e)
        }
    }

    const onDeleteFileClicked = async (userDocId) => {
        try {
            await ApiManager.UserService.deleteFile(userDocId)
            props.onUserFilesChanged()

            message.success('File deleted', 2)
        } catch (e) {
            console.error(e);
            message.error('Could not delete file', 2)
        }
    }

    const uploadFile = async () => {
        try {
            const result = await ApiManager.UserService.uploadUserDocument(selectedFile.file, props.userId, selectedFile.userDocumentCategory,
                DocumentOrigin.OTHER, documentFileName(selectedFile.userDocumentCategory, selectedFile.score))

            await ApiManager.UserService.updateScore({
                userId: props.userId,
                score: selectedFile.score,
                userDocumentId: result.data.userDocumentId,
                documentCategory: selectedFile.userDocumentCategory
            })

            setSelectedFile(null)
            props.onUserFilesChanged()

            message.success('File uploaded', 2)
        } catch (e) {
            console.error(e)
            message.error('Could not upload file', 2)
        }
    }

    const checkFileScore = async (file: any, userDocumentCategory: UserDocumentCategory) => {
        const originalFileName = fileNameWithoutExtension(file.name)
        try {

            const result = await ApiManager.UserService.checkDocumentScore(file, userDocumentCategory)

            setSelectedFile({
                file,
                originalFileName,
                score: result?.data?.score,
                userDocumentCategory,
                fileType: userDocumentCategoryToText(userDocumentCategory)
            })

            message.success('File uploaded', 2)
        } catch (e) {
            setSelectedFile({
                file,
                originalFileName,
                score: '',
                userDocumentCategory,
                fileType: userDocumentCategoryToText(userDocumentCategory)
            })
            console.error(e)
            message.error('Could not upload file', 2)
        }
    }

    const userDocumentCategoryToText = (d: UserDocumentCategory) => {
        switch (d) {
            case UserDocumentCategory.OTHER_CREDIT_DOCUMENTS:
                return 'Creditreform'
            case UserDocumentCategory.SCHUFA:
                return 'SCHUFA'
            default:
                break;
        }
        return ''
    }

    const documentFileName = (d: UserDocumentCategory, score) => {
        var prefix = '';
        switch (d) {
            case UserDocumentCategory.OTHER_CREDIT_DOCUMENTS:
                prefix = 'Creditreform'
                break;
            case UserDocumentCategory.SCHUFA:
                prefix = 'SCHUFA'
                break;
            default:
                break;
        }
        return `${prefix} - Score ${score}`
    }

    return (
        <div className={styles.tab}>
    
            <br />
            <Row gutter={24} justify={"space-between"} align={"middle"}>
                <Col span={8}>
                    <h3 style={{ textAlign: "center" }}>
                        Creditreform Auskunft
                    </h3>
                    <div className={styles.greyBox}>
                        <Row justify={"center"} align={"middle"}>
                            <Col>
                                <div className={styles.dropButton}>
                                    <DropzoneUpload
                                        buttonText={"Datei auswählen"}
                                        onDropFiles={(files: any[]) => {
                                            checkFileScore(
                                                files[0],
                                                UserDocumentCategory.OTHER_CREDIT_DOCUMENTS
                                            );
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row justify={"center"} align={"middle"}>
                            <Col>
                                <br />

                                <p>
                                    VC Auskunft hierhinziehen, oder klicken um
                                    Datei auszuwählen
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={8}>
                    <h3 style={{ textAlign: "center" }}>SCHUFA Auskunft</h3>
                    <div className={styles.greyBox}>
                        <Row justify={"center"} align={"middle"}>
                            <Col>
                                <div className={styles.dropButton}>
                                    <DropzoneUpload
                                        buttonText={"Datei auswählen"}
                                        onDropFiles={(files: any[]) => {
                                            checkFileScore(
                                                files[0],
                                                UserDocumentCategory.SCHUFA
                                            );
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row justify={"center"} align={"middle"}>
                            <Col>
                                <br />

                                <p>
                                    VC Auskunft hierhinziehen, oder klicken um
                                    Datei auszuwählen
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={8}>
                    <h3 style={{ textAlign: "center" }}>Obligo</h3>
                    <div>
                        <Row justify={"center"} align={"middle"}>
                            <Col>
                                <div className={styles.dropButton}>
                                    <Button
                                        type={"primary"}
                                        onClick={() =>
                                            ui.gettingObligo
                                                ? () => {}
                                                : onNewReportClicked(
                                                      CreditworthinessReportType.OBLIGO
                                                  )
                                        }
                                    >
                                        Obligo Anfordern
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Row justify={"center"} align={"middle"}>
                            <Col>
                                <br />

                                <p>
                                    Den Button klicken um eine neue Obligo anzufordern.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <br />
            {selectedFile ? (
                <React.Fragment>
                    <Row>
                        <Col span={24}>
                            <ListItem
                                key={selectedFile.originalFileName}
                                className={
                                    selectedFile.score
                                        ? styles.greenBorder
                                        : styles.redBorder
                                }
                                type={ListItemTypes.Document}
                                text={selectedFile.originalFileName}
                                onNextToLastIconClicked={() => {
                                    // onViewUserDocClicked(d.userDocumentId)
                                }}
                                onLastIconClicked={() => {
                                    // onDeleteFileClicked(d.userDocumentId)
                                }}
                            />
                            <p
                                className={
                                    selectedFile.score
                                        ? styles.greenText
                                        : styles.redText
                                }
                            >
                                {selectedFile.score
                                    ? `${selectedFile.fileType} Score von ${selectedFile.score} wurde erfolgreich erkanntl. `
                                    : `${selectedFile.fileType} Score konnte nicht ausgelesen werden. Überprüfen Sie die hochgeladene Datei.`}
                            </p>
                        </Col>
                    </Row>
                    <Row justify={"end"}>
                        <Col>
                            <Space size={24}>
                                <Button
                                    type={"primary"}
                                    onClick={
                                        selectedFile.score
                                            ? uploadFile
                                            : () => {}
                                    }
                                    disabled={!selectedFile.score}
                                >
                                    Datei hochladen
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </React.Fragment>
            ) : null}
            <br />
            <Row>
                <Col span={24}>
                    <h2>Bonitäts Berichte</h2>
                    {!props.userDocuments
                        .filter(
                            (d) =>
                                d.documentCategory ===
                                    UserDocumentCategory.OTHER_CREDIT_DOCUMENTS ||
                                d.documentCategory ===
                                    UserDocumentCategory.SCHUFA
                        ).length ? <Empty className={"ant-empty-normal-custom"} description={
            <span>
              Es wurden noch keine Bonitätsberichte für diesen Kunden angefragt. 
            </span>
          } image={Empty.PRESENTED_IMAGE_SIMPLE} ></Empty> : null}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {props.userDocuments
                        .filter(
                            (d) =>
                                d.documentCategory ===
                                    UserDocumentCategory.OTHER_CREDIT_DOCUMENTS ||
                                d.documentCategory ===
                                    UserDocumentCategory.SCHUFA
                        )
                        .map((d) => (
                            <ListItem
                                key={d.userDocumentId}
                                type={ListItemTypes.Document}
                                text={d.documentName}
                                onNextToLastIconClicked={() => {
                                    onViewUserDocClicked(d.userDocumentId);
                                }}
                                onLastIconClicked={() => {
                                    onDeleteFileClicked(d.userDocumentId);
                                }}
                            />
                        ))}
                </Col>
            </Row>
        </div>
    );
};
