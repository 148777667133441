import * as React from "react";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, Form, Input} from 'antd';
import styles from "components/common/pages/authentication-page/AuthenticationPage.module.scss";
import {RouteStrings} from "routing/Routes";
import {Auth, useAuth} from "../../../../hooks/Auth";

interface AuthenticationPageProps {
}

export const AuthenticationPage = (props: AuthenticationPageProps) => {
  const history = useHistory();
  const auth: Auth = useAuth();

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 24},
  };
  const tailLayout = {
    wrapperCol: {offset: 0, span: 16},
  };

  const handleLogin = async (): Promise<boolean> => {
    try {
      await auth.logIn(username, password);
      history.push(RouteStrings.AdminPeople);
      return true
    } catch (error) {
      console.error(error);
      return false
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.innerContainer}>
        <Form className={styles.form}
          {...layout}
          layout="vertical"
          name="basic"
          initialValues={{remember: true}}
        >
          <div className={styles.logo}></div>

          <Form.Item
            className={styles.formItem}
            label="Username"
            name="username"
            rules={[{required: true, message: 'Please enter your username!'}]}
          >
            <Input data-cy="input_username" className={styles.inputUsername} onChange={(e) => {setUsername(e.target.value);}} />
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            label="Password"
            name="password"
            rules={[{required: true, message: 'Please enter your password!'}]}
          >
            <Input.Password data-cy="input_password" className={styles.inputPassword} onChange={(e) => {setPassword(e.target.value);}} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button data-cy="button_submit" className={styles.button} type="primary" htmlType="submit" onClick={() => {
              handleLogin()
            }}>
              Submit
					</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
