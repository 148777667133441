import * as React from "react";
import {useState} from "react";
import styles from "./AccountInformationPageTabs.module.scss";
import {ContractData, UserData} from "../../../../services/api/types";
import {useTranslation} from "react-i18next";
import {ListItem, ListItemTypes} from "../list-item/ListItem";
import ApiManager from "../../../../services/api/ApiManager";
import UiHelper from "../../../../helpers/uiHelpers";
import {Empty} from "antd";

export interface ContractsTabProps {
    contracts: ContractData[],
    user: UserData
}

export const ContractsTab = (props: ContractsTabProps) => {
    const {t} = useTranslation()

    const [selectedContract, setSelectedContract] = useState(null as ContractData)
    const [contractPdf, setContractPdf] = useState(null)

    const contractLabel = (contract: ContractData): string => {
        return contract.contractName // TODO more details??
    }

    const onViewContractPdfClicked = async (contract: ContractData) => {
        try {
            if (contract === selectedContract) {
                setSelectedContract(null)
                setContractPdf(null)
                return
            }
            const result = await ApiManager.ApplicationService.getContractPdf(contract.contractId);
            setContractPdf({url: result.data.pdf, contractId: contract.contractId});
            setSelectedContract(contract)
        } catch (error) {
            console.error(error)
        }
    }

    const onDownloadContractPdfClicked = async (contractId: number, contractName: string) => {
        try {
            const result = await ApiManager.ApplicationService.getContractPdf(contractId)

            UiHelper.downloadPDFFromUrl(result.data.pdf, contractName)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={styles.tab}>
            <h2 className={styles.textAlignStart}>{t('contracts')}</h2>

            {!props.contracts.length ? <Empty className={"ant-empty-normal-custom"} description={
            <span>
              Es wurden noch keine Verträge für diesen Kunden angelegt. 
            </span>
          } image={Empty.PRESENTED_IMAGE_SIMPLE} ></Empty> : null}
            {
                props.contracts.map((contract, i) =>
                    <>
                        <ListItem data-cy={`contract_list_item_${i}`}
                            key={i}
                            type={contract.signedAt ? ListItemTypes.ContractSigned : ListItemTypes.Contract}
                            text={contractLabel(contract)}
                            selectedDark={selectedContract === contract}
                            onClick={(e) => {
                                e.stopPropagation()
                                onViewContractPdfClicked(contract)
                            }}
                            onNextToLastIconClicked={(e) => {
                                e.stopPropagation()
                                onViewContractPdfClicked(contract)
                            }}
                            onLastIconClicked={(e) => {
                                e.stopPropagation()
                                onDownloadContractPdfClicked(contract.contractId, contract.contractName)
                            }}
                        />
                        {
                            contractPdf && contractPdf.contractId === contract.contractId ?
                                <div style={{textAlign: 'center', background: 'black'}}>
                                    <object style={{width: 714, height: 1010}} data={contractPdf.url} type="application/pdf">
                                        <iframe src={contractPdf.url}></iframe>
                                    </object>
                                </div>
                                : null
                        }
                    </>
                )}
        </div>
    );
};
