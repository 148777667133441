import i18n from "i18n/i18n";

export const parseLocaleFormattedFloat = (s: string): number => {
	const thousandSeparator: string = i18n.t('thousand_separator')
	const decimalSeparator: string = i18n.t('decimal_separator')

	const replaced: string = s.split(thousandSeparator).join('') // remove thousands
		.split(decimalSeparator).join('.') // replace decimal

	try {
		return parseFloat(replaced)
	} catch (e) {
		console.error(e)
		return null
	}
}
