import CollapsibleSection from "components/common/presenters/collapsible-section/CollapsibleSection";
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {List, Card, Checkbox, Button, Form, Input, Row, Col} from "antd";
import styles from "components/common/presenters/application-page-tabs/contracts-tab/PdfComponents.module.scss";
import {ObjectData} from "services/api/types";

export interface PdfComponentsProps {
    onPdfNameChange: (name) => void;
    generating: boolean;
    onGenerateContractClicked: (contractDocuments: ContractDocument[], documentWithSignature: boolean) => void
    objects: ObjectData[];
    guarantors: any[];
}

export enum ContractElements {
    ApplicationInformation = 'Vertrag',
    Beneficiaries = 'WB Formular - Kunde',
    BeneficiariesForGuarantor1 = 'WB Formular - Bürge 1',
    BeneficiariesForGuarantor2 = 'WB Formular - Bürge 2',
    Guarantor = 'Bürgschaftserklärung',
    ObjectAcceptanceDocument = 'Abnahme - Objekt ',
    InsuranceOfferDocument = 'Vers. Erklär.',
    InsuranceOffer = 'Versicherungsangebot - Objekt ',
    AGB = 'AGB',
    PEP = 'Infoblatt PEP',
    InsuranceAdvisoryProtocol  = 'Beratungsprotokoll',
    DataProtectionPolicy  = 'Datenschutz',
    CostOverview = 'Kostenübersicht',
    RateHistory = 'Ratenverlauf'
}

export enum ContractType {
    Contract = 'Vertragsdokumente',
    Guarantor = 'Bürgschaftsdokumente',
    Additional = 'Zusätzliche Dokumente'
}

export interface ContractDocument {
    elements: ContractElements[],
    contractName: string
}


const contractTypes = [
    {
        contractType: ContractType.Contract,
        elements: [ContractElements.ApplicationInformation, ContractElements.RateHistory,
            ContractElements.AGB, ContractElements.DataProtectionPolicy, ContractElements.PEP,
            ContractElements.Beneficiaries, ContractElements.CostOverview,
            ContractElements.ObjectAcceptanceDocument + "1",
            ContractElements.ObjectAcceptanceDocument + "2", ContractElements.ObjectAcceptanceDocument + "3",
            ContractElements.ObjectAcceptanceDocument + "4",  ContractElements.ObjectAcceptanceDocument + "5"]
    },
    {
        contractType: ContractType.Guarantor,
        elements: [ContractElements.Guarantor, ContractElements.BeneficiariesForGuarantor1, ContractElements.BeneficiariesForGuarantor2]
    },
    {
        contractType: ContractType.Additional,
        elements: [
            ContractElements.InsuranceOffer+ "1",
            ContractElements.InsuranceOffer+ "2",
            ContractElements.InsuranceOffer+ "3",
            ContractElements.InsuranceOffer+ "4",
            ContractElements.InsuranceOffer+ "5",
            ContractElements.InsuranceAdvisoryProtocol,
            ContractElements.InsuranceOfferDocument]
    }
];

export const PdfComponents = (props: PdfComponentsProps) => {

    const sections = contractTypes.map(c => {return {
        columnName: c.contractType.toString(),
        contractType: c.contractType,
        elements: c.elements.map(e => { return {
            text: e.toString(),
            active: true
        }})
    }}); 

    const [activeCheckBoxes, setActiveCheckBoxes] = useState(sections)
    const [documentWithSignature, setDocumentWithSignature] = useState(false)

    useEffect(() => {

        const sections = contractTypes.map(c => {
            if(c.contractType === ContractType.Guarantor && props.guarantors.length === 0) {
                return null;
            }
            return {
            columnName: c.contractType.toString(),
            contractType: c.contractType,
            elements: c.elements.map(e => {
                if(e.toString().startsWith(ContractElements.ObjectAcceptanceDocument)) {
                   const index = parseInt(e.slice(e.length-1, e.length));
                    if(index <= props.objects.length) {
                        return {
                            text: e.toString(),
                            active: true
                        }
                    } else {
                        return null;
                    }
                } if(e.toString().startsWith(ContractElements.InsuranceOffer)) {
                    const index = parseInt(e.slice(e.length-1, e.length)) - 1;
                     if(props.objects[index] && props.objects[index].insuranceQuote) {
                         return {
                             text: e.toString(),
                             active: true
                         }
                     } else {
                         return null;
                     }
                 } else {
                    return {
                        text: e.toString(),
                        active: true
                    }
                }
            }).filter(f => !!f)
        }}).filter(f => !!f); 

        setActiveCheckBoxes(sections);

    }, [props.objects]);

    
    function activeCheckBoxesToContractDocuments() {
        const contractDocuments = [];
        activeCheckBoxes.forEach(a => {
            const contractElements = [];

            a.elements.forEach(e => {
                if(!e.active) {
                    return;
                }
                contractElements.push(e.text);
            })

            if(!contractElements.length) {
                return;
            }
 
            contractDocuments.push({
                    elements: contractElements,
                    contractName: a.columnName,
                    contractType: a.contractType
            })
        })

        return contractDocuments;
    }

    function activeSignatureCheckbox() {
        let active = false;
        activeCheckBoxes.forEach(a => {
            const pagesWithSignature = [ContractElements.ApplicationInformation, ContractElements.Beneficiaries, ContractElements.BeneficiariesForGuarantor1, ContractElements.BeneficiariesForGuarantor2,
                ContractElements.Guarantor, ContractElements.ObjectAcceptanceDocument + "1", ContractElements.ObjectAcceptanceDocument + "2", ContractElements.ObjectAcceptanceDocument + "3",
                ContractElements.ObjectAcceptanceDocument + "4", ContractElements.ObjectAcceptanceDocument + "5",
                ContractElements.InsuranceOffer+ "1", ContractElements.InsuranceOffer+ "2", ContractElements.InsuranceOffer+ "3",
                ContractElements.InsuranceOffer+ "4",  ContractElements.InsuranceOffer+ "5",
                ContractElements.InsuranceAdvisoryProtocol,
                ContractElements.InsuranceOfferDocument
              ]
            a.elements.forEach(e => {
                if(!!pagesWithSignature.find(p => p && p.toString() == e.text) && e.active) {
                    active = true
                }
            })
        })

        return active;
    }

    return (
        <>
            <CollapsibleSection
                text="PDF Bestandteile"
                initialCollapsedState={true}
            >
                <List
                    grid={{ gutter: 8, column: 3 }}
                    dataSource={activeCheckBoxes}
                    renderItem={(o) => (
                        <List.Item>
                            <div className={styles.pdfComponents}>
                                <div className={styles.columenTitle}>
                                    {o.columnName}
                                </div>
                                {o.elements.map((e) => (
                                    <div
                                        className={classnames(
                                            styles.pdfComponent,
                                            { [styles.inactive]: !e.active }
                                        )}
                                    >
                                        <div className={styles.checkBox}>
                                            <Checkbox
                                                checked={e.active}
                                                onChange={(ev) => {
                                                    const foundColumn =
                                                        activeCheckBoxes.find(
                                                            (a) =>
                                                                a.columnName ==
                                                                o.columnName
                                                        );
                                                    const el =
                                                        foundColumn.elements.find(
                                                            (fe) =>
                                                                fe.text ===
                                                                e.text
                                                        );
                                                    el.active = !el.active;
                                                    setActiveCheckBoxes([
                                                        ...activeCheckBoxes,
                                                    ]);
                                                }}
                                            />
                                        </div>
                                        {/* <div className={styles.dragElement}></div> */}
                                        <div
                                            // className={classnames({
                                            //     [styles.inactive]: !e.active,
                                            // })}
                                        >
                                            {e.text}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </List.Item>
                    )}
                />
            </CollapsibleSection>
            <div className={styles.buttonAtBottom}>
                {/*  */}
                <Row gutter={[24, 24]}>
                    <Col span={9}>
                        <div className={styles.selectDeselectButtons}>
                            <Button
                                data-cy="button_save"
                                type="primary"
                                size="small"
                                onClick={() => {
                                    activeCheckBoxes.forEach((a) => {
                                        a.elements.forEach((el) => {
                                            el.active = false;
                                        });
                                    });
                                    setActiveCheckBoxes([...activeCheckBoxes]);
                                }}
                            >
                                {"Alle abwählen"}
                            </Button>
                            <Button
                                data-cy="button_save"
                                type="primary"
                                size="small"
                                onClick={() => {
                                    activeCheckBoxes.forEach((a) => {
                                        a.elements.forEach((el) => {
                                            el.active = true;
                                        });
                                    });
                                    setActiveCheckBoxes([...activeCheckBoxes]);
                                }}
                            >
                                {"Alle auswählen"}
                            </Button>
                        </div>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            validateStatus={""}
                            label="PDF Name"
                            className={styles.lineHeight50}
                        >
                            <Input
                                size="small"
                                placeholder="PDF Name"
                                onChange={(e) =>
                                    props.onPdfNameChange(e.target.value)
                                }
                            ></Input>
                        </Form.Item>
                        {activeSignatureCheckbox() ? <div style={{fontSize: '14px'}}>
                            <Checkbox
                                checked={
                                    documentWithSignature
                                }
                                onChange={(e) => {
                                    const isChecked = e.target.checked;

                                    setDocumentWithSignature(isChecked);
                                }}
                            >
                                Dokument mit elektronischer Unterschrift
                                erstellen
                            </Checkbox>
                        </div> : null}
                    </Col>
                    <Col span={5}>
                        <div className={styles.generatePdfButton}>
                            <Button
                                data-cy="button_save"
                                type="primary"
                                size="small"
                                disabled={props.generating}
                                onClick={() =>
                                    props.onGenerateContractClicked(
                                        activeCheckBoxesToContractDocuments(),
                                        activeSignatureCheckbox() ? documentWithSignature : false
                                    )
                                }
                            >
                                {props.generating
                                    ? "Generating ..."
                                    : "PDF Ersttellen"}
                            </Button>
                        </div>
                    </Col>
                </Row>

                {/* </div> */}
            </div>
        </>
    );
}

