import {CalculatorLoanObject, RepaymentPlanInstallment} from "services/calculator/types";
import {getRepaymentPlanDates} from "helpers/loanCalculationHelpers";
import {getPeriodRate, getSumOfRepayments} from "./loanObjectGetters";
import {roundToDecimalPoints} from "../../../helpers/numberHelpers";

export const calculateRepaymentPlan = (loanObject: CalculatorLoanObject): RepaymentPlanInstallment[] => {
	if (loanObject.paidOutAt === "" || loanObject.loanDuration == null || loanObject.loanDuration === 0) {
		return []
	}

	const repaymentDates = getRepaymentPlanDates(loanObject)
	const periodRate = getPeriodRate(loanObject)

	const result: RepaymentPlanInstallment[] = []

	repaymentDates.forEach(d => {
		result.push({
			isChecked: true,
			date: d,
			amount: periodRate,
			isCustomAmount: false,
		})
	})

	return result
};

export const recalculatePaymentPlanByCustomization = (repaymentPlan: RepaymentPlanInstallment[], loanObject: CalculatorLoanObject): RepaymentPlanInstallment[] => {
	if (repaymentPlan?.length === 0 || !loanObject) {
		return repaymentPlan
	}

	const sumOfRepayments = getSumOfRepayments(loanObject);
	const sumOfCustomMonths = getSumOfCustomMonths(repaymentPlan);
	const remainingAmountToSplit: number = sumOfRepayments - sumOfCustomMonths

	const numOfMonthsToSplitOn = repaymentPlan.filter(month =>
		month.isChecked && !month.isCustomAmount
	).length

	const splitProportion = roundToDecimalPoints(remainingAmountToSplit / numOfMonthsToSplitOn, 2)

	const newPlan: RepaymentPlanInstallment[] = repaymentPlan.map(month => {
		if (!month.isChecked) {
			return {
				...month,
				amount: 0,
				isCustomAmount: true
			}
		}

		if (month.isCustomAmount) {
			if (month.amount === splitProportion) {
				return {
					...month,
					isCustomAmount: false // cleanup, is split proportion is accidentally equal to custom amount
				}
			}

			return {
				...month,
				isChecked: true,
			}
		}

		return {
			isChecked: true,
			date: month.date,
			amount: splitProportion,
			isCustomAmount: false,
		}
	})

	return newPlan
};

const getSumOfCustomMonths = (repaymentPlan: RepaymentPlanInstallment[]): number => {
	let sum = 0

	repaymentPlan.forEach(month => {
		if (month.isCustomAmount && month.isChecked) {
			sum += month.amount
		}
	})

	return sum
}
