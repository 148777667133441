export class InputValidationResult {
	public validationError: boolean = true;
	public errorMessage: string = null;
}


export class PasswordValidator {
    public static validatePassword(password: string): InputValidationResult {
		const result = new InputValidationResult();

		result.validationError = true;
		result.errorMessage = "Ihr Passwort muss mindestens 8 Zeichen lang sein, und mindestens 1 Groß- und 1 Kleinuchstaben, sowie 1 Zahl und 1 Zeichen enthalten.";

		/* eslint-disable */
		if (
			password &&
			password.length >= 8 &&
			password.length <= 99 &&
			/[$\^\*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/.test(password) && // Cognito password special chars: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
			/[A-Z]/.test(password)
		) {
			// Check if there's at least 1 uppercase letter
			result.validationError = false;
			result.errorMessage = null;
		}
		/* eslint-enable */

		return result;
	}

	public static validateReenteredPassword(password: string, reenteredPassword: string): InputValidationResult {
		const result = new InputValidationResult();

		result.validationError = true;
		result.errorMessage = "Ihre Passwörter stimmen nicht über ein.";

		if (password === reenteredPassword) {
			result.validationError = false;
			result.errorMessage = null;
		}

		return result;
	}
}
