import {OffersData} from "../api/types";

export const getOfferBoundToTheCalculation = (offers: OffersData[], calculationId: number): OffersData|null => {
    for (const offer of offers) {
        if (offer.calculationId === calculationId) {
            return offer
        }
    }

    return null
}

export const toThousands = (n: number): number => {
    if(!n) {
        return n;
    }
    
    if(n >= 1000) {
       return parseInt((n / 1000).toString());
    }

    return parseFloat((n / 1000).toFixed(3));
}
