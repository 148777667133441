import {Tabs} from 'antd';
import * as React from "react";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import "components/common/presenters/tab-menu/TabMenu.scss";

const {TabPane} = Tabs;

export interface TabMenuContent {
    title: string
    key: string
    content: JSX.Element
}

export interface TabMenuProps {
    className?: string;
    items: TabMenuContent[];
    defaultItem?: TabMenuContent;
    onTabChange?: (activeKey) => void;
}

export const TabMenu = (props: TabMenuProps) => {
    const location = useLocation()

    const [activeTab, setActiveTab] = useState(props.items[0] as TabMenuContent);

    useEffect(() => {
        if (props.defaultItem) {
            updateTabNameInBrowserUrl(props.defaultItem.key)
            setActiveTab(props.defaultItem)
        }

        updateTabNameInBrowserUrl(activeTab.key)
    }, [props.defaultItem])

    const updateTabNameInBrowserUrl = (activeKey: string) => {
        const queryParams = new URLSearchParams(location.search)
        queryParams.set("tab", activeKey)
        window.history.replaceState({pageID: getRandomInt(0, Number.MAX_SAFE_INTEGER).toString()}, 'AKF Loan', window.location.origin + window.location.pathname + "?" + queryParams.toString());
    }

    const onTabChange = (activeKey: string) => {
        const foundItem = props.items.find(item => item.key === activeKey)
        if (foundItem) {
            updateTabNameInBrowserUrl(activeKey)
            setActiveTab(foundItem)

            if (props.onTabChange) {
                props.onTabChange(activeKey)
            }
        }
    };

    const renderTabBar = (props, DefaultTabBar) => (
        <DefaultTabBar className={"stickyBar"} {...props}/>
    );

	return (
        <Tabs animated
              renderTabBar={renderTabBar}
              defaultActiveKey={props.items[0]?.key}
              activeKey={activeTab.key}
              onChange={(activeKey) => {onTabChange(activeKey)}}
        >
            {
                props.items.map(item =>
                    <TabPane key={item.key}
                             tab={item.title}
                    >
                        {item.content}
                    </TabPane>
                )
            }
        </Tabs>
	);
};

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
