import * as React from "react";
import {useState} from "react";
import styles from "./SettingsPage.module.scss";
import {Button, Input, message, Space} from "antd";
import {CheckCircleFilled, CloseCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import ApiManager from "../../../../services/api/ApiManager";

export interface ChangePasswordSectionProps {
	showForgotPasswordHint: boolean
}

export interface ChangePasswordSectionState {
	currentPassword?: string
	newPassword?: string
	newPasswordRepeat?: string
}

export const ChangePasswordSection = (props: ChangePasswordSectionProps) => {
	const {t} = useTranslation()

	const defaultState: ChangePasswordSectionState = {
		currentPassword: "",
		newPassword: "",
		newPasswordRepeat: "",
	}

	const [state, setState] = useState(defaultState)

	const handleResetPassword = async () => {
		try {
			const result = await ApiManager.UserService.changePassword(state.currentPassword, state.newPassword)
		} catch (e) {
			console.error(e)
			message.error('Konnte nicht Speichern', 2)
		} finally {
			setState(defaultState)
		}
	}

	return (
		<div className={styles.whiteBox}>
			<h2 className={styles.textLeft}>{t('reset_password')}</h2>

			<h4>{t("current_password")}</h4>
			<Input.Password data-cy={"input_current_password"}
							className={styles.fixedWidthInput}
							value={state.currentPassword}
							onChange={(e) => {
								setState({...state, currentPassword: e.target.value})
							}}
			/>
			<br/>
			<br/>

			<h4>{t("new_password")}</h4>
			<Input.Password data-cy={"input_new_password"}
							className={styles.fixedWidthInput}
							value={state.newPassword}
							onChange={(e) => {
								setState({...state, newPassword: e.target.value})
							}}
			/>
			<br/>
			<br/>

			<h4>{t("repeat_new_password")}</h4>
			<Space size={24}>
				<Input.Password data-cy={"input_repeat_new_password"}
								className={styles.fixedWidthInput}
								value={state.newPasswordRepeat}
								onChange={(e) => {
									setState({...state, newPasswordRepeat: e.target.value})
								}}
				/>
				{
					state.newPassword?.length > 0 ?
						<>
							{
								state.newPassword === state.newPasswordRepeat ?
									<CheckCircleFilled className={styles.green}/>
									: <CloseCircleOutlined className={styles.red}/>
							}
						</> : null
				}
			</Space>

			<br/>
			<br/>

			<Space size={6}>
				<Button type={"primary"}
						disabled={
							! (state.newPassword?.length > 0 && state.newPassword === state.newPasswordRepeat)
						}
						onClick={() => {
							handleResetPassword()
						}}
				>
					{t('reset_password')}
				</Button>
				{
					props.showForgotPasswordHint ?
						<>
							&nbsp;
							&nbsp;

							<InfoCircleOutlined/>

							<p className={styles.infoText}>
								{t('forgot_password_info')}
							</p>
						</> : null
				}
			</Space>
		</div>
	)
}
