import {CloudUploadOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import React from 'react';
import Dropzone from 'react-dropzone'


export interface DropzoneUploadProps {
    onDropFiles: (acceptedFiles) => void;
    buttonText?: string
}

export function DropzoneUpload(props) {
    return (
        <Dropzone onDrop={props.onDropFiles}>
            {({getRootProps, getInputProps}) => (

                <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button data-cy="button_upload_file"
                            type="primary"
                            htmlType="submit"
                            onClick={() => { }}>
                            <CloudUploadOutlined /> {props.buttonText ? props.buttonText : 'Upload File'}
                        </Button>
                    </div>
                </section>
            )}
        </Dropzone>
    );
}
