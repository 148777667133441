import * as React from "react";
import {useEffect, useState} from "react";
import SignatureCanvas from "react-signature-canvas";
import styles from "components/common/presenters/application-page-tabs/ApplicationPageTabs.module.scss";
import CollapsibleSection from "components/common/presenters/collapsible-section/CollapsibleSection";
import {ListItem, ListItemTypes} from "components/common/presenters/list-item/ListItem";
import {Button, Checkbox, Col, Empty, Form, Input, message, Radio, Row} from "antd";
import {CloudDownloadOutlined} from "@ant-design/icons";
import ApiManager from "services/api/ApiManager";
import UiHelper from "../../../../helpers/uiHelpers";
import {InsuranceSection} from "components/common/presenters/application-page-tabs/contracts-tab/InsuranceSection";
import {InsuranceStatmentSection} from "components/common/presenters/application-page-tabs/contracts-tab/InsuranceStatementSection";
import {BeneficiarySection, BeneficiarySectionType} from "components/common/presenters/application-page-tabs/contracts-tab/BeneficiarySection";
import {ConfirmationOfObjectAcceptance} from "components/common/presenters/application-page-tabs/contracts-tab/ConfirmationOfObjectAcceptance";
import {ContractDocument, ContractType, PdfComponents} from "components/common/presenters/application-page-tabs/contracts-tab/PdfComponents";
import {ListContacts} from "components/common/presenters/list-contacts/ListContacts";
import {BankConnection} from "components/common/presenters/bank-connection/BankConnection";
import {ContractDataExtended, ContractDraftData, OffersDataExtened, UserData} from "services/api/types";
import {ListSignatories} from "components/common/presenters/list-signatories/ListSignatories";
import {SearchPeopleModal} from "components/common/presenters/application-page-tabs/objects-tab/SearchPeopleModal";
import {ContentDisposition} from "services/api/applicationService";
import {getUserContactsFromAkf} from "services/api/akfIntegrationService";

export interface ContractsTabProps {
    offers: OffersDataExtened[];
    contractDrafts: ContractDraftData[];
    contracts: ContractDataExtended[];
    onContractGenerated: (contractGenerated) => void;
    onContractDraftGenerated: (contractDraftGenerated) => void;
    onDataUpdated: () => void;
    user: UserData;
    applicationId: any;
}

export const ContractsTab = (props: ContractsTabProps) => {
    const [generateContractButtonDisabled, setGenerateContractButtonDisabled] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [selectedOfferPdf, setSelectedOfferPdf] = useState(null);
    const [offers, setOffers] = useState([]);
    const [contacts, setContacts] = useState([]);
    useEffect(() => {
        setOffers([...props.offers]);
    }, [props.offers]);

    const [selectedContractTemplate, setSelectedContractTemplate] = useState(null);
    const [contractDrafts, setContractDrafts] = useState([]);
    useEffect(() => {
        setContractDrafts([...props.contractDrafts]);
        if (selectedContractTemplate) {
            setSelectedContractTemplate(props.contractDrafts.find(c => c.contractDraftId === selectedContractTemplate.contractDraftId))
        }
    }, [props.contractDrafts]);

    const [selectedContract, setSelectedContract] = useState(null);
    const [contractPdf, setContractPdf] = useState(null);
    const [contracts, setContracts] = useState([]);
    useEffect(() => {
        setContracts([...props.contracts.map(c => {return {...c, signed: c.signedAt}})]);
        setSelectedContract(null);
        setContractPdf(null);
    }, [props.contracts]);

    useEffect(() => {
        fetchContactPersons();
    }, [props.applicationId]);

    const fetchContactPersons = async () => {
        try {
            if (props.user.akfApiCustomerId) {
                const contacts = await getUserContactsFromAkf(
                    props.user.akfApiCustomerId
                );

                if (contacts.length) {
               //     const temp = contacts.map(c => { return {...c, isBeneficialOwner: true}});
                    setContacts(contacts); //[...temp, {...temp[0], akfContactPersonId: 4343 }]);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    async function onDownloadContractPdfClicked(contractId, contractName) {
        try {
            const result = await ApiManager.ApplicationService.getContractPdf(contractId, ContentDisposition.Attachment);

            UiHelper.downloadPDFFromUrl(result.data.pdf, contractName);
        } catch (error) {
            console.error(error);
        }
    }

    async function onViewContractPdfClicked(contract) {
        try {
            if (contract === selectedContract) {
                setSelectedContract(null);
                setContractPdf(null);
                return;
            }
            const result = await ApiManager.ApplicationService.getContractPdf(contract.contractId);
            setContractPdf({url: result.data.pdf, contractId: contract.contractId});
            setSelectedContract(contract)
        } catch (error) {
            console.error(error);
        }
    }

    async function onViewOfferPdfClicked(offerId) {
        try {
            const result = await ApiManager.ApplicationService.getOfferPdf(offerId);
            setSelectedOfferPdf({url: result.data.pdf, offerId});
            setSelectedOffer(null);
        } catch (error) {
            console.error(error);
        }
    }

    async function onDownloadOfferPdfClicked(offerId, offerName) {
        try {
            const result = await ApiManager.ApplicationService.getOfferPdf(offerId);

            UiHelper.openUrlInNewTab(result.data.pdf);
        } catch (error) {
            console.error(error);
        }
    }

    async function onGenerateContractClicked(pdfName, selectedContractTemplate, contractDocuments, documentWithSignature) {
        try {
            if (!selectedContractTemplate) {
                return;
            }
            if (generateContractButtonDisabled) {
                return;
            }
            const data = {
                contractDraftId: selectedContractTemplate.contractDraftId,
                offerId: selectedContractTemplate.offerId,
                userId: selectedContractTemplate.userId,
                applicationId: selectedContractTemplate.applicationId,
                pdfName: /\.pdf$/.test(pdfName) ? pdfName : (pdfName + '.pdf'),
                contractDocuments,
                documentWithSignature
            };

            setGenerateContractButtonDisabled(true);
            const response = await ApiManager.ApplicationService.createContract(data);

            message.success('Daten Gespeichert', 2);
            setSelectedOffer(null);
            props.onDataUpdated();
        } catch (error) {
            console.error(error);
            const errorText = error?.response?.data?.errors?.join(' ');
            message.error('Konnte nicht Speichern' + ' ' + errorText, 2)
        }
        setGenerateContractButtonDisabled(false);
    }

    function getNumberOfSignatoriesLeft(contract: ContractDataExtended): number {
        const numberOfSignatoriesLeft = (contract.signatories || []).filter(s => !s.base64Signature);

        return numberOfSignatoriesLeft.length;
    }

    function ButtonsInOffersTop(props) {
        return <div className={styles.buttonsGroup}>
            <div className={styles.icon}>
                <CloudDownloadOutlined />
            </div>

            <Button data-cy="button_save"
                disabled={generateContractButtonDisabled}
                type="primary"
                size="small"
                htmlType="submit"
                onClick={generateContractButtonDisabled ? () => { } : props.onClick}>
                Vertragsvorlage {props.extraText ? props.extraText : ''}
            </Button>
        </div>;
    }

    return (
        <div className={styles.tab}>
            <CollapsibleSection text="Erstellte Angebote">
                {offers.map((o, i) =>
                    <React.Fragment>
                        <ListItem
                            type={ListItemTypes.SelectOffer}
                            selectedDark={selectedOffer === o}
                            htmlText={o.offerName}
                            onClick={() => {
                                selectedOffer === o ? setSelectedOffer(null) : setSelectedOffer(o);
                                setSelectedOfferPdf('');
                            }}
                        />
                        {selectedOffer === o ?
                            <SelectedOfferSection
                                onContractDraftGenerated={props.onDataUpdated}
                                selectedOffer={selectedOffer}
                                userId={selectedOffer.userId}
                                applicationId={selectedOffer.applicationId}
                                offerId={selectedOffer.offerId}
                                userName={`${props.user.firstName} ${props.user.lastName}`}
                            />
                            : null}
                        {selectedOfferPdf && selectedOfferPdf.offerId === o.offerId ? <div style={{textAlign: 'center', background: 'black'}}>
                            <object style={{width: 714, height: 1010}} data={selectedOfferPdf.url} type="application/pdf">
                                <iframe src={selectedOfferPdf.url}></iframe>
                            </object>
                        </div> : null}
                    </React.Fragment>
                )}
            </CollapsibleSection>
            <CollapsibleSection text="Vertragsvorlagen">
                {contractDrafts.map((ct, i) =>
                    <React.Fragment>
                        <ListItem
                            type={ListItemTypes.ContractTemplate}
                            selectedDark={selectedContractTemplate?.contractDraftId === ct.contractDraftId}
                            htmlText={ct?.offer?.akfOfferId ? `${ct.contractTemplateName} -  <span style="font-weight: 900;">${ct?.offer?.akfOfferId}-A<span>` : ct.contractTemplateName}
                            onClick={() => {selectedContractTemplate?.contractDraftId === ct.contractDraftId ? setSelectedContractTemplate(null) : setSelectedContractTemplate(ct)}}
                            onNextToLastIconClicked={(e) => { }}
                            onLastIconClicked={(e) => { }}
                        />
                        {selectedContractTemplate?.contractDraftId === ct.contractDraftId ? <SelectedContractTemplateSection
                            selectedContractTemplate={selectedContractTemplate}
                            onDataUpdated={props.onDataUpdated}
                            onGenerateContractClicked={onGenerateContractClicked}
                            generating={generateContractButtonDisabled}
                            contacts={contacts}
                        /> : null}
                    </React.Fragment>
                )}
            </CollapsibleSection>
            <CollapsibleSection text="Erstellte Verträge" >
                {contracts.map((c, i) =>
                    <React.Fragment>
                        <ListItem
                            type={c.signed ? ListItemTypes.ContractSigned : ListItemTypes.ContractSigning}
                            textNextToIcons={c.documentWithSignature && getNumberOfSignatoriesLeft(c) > 0 ? `${getNumberOfSignatoriesLeft(c)} unterschriften ausstehend` : ''}
                            htmlText={c.contractName}
                            selectedDark={selectedContract === c}
                            onClick={(e) => {e.stopPropagation(); onViewContractPdfClicked(c);}}
                            onNextToLastIconClicked={(e) => {e.stopPropagation(); onViewContractPdfClicked(c);}}
                            onLastIconClicked={(e) => {e.stopPropagation(); onDownloadContractPdfClicked(c.contractId, c.contractName);}}
                        />
                        {contractPdf && contractPdf.contractId === c.contractId ? <div style={{textAlign: 'center', background: 'black'}}>
                            <object style={{width: 714, height: 1010}} data={contractPdf.url} type="application/pdf">
                                <iframe src={contractPdf.url}></iframe>
                            </object>
                        </div> : null}
                    </React.Fragment>
                )}


            </CollapsibleSection>
            { selectedContract && selectedContract.documentWithSignature && selectedContract.signatories.length > 0 && !selectedContract?.signedAt ? <SignatoryForm user={props.user} selectedContract={selectedContract} onSigned={() => props.onDataUpdated()} /> : null}
        </div>
    );
};

const SelectedContractTemplateSection = (props) => {
    const [dataFields, setDataFields] = useState({
        pdfName: ''
    });

    const [guarantors, setGuarantors] = useState([]);

    useEffect(() => {
        fetchGuarantorsContactPeople();
    }, [props.selectedContractTemplate.contractDraftId]);

    const fetchGuarantorsContactPeople = async () => {

            const guarantors = props.selectedContractTemplate?.guarantors || [];

            const guarantorsWithContactPeople = await Promise.all(guarantors.map(async g => await fetchContactPeople(g)));

            setGuarantors(guarantorsWithContactPeople);
    };

    const fetchContactPeople = async (guarantor) => {
        try {
            if (guarantor.akfApiCustomerId) {
                const contacts = await getUserContactsFromAkf(
                    guarantor.akfApiCustomerId
                );

                return { ...guarantor, contacts };
            } else {
                return { ...guarantor, contacts: [] };
            }
        } catch (error) {
            console.error(error);
            return { ...guarantor, contacts: [] };
        }
    };

    if (!props.selectedContractTemplate) {
        return null;
    }

    async function onSaveBeneficiariesClicked(beneficiaries, beneficialOwnerType) {
        try {
            const request = {
                beneficiaries: JSON.stringify(beneficiaries),
                beneficialOwnerType: beneficialOwnerType
            }
            await ApiManager.ApplicationService.patchContractDraft(request, props.selectedContractTemplate.contractDraftId);

            message.success('Daten Gespeichert', 2);
            props.onDataUpdated();
        } catch (error) {
            console.error(error);
            const errorText = error?.response?.data?.errors?.join(' ');
            message.error('Konnte nicht Speichern' + ' ' + errorText, 2);
        }
    }

    async function onSaveBeneficiariesForGuarantorClicked(beneficiaries, beneficialOwnerType, guarantor) {
        try {
            let originalGuarantor = props.selectedContractTemplate.guarantors.find(g => g.akfApiCustomerId == guarantor.akfApiCustomerId)

            originalGuarantor.beneficiaries = beneficiaries;
            originalGuarantor.beneficialOwnerType = beneficialOwnerType;

            const request = {
                guarantors: JSON.stringify( props.selectedContractTemplate.guarantors)
            };

            await ApiManager.ApplicationService.patchContractDraft(request, props.selectedContractTemplate.contractDraftId);

            message.success('Daten Gespeichert', 2);

            const uiGuarantor = guarantors.find(g => g.akfApiCustomerId == guarantor.akfApiCustomerId)
            guarantors.splice(guarantors.indexOf(uiGuarantor), 1, {...uiGuarantor, beneficiaries, beneficialOwnerType})

            setGuarantors([...guarantors]);
            props.onDataUpdated();
        } catch (error) {
            console.error(error);
            const errorText = error?.response?.data?.errors?.join(' ');
            message.error('Konnte nicht Speichern' + ' ' + errorText, 2);
        }
    }

    return (
        <div className={styles.contractTemplateFieldsInner}>
            <InsuranceSection
                selectedContractTemplate={props.selectedContractTemplate}
                onDataUpdated={props.onDataUpdated}
            />
            <hr />
            <InsuranceStatmentSection
                selectedContractTemplate={props.selectedContractTemplate}
                onDataUpdated={props.onDataUpdated}
            />
            <hr />
            <BeneficiarySection
                selectedContractTemplate={props.selectedContractTemplate}
                onDataUpdated={props.onDataUpdated}
                beneficiarySectionType={BeneficiarySectionType.Kunde}
                onSaveClicked={(beneficiaries, beneficialOwnerType) => onSaveBeneficiariesClicked(beneficiaries, beneficialOwnerType)}
                title={"Wirtschaftlich Berechtigte - Kunde"}
                type
                beneficialOwnerType={props.selectedContractTemplate.beneficialOwnerType}
                beneficiaries={props.contacts.filter(s => s.isBeneficialOwner).map(c => {
                    return {...(props.selectedContractTemplate?.beneficiaries || []).find(bb => bb.akfContactPersonId === c.akfContactPersonId), ...c}})}
            />
            {
                guarantors.map((g, i) => <>
                            <hr />
                            <BeneficiarySection
                                selectedContractTemplate={props.selectedContractTemplate}
                                onDataUpdated={props.onDataUpdated}
                                beneficiarySectionType={BeneficiarySectionType.Burge}
                                onSaveClicked={
                                    (beneficiaries, beneficialOwnerType) => onSaveBeneficiariesForGuarantorClicked(beneficiaries, beneficialOwnerType, g)}
                                title={"Wirtschaftlich Berechtigte - Bürge " + (i+1)}
                                beneficialOwnerType={g.beneficialOwnerType}
                                beneficiaries={(g.contacts.filter(s => s.isBeneficialOwner) || [])
                                    .map(c => {
                                        const existingBeneficiaries = (g.beneficiaries || []).find(bb => bb.akfContactPersonId === c.akfContactPersonId);
                                        return {...existingBeneficiaries, ...c}})}
                            />
                </>)
            }
            <hr />
            <ConfirmationOfObjectAcceptance
                selectedContractTemplate={props.selectedContractTemplate}
                onDataUpdated={props.onDataUpdated}
            />
            <hr />
            <PdfComponents
                objects={props.selectedContractTemplate.objects}
                guarantors={props.selectedContractTemplate.guarantors || []}
                onPdfNameChange={(name) => setDataFields({pdfName: name})}
                generating={props.generating}
                onGenerateContractClicked={(contractDocuments: ContractDocument[], documentWithSignature: boolean) => {
                    if(!dataFields.pdfName) {
                        message.error('Bitte geben Sie einen Namen für die Datei ein.', 3);
                        return;
                    }
                    props.onGenerateContractClicked(dataFields.pdfName, props.selectedContractTemplate, contractDocuments, documentWithSignature)
                }}
             />
        </div>
    );
}

const SelectedOfferSection = (props) => {
    const [selectedGuarantors, setSelectedGuarantors] = useState([]);

    const [offerFields, setOfferFields] = useState({
        fee: 0,
        contractType: '',
        confirmationOfAcceptance: null,
        printDueDate: null,
        actInOwn: null,
        creditInstitutionId: null,
        accountOwner: '',
        iban: '',
        bankConnectionId: undefined,
        newBankAccount: false,
        guarantors: []
    });
    useEffect(() => {
        setOfferFields({
            fee: props.selectedOffer.fee,
            contractType: props.selectedOffer.contractType,
            confirmationOfAcceptance: props.selectedOffer.confirmationOfAcceptance,
            printDueDate: props.selectedOffer.printDueDate,
            actInOwn: props.selectedOffer.actInOwn,
            creditInstitutionId: null,
            accountOwner: '',
            iban: '',
            bankConnectionId: props.selectedOffer.bankConnectionId,
            newBankAccount: false,
            guarantors: props.selectedOffer.guarantors
        });
    }, [props.selectedOffer]);

    const [uiData, setUiData] = useState({
        selectedFee: 4,
        andereFeeString: '0',
        guarantorPhrase1: '',
        guarantorPhrase2: '',
        searchGurantorModal: '0'
    });
    useEffect(() => {
        let selectedFee = {'120': 1, '140': 2, '175': 3}[props.selectedOffer.fee];
        selectedFee = selectedFee ? selectedFee : 4;
        setUiData({
            selectedFee: selectedFee,
            andereFeeString: selectedFee === 4 ? props.selectedOffer.fee.toString() : '',
            guarantorPhrase1: '',
            guarantorPhrase2: '',
            searchGurantorModal: '0'
        });
    }, [props.selectedOffer]);

    async function onCreateDraftClicked() {
        try {
            if (isDataInvalid().length > 0) {
                return message.error('Data invalid, please fill in the form sections ' + isDataInvalid().join(', '), 4);
            }

            const data = {
                userId: props.userId,
                applicationId: props.applicationId,
                offerId: props.offerId,
                ...offerFields,
                guarantors: offerFields.guarantors ? JSON.stringify(selectedGuarantors) : undefined
            };

            const response = await ApiManager.ApplicationService.createContractDraft(data);

            message.success('Daten Gespeichert', 2);

            props.onContractDraftGenerated(response.data);
        } catch (error) {
            console.error(error);
            const errorText = error?.response?.data?.errors?.join(' ');
            message.error('Konnte nicht Speichern' + ' ' + errorText, 2)
        }
    }


    function isDataInvalid() {
        return [
            feeInvalid() ? 'Antragsprüfungsgebühr' : null
        ].filter(e => !!e);
    }

    function feeInvalid() {
        const isInvalid = uiData.selectedFee === 4 && (parseFloat(uiData.andereFeeString) < 0 || (offerFields.fee !== 0 && uiData.andereFeeString !== (offerFields.fee || '').toString()));
        return isInvalid;
    }

    function bankAccountInvalid() {
        if (offerFields.newBankAccount) {
            return !offerFields.creditInstitutionId || !offerFields.iban || !offerFields.accountOwner;
        } else {
            return !offerFields.bankConnectionId;
        }
    }

    if (!props.selectedOffer) {
        return null;
    }

    return (
        <div className={styles.offerFieldsInner}>
            <h3>Antragsprüfungsgebühr</h3>
            <div className={styles.centerRadioButtons}>
                <Radio.Group
                    onChange={(e) => {
                        setUiData({ ...uiData, selectedFee: e.target.value });
                        setOfferFields({
                            ...offerFields,
                            fee: [0, 120, 140, 175, 0][e.target.value],
                        });
                    }}
                    value={uiData.selectedFee}
                >
                    <Radio value={1}>120 EUR</Radio>
                    <Radio value={2}>140 EUR</Radio>
                    <Radio value={3}>175 EUR</Radio>
                    <Radio value={4}>Anderer Betrag:</Radio>
                </Radio.Group>
                <Form.Item
                    validateStatus={
                        uiData.selectedFee === 4 &&
                        (parseFloat(uiData.andereFeeString) < 0 ||
                            (offerFields.fee !== 0 &&
                                uiData.andereFeeString !==
                                    (offerFields.fee || "").toString()))
                            ? "error"
                            : ""
                    }
                    className={styles.formItem}
                >
                    <Input
                        size="small"
                        className={styles.andereFee}
                        value={
                            uiData.selectedFee === 4
                                ? uiData.andereFeeString
                                : ""
                        }
                        placeholder=""
                        onChange={(e) => {
                            setUiData({
                                ...uiData,
                                andereFeeString: e.target.value,
                            });
                            setOfferFields({
                                ...offerFields,
                                fee: parseFloat(e.target.value),
                            });
                        }}
                    ></Input>
                </Form.Item>
            </div>
            <br />
            <br />
            <h3>Vertragsart</h3>
            <Radio.Group
                onChange={(e) => {
                    setOfferFields({
                        ...offerFields,
                        contractType: ["", "€", "%"][e.target.value],
                    });
                }}
                value={{ "€": 1, "%": 2 }[offerFields.contractType]}
            >
                <Radio value={1}>Kredit - Zinsen in €</Radio>
                <Radio value={2}>Kredit - Zinsen in %</Radio>
            </Radio.Group>
            <br />
            <br />
            <h3>Abnahmebestätigung</h3>
            <Radio.Group
                onChange={(e) => {
                    setOfferFields({
                        ...offerFields,
                        confirmationOfAcceptance: [null, true, false][
                            e.target.value
                        ],
                    });
                }}
                value={
                    { true: 1, false: 2 }[offerFields.confirmationOfAcceptance]
                }
            >
                <Radio value={1}>Ja</Radio>
                <Radio value={2}>Nein</Radio>
            </Radio.Group>
            <br />
            <br />
            <h3>Fälligkeitsdatum andrucken</h3>
            <Radio.Group
                onChange={(e) => {
                    setOfferFields({
                        ...offerFields,
                        printDueDate: [null, true, false][e.target.value],
                    });
                }}
                value={{ true: 1, false: 2 }[offerFields.printDueDate]}
            >
                <Radio value={1}>Ja</Radio>
                <Radio value={2}>Nein</Radio>
            </Radio.Group>
            <br />
            <br />
            <h3>Der Kunde handelt im eigenen Namen und auf eigene Rechnung?</h3>
            <Radio.Group
                onChange={(e) =>
                    setOfferFields({
                        ...offerFields,
                        actInOwn: [null, true, false][e.target.value],
                    })
                }
                value={{ true: 1, false: 2 }[offerFields.actInOwn]}
            >
                <Radio value={1}>Ja</Radio>
                <Radio value={2}>Nein</Radio>
            </Radio.Group>
            <br />
            <br />
            <h3>Bürgen</h3>
            <Row gutter={[24, 24]}>
                <Col span={12}>
                    <h4>Bürge 1 Auswählen</h4>
                    <Input
                        data-cy="input_guarantor"
                        className={styles.shortInput}
                        value={uiData.guarantorPhrase1}
                        onClick={() => {
                            setUiData({ ...uiData, searchGurantorModal: "1" });
                        }}
                    ></Input>
                </Col>
                <Col span={12}>
                    <h4>Bürge 2 Auswählen</h4>
                    <Input
                        data-cy="input_guarantor"
                        className={styles.shortInput}
                        value={uiData.guarantorPhrase2}
                        onClick={() => {
                            setUiData({ ...uiData, searchGurantorModal: "2" });
                        }}
                    ></Input>
                </Col>
            </Row>
            <br />
            <br />
            <BankConnection
                bankConnections={props.selectedOffer.bankConnections || []}
                onDataChanged={(data) =>
                    setOfferFields({ ...offerFields, ...data })
                }
                bankConnectionId={props.selectedOffer.bankConnectionId}
                userName={props.userName}
                infoTextVisible={true}
            />

            <div className={styles.buttonAtBottom}>
                <Button
                    data-cy="button_save"
                    className={styles.button}
                    type="primary"
                    size="small"
                    htmlType="submit"
                    onClick={onCreateDraftClicked}
                >
                    Vorlage Erstellen
                </Button>
            </div>
            <SearchPeopleModal
                isOpen={uiData.searchGurantorModal !== "0"}
                onOk={(row) => {
                    const key = `guarantorPhrase${uiData.searchGurantorModal}`;
                    const phrase = `${row.firstName || ""} ${
                        row.lastName || ""
                    }`;

                    const guarantors = offerFields.guarantors || [];

                    const guarantor = { 
                        akfApiCustomerId: row.akfApiCustomerId,
                        bankUserId: row.bankUserId,
                        name: phrase,
                        lastName: row.lastName,
                        firstName: row.firstName,
                        addressLine1: row.addressLine1,
                        addressPostalCode: row.addressPostalCode,
                        addressStreetNumber: row.addressStreetNumber,
                        addressCity: row.addressCity,
                        birthDate: row.birthDate,
                        taxId: row.taxId
                    };

                    if( !guarantors.find(u => u.akfApiCustomerId === guarantor.akfApiCustomerId)) {
                        guarantors.push(guarantor);
                    }

                    setSelectedGuarantors([...guarantors]);
                    setOfferFields({ ...offerFields, guarantors: guarantors });
                    setUiData({
                        ...uiData,
                        searchGurantorModal: "0",
                        [key]: phrase,
                    });
                }}
                onCancel={() => {
                    setUiData({ ...uiData, searchGurantorModal: "0" });
                }}
            />
        </div>
    );
}

export interface SignatoryFormProps {
    user: UserData,
    selectedContract: ContractDataExtended;
    onSigned: () => void
}

const SignatoryForm = (props: SignatoryFormProps) => {
    const [signingButtonDisabled, setSigningButtonDisabled] = useState(false);
    const [data, setData] = useState({
        name: '',
        agreeSign: false,
        acceptTerms: false,
        base64Signature: '',
        alreadySigned: false
    });
    const [uiData, setUiData] = useState({
        clickedSignButton: false
    })

    const [selectedSignatory, setSelectedSignatory] = useState(null);

    useEffect(() => {
        setData({
            name: '',
            agreeSign: false,
            acceptTerms: false,
            base64Signature: '',
            alreadySigned: false
        });
        if (signature) {
            signature.clear();
            signature.on();
        }
    }, [props.selectedContract]);

    let signature = null as any;

    async function onSignContractClicked() {
        try {
            if (signingButtonDisabled) {
                return;
            }
            if (isDataInvalid().length > 0) {
                setUiData({...uiData, clickedSignButton: true})
                return message.error('Data invalid, please fill in inputs', 4);
            }

            setSigningButtonDisabled(true);
            setUiData({...uiData, clickedSignButton: false})
            const response = await ApiManager.ApplicationService.signContractPdf(
                props.selectedContract.contractId,
                {
                    base64Signature: signature.toDataURL(),
                    signatoryName: data.name,
                    id: selectedSignatory?.id,
                    isGuarantor: selectedSignatory.isGuarantor,
                    guarantorIndex: selectedSignatory.guarantorIndex
                }
               );

            message.success('Daten Gespeichert', 2);

            props.onSigned();
        } catch (error) {
            console.error(error);
            const errorText = error?.response?.data?.errors?.join(' ');
            message.error('Konnte nicht Speichern' + ' ' + errorText, 2)
        }
        setSigningButtonDisabled(false);
    }

    function isDataInvalid() {
        return [
            !data.name ? 'Signatory Name' : null,
            !data.agreeSign ? 'Agreement' : null,
            !data.acceptTerms ? 'T&C' : null
        ].filter(e => !!e);
    }

    const signatories = props.selectedContract.signatories;

    return (
        <div className={styles.signatureInner}>
            <div>
                <h4>Unterschriften benötigt von:</h4>
            </div>
            <div>
                <ListSignatories
                    signatories={(signatories).map(o => {
                        return {
                            ...o,
                            signed: !!o.base64Signature,
                            selected: selectedSignatory && selectedSignatory?.id === o.id,
                            text: o.signatoryName
                        }
                    })}
                    onClick={(o) => {
                        if (o.base64Signature) {
                            setSelectedSignatory({...o})
                            setData({
                                name: o.signatoryName,
                                agreeSign: true,
                                acceptTerms: true,
                                base64Signature: o.base64Signature,
                                alreadySigned: true
                            });
                        } else {
                            setSelectedSignatory({...o})
                            if (signature) {
                                signature.clear();
                                signature.on();
                            }
                            setData({
                                name: o.signatoryName,
                                agreeSign: false,
                                acceptTerms: false,
                                base64Signature: '',
                                alreadySigned: false
                            });
                        }
                    }}
                />
            </div>
            {selectedSignatory ?
                <div className={styles.signatureSection}>
                    <div>
                        <h4>Name</h4>
                        <Form.Item
                            validateStatus={uiData.clickedSignButton && !data.name ? "error" : ''}
                            className={styles.formItem}
                        >
                            <Input
                                value={data.name} placeholder=""
                                disabled={data.alreadySigned}
                                onChange={(e) => setData({...data, name: e.target.value})}>
                            </Input>
                        </Form.Item>
                        <br />
                        <br />
                        <Form.Item
                            validateStatus={uiData.clickedSignButton && !data.agreeSign ? "error" : ''}
                            className={styles.formItem}
                        >
                            <Checkbox checked={data.agreeSign} onChange={(e) => {setData({...data, acceptTerms: e.target.checked, agreeSign: e.target.checked})}}>
                                Ich bestätige das Dokument gelesen und verstanden zu haben. Mit meiner elektronischen Unterschrift wird dieses Dokument rechtskräftig unterschrieben. 
					        </Checkbox>
                        </Form.Item>
                    </div>
                    <div style={{ paddingLeft: "30px"}}>
                        <h4>Unterschrift</h4>
                        <div className={styles.customersigForm}>
                            <SignatureCanvas ref={(ref) => {
                                signature = ref;
                                if (signature) {
                                    if (data.base64Signature) {
                                        signature.clear();
                                        signature.fromDataURL(data.base64Signature);
                                        signature.off();
                                    }
                                }
                            }}
                                penColor='black'
                                canvasProps={{width: 500, height: 207, className: data.alreadySigned ? styles.sigCanvasDisabled : 'sigCanvas'}} />
                        </div>
                        <br />
                        <div>
                            <Button
                                type="default"
                                style={{marginRight: '15px'}}
                                htmlType="submit"
                                disabled={signingButtonDisabled || data.alreadySigned}
                                onClick={() => {signature.clear();}}>
                                Abbrechen
				            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={signingButtonDisabled || data.alreadySigned}
                                onClick={() => onSignContractClicked()}>
                                {signingButtonDisabled ? 'Unterschreiben ...' : 'Unterschreiben'}
                            </Button>
                        </div>
                    </div>
                </div> : null}
        </div>
    );
}
