import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./SettingsPage.module.scss";
import {CSVLink} from "react-csv";
import {Button, Col, Input, message, Row, Table} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {DealershipConditionGroup} from "../../../../services/api/types";
import moment from "moment";
import {DATE_FORMAT} from "../../../../services/api/Globals";
import ApiManager from "../../../../services/api/ApiManager";
import {SelectFileButton} from "./SelectFileButton";

export interface ManageDealersSectionProps {
	existingDealershipConditionGroups: DealershipConditionGroup[]
	onDataChange: () => void
}

export interface ManageDealersSectionState {

}

export const ManageDealersSection = (props: ManageDealersSectionProps) => {
	const {t} = useTranslation()

	const [selectedFile, setSelectedFile] = useState(null)
	const [uploadDealerName, setUploadDealerName] = useState("")
	const [uploadConditionType, setUploadConditionType] = useState("")

	const columns = [
		{
			title: t('dealer'),
			dataIndex: 'dealerName',
		},
		{
			title: t('condition_type'),
			dataIndex: 'conditionType',
		},
		{
			title: t('data:created_at'),
			dataIndex: 'createdAt',
			render: (createdAt) => moment(createdAt).format(DATE_FORMAT.DE)
		},
		{
			title: '',
			dataIndex: 'rowIndex',
			width: 80,
			render: (rowIndex) => renderRowButtons(rowIndex),
		},
	]

	const dataSource = props.existingDealershipConditionGroups.map((value, i) => {
		return {
			...value,
			key: i
		}
	})

	const renderRowButtons = (rowIndex: number) => {
		const dealershipConditionGroup = dataSource.find(d => d.key === rowIndex)

		return (
			<div>
				<DeleteOutlined data-cy={`delete_dealer_condition_group_${rowIndex}`}
								onClick={() =>
									handleDelete(dealershipConditionGroup.dealerName, dealershipConditionGroup.conditionType)
								}
				/>
			</div>
		)
	}

	const handleDelete = async (dealerName: string, conditionType: string) => {
		try {
			await ApiManager.UserService.deleteDealershipConditionGroup(dealerName, conditionType)
			props.onDataChange()
			message.success('Daten gelöscht', 2)
		} catch (e) {
			console.error(e)
			message.error('Konnte nicht gelöscht werden', 2)
		}
	}

	const handleUpload = async () => {
		if (selectedFile) {
			try {
				await ApiManager.UserService.uploadDealershipConditions(selectedFile, uploadDealerName, uploadConditionType) // TODO
				props.onDataChange()
				setSelectedFile(null)
				setUploadDealerName("")
				setUploadConditionType("")
				message.success('Daten gelöscht', 2)
			} catch (e) {
				console.error(e)
				if (e.response?.status === 400 && e.response.data?.errors[0] === 'ErrCsvHeadersMismatch') {
					message.error(t('errors:csv_format_error'), 6)
				} else {
					message.error('Konnte nicht gelöscht werden', 2)
				}
			}
		}
	}

	const exampleCsvData = [
		["Laufzeit", "Raten", "Faktor", "Zinssatz", "Provision", "Interner Zinssatz", "Disagio", "Refi Zins", "Zinsmarge", "Beispielrate"],
		["36 Monate", "36", "1.0405", "2.59%", "0.50%", "2.470%", "36 Monate", "0.90%", "1.57%", "2890.28€"],
		["36 Monate", "36", "1.0405", "2.59%", "0.50%", "2.470%", "36 Monate", "0.90%", "1.57%", "2890.28€"],
	]

	return (
		<div>
			<h2 className={styles.textLeft}>{t('manage_dealer_data')}</h2>

			<Table columns={columns}
				   dataSource={dataSource}
				   pagination={{position: ["bottomCenter"], showSizeChanger: false, pageSize: 10, total: dataSource.length}}
			/>
			<br/>
			<br/>

			<div className={styles.whiteBox}>
				<h4>
					{t('upload_dealer_conditions_part_1')}
					<span className={styles.blueLink}>
						<CSVLink filename={`Bonitätsmitarbeiterliste.csv`}
								 data={exampleCsvData}
						>
							{" " + t('upload_dealer_conditions_part_2') + " "}
						</CSVLink>
					</span>
					{t('upload_dealer_conditions_part_3')}
				</h4>


				<Input disabled={true}
					   value={selectedFile?.name}
					   suffix={
						   selectedFile?.name ?
							   <DeleteOutlined onClick={() => {
								   setSelectedFile(null)
							   }}/>
							   : null
					   }
				/>
				<br/>
				<br/>

				<SelectFileButton disabled={selectedFile}
								  onDropFiles={(files: any[]) => {
									const file = files[0]
									  setSelectedFile(file)
								  }}
				/>
				<br/>
				<br/>

				<Row gutter={24}>
					<Col span={12}>
						<h4>{t('dealer')}</h4>
						<Input data-cy={"input_dealer_name"}
							   value={uploadDealerName}
							   onChange={(e) => {
							   	setUploadDealerName(e.target.value)
							   }}
						/>
					</Col>
					<Col span={12}>
						<h4>{t('condition_type')}</h4>
						<Input data-cy={"condition_type"}
							   value={uploadConditionType}
							   onChange={(e) => {
								   setUploadConditionType(e.target.value)
							   }}
						/>
					</Col>
				</Row>
				<br/>
				<br/>

				<Button type={"primary"}
						data-cy={"button_select_file"}
						disabled={! (selectedFile && uploadDealerName && uploadConditionType)}
						onClick={() => {
							handleUpload()
						}}
				>
					{t('import_list')}
				</Button>
			</div>
		</div>
	)
}
