import ApiManager from 'services/api/ApiManager';
import {ApiWrapper} from 'services/api/ApiWrapper';
import {IndustryTypeSetting, UserData, UserType} from 'services/api/types';
import config from '../../config';

const axios = require('axios').default;
axios.defaults.withCredentials = true;

export interface SearchFields {
    bank_user_id: string;
    match_code: string;
    address_line_1: string;
    email: string;
    last_name: string;
    address_postal_code: string;
    address_city: string;
    mobile_number: string;
}

const mockedUser = {
    customer_id: 187,
    customer_number: 8465454,
    salutation: "Sehr geehrte/r Frau/Herr",
    last_name: "Ortmann",
    first_name: "Michelle",
    address: {
      street_name: "Perlenpfuhl",
      street_number: "828",
      postal_code: "66601",
      city: "Wiedenborstel",
      country: "Deutschland",
      address_suffix: null
    },
    email_address: "keinpostfach@akf.de",
    is_active: true,
    tax_id: "71735631791",
    tax_number: null,
    customer_type: "private",
    customer_classification: [
      "Kunde"
    ],
    phone1: "0049-202-611917",
    phone2: null,
    fax: "0049-202-613763",
    name_affix: "Gabelstapler-Ersatzteile",
    birthdate: "1967-07-14",
    birth_country: "Deutschland",
    nationality: "Deutschland",
    vat: null,
    crefo: "0",
    industry: "460",
    industry_description: "Großhandel (ohne Handel mit Kraftfahrzeugen)",
    bank_details: null
  };

type AkfUserData = typeof mockedUser;

export const mapAkfUserToUserData = (user: AkfUserData, industryTypes: IndustryTypeSetting[]) : UserData => {
    const foundIndustry = industryTypes.find(i => i.key == user.industry)
    return {
        isAkf: true,
        akfApiCustomerId: user.customer_id,
        bankUserId: user.customer_number,
        salutation: user.salutation,
        lastName: user.last_name,
        firstName: user.first_name,

        addressLine1: user.address.street_name,
        addressStreetNumber: user.address.street_number,
        addressPostalCode: user.address.postal_code,
        addressCity: user.address.city,
        country: user.address.country,
        // user.address_suffix

        email: user.email_address,
       // user.is_active,
        taxId: user.tax_id,
        taxNumber: user.tax_number,
        isPrivate: user.customer_type === 'private',
        userType: user.customer_classification.map(s => {
            switch (s) {
                case "Kunde":
                    return UserType.Kunde;
                    break;
                case "Händler":
                    return UserType.HandlerLieferant;
                    break;
                case "Hersteller":
                    return UserType.Hersteller;
                    break;
                case "Vermittler":
                    return UserType.Vermittler;
                    break;
            
                default:
                    return ''
            }
        }).filter(f => !!f),

        mobileNumber: user.phone2,
        oldschoolphoneNumber: user.phone2,
        faxNumber: user.fax,
        addon: user.name_affix,
        // title: user.name_affix,
        birthDate: user.birthdate,
        birthCountry: user.birth_country,
        nationality: user.nationality,
        vatTaxId: user.vat,
        industryTypeId: foundIndustry?.industryTypeId
        //: user.crefo,
        //: user.industry,
        //: user.industry_description,

    }
}


// export const mockedAkfUsersData = [
//     mockedUser
//   ].map(m => mapAkfUserToUserData(m));


// export const getUserFromAkf = async (userId: string|number) => {
//     const user = await get(`/users/akf/customers/${userId}`);

//     return mapAkfUserToUserData(user);
// }

export const getUserFromAkf = async (akfApiCustomerId) => {
    let path = `/users/akf/customers/${akfApiCustomerId}`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    const r = ApiManager.handleNetworkCallResult(networkCall, "data");

    return r.data;
};

export const getUserContactsFromAkf = async (akfApiCustomerId) => {
    let path = `/users/akf/customers/${akfApiCustomerId}/contacts`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    const r = ApiManager.handleNetworkCallResult(networkCall, "data");

    return r.data;
};


export const getUsersFromAkf = async (searchFields: SearchFields) => {
    let queryArray = [];
    if(searchFields?.bank_user_id) {
        queryArray.push({key: 'customer_number', value: searchFields.bank_user_id})
    }
    if(searchFields?.match_code) {
        queryArray.push({key: 'customer_matchcode', value: searchFields.match_code})
    }
    if(searchFields?.last_name) {
        queryArray.push({key: 'last_name', value: searchFields.last_name})
    }
    if(searchFields?.address_line_1) {
        queryArray.push({key: 'street', value: searchFields.address_line_1})
    }
    if(searchFields?.address_postal_code) {
        queryArray.push({key: 'postal_code', value: searchFields.address_postal_code})
    }
    if(searchFields?.address_city) {
        queryArray.push({key: 'city', value: searchFields.address_city})
    }
    if(searchFields?.email) {
        queryArray.push({key: 'email', value: searchFields.email})
    }
    if(searchFields?.mobile_number) {
        queryArray.push({key: 'phone_number', value: searchFields.mobile_number})
    }

    if(!queryArray.length) {
        return [];
    }

    const query = queryArray.length > 0 ? `?${queryArray.map(e => `${e.key}=${encodeURIComponent(e.value)}`).join('&')}` : '';

    let path = `/users/akf/customers${query}`;

    const apiCall = ApiWrapper.get(path);

    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

    const r = ApiManager.handleNetworkCallResult(networkCall, "data");

    const users = r.data || [];

    return users;
}

const get = async (path: string) => {
    const fullPath = getModifiedPath(path);
    try {
        return await axios.get(fullPath);
    } catch (error) {
        console.error('Error from the akf api', error)
        throw error;
    }
}

function getModifiedPath(path: string): any {
    const {AKF_INTEGRATION_API} = config.api;

    return `${AKF_INTEGRATION_API}${path}`;
}
