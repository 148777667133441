import CollapsibleSection from "components/common/presenters/collapsible-section/CollapsibleSection";
import React, {useEffect, useState} from "react";
import styles from "components/common/presenters/application-page-tabs/contracts-tab/BeneficiarySection.module.scss";
import {Button, Input, Radio, Form, message, Checkbox} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import {ListContacts} from "components/common/presenters/list-contacts/ListContacts";
import ApiManager from "services/api/ApiManager";

enum BeneficialOwnerType {
    CUSTOMER_IS_BENEFICIAL_OWNER = 1,
    NO_BENEFICIAL_OWNER = 2,
    FOLLOWING_BENEFICIAL_OWNER = 3
}

export enum BeneficiarySectionType {
    Kunde = 1,
    Burge = 2
}

export const BeneficiarySection = (props) => {
    const [selectedContactPerson, setSelectedContactPerson] = useState(null);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [beneficialOwnerType, setBeneficialOwnerType] = useState(0);

    useEffect(() => {
        const beneficiaries = props.beneficiaries || [];
        setBeneficiaries(beneficiaries.map(b => { return {
            shares: '',
            includedInContract: false,
            ...b
        }}));
        setBeneficialOwnerType(props.beneficialOwnerType || 0);
        setSelectedContactPerson(null)
    }, [props.selectedContractTemplate]);


    return (
        <CollapsibleSection text={props.title} initialCollapsedState={true}>
            <Radio.Group onChange={(e) => {
                setSelectedContactPerson(null)
                setBeneficialOwnerType(e.target.value)
            }} value={beneficialOwnerType} >
                <div>
                    <Radio value={1}>{props.beneficiarySectionType == BeneficiarySectionType.Kunde ? "der Kunde ist der wirtschaftlich Berechtigte" : "der Bürge ist der wirtschaftlich Berechtigte"}</Radio>
                </div>
                <div>
                    <Radio value={2}>es gibt keinen Wirtschaftlich Berechtigten</Radio>
                </div>
                <div>
                    <Radio value={3}>es gibt folgende Wirtschaftlich Berechtigte</Radio>
                </div>
                <div>
                    {beneficialOwnerType ===3 && (props.beneficiaries && props.beneficiaries.length === 0) ? <span style={{color: '#FF7A45', marginLeft: '23px'}}>Es wurden noch keine Gesprächspartner hinzugefügt, welche als wirtschaftlich Berechtigte markiert sind.</span> : ''}
                </div>
            </Radio.Group>
            {beneficialOwnerType === 3 ? <div className={styles.beneficiariesList}>
                <ListContacts
                    contacts={beneficiaries.map(o => {
                        return {
                            ...o,
                            selected: o.akfContactPersonId === (selectedContactPerson || {}).akfContactPersonId,
                            tick: o.includedInContract,
                            contactName: `${o.firstName} ${o.lastName}`
                        }
                    })}
                    onClick={(o) => {
                        if (beneficialOwnerType === BeneficialOwnerType.FOLLOWING_BENEFICIAL_OWNER) {
                            setSelectedContactPerson(o)
                        }
                    }}
                />
            </div> : null }
            <br />
            {selectedContactPerson && beneficialOwnerType === BeneficialOwnerType.FOLLOWING_BENEFICIAL_OWNER && selectedContactPerson.akfContactPersonId ?
                <React.Fragment>
                    <div className={styles.insuranceOfferTable}>
                        <table>
                            <tr>
                                <td>Vorname:</td>
                                <td className={styles.notEditable}>{selectedContactPerson.firstName}</td>
                            </tr>
                            <tr>
                                <td>Nachname:</td>
                                <td className={styles.notEditable}>{selectedContactPerson.lastName}</td>
                            </tr>
                            <tr>
                                <td>Strasse + Hausnummer:</td>
                                <td className={styles.notEditable}>{`${selectedContactPerson.addressLine1 || ''} ${selectedContactPerson.addressStreetNumber || ''}`}</td>
                            </tr>
                            <tr>
                                <td>Postleitzahl</td>
                                <td className={styles.notEditable}>{selectedContactPerson.addressPostalCode}</td>
                            </tr>
                            <tr>
                                <td>Stadt</td>
                                <td className={styles.notEditable}>{selectedContactPerson.city}</td>
                            </tr>
                            <tr>
                                <td>Geburtstdatum</td>
                                <td className={styles.notEditable}>{selectedContactPerson.birthDate}</td>
                            </tr>
                            <tr>
                                <td>Steuer-ID</td>
                                <td className={styles.notEditable}>{selectedContactPerson.taxId}</td>
                            </tr>
                            <tr>
                                <td>Beteiligungsquote / Anteile / Stimmrechte</td>
                                <td>
                                    <Form.Item
                                        validateStatus=''
                                        className={styles.formItem}
                                    >
                                        <Input
                                            size='small'
                                            value={selectedContactPerson.shares}
                                            placeholder={'25'} onChange={(e) => {
                                                const foundBeneficiar = beneficiaries.find(b => b.akfContactPersonId === selectedContactPerson?.akfContactPersonId)
                                                if (foundBeneficiar) {
                                                    foundBeneficiar.shares =  e.target.value;
                                                    setSelectedContactPerson(foundBeneficiar);
                                                }
                                                setBeneficiaries([...beneficiaries])
                                            }}></Input>
                                    </Form.Item></td>
                            </tr>
                        </table>
                        <br />
                        <div>
                                    <Checkbox checked={selectedContactPerson.includedInContract} onChange={(e) => { 
                                            const foundBeneficiar = beneficiaries.find(b => b.akfContactPersonId === selectedContactPerson?.akfContactPersonId)
                                            if (foundBeneficiar) {
                                                foundBeneficiar.includedInContract = e.target.checked;
                                                setSelectedContactPerson(foundBeneficiar);
                                            }
                                            
                                            setBeneficiaries([...beneficiaries])
                                        }}>
                                        Diesen Gesprächspartner als wirtschaftlich Berechtigten andrucken. 
					                </Checkbox>
                             </div>
                    </div>
                    <br />
                </React.Fragment> : null
            }
            <div className={styles.buttonsOnRight}>
                <Button data-cy="button_save"
                    type="primary"
                    size="small"
                    onClick={() => props.onSaveClicked(beneficiaries, beneficialOwnerType)}>
                    <CheckOutlined /> Speichern
						</Button>
            </div>
        </CollapsibleSection>
    );
}

