import CollapsibleSection from "components/common/presenters/collapsible-section/CollapsibleSection";
import React, {useEffect, useState} from "react";
import styles from "components/common/presenters/application-page-tabs/contracts-tab/InsuranceSection.module.scss";
import {ListObjects} from "components/common/presenters/list-objects/ListObjects";
import {Button, Checkbox, Col, Form, Input, message, Radio, Row, Select} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import moment from "moment";
import ApiManager from "services/api/ApiManager";
import {machineTypesOptions} from "components/common/presenters/application-page-tabs/objects-tab/ObjectsTab";
import {Store, useStore} from "../../../../../hooks/Store";
import {AnchoredSelect} from "../../anchored-select/AnchoredSelect";
import {deductibleValue, deductibleValues, movableMachineTypesList, PriceType, stationaryMachineTypesList} from "components/common/presenters/application-page-tabs/contracts-tab/insuranceService";
import insuranceCalculator from "./insuranceService"

export const InsuranceSection = (props) => {
    const [selectedObject, setSelectedObject] = useState(null);

    return (
        <CollapsibleSection text="Versicherungsangebot" initialCollapsedState={true}>
            <ListObjects
                objects={props.selectedContractTemplate.objects.map(o => {return {...o, selected: o.objectId === selectedObject?.objectId}})}
                onClick={(o) => {setSelectedObject(selectedObject?.objectId === o.objectId ? null : o)}}
            />
            {selectedObject?.objectId ?
                <InsuranceOfferTable
                    selectedObject={selectedObject}
                    onObjectUpdated={props.onDataUpdated}
                /> : null}
        </CollapsibleSection>
    );
}


const InsuranceOfferTable = (props) => {
    const store: Store = useStore()

    const initialState = {
        insuranceQuote: false,
        yearBuilt: null,
        objectType: null,
        machineType: null,
        priceType: null,
        vehicleLetterAvailable: null,
        deductible: null
    };
    const [objectFields, setObjectFields] = useState({
        ...initialState
    });

    const [ratePerMonth, setRatePerMonth] = useState(null);


    useEffect(() => {
        setObjectFields({
            insuranceQuote: props.selectedObject.insuranceQuote,
            yearBuilt: props.selectedObject.yearBuilt,
            objectType: props.selectedObject.objectType,
            machineType: props.selectedObject.machineType,
            priceType: props.selectedObject.priceType,
            vehicleLetterAvailable: props.selectedObject.vehicleLetterAvailable,
            deductible: props.selectedObject.deductible
        });
        setRatePerMonth(calculateMontlyRate(props.selectedObject));
    }, [props.selectedObject]);

    useEffect(() => {
        setRatePerMonth(calculateMontlyRate(objectFields))
      }, [objectFields.objectType, objectFields.machineType, objectFields.yearBuilt, objectFields.vehicleLetterAvailable, objectFields.deductible])

    const enabledObjectTypes = store.data?.settings?.objectGroups?.filter(o => o.isEnabled) || []

    async function onSaveObject() {
        try {
            const data = {
                ...objectFields,
                yearBuilt: objectFields.yearBuilt ? (objectFields.yearBuilt.length === 4 ? `${objectFields.yearBuilt}-01-01` : objectFields.yearBuilt) : undefined
            };

            const response = await ApiManager.ApplicationService.editObject(data, props.selectedObject.objectId);

            message.success('Daten Gespeichert', 2);
            props.onObjectUpdated(response.data);
        } catch (error) {
            console.error(error);
            console.error(error);
            const errorText = error?.response?.data?.errors?.join(' ');
            message.error('Konnte nicht Speichern' + ' ' + errorText, 2);
        }
    }

    function calculateMontlyRate({yearBuilt, objectType, machineType, vehicleLetterAvailable, deductible}) {
        if(yearBuilt && objectType && machineType && vehicleLetterAvailable && deductible) {
            try {
                const calculation = insuranceCalculator.calculateInsurance({
                    purchasePrice: props.selectedObject.price,
                    priceType: PriceType.Netto,
                    deductible: parseFloat(deductible) as deductibleValue,
                    constructionYear: parseInt(yearBuilt.substring(0,4)),
                    constructionMonth: 1,
                    vehicleLetterAvailable: vehicleLetterAvailable,
                    objectType: objectType,
                    machineType: machineType
                })
                return calculation.ratePerMonth;
            } catch (error) {

                return error.toString();
            }

        } else {
           return null;
        }
    }

    return (
        <React.Fragment>
            <div className={styles.insuranceOfferTable}>
                <br />
                <div>
                    <Checkbox
                        checked={objectFields.insuranceQuote}
                        onChange={(e) => {
                            const isChecked = e.target.checked;

                            setObjectFields({
                                ...objectFields,
                                insuranceQuote: isChecked,
                            });
                        }}
                    >
                        Versicherungsangebot für dieses Objekt erstellen
                    </Checkbox>
                </div>
                <br />
                {objectFields.insuranceQuote ?
                <table>
                    <tr>
                        <td>Objekt</td>
                        <td className={styles.notEditable}>
                            {props.selectedObject.objectName}
                        </td>
                    </tr>
                    <tr>
                        <td>Objekt SN</td>
                        <td className={styles.notEditable}>
                            {props.selectedObject.serialNumber}
                        </td>
                    </tr>
                    <tr>
                        <td>Baujahr</td>
                        <td>
                            <Form.Item
                                validateStatus={
                                    objectFields.yearBuilt
                                        ? moment(
                                              objectFields.yearBuilt?.substr(
                                                  0,
                                                  4
                                              ),
                                              "YYYY",
                                              true
                                          ).isValid()
                                            ? ""
                                            : "error"
                                        : ""
                                }
                                className={styles.formItem}
                            >
                                <Input
                                    size="small"
                                    value={objectFields.yearBuilt?.substr(0, 4)}
                                    placeholder={"YYYY"}
                                    onChange={(e) => {
                                        setObjectFields({
                                            ...objectFields,
                                            yearBuilt: e.target.value.substr(
                                                0,
                                                4
                                            ),
                                        });
                                    }}
                                ></Input>
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td>Kaufpreis (netto)</td>
                        <td className={styles.notEditable}>
                            {props.selectedObject.price}
                        </td>
                    </tr>
                    <tr>
                        <td>Objekt Type</td>
                        <td>
                            <Radio.Group
                                onChange={(e) => {
                                    setObjectFields({
                                        ...objectFields,
                                        objectType: e.target.value,
                                        machineType: null
                                    });
                                }}
                                value={objectFields.objectType}
                            >
                                <Radio value="movable">Fahrbar</Radio>
                                <Radio value="stationary">Stationär</Radio>
                            </Radio.Group>
                            {/* <Form.Item
                                validateStatus=''
                                className={styles.formItem}
                            >

                                <AnchoredSelect data-cy="dropdown_object_type"
                                    value={objectFields.objectTypeId}
                                    onChange={(e) => setObjectFields({...objectFields, objectTypeId: e})}
                                    showSearch={true}
                                    optionFilterProp={"children"}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        enabledObjectTypes.map((ot, i) =>
                                            <Select.Option data-cy={`dropdown_object_type_option_${i}`} key={i} value={ot.objectTypeId}>{ot.description}</Select.Option>
                                        )
                                    }
                                </AnchoredSelect>
                            </Form.Item> */}
                        </td>
                    </tr>
                    <tr>
                        <td>Maschinenart</td>
                        <td>
                            <Form.Item
                                validateStatus=""
                                className={styles.formItem}
                            >
                                <AnchoredSelect
                                    data-cy="dropdown_machine_type"
                                    value={objectFields.machineType}
                                    onChange={(e) =>
                                        setObjectFields({
                                            ...objectFields,
                                            machineType: e,
                                        })
                                    }
                                >
                                    {objectFields.objectType && (objectFields.objectType == 'movable' ? movableMachineTypesList : stationaryMachineTypesList).map((k, i) =>
        <Select.Option data-cy={`machine_types_option_${i}`} key={i} value={k}>{k}</Select.Option>)
                                    }
    )
                                </AnchoredSelect>
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td>Kfz Brief Vorhanden?</td>
                        <td>
                            <Radio.Group
                                onChange={(e) => {
                                    setObjectFields({
                                        ...objectFields,
                                        vehicleLetterAvailable: e.target.value,
                                    });
                                }}
                                value={objectFields.vehicleLetterAvailable}
                            >
                                <Radio value={true}>Ja</Radio>
                                <Radio value={false}>Nein</Radio>
                            </Radio.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>Selbstbehalt</td>
                        <td>
                            <Form.Item
                                validateStatus=""
                                className={styles.formItem}
                            >
                                <AnchoredSelect
                                    data-cy="dropdown_deductible"
                                    value={objectFields.deductible}
                                    onChange={(e) =>
                                        setObjectFields({
                                            ...objectFields,
                                            deductible: e,
                                        })
                                    }
                                >
                                    {deductibleValues.map(d => <Select.Option
                                        data-cy={`deductible_option_${d}`}
                                        key={d}
                                        value={d.toString()}
                                    >
                                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(d).substring(0, new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(d).length-5)} EUR
                                    </Select.Option>)}
                                    
                                </AnchoredSelect>
                            </Form.Item>
                        </td>
                    </tr>
                </table> : null }
            </div>
            <br />
            <Row gutter={[0, 32]} align="bottom">
                <Col span={12}>
                {objectFields.insuranceQuote ?
                    <div className={styles.insuranceOfferTable}>
                        <table className={styles.noBorder}>
                            <tr className={styles.borderBottom}>
                                <td className={styles.bold}>Ratenberechnung</td>
                            </tr>
                            <tr className={styles.borderBottom}>
                                <td>Monatliche Rate:</td>
                                <td>{ratePerMonth ? (typeof ratePerMonth == 'string' ? ratePerMonth : new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ratePerMonth) + ' EUR') : ''}</td>
                            </tr>
                        </table>
                    </div> : null }
                </Col>
                <Col span={12}>
                    <div className={styles.buttonsOnRight}>
                        <Button
                            className={styles.buttonsSpace}
                            type="default"
                            size="small"
                            onClick={() => {
                                setObjectFields({ ...initialState });
                            }}
                        >
                            Löschen
                        </Button>
                        <Button
                            data-cy="button_save"
                            type="primary"
                            size="small"
                            onClick={onSaveObject}
                        >
                            <CheckOutlined /> Speichern
                        </Button>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}
