import CollapsibleSection from "components/common/presenters/collapsible-section/CollapsibleSection";
import React, {useEffect, useState} from "react";
import {Radio, Checkbox, Input, Button, message} from "antd";
import styles from "components/common/presenters/application-page-tabs/contracts-tab/InsuranceStatmentSection.module.scss";
import {CheckOutlined} from "@ant-design/icons";
import ApiManager from "services/api/ApiManager";

export const InsuranceStatmentSection = (props) => {
    const initialObject = {
        typeOfAddmission: 0,
        insuranceType: {
            sendNonBindingInsuranceOffer: false,
            insuranceToBeIncluded: false,
            insuranceOurselves: false,
            inExistingFrameworkInsurance: false,
            inIndividualContract: false,
            volkaskoInsurance: false,
            fullInsurance: false,
            theftInsurance: false,
            heilInsurance: false
        },
        nameOfInsuranceCompany: '',
        addressOfInsuranceCompany: '',
        insuranceNumber: ''
    };
    const [dataFields, setDataFields] = useState({...initialObject});

    useEffect(() => {
        setDataFields({
            typeOfAddmission: props.selectedContractTemplate.typeOfAddmission,
            insuranceType: {
                sendNonBindingInsuranceOffer: props.selectedContractTemplate.sendNonBindingInsuranceOffer,
                insuranceToBeIncluded: props.selectedContractTemplate.insuranceToBeIncluded,
                insuranceOurselves: props.selectedContractTemplate.insuranceOurselves,
                inExistingFrameworkInsurance: props.selectedContractTemplate.inExistingFrameworkInsurance,
                inIndividualContract: props.selectedContractTemplate.inIndividualContract,
                volkaskoInsurance: props.selectedContractTemplate.volkaskoInsurance,
                fullInsurance: props.selectedContractTemplate.fullInsurance,
                theftInsurance: props.selectedContractTemplate.theftInsurance,
                heilInsurance: props.selectedContractTemplate.heilInsurance
            },
            nameOfInsuranceCompany: props.selectedContractTemplate.nameOfInsuranceCompany,
            addressOfInsuranceCompany: props.selectedContractTemplate.addressOfInsuranceCompany,
            insuranceNumber: props.selectedContractTemplate.insuranceNumber
        });
    }, [props.selectedContractTemplate]);

    async function onSaveObject() {
        try {
            const data = {
                ...dataFields,
                ...dataFields.insuranceType
            };
            delete data.insuranceType;

            await ApiManager.ApplicationService.patchContractDraft(data, props.selectedContractTemplate.contractDraftId);

            message.success('Daten Gespeichert', 2);
            props.onDataUpdated();
        } catch (error) {
            console.error(error);
            console.error(error);
            const errorText = error?.response?.data?.errors?.join(' ');
            message.error('Konnte nicht Speichern' + ' ' + errorText, 2);
        }
    }

    return (
        <CollapsibleSection text="Versicherungserklärung" initialCollapsedState={true}>
            <div className={styles.insuranceStatments}>
                <div>Art der Zulassung</div>
                <Radio.Group onChange={(e) => setDataFields({...dataFields, typeOfAddmission: e.target.value})} value={dataFields.typeOfAddmission} >
                    <div>
                        <Radio value={1}>Zulassungsbescheinigung Teil II (Kfz-Brief)</Radio>
                    </div>
                    <div>
                        <Radio value={2}>Einzelgenehmigung / Ausnahmegenehmigung</Radio>
                    </div>
                    <div>
                        <Radio value={3}>TÜV-Gutachten ohne Strassenzulasung</Radio>
                    </div>
                </Radio.Group>
                <br />
                <div>Versicherungsart</div>
                <div>
                    <div>
                        <Checkbox checked={dataFields.insuranceType.sendNonBindingInsuranceOffer} onChange={(e) => setDataFields({...dataFields, insuranceType: {...dataFields.insuranceType, sendNonBindingInsuranceOffer: e.target.checked}})}>Bitte senden Sie ein unverbindliches Versicherungsangebot zu</Checkbox>
                    </div>
                    <div>
                        <Checkbox checked={dataFields.insuranceType.insuranceToBeIncluded} onChange={(e) => setDataFields({...dataFields, insuranceType: {...dataFields.insuranceType, insuranceToBeIncluded: e.target.checked}})}>Wir wünschen den Einschluss der angebotenen Versicherung</Checkbox>
                    </div>
                    <div>
                        <Checkbox checked={dataFields.insuranceType.insuranceOurselves} onChange={(e) => setDataFields({...dataFields, insuranceType: {...dataFields.insuranceType, insuranceOurselves: e.target.checked}})}>Wir versichern selbst</Checkbox>
                    </div>
                    <div className={styles.indentation}>
                        <div>
                            <Checkbox checked={dataFields.insuranceType.inExistingFrameworkInsurance} onChange={(e) => setDataFields({...dataFields, insuranceType: {...dataFields.insuranceType, inExistingFrameworkInsurance: e.target.checked}})}>Einschluss in bereits bestehende Rahmenversicherung</Checkbox>
                        </div>
                        <div>
                            <Checkbox checked={dataFields.insuranceType.inIndividualContract} onChange={(e) => setDataFields({...dataFields, insuranceType: {...dataFields.insuranceType, inIndividualContract: e.target.checked}})}>Abschluss einer Maschinen(bruch)versicherung im Einzelvertac</Checkbox>
                        </div>
                        <div>
                            <Checkbox checked={dataFields.insuranceType.volkaskoInsurance} onChange={(e) => setDataFields({...dataFields, insuranceType: {...dataFields.insuranceType, volkaskoInsurance: e.target.checked}})}>Abschluss einer Vollkasko-Versicherung</Checkbox>
                        </div>
                        <div>
                            <Checkbox checked={dataFields.insuranceType.fullInsurance} onChange={(e) => setDataFields({...dataFields, insuranceType: {...dataFields.insuranceType, fullInsurance: e.target.checked}})}>Abschluss einer Teilkasko-Versicherung in Verbindung mi dem Abschluss einer Maschinen(bruch)versicherung</Checkbox>
                        </div>
                        <div>
                            <Checkbox checked={dataFields.insuranceType.theftInsurance} onChange={(e) => setDataFields({...dataFields, insuranceType: {...dataFields.insuranceType, theftInsurance: e.target.checked}})}>Abschluss einer Feuer -, Einbruch-, Diebstahlversicherung</Checkbox>
                        </div>
                        <div>
                            <Checkbox checked={dataFields.insuranceType.heilInsurance} onChange={(e) => setDataFields({...dataFields, insuranceType: {...dataFields.insuranceType, heilInsurance: e.target.checked}})}>Sturm-, Hagel-Versicherung</Checkbox>
                        </div>
                    </div>
                    <br />
                    <div className={styles.insuranceCompanyInfo}>
                        <div>
                            <h4>Name Ihrer Versicherungsgesellschaft</h4>
                            <Input value={dataFields.nameOfInsuranceCompany} placeholder="" onChange={(e) => setDataFields({...dataFields, nameOfInsuranceCompany: e.target.value})}></Input>
                        </div>
                        <div>
                            <h4>Anschrift Ihrer Versicherungsgesellschaft</h4>
                            <Input.TextArea rows={3} value={dataFields.addressOfInsuranceCompany} placeholder="" onChange={(e) => setDataFields({...dataFields, addressOfInsuranceCompany: e.target.value})}></Input.TextArea>
                        </div>
                        <div>
                            <h4>Versicherungsscheinnummer (falls bereits vorhanden)</h4>
                            <Input value={dataFields.insuranceNumber} placeholder="" onChange={(e) => setDataFields({...dataFields, insuranceNumber: e.target.value})}></Input>
                        </div>

                    </div>
                    <br />
                    <div className={styles.buttonsOnRight}>
                        <Button
                            className={styles.buttonsSpace}
                            type="default"
                            size="small"
                            onClick={() => {setDataFields({...initialObject})}}>
                            Zurücksetzen
						</Button>
                        <Button data-cy="button_save"
                            type="primary"
                            size="small"
                            onClick={onSaveObject}>
                            <CheckOutlined /> Speichern
						</Button>
                    </div>
                </div>
            </div>
        </CollapsibleSection>
    );
}
