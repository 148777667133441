import ApiManager from "../services/api/ApiManager";
import {message} from "antd";
import {Store, useStore} from "./Store";
import {BoUserProfile} from "../services/api/types";
import {useTranslation} from "react-i18next";

export interface Auth {
	profile: BoUserProfile
	logIn: (username: string, password: string) => Promise<void>
	logOut: () => Promise<void>
    registerBoUser: (token: string, password: string) => Promise<any>
	checkAuth: () => Promise<boolean>
    loopRefreshSession: () => Promise<void>
}

export const useAuth = (): Auth => {
    const {t} = useTranslation()
    const store: Store = useStore()

	const logIn = async (username: string, password: string) => {
		try {
			await ApiManager.UserService.loginBoUser(username, password);
            fetchProfile()
            fetchSettings()
		} catch (e) {
			console.error(e)
            if (e.response?.status === 403) {
                message.error(t('errors:account_disabled'), 2)
            } else {
                message.error(t('errors:could_not_login'), 2)
            }
		}
	}

	const logOut = async () => {
		try {
            const existingTimeoutId = loadTimeoutId()
            clearTimeout(existingTimeoutId)

			store.reset()
			await ApiManager.UserService.logoutBoUser()
		} catch (e) {
			console.error(e)
			message.error("Error during logout.")
		}
	}

    const registerBoUser = async (token: string, password: string) => {
        try {
            return await ApiManager.UserService.registerBoUser(token, password);
		} catch (e) {
			console.error(e)
			message.error("Error during reseting password.")
		}
    };

	const checkAuth = async (): Promise<boolean> => {
		try {
			await ApiManager.UserService.checkBoUserAuth();

			if (store.isEmpty()) {
				fetchProfile()
				fetchSettings()
			}

			return true
		} catch (error) {
			console.error(`checkAuth failed: ${error.message}`);
			return false
		}
	}

    const loopRefreshSession = async (): Promise<void> => {
        const existingTimeoutId = loadTimeoutId()

        if (existingTimeoutId) {
            clearTimeout(existingTimeoutId)
        }

        try {
            await ApiManager.UserService.refreshSession()
        } catch (e) {
            console.log("session refresh failed")
            console.error(e)
            return
        }

        const timeoutId = setTimeout(loopRefreshSession, 50 * 60 * 1000)

        saveTimeoutId(timeoutId)
    }

	const fetchProfile = async () => {
		const profileResponse = await ApiManager.UserService.getProfileInfo()
		store.setData({
			profile: {
				...profileResponse
			}
		})
	}

	const fetchSettings = async () => {
		const result = await ApiManager.UserService.getSettings()
		store.setData({
			settings: {
				...result.data
			}
		})
	}

	return {
		profile: store.data.profile,
		logIn,
		logOut,
        registerBoUser,
		checkAuth,
        loopRefreshSession,
	} as Auth
}

const saveTimeoutId = (timeoutId) => {
    localStorage.setItem("session_refresh_timeout_id", timeoutId)
}

const loadTimeoutId = (): any => {
    return localStorage.getItem("session_refresh_timeout_id")
}
