import moment, {Moment} from "moment";
import {currencyLocaleFormatter} from "./formatters";
import {DATE_FORMAT} from "../services/api/Globals";
import {CalculatorLoanObject} from "../services/calculator/types";
import {getNumberOfPayments} from "../services/calculator/calculation-actions/loanObjectGetters";

export const getRepaymentPlanDates = (loanObject: CalculatorLoanObject): Moment[] => {
	const startDate: Moment = moment(loanObject.dateOfFirstPayment, DATE_FORMAT.API)
	const numberOfPayments = getNumberOfPayments(loanObject)
	const monthsStep = 12 / loanObject.paymentsPerYear

	const result: Moment[] = []

	for (let i = 0; i < numberOfPayments; i++) {
		const dateStep = moment(startDate, DATE_FORMAT.API).add(i * monthsStep, "months")
		result.push(dateStep);
	}

	return result;
};

export const roundTo2DecimalPlaces = (num: number): number => {
	return Math.round(num * 100 + Number.EPSILON) / 100;
};

export const customParseFloat = (input: any, isCurrency?: boolean): number => {
	try {
		if (input == null) {
			return null;
		}

		if (typeof input === 'number') {
			return input;
		}

		let num,
			commaSeparator: ',' | '.';


		num = input.replace("€", "");
		num = num.replace("%", "");
	
		if (isCurrency) {
			// TODO: Uncomment and remove commaSeparator statement from below when i18n is fixed
			// if (i18n.language === "en") {
			// 	commaSeparator = ",";
			// } else {
			// 	commaSeparator = ".";
			// }
			commaSeparator = ".";
			
			num = num.split(commaSeparator).join("");
			num = num.replace(/,/g, ".");

			num = parseFloat(num);
		} else {
			num = parseFloat(input);
		}
	
		if (isNaN(num)) {
			num = null;
		}
	
		// console.log(commaSeparator, input, num);
		return num;
	} 
	catch (err) {
		console.error(err);
		// console.log(input);
		return input as any;
	}
};

export const customParseString = (input: any, formatAsCurrency?: boolean): string => {
	try {
		if (input == null) {
			return "";
		}
	
		let str = `${input}`;
	
		if (formatAsCurrency) {
			str = `€${currencyLocaleFormatter(input)}`;
		}
	
		return str;
	} catch (err) {
		console.log(err);
		return input;
	}
};

