import * as React from "react";
import {useEffect, useState} from "react";
import styles from "components/common/presenters/calculator/Calculator.module.scss";
import classnames from "classnames";
import {Button, Checkbox, Col, DatePicker, Divider, InputNumber, message, Radio, Row, Space, Tooltip} from "antd";
import {CalculatorOutlined, CheckOutlined, RedoOutlined} from "@ant-design/icons";
import {RatesAdjustmentForm} from "components/common/presenters/calculator/RatesAdjustmentForm";
import {CalculatorState, PaymentsPerYear, RepaymentPlanInstallment} from "services/calculator/types";
import {calculateRepaymentPlan} from "services/calculator/calculation-actions/calculateRepaymentPlan";
import {LocalizedNumberInput} from "../localized-number-input/LocalizedNumberInput";
import {useTranslation} from "react-i18next";
import {initCalculatorState} from "../../../../services/calculator/defaults";
import {DATE_FORMAT} from "../../../../services/api/Globals";
import moment from "moment";
import {roundToDecimalPoints} from "../../../../helpers/numberHelpers";
import {
    reCalculateCommissionFromDesiredMonthlyRate,
    reCalculateDiscountKpFromDesiredMonthlyRate,
    reCalculateDownPaymentFromDesiredMonthlyRate,
    reCalculateInterestRateFromDesiredMonthlyRate,
    reCalculateRemainingValueFromDesiredMonthlyRate
} from "../../../../services/calculator/calculation-actions/reCalculateFromCustomMonthlyRate";
import {
    getRunUpTime,
    getBgInternal,
    getCashMargin,
    getCashMarginPercentage,
    getCommissionPercentage,
    getCustomerInterestRate,
    getDiscountKpPercentage,
    getDiscountPercentage,
    getDownpaymentPercentage,
    getMonthlyRate,
    getNominalInterestRate,
    getPANGVnterestRate,
    getPeriodRate,
    getRemainingValuePercentage,
    getWorkGrantPercentage,
    getBgInternal2
} from "../../../../services/calculator/calculation-actions/loanObjectGetters";
import ApiManager from "../../../../services/api/ApiManager";
import {Calculation} from "../../../../services/api/types";

export interface CalculatorProps {
    isExampleCalculator: boolean
    applicationId?: number
    userId?: number
    selectedCalculation?: Calculation
    setActiveTabIndex?: (index: string) => void
    onDataChange?: () => void
}

export const Calculator = (props: CalculatorProps) => {
    const {t} = useTranslation();

    const defaultState = initCalculatorState()
    const [state, setState] = useState(defaultState as CalculatorState);

    useEffect(() => {
        if (props.selectedCalculation) {
            setState({
                isSelectedCalculationJustLoaded: true,
                isLocked: props.selectedCalculation.isBoundToAnyOffer,
                loanObject: {
                    loanAmount: props.selectedCalculation.loanAmount,
                    loanDuration: props.selectedCalculation.loanDuration,
                    customMonthlyRate: props.selectedCalculation.customMonthlyRate,
                    acquisitionCost: props.selectedCalculation.acquisitionCost,
                    annualInterestRate: props.selectedCalculation.annualInterestRate,
                    discountKp: props.selectedCalculation.discountKp,
                    workGrant: props.selectedCalculation.workGrant,
                    downpayment: props.selectedCalculation.downpayment,
                    commission: props.selectedCalculation.commission,
                    discount: props.selectedCalculation.discount,
                    remainingValue: props.selectedCalculation.remainingValue,
                    hedgedProceeds: props.selectedCalculation.hedgedProceeds,
                    additionalProceeds: props.selectedCalculation.additionalProceeds,
                    contractStartAt: props.selectedCalculation.contractStartAt,
                    dateOfFirstPayment: props.selectedCalculation.dateOfFirstPayment,
                    paidOutAt: props.selectedCalculation.paidOutAt,
                    paymentsPerYear: props.selectedCalculation.paymentsPerYear,
                    runUpTime: props.selectedCalculation.runUpTime,
                    prePay: props.selectedCalculation.prePay,
                    discountKpPercentage: props.selectedCalculation.discountKpPercentage,
                    discountPercentage: props.selectedCalculation.discountPercentage,
                    downpaymentPercentage: props.selectedCalculation.downpaymentPercentage,
                    workGrantPercentage: props.selectedCalculation.workGrantPercentage,
                    commissionPercentage:  props.selectedCalculation.commissionPercentage,
                    remainingValuePercent: props.selectedCalculation.remainingValuePercent,
                    bgInternal: props.selectedCalculation.bgInternal,
                },
                repaymentPlan: props.selectedCalculation.repaymentPlan?.length > 0 ?
                    props.selectedCalculation.repaymentPlan.map(p => {
                        return {
                            isChecked: p.amount !== 0,
                            date: moment(p.date),
                            amount: p.amount,
                            isCustomAmount: p.isCustomAmount,
                        } as RepaymentPlanInstallment
                    })
                    : [],
            })
        } else {
            setState(defaultState)
        }
    }, [props.selectedCalculation])

    useEffect(() => {
        if (state.isSelectedCalculationJustLoaded) {
            setState({...state, isSelectedCalculationJustLoaded: false})
        } else {
            const repaymentPlan = calculateRepaymentPlan(state.loanObject);
            setState({...state, repaymentPlan: repaymentPlan});
        }
    }, [state.loanObject])

    const recalculateInterestRateByDesiredMonthlyRate = () => {
        let newLoanObject = reCalculateInterestRateFromDesiredMonthlyRate(state.loanObject);

        setState(prevState => {
            return {...prevState, loanObject: newLoanObject}
        })
    };

    const recalculateDiscountKpByDesiredMonthlyRate = () => {
        let newLoanObject = reCalculateDiscountKpFromDesiredMonthlyRate(state.loanObject);

        setState(prevState => {
            return {...prevState, loanObject: newLoanObject}
        })
    };

    const recalculateDownPaymentByDesiredMonthlyRate = () => {
        let newLoanObject = reCalculateDownPaymentFromDesiredMonthlyRate(state.loanObject);

        setState(prevState => {
            return {...prevState, loanObject: newLoanObject}
        })
    };

    const reCalculateCommissionByDesiredMonthlyRate = () => {
        let newLoanObject = reCalculateCommissionFromDesiredMonthlyRate(state.loanObject);

        setState(prevState => {
            return {...prevState, loanObject: newLoanObject}
        })
    };

    const recalculateRemainingValueByDesiredMonthlyRate = () => {
        let newLoanObject = reCalculateRemainingValueFromDesiredMonthlyRate(state.loanObject);

        setState(prevState => {
            return {...prevState, loanObject: newLoanObject}
        })
    };

    const handleReset = () => {
        const resetState = initCalculatorState();
        setState(resetState)
    };

    const handleSave = async (): Promise<boolean> => {
        const reqPayload = {
            ...state.loanObject,
            discountKpPercentage: state.loanObject.discountKpPercentage || 0,
            discountPercentage: getDiscountPercentage(state.loanObject) || 0,
            downpaymentPercentage: state.loanObject.downpaymentPercentage || 0,
            workGrantPercentage: state.loanObject.workGrantPercentage || 0,
            commissionPercentage: state.loanObject.commissionPercentage || 0,
            remainingValuePercent: state.loanObject.remainingValuePercent || 0,
            bgInternal: getBgInternal(state.loanObject) || 0,
            runUpTime: getRunUpTime(state.loanObject) || 0,
            monthlyRate: getMonthlyRate(state.loanObject) || 0,
            periodRate: getPeriodRate(state.loanObject) || 0,
            customerInterestRate: getCustomerInterestRate(state.loanObject) || 0,
            nominalInterestRate: getNominalInterestRate(state.loanObject) || 0,
            pangvInterestRate: getPANGVnterestRate(state.loanObject) || 0,
            cashMargin: getCashMargin(state.loanObject) || 0,
            cashMarginPercentage: getCashMarginPercentage(state.loanObject) || 0,
            applicationId: props.applicationId,
            userId: props.userId,
            repaymentPlan: state.repaymentPlan.map(p => {
                return {
                    date: p.date.format(DATE_FORMAT.API),
                    amount: p.amount,
                    isCustomAmount:  p.amount == 0 ? true : p.isCustomAmount
                }
            })
        }

        try {
            let res = null;
            if (props.selectedCalculation) {
                res = await ApiManager.ApplicationService.updateCalculation(props.selectedCalculation.calculationId, reqPayload)
            } else {
                res = await ApiManager.ApplicationService.createCalculation(reqPayload)
            }
            console.log('Response: ', res)
            message.success('Daten Gespeichert', 2)
            props.onDataChange()

            return true
        } catch (e) {
            console.error(e)
            message.error('Konnte nicht Speichern', 2)
            return false
        }
    };

    const positionedCalcStyles = classnames(styles.iconCalcPositioned, {
        [styles.iconCalcPositionedGrey]: !state.loanObject.customMonthlyRate || state.isLocked,
    });

    return (
        <div className={styles.calc}>
            <div className={styles.calcInner}>
                <Row>
                    <Col span={12}>
                        <Row gutter={[24, 24]}>
                            <Col>
                                <h4>Kaufpreis</h4>
                                <LocalizedNumberInput
                                    data-cy="input_loan_amount"
                                    prefix={t('currency_sign')}
                                    disabled={state.isLocked}
                                    value={state.loanObject.loanAmount}
                                    precision={2}
                                    onChange={(e) => {
                                        setState(prevState => {
                                            return {...prevState, loanObject: {...prevState.loanObject, loanAmount: e}}
                                        })
                                    }}
                                />
                            </Col>
                            <Col>
                                <h4>Zinsrate</h4>
                                <LocalizedNumberInput
                                    data-cy="input_annual_interest_rate"
                                    prefix={'%'}
                                    disabled={state.isLocked}
                                    value={state.loanObject.annualInterestRate}
                                    precision={6}
                                    onChange={(e) => {
                                        setState(prevState => {
                                            return {...prevState, loanObject: {...prevState.loanObject, annualInterestRate: e}}
                                        })
                                    }}
                                />
                            </Col>
                            <Col>
                                <Tooltip placement={"top"}
                                    title={t('recalculate_with_desired_monthly_rate')}
                                >
                                    <CalculatorOutlined data-cy="button_recalc_annual_interest_rate"
                                        className={positionedCalcStyles}
                                        onClick={() => {
                                            if (state.loanObject.customMonthlyRate) {
                                                recalculateInterestRateByDesiredMonthlyRate()
                                            }
                                        }}
                                    />
                                </Tooltip>

                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <h4>Laufzeit</h4>
                                <InputNumber
                                    data-cy="input_loan_duration"
                                    className={styles.minWidthInput}
                                    disabled={state.isLocked}
                                    value={state.loanObject.loanDuration}
                                    step={6}
                                    min={0}
                                    onChange={(e) => {
                                        setState(prevState => {
                                            return {...prevState, loanObject: {...prevState.loanObject, loanDuration: e as number}}
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <h4>PPY</h4>
                        <Radio.Group data-cy="radio_ppy"
                            disabled={state.isLocked}
                            value={state.loanObject.paymentsPerYear}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, paymentsPerYear: e.target.value}}
                                })
                            }}
                        >
                            <Radio data-cy="radio_ppy_monthly" value={PaymentsPerYear.Monthly}>Monatlich</Radio>
                            <Radio data-cy="radio_ppy_quarterly" value={PaymentsPerYear.Quarterly}>Quartal</Radio>
                            <Radio data-cy="radio_ppy_yearly" value={PaymentsPerYear.Yearly}>Jährlich</Radio>
                        </Radio.Group>
                        <br />
                        <br />

                        <Checkbox data-cy={"check_pre_pay"}
                            disabled={state.isLocked}
                            checked={state.loanObject.prePay}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, prePay: e.target.checked}}
                                })
                            }}
                        >
                            Vorschüssig
				        </Checkbox>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={12}>
                    <Col span={6}>
                        <h4>- Nachlass auf KP</h4>
                        <LocalizedNumberInput
                            data-cy="input_discount_kp_percent"
                            className={"ant-input-affix-wrapper--small"}
                            prefix={'%'}
                            disabled={state.isLocked}
                            value={getDiscountKpPercentage(state.loanObject)}
                            precision={2}
                            onChange={(e) => {
                                const discountKpPercentage = e
                                const discountKp = roundToDecimalPoints((discountKpPercentage / 100) * state.loanObject.loanAmount, 2)

                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, discountKpPercentage, discountKp}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={5}>
                        <h4>&nbsp;</h4>
                        <LocalizedNumberInput
                            data-cy="input_discount_kp"
                            className={"ant-input-affix-wrapper--small"}
                            prefix={t('currency_sign')}
                            disabled={state.isLocked}
                            value={state.loanObject.discountKp}
                            precision={2}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, discountKp: e}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={1}>
                        <Tooltip placement={"top"}
                            title={t('recalculate_with_desired_monthly_rate')}
                        >
                            <CalculatorOutlined data-cy="button_recalc_discount_kp"
                                className={positionedCalcStyles}
                                onClick={() => {
                                    if (state.loanObject.customMonthlyRate) {
                                        recalculateDiscountKpByDesiredMonthlyRate()
                                    }
                                }}
                            />
                        </Tooltip>
                    </Col>
                    {/* <Col span={6}>
                        <h4>Gesicherte Nacherlöse</h4>
                        <LocalizedNumberInput
                            data-cy="input_hedged_proceeds"
                            className={styles.minWidthInput}
                            prefix={t('currency_sign')}
                            disabled={state.isLocked}
                            value={state.loanObject.hedgedProceeds}
                            precision={2}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, hedgedProceeds: e}}
                                })
                            }}
                        />
                    </Col> */}
                    {/* <Col span={6}>
                        <h4>Nacherlös gem. Vertrag</h4>
                        <LocalizedNumberInput
                            data-cy="input_additional_proceeds"
                            className={styles.minWidthInput}
                            prefix={t('currency_sign')}
                            disabled={state.isLocked}
                            value={state.loanObject.additionalProceeds}
                            precision={2}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, additionalProceeds: e}}
                                })
                            }}
                        />
                    </Col> */}
                </Row>
                <br />
                <Row gutter={12}>
                    <Col span={6}>
                        <h4>- Disagio</h4>
                        <LocalizedNumberInput
                            data-cy="input_discount_percent"
                            className={"ant-input-affix-wrapper--small"}
                            prefix={'%'}
                            disabled={state.isLocked}
                            value={getDiscountPercentage(state.loanObject)}
                            precision={2}
                            onChange={(e) => {
                                const discountPercentage = e
                                const discount = roundToDecimalPoints((state.loanObject.loanAmount - state.loanObject.discountKp) * (discountPercentage / 100), 2)

                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, discount}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={6}>
                        <h4>&nbsp;</h4>
                        <LocalizedNumberInput
                            data-cy="input_discount"
                            className={"ant-input-affix-wrapper--small"}
                            prefix={t('currency_sign')}
                            disabled={true}
                            value={state.loanObject.discount}
                            precision={2}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, discount: e}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={6}>
                        <h4>- Anzahlung</h4>
                        <LocalizedNumberInput
                            data-cy="input_downpayment_percent"
                            className={"ant-input-affix-wrapper--small"}
                            prefix={'%'}
                            disabled={state.isLocked}
                            value={getDownpaymentPercentage(state.loanObject)}
                            precision={2}
                            onChange={(e) => {
                                const downpaymentPercentage = e
                                const downpayment = roundToDecimalPoints((downpaymentPercentage / 100) * state.loanObject.loanAmount, 2)

                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, downpayment}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={5}>
                        <h4>&nbsp;</h4>
                        <LocalizedNumberInput
                            data-cy="input_downpayment"
                            className={"ant-input-affix-wrapper--small"}
                            prefix={t('currency_sign')}
                            disabled={state.isLocked}
                            value={state.loanObject.downpayment}
                            precision={2}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, downpayment: e}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={1}>
                        <Tooltip placement={"top"}
                            title={t('recalculate_with_desired_monthly_rate')}
                        >
                            <CalculatorOutlined data-cy="button_recalc_downpayment"
                                className={positionedCalcStyles}
                                onClick={() => {
                                    if (state.loanObject.customMonthlyRate) {
                                        recalculateDownPaymentByDesiredMonthlyRate()
                                    }
                                }}
                            />
                        </Tooltip>
                    </Col>
                    <Col span={6}>
                        <h4>- Werkzuschuss</h4>
                        <LocalizedNumberInput
                            data-cy="input_work_grant_percent"
                            className={"ant-input-affix-wrapper--small"}
                            prefix={'%'}
                            disabled={state.isLocked}
                            value={getWorkGrantPercentage(state.loanObject)}
                            precision={2}
                            onChange={(e) => {
                                const workGrantPercentage = e
                                const workGrant = roundToDecimalPoints((workGrantPercentage / 100) * state.loanObject.loanAmount, 2)

                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, workGrant}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={6}>
                        <h4>&nbsp;</h4>
                        <LocalizedNumberInput
                            data-cy="input_work_grant"
                            className={"ant-input-affix-wrapper--small"}
                            prefix={t('currency_sign')}
                            disabled={state.isLocked}
                            value={state.loanObject.workGrant}
                            precision={2}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, workGrant: e}}
                                })
                            }}
                        />
                    </Col>
                </Row>
                <br />
                <Row gutter={12}>
                    <Col span={6}>
                        <h4>+ Provision</h4>
                        <LocalizedNumberInput
                            data-cy="input_commission_percent"
                            className={"ant-input-affix-wrapper--small"}
                            prefix={'%'}
                            disabled={state.isLocked}
                            value={getCommissionPercentage(state.loanObject)}
                            precision={2}
                            onChange={(e) => {
                                const commissionPercentage = e
                                const commission = roundToDecimalPoints((commissionPercentage / 100) * (state.loanObject.loanAmount - state.loanObject.downpayment), 2)

                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, commissionPercentage, commission}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={5}>
                        <h4>&nbsp;</h4>
                        <LocalizedNumberInput
                            data-cy="input_commission"
                            className={"ant-input-affix-wrapper--small"}
                            prefix={t('currency_sign')}
                            disabled={state.isLocked}
                            value={state.loanObject.commission}
                            precision={2}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, commission: e}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={1}>
                        <Tooltip placement={"top"}
                            title={t('recalculate_with_desired_monthly_rate')}
                        >
                            <CalculatorOutlined data-cy="button_recalc_commission"
                                className={positionedCalcStyles}
                                onClick={() => {
                                    if (state.loanObject.customMonthlyRate) {
                                        reCalculateCommissionByDesiredMonthlyRate()
                                    }
                                }}
                            />
                        </Tooltip>
                    </Col>
                    <Col span={6}>
                        <h4>Restwert</h4>
                        <LocalizedNumberInput
                            data-cy="input_remaining_value_percent"
                            className={"ant-input-affix-wrapper--small"}
                            prefix={'%'}
                            disabled={state.isLocked}
                            value={getRemainingValuePercentage(state.loanObject)}
                            precision={2}
                            onChange={(e) => {
                                const remainingValuePercent = e
                                const remainingValue = roundToDecimalPoints((remainingValuePercent / 100) * state.loanObject.loanAmount, 2)

                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, remainingValue}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={5}>
                        <h4>&nbsp;</h4>
                        <LocalizedNumberInput
                            data-cy="input_remaining_value"
                            className={"ant-input-affix-wrapper--small"}
                            prefix={t('currency_sign')}
                            disabled={state.isLocked}
                            value={state.loanObject.remainingValue}
                            precision={2}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, remainingValue: e}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={1}>
                        <Tooltip placement={"top"}
                            title={t('recalculate_with_desired_monthly_rate')}
                        >
                            <CalculatorOutlined data-cy="button_recalc_remaining_value"
                                className={positionedCalcStyles}
                                onClick={() => {
                                    if (state.loanObject.customMonthlyRate) {
                                        recalculateRemainingValueByDesiredMonthlyRate()
                                    }
                                }}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                <br />
                <Row gutter={24}>
                    <Col span={12}>
                        <h4>MBG Intern</h4>
                        <LocalizedNumberInput disabled={true}
                            prefix={t('currency_sign')}
                            value={getBgInternal(state.loanObject)}
                            precision={2}
                        />
                    </Col>
                    <Col span={12}>
                        <h4>BG Internal 2</h4>
                        <LocalizedNumberInput disabled={true}
                            prefix={t('currency_sign')}
                            value={getBgInternal2(state.loanObject)}
                            precision={2}
                        />
                    </Col>
                </Row>
                <Divider />

                <Row gutter={24}>
                    <Col span={12}>
                        <h4>Vertragsbeginn - Nur Informativ!</h4>
                        <DatePicker data-cy="input_contract_start_at"
                            format={DATE_FORMAT.DE}
                            disabled={state.isLocked}
                            value={state.loanObject.contractStartAt ? moment(state.loanObject.contractStartAt) : null}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, contractStartAt: e?.format(DATE_FORMAT.API)}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <h4>Fälligkeit der 1. Rate </h4>
                        <DatePicker data-cy="input_date_of_first_payment"
                            format={DATE_FORMAT.DE}
                            disabled={state.isLocked}
                            dateRender={current => {
                                if (current.date() === 1 || current.date() === 15) {
                                    return (
                                        <div className="ant-picker-cell ant-picker-cell-in-view">
                                          {current.date()}
                                        </div>
                                      );
                                } else {
                                    return (
                                        <div className="ant-picker-cell">
                                          {current.date()}
                                        </div>
                                      );
                                }
        
                              }}
                            value={state.loanObject.dateOfFirstPayment ? moment(state.loanObject.dateOfFirstPayment) : null}
                            onChange={(e) => {
                                if(e.date() !== 1 || e.date() !== 1) {
                                    return;
                                }
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, dateOfFirstPayment: e?.format(DATE_FORMAT.API)}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <h4>Auszahlung am</h4>
                        <DatePicker data-cy="input_paid_out_at"
                            format={DATE_FORMAT.DE}
                            disabled={state.isLocked}
                            value={state.loanObject.paidOutAt ? moment(state.loanObject.paidOutAt) : null}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, paidOutAt: e?.format(DATE_FORMAT.API)}}
                                })
                            }}
                        />
                    </Col>
                </Row>

                <br />

                <Row gutter={24}>
                    <Col span={12}>
                        <h4>Vorlaufmonate</h4>
                        <InputNumber data-cy="input_run_up_time"
                            disabled={true}
                            value={getRunUpTime(state.loanObject)}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, runUpTime: e as number}}
                                })
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <h4>Zahlungen/Jahr</h4>
                        <InputNumber disabled={true}
                            value={state.loanObject.paymentsPerYear}
                        />
                    </Col>
                </Row>
                <br />
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <h4>Montaliche Rate</h4>
                        <LocalizedNumberInput
                            className={"ant-input-affix-wrapper--small"}
                            prefix={t('currency_sign')}
                            disabled={true}
                            value={getMonthlyRate(state.loanObject)}
                            precision={2}
                        />
                    </Col>
                    <Col span={12}>
                        <h4>Rate pro Termin</h4>
                        <LocalizedNumberInput
                            className={"ant-input-affix-wrapper--small"}
                            prefix={t('currency_sign')}
                            disabled={true}
                            value={getPeriodRate(state.loanObject)}
                            precision={2}
                        />
                    </Col>
                    <Col span={8}>
                        <h4>Gewünschte monatliche Rate</h4>
                        <LocalizedNumberInput data-cy="input_custom_monthly_rate"
                            className={"ant-input-affix-wrapper--small"}
                            prefix={t('currency_sign')}
                            disabled={state.isLocked}
                            value={state.loanObject.customMonthlyRate}
                            precision={2}
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, loanObject: {...prevState.loanObject, customMonthlyRate: e}}
                                })
                            }}
                        />
                    </Col>
                </Row>

                <br />

                <Row gutter={24}>
                    <Col span={4}>
                        <div className={styles.labelSmall}>Kundenzins in %</div>
                        <div className={styles.valueCalc}>{getCustomerInterestRate(state.loanObject)}</div>
                    </Col>
                    <Col span={2}>
                        <div className={styles.verticalSeparator}></div>
                    </Col>
                    <Col span={4}>
                        <div className={styles.labelSmall}>Nominalzins in %</div>
                        <div className={styles.valueCalc}>{getNominalInterestRate(state.loanObject)}</div>
                    </Col>
                    <Col span={2}>
                        <div className={styles.verticalSeparator}></div>
                    </Col>
                    <Col span={4}>
                        <div className={styles.labelSmall}>PANGV (AIBD)</div>
                        <div className={styles.valueCalc}>{getPANGVnterestRate(state.loanObject)}</div>
                    </Col>
                </Row>
                <br />

                <Row gutter={24}>
                    <Col span={4}>
                        <div className={styles.labelSmall}>BWMarge</div>
                        <div className={styles.valueCalc}>{getCashMargin(state.loanObject)}</div>
                    </Col>
                    <Col span={2}>
                        <div className={styles.verticalSeparator}></div>
                    </Col>
                    <Col span={4}>
                        <div className={styles.labelSmall}>BWMarge %</div>
                        <div className={styles.valueCalc}>{getCashMarginPercentage(state.loanObject)}</div>
                    </Col>
                </Row>
                <br />
                <br />

                <Space size={24}>
                    {
                        !props.isExampleCalculator ?
                            <Button data-cy="button_save_calculation"
                                type="primary"
                                disabled={state.isLocked}
                                onClick={() => {
                                    handleSave()
                                }}>
                                <CheckOutlined /> Speichern
							</Button>
                            : null
                    }

                    <Button data-cy="button_reset_calculation"
                        type="primary"
                        disabled={state.isLocked}
                        onClick={() => {handleReset();}}>
                        <RedoOutlined /> Reset
					</Button>
                </Space>
                <br />
                <br />

                {
                    !props.isExampleCalculator ?
                        <Button data-cy="button_save_and_go"
                            type="primary"
                            disabled={state.isLocked}
                            onClick={async () => {
                                const isSuccess = await handleSave()
                                if (isSuccess) {
                                    props.setActiveTabIndex(`2`)
                                }
                            }}>
                            <CalculatorOutlined /> Speichern und direkt zum Angebot
						</Button>
                        : null
                }
            </div>
            <br />

            <RatesAdjustmentForm
                isLocked={state.isLocked}
                loanObject={state.loanObject}
                repaymentPlan={state.repaymentPlan}
                onChange={(newRepaymentPlan: RepaymentPlanInstallment[]) =>
                    setState({...state, repaymentPlan: newRepaymentPlan})
                }
                onReset={() => {
                    const repaymentPlan = calculateRepaymentPlan(state.loanObject);

                    setState({...state, repaymentPlan: repaymentPlan});
                }}
                onSaveCalculation={() => {
                    if (!props.isExampleCalculator) {
                        handleSave()
                    }
                }}
            />
        </div>
    );
};
