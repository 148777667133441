import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, Input, message, Modal, Row, Select} from "antd";
import {ColumnsType, PeopleTable} from "components/common/presenters/people-table/PeopleTable";
import styles from "./ObjectsTab.module.scss";
import {Store, useStore} from "hooks/Store";
import {CountrySetting} from "services/api/types";
import ApiManager from "services/api/ApiManager";

export interface SearchPeopleModalProps {
	isOpen: boolean
	onOk: (data: any) => void
	onCancel: () => void
}

export const SearchPeopleModal = (props: SearchPeopleModalProps) => {
	const {t} = useTranslation()
    const store: Store = useStore()

	const [data, setData] = useState(null)

    const enabledCountries: CountrySetting[] = store.data?.settings?.countries?.filter(c => c.isEnabled) || []

    const syncUserData = async (userData) => {
        try {

            const addressCountryId = (enabledCountries.filter(k => k.name.toLowerCase() === userData?.country?.toLowerCase())[0]||{}).countryId;
            const birthCountryId = (enabledCountries.filter(k => k.name.toLowerCase() === userData?.birthCountry?.toLowerCase())[0]||{}).countryId;
            const sendingData = {
                addressCountryId,
                birthCountryId,
                ...userData,
                firstName: userData.firstName || '',
                email: userData.email || '',
                title: userData.title || '',
                addressLine1: userData.addressLine1 || '',
                addressStreetNumber: userData.addressStreetNumber || '',
                addressCity: userData.addressCity || '',
                addressPostalCode: userData.addressPostalCode || '',
                mobileNumber: userData.mobileNumber || '',
                salutation: userData.salutation || '',
            }
            delete sendingData.isAkf;
            delete sendingData.country;
            delete sendingData.birthCountry;
            delete sendingData.role;
            delete sendingData.name;
            const result = await ApiManager.UserService.syncAkfUser(sendingData)
            return result.data;
        } catch (e) {
            console.error(e)
            message.error('Unexpected error')
            return {};
        }
    }



	return (
		<Modal
            width={'80%'}
			   visible={props.isOpen}
			   title={"Person Suchen"}
			   onOk={() => props.onOk(data)}
			   onCancel={() => props.onCancel()}
		>
			<Row gutter={24}>
			    <PeopleTable
                    onRowClicked={async (row, event) => { 
                        let syncedUser = row;
                        if(row.isAkf) {
                        
                            syncedUser = await syncUserData(syncedUser)
                        }
                         props.onOk(row) }}
                    onArrowClicked={async (row) => { 
                        let syncedUser = row;
                        if(row.isAkf) {
                        
                            syncedUser = await syncUserData(row)
                        }
                         props.onOk(syncedUser) }}
                    fieldsSearch={true}
                    columnsType={ColumnsType.ExtraFields}
                />
			</Row>
			<br/>

		</Modal>
	)
}
