import React, {useEffect, useState} from "react";
import {Redirect, Route} from "react-router-dom";
import {Spin} from "antd";
import {RouteStrings} from "./Routes";
import {Auth, useAuth} from "../hooks/Auth";

export const PrivateRoute = ({children, ...rest}) => {
	const auth: Auth = useAuth()

	const [isAuthed, setIsAuthed] = useState(null)

	useEffect(() => {
		auth.checkAuth()
			.then((res) => {
				setIsAuthed(res)
                auth.loopRefreshSession()
			})
			.catch(() => setIsAuthed(false))
	}, [children])

	return (
		<Route
			{...rest}
			render={({location}) => {
				switch (isAuthed) {
					case null:
						return <Spin size={"large"} delay={500}/>
					case false:
						return <Redirect to={{
							pathname: RouteStrings.Login,
							state: {from: location}
						}}/>
					case true:
						return children
				}
			}}
		/>
	)
}
