import * as React from "react";
import {useEffect, useState} from "react";
import styles from "components/common/presenters/application-page-tabs/ApplicationPageTabs.module.scss";
import {Button, Checkbox, Col, DatePicker, Input, message, Row, Select, Spin} from "antd";
import {CheckCircleFilled, CheckOutlined, CloseCircleOutlined} from "@ant-design/icons";
import {ApplicationData, Branch, Calculation, ContactPersonFunctionSetting, EmployeeCompletion, MarketVoteAnalystSetting, ObjectData, ObjectType, ObjectTypeSetting, OffersData, RewardShopRecipient, UserData, UserDocumentCategory, UserType, Vendor} from "../../../../../services/api/types";
import {ListContacts} from "components/common/presenters/list-contacts/ListContacts";
import CollapsibleSection from "components/common/presenters/collapsible-section/CollapsibleSection";
import {Store, useStore} from "../../../../../hooks/Store";
import {AnchoredSelect} from "../../anchored-select/AnchoredSelect";
import ApiManager from "services/api/ApiManager";
import {RequiredDocumentsSectionTab} from "components/common/presenters/application-page-tabs/marketvote-tab/RequiredDocumentsSection";
import {MarktVoteSection} from "components/common/presenters/application-page-tabs/checklist-tab/MarktVoteSection";
import {ListItem, ListItemTypes} from "components/common/presenters/list-item/ListItem";
import moment from "moment";
import {DATE_FORMAT} from "services/api/Globals";
import UiHelper from "../../../../../helpers/uiHelpers";
import {SearchPeopleModal} from "components/common/presenters/application-page-tabs/objects-tab/SearchPeopleModal";
import {useTranslation} from "react-i18next";
import {debug} from "console";

export interface ChecklistTabState {
    selectedOfferId: number;
    selectedOffer?: OffersData;

    selectedCalculation?: Calculation;

    commissionRecipientId: number;
    rewardShopRecipientId: number;
    vendorId: string;

    marketVoteAnalystId?: number;
    documentCategoriesUploaded: UserDocumentCategory[];
    documentCategoriesInWu: UserDocumentCategory[];

    userId: number;
    isLocked: boolean;
    submittedAt?: Date;
    isUserChecked: boolean;
    isOfferChecked: boolean;
    isMarketVoteChecked: boolean;
    branch?: Branch;
    employeeCompletion?: EmployeeCompletion;

    firstName: string,
    birthDate: string,
    mobileNumber: string,
    industryTypeId: number,

    createdAt?: Date;
}

export interface ChecklistTabProps {
    applicationInformation: ApplicationData
    onApplicationUpdated: () => void
    offers: OffersData[];
    userData?: UserData;
    onGoToCalculation: (calculationId: number) => void
}

export const ChecklistTab = (props: ChecklistTabProps) => {
    const store: Store = useStore()
    const {t} = useTranslation()
    
    const vendors: Vendor[] = store.data?.settings?.vendors || []

    let selectedCalculation = null;
    const selectedOfferId = props.applicationInformation?.selectedOffer;
    let foundOffer = null;
    if (selectedOfferId) {
        foundOffer = props.offers.find(b => b.offerId == selectedOfferId);
        selectedCalculation = foundOffer.calculation;
    }

    const [data, setData] = useState({
        selectedOfferId: props.applicationInformation.selectedOffer || null,
        selectedCalculation,
        commissionRecipientId: props.applicationInformation.commissionRecipientId || null,
        rewardShopRecipientId: props.applicationInformation.rewardShopRecipientId || null,
        vendorId: props.applicationInformation.vendorId || foundOffer?.vendorId,

        firstName: props.applicationInformation.marketVotes[0]?.firstName || props.userData.firstName,
        birthDate: props.applicationInformation.marketVotes[0]?.birthDate || props.userData.birthDate,
        mobileNumber: props.applicationInformation.marketVotes[0]?.mobileNumber || props.userData.oldschoolphoneNumber || props.userData.mobileNumber,
        industryTypeId: props.applicationInformation.marketVotes[0]?.industryTypeId || props.userData.industryTypeId,

        marketVoteAnalystId: props.applicationInformation.marketVotes[0]?.marketVoteAnalystId ? props.applicationInformation.marketVotes[0].marketVoteAnalystId : null,
        documentCategoriesUploaded: props.applicationInformation.marketVotes[0]?.documentCategoriesUploaded ? props.applicationInformation.marketVotes[0]?.documentCategoriesUploaded : [],
        documentCategoriesInWu: props.applicationInformation.marketVotes[0]?.documentCategoriesInWu ? props.applicationInformation.marketVotes[0]?.documentCategoriesInWu : [],

        isUserChecked: props.applicationInformation.isUserChecked,
        isOfferChecked: props.applicationInformation.isOfferChecked,
        isMarketVoteChecked: props.applicationInformation.isMarketVoteChecked,
        branch: props.applicationInformation.branch ? props.applicationInformation.branch : Branch.Berlin,
        employeeCompletion: props.applicationInformation.employeeCompletion ? props.applicationInformation.employeeCompletion : EmployeeCompletion.IPSUM1
    } as ChecklistTabState)

    const [uiData, setUiData] = useState({
        commissionRecipientPhrase: props.applicationInformation.commissionRecipientName || '',
        searchPeopleModalKey: ''
    })

    const [commissionRecipientData, setCommissionRecipientData] = useState({
        data: [],
        commissionRecipientTerm: '',
        fetching: false,
    })

 //   const enabledMarketVoteAnalysts: MarketVoteAnalystSetting[] = store.data?.settings?.marketVoteAnalysts?.filter(a => a.isEnabled) || []

    const enabledContactPersonFunctions: ContactPersonFunctionSetting[] = store.data?.settings?.contactPersonFunctions?.filter(f => f.isEnabled) || [];
    const  beneficialOwnerFunctionIds = enabledContactPersonFunctions.filter(f => f.functionName.toLowerCase() === "wirtschaftlich Berechtigter (fiktiv)".toLowerCase() ||
    f.functionName.toLowerCase() === "wirtschaftlich Berechtigter".toLowerCase()).map(f => f.contactPersonFunctionId);
    const shareholderFunctionIds =  enabledContactPersonFunctions.filter(f => f.functionName.toLowerCase() === "Gesellschafter".toLowerCase()).map(f => f.contactPersonFunctionId); 
    const managingDirectorFunctionIds =  enabledContactPersonFunctions.filter(f => f.functionName.toLowerCase() === "Geschäftsführer".toLowerCase()).map(f => f.contactPersonFunctionId);
    const enabledRewardShopRecipients: RewardShopRecipient[] = store.data?.settings?.rewardShopRecipients?.filter(a => a.isEnabled) || []
    const enabledObjectTypes = store.data?.settings?.objectGroups?.filter(o => o.isEnabled) || []

    const handleDocumentUploadedChecked = (isChecked: boolean, documentCategory: UserDocumentCategory) => {
        const documentCategoriesUploaded = isChecked ?
            [...data.documentCategoriesUploaded, documentCategory] :
            data.documentCategoriesUploaded.filter(d => d !== documentCategory)

        const documentCategoriesInWu = isChecked ?
            data.documentCategoriesInWu.filter(d => d !== documentCategory) :
            [...data.documentCategoriesInWu]

        setData({
            ...data,
            documentCategoriesUploaded: documentCategoriesUploaded,
            documentCategoriesInWu: documentCategoriesInWu
        })
    }

    const handleDocumentUploadedInWUChecked = (isChecked: boolean, documentCategory: UserDocumentCategory) => {
        const documentCategoriesInWu = isChecked ?
            [...data.documentCategoriesInWu, documentCategory] :
            data.documentCategoriesInWu.filter(d => d !== documentCategory)

        const documentCategoriesUploaded = isChecked ?
            data.documentCategoriesUploaded.filter(d => d !== documentCategory) :
            [...data.documentCategoriesUploaded]

        setData({
            ...data,
            documentCategoriesInWu: documentCategoriesInWu,
            documentCategoriesUploaded: documentCategoriesUploaded
        })
    }

    const fetchCommissionRecipientBySearch = async (term) => {
        try {
            setCommissionRecipientData({
                ...commissionRecipientData,
                fetching: true,
            })
            const usersResult = await ApiManager.UserService.getUsers(1, 10,
                [UserType.Person.toString()],
                term);

            setCommissionRecipientData({
                ...commissionRecipientData,
                data: usersResult.data,
                fetching: false,
            })
        } catch (error) {
            console.error(error);
            setCommissionRecipientData({
                ...commissionRecipientData,
                fetching: false,
            })
        }
    };

    const onViewMarktvotumDocClicked = async () => {
        try {
            const signedUrlResponse = await ApiManager.ApplicationService.getMarktvotumDocumentSignedUrl(props.applicationInformation.applicationId)
            const signedUrl = signedUrlResponse.data
            UiHelper.openUrlInNewTab(signedUrl)
        } catch (e) {
            console.error(e)
            message.error('Could not view marktvote', 2)
        }
    }

    const onDeleteMarktvotum = async () => {
        try {
            await ApiManager.ApplicationService.deleteMarketVote(props.applicationInformation.applicationId)
            message.success('Marktvote deleted', 2)
            props.onApplicationUpdated()
        } catch (e) {
            console.error(e);
            message.error('Could not delete marktvote', 2)
        }
    }

    const updatePersonDataForRpa = async () => {
        debugger;
        try {
            const reqBody = {
                firstName: data.firstName,
                birthDate: data.birthDate,
                mobileNumber: data.mobileNumber,
                industryTypeId: data.industryTypeId
            };
            await ApiManager.ApplicationService.upsertMarketVote(props.applicationInformation.applicationId, reqBody)

            message.success('Daten Gespeichert', 2)
            props.onApplicationUpdated()
        } catch (e) {
            console.error(e);
            message.error('Konnte nicht Speichern', 2)
        }
    }

    const updateSelectOfferSection = async () => {
        try {
            const dataUpload = {
                applicationId: props.applicationInformation.applicationId,
                selectedOffer: data.selectedOfferId,
                commissionRecipientId: data.commissionRecipientId,
                rewardShopRecipientId: data.rewardShopRecipientId,
                vendorId: data.vendorId
            };
            await ApiManager.ApplicationService.updateApplication(dataUpload as ApplicationData)
            let selectedCalculation = null;
            const selectedOfferId = data.selectedOfferId;
            if (selectedOfferId) {
                selectedCalculation = props.offers.find(b => b.offerId == selectedOfferId).calculation;
            }
            setData({...data, selectedCalculation})

            message.success('Daten Gespeichert', 2)
            props.onApplicationUpdated()
        } catch (e) {
            console.error(e);
            message.error('Konnte nicht Speichern', 2)
        }
    }

    useEffect(() => {
        // if (commissionRecipientData.commissionRecipientTerm != null) {
        //     fetchCommissionRecipientBySearch()
        // }
    }, [commissionRecipientData.commissionRecipientTerm])

    const renderRequiredDocuments = () => {
        return <RequiredDocumentsSectionTab
            applicationInformation={props.applicationInformation}
            userData={props.userData}
            documentCategoriesUploaded={data.documentCategoriesUploaded}
            documentCategoriesInWu={data.documentCategoriesInWu}
            handleDocumentUploadedChecked={handleDocumentUploadedChecked}
            handleDocumentUploadedInWUChecked={handleDocumentUploadedInWUChecked}
        />
    }

    
    return (
        <React.Fragment>
            <CollapsibleSection text="Kalkulationen">
                <div className={styles.checklistTab}>
                    <div>
                        <div>
                            Angebot
					</div>
                        <AnchoredSelect data-cy="dropdown_offer_id"
                            style={{maxWidth: '700px'}}
                            value={data.selectedOfferId}
                            onChange={(e) => {
                            const selectedOffer = props.offers.find(o => o.offerId === e);
                            setData({
                                ...data,
                                selectedOfferId: e,
                                selectedOffer,
                                commissionRecipientId: null,
                                rewardShopRecipientId: null,
                                vendorId: selectedOffer.vendorId
                            });
                            setUiData({...uiData, commissionRecipientPhrase: ''});
                            }}>
                            {props.offers.map((l, i) => <Select.Option data-cy={`offer_option_${i}`} key={i} value={l.offerId}><div dangerouslySetInnerHTML={(()=>{ return {__html: l.offerName}; })()}></div></Select.Option>)}
                        </AnchoredSelect>
                        <br />
                        <br />
                        <div>
                            <Button data-cy="button_save"
                                type="primary"
                                disabled={!data.selectedOffer}
                                onClick={data.selectedOffer ? () => props.onGoToCalculation(data.selectedOffer.calculationId) : () => { }}>
                                Zur kalkulation
						</Button>
                        </div>
                        <br />
                        <div>Provisionsempfänger</div>
                        <Input data-cy="input_commission"
                            className={styles.shortInput}
                            allowClear
                            value={uiData.commissionRecipientPhrase}
                            onChange={(e) => {
                                if(!e.currentTarget.value) {
                                    setData({...data, commissionRecipientId: undefined })
                                    setUiData({...uiData, commissionRecipientPhrase: ''})
                                }
                            }}
                            onClick={(e) => {
                                setUiData({...uiData, searchPeopleModalKey: 'commissionRecipient'})
                            }}>
                        </Input>

                        {/* <div>
                            Provisionsempfänger
					</div>
                        <Select
                            style={{maxWidth: '700px'}}
                            value={data.commissionRecipientId}
                            onSearch={async v => await fetchCommissionRecipientBySearch(v)}
                            onChange={(e) => {
                                setData({...data, commissionRecipientId: e})
                                // setCommissionRecipientData({
                                //      commissionRecipientTerm: parseInt(e),
                                //     commissionRecipientTerm: '',
                                //     data: [],
                                //     fetching: false,
                                // })
                            }}
                            notFoundContent={commissionRecipientData.fetching ? <Spin size="small" /> : null}
                            showSearch={true}
                            filterOption={false}
                        >
                            {
                                commissionRecipientData.data.map((l, i) => <Select.Option data-cy={`commission_recipient_option_${i}`} key={i} value={l.id}>{`${l.firstName} ${l.lastName}`}</Select.Option>)
                            }
                        </Select> */}
                        <br />
                        <br />
                        <div>Vendor</div>
                            <AnchoredSelect data-cy="dropdown_vendor_id"
                                value={data.vendorId}
                                onChange={(e) => {
                                    setData({...data, vendorId: e});
                                }}
                                showSearch={true}
                                optionFilterProp={"children"}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    vendors.map((p, i) =>
                                        <Select.Option data-cy={`dropdown_vendor_${i}`} key={p.Code} value={p.Code}>{p.Bezeichnung}</Select.Option>
                                    )
                                }
                        </AnchoredSelect>

                        <br />
                        <div>
                        {t('reward_shop_recipient')}
					</div>
                        <AnchoredSelect data-cy="dropdown_reward_shop_recipient"
                            style={{maxWidth: '700px'}}
                            showSearch={true}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={data.rewardShopRecipientId}
                            onChange={(e) => setData({...data, rewardShopRecipientId: e})}>
                                {
                            enabledRewardShopRecipients.map((r, i) =>
                                <Select.Option key={i} data-cy={`dropdown_analyst_option_${i}`} value={r.rewardShopRecipientId}>{`${r.merchant} ${r.sellerFirstName} ${r.sellerLastName}`}</Select.Option>
                            )
                            }
                        </AnchoredSelect>
                        <br />
                        {data.selectedOffer ? <React.Fragment>
                            <h3>Bürgen</h3>
                            <ListContacts
                                contacts={((data.selectedOffer.guarantorId ? [{contactName: data.selectedOffer.guarantorName}] : []) as any)}
                                onClick={(o) => { }}
                            />
                        </React.Fragment> : null}
                        <br />
                      
                        <div className={styles.textRight} >
                            <Button data-cy="button_save"
                                type="primary"
                                size="small"
                                htmlType="submit"
                                onClick={() => updateSelectOfferSection()}>
                                Speichern
					</Button>
                        </div>
                    </div>
                    <SearchPeopleModal 
            	isOpen={!!uiData.searchPeopleModalKey}
                onOk={(row) => {
                    const phrase = `${row.firstName || ""} ${row.lastName || ""}`;
                    
                    setData({...data, commissionRecipientId: row.userId })
                    setUiData({...uiData, searchPeopleModalKey: '', commissionRecipientPhrase: phrase})
                }}
                onCancel={() => {
                    setUiData({...uiData, searchPeopleModalKey: '' })
                }}
            />
                </div>
            </CollapsibleSection>
            <CollapsibleSection text="Objekt">
                <div className={styles.checklistTab}>
                    <h3 className={styles.textLeft}>Objektdaten</h3>
                    <br />
                    {MainObjekt(props.applicationInformation.objects?.find(o => o.type === ObjectType.MAIN), enabledObjectTypes)}
                    <br />
                    {props.applicationInformation.objects?.filter((o => o.type === ObjectType.CHILD)).map((o, index) => SmallObjekt(o, index + 2))}
                </div>
            </CollapsibleSection>
            <CollapsibleSection text="Personen">
                <div className={styles.checklistTab}>
                    <div>
                        Vorname
					</div>
                    <Input data-cy="input_title"
                        placeholder=""
                        value={data.firstName}
                        disabled={false}
                        onChange={(e) => {
                            setData({...data, firstName: e.target.value})
                        }}
                    />
                    {!data.firstName ? <span className={styles.iconFailRed} ><CloseCircleOutlined /></span> : null}
                    <br />
                    <br />
                    <div>
                        Geburtsdatum
					</div>
                    <DatePicker data-cy={"picker_birthdate"}
                            format={DATE_FORMAT.DE}
                            showToday={false}
                            value={data.birthDate ? moment(data.birthDate, DATE_FORMAT.API) : null}
                            onChange={(e) => {
                                setData({...data, birthDate: e.format(DATE_FORMAT.API)})
                            }}
                        />
                    {!data.birthDate ? <span className={styles.iconFailRed} ><CloseCircleOutlined /></span> : null}
                    <br />
                    <br />
                    <div>
                        Telefon
					</div>
                    <Input data-cy="input_title"
                        placeholder=""
                        value={data.mobileNumber}
                        disabled={false}
                        onChange={(e) => {
                            setData({...data, mobileNumber: e.target.value})
                        }}
                    />
                    {!data.mobileNumber ? <span className={styles.iconFailRed} ><CloseCircleOutlined /></span> : null}
                    <br />
                    <br />
                    <div>
                        Branche
					</div>
                    {/* <Input disabled={true}
                        value={store.data?.settings?.industryTypes?.find(t => t.industryTypeId === props.userData.industryTypeId)?.description || ""}
                    /> */}
                          
                                    <AnchoredSelect data-cy="dropdown_industry_type"
                                        value={data.industryTypeId}
                                        onChange={(e) => {
                                            setData({...data, industryTypeId: e});
                                        }}
                                        showSearch={true}
                                        optionFilterProp={"children"}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            store.data?.settings?.industryTypes?.map((industryType, i) => {
                                                return <Select.Option key={i} data-cy={`option_${i}`} value={industryType.industryTypeId}>{industryType.description}</Select.Option>
                                            })
                                        }
                                    </AnchoredSelect>
                    {!(data.industryTypeId) ? <span className={styles.iconFailRed} ><CloseCircleOutlined /></span> : null}
                    <br />
                    <div className={styles.textRight} >
                            <Button data-cy="button_save"
                                type="primary"
                                size="small"
                                htmlType="submit"
                                onClick={() => updatePersonDataForRpa()}>
                                Speichern
					</Button>
                        </div>
                    <br />
                    <p className={styles.width200}>Wirtschaftliche Berechtigten angelegt? {(props.userData.contactPersons || []).find(c => !!beneficialOwnerFunctionIds.find(b => b === c.contactPersonFunctionId)) ? 
                        <span className={styles.iconSuccessGreen} ><CheckCircleFilled /></span> 
                    : <span className={styles.iconFailRed + ' ' + styles.floatRight} ><CloseCircleOutlined /></span>} </p>

                    <p className={styles.width200}>Gesellschafter angelegt? {(props.userData.contactPersons || []).find(c => !!shareholderFunctionIds.find(b => b === c.contactPersonFunctionId)) ? 
                        <span className={styles.iconSuccessGreen} ><CheckCircleFilled /></span> 
                    : <span className={styles.iconFailRed + ' ' + styles.floatRight} ><CloseCircleOutlined /></span>}      
                    </p>
                    
                    <p className={styles.width200}>Geschaftsfuhrer angelegt? {(props.userData.contactPersons || []).find(c => !!managingDirectorFunctionIds.find(b => b === c.contactPersonFunctionId)) ? 
                        <span className={styles.iconSuccessGreen} ><CheckCircleFilled /></span> 
                    : <span className={styles.iconFailRed + ' ' + styles.floatRight} ><CloseCircleOutlined /></span>}</p>
            
                </div>
            </CollapsibleSection>
            <CollapsibleSection
                //initialCollapsedState={props.applicationInformation.marketVotes.length > 0}
                initialCollapsedState={false}
                text="Marktvotum">
                <MarktVoteSection
                    applicationInformation={props.applicationInformation}
                    userData={props.userData}
                    onApplicationUpdated={props.onApplicationUpdated}
                    selectedFromOfferCalculation={data.selectedCalculation}
                />
            </CollapsibleSection>
            {props.applicationInformation.marketVotes.map((c, i) =>
                <ListItem
                    key={i}
                    type={ListItemTypes.Marktvotum}
                    selectedDark={false}
                    text={moment(c.createdAt).format(DATE_FORMAT.DE) + ' - Marktvotum'}
                    onNextToLastIconClicked={(e) => {e.stopPropagation(); onViewMarktvotumDocClicked()}}
                    onLastIconClicked={(e) => {e.stopPropagation(); onDeleteMarktvotum()}}
                />
            )}
            <br />
            <Button data-cy="button_save"
                className={styles.button}
                type="primary"
                htmlType="submit"
                onClick={async () => {
                    try {
                        await ApiManager.ApplicationService.submitApplication(props.applicationInformation.applicationId)
                        props.onApplicationUpdated()
                        message.success('Anträg Eingereicht', 2)
                    } catch (e) {
                        console.error(e)
                        message.error('Konnte nicht Speichern')
                    }
                }}>
                <CheckOutlined /> Anträg abschließen
					</Button>
        </React.Fragment>
    );
};

const MainObjekt = (mainObject: ObjectData, enabledObjectTypes: ObjectTypeSetting[]) => {
    if (!mainObject) {
        return null;
    }

    const objectType = enabledObjectTypes.find(o => o.objectTypeId == mainObject.objectId);

    return (
        <div>
            <h4>Objekt 1</h4>
            <br />
            <Row gutter={[0, 24]} >
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Objektbezeichnung
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {mainObject.objectName}
                    </div>
                </Col>
                <Col className={styles.objectCellRight} span={1}>
                </Col>
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Lieferant
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {mainObject.lieferantId}
                    </div>
                </Col>
            </Row>
            <Row gutter={[0, 24]} >
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Objekttyp
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {objectType ? objectType.description : ''}
                    </div>
                </Col>
                <Col className={styles.objectCellRight} span={1}>
                </Col>
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Lieferdatum
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {mainObject.deliveryDate}
                    </div>
                </Col>
            </Row>
            <Row gutter={[0, 24]} >
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Nettokaufpreis
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {mainObject.price}
                    </div>
                </Col>
                <Col className={styles.objectCellRight} span={1}>
                </Col>
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Ausstattung
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {mainObject.features}
                    </div>
                </Col>
            </Row>
            <Row gutter={[0, 24]} >
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Seriennummer
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {mainObject.serialNumber}
                    </div>
                </Col>
                <Col className={styles.objectCellRight} span={1}>
                </Col>
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Halter
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {mainObject.owner}
                    </div>
                </Col>
            </Row>
            <Row gutter={[0, 24]} >
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Hersteller
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {mainObject.manufacturer}
                    </div>
                </Col>
                <Col className={styles.objectCellRight} span={1}>
                </Col>
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Erstzulassung
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {mainObject.firstRegistration}
                    </div>
                </Col>
            </Row>
            <Row gutter={[0, 24]} >
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Auszahlungsbetrag
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {mainObject.payoutAmount}
                    </div>
                </Col>
                <Col className={styles.objectCellRight} span={1}>
                </Col>
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Fahrzeugtyp
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {mainObject.machineType}
                    </div>
                </Col>
            </Row>
        </div>
    );
}


const SmallObjekt = (smallObject: ObjectData, index: number) => {
    if (!smallObject) {
        return null;
    }
    return (
        <div>
            <h4>Objekt {index}</h4>
            <br />
            <Row gutter={[0, 24]} >
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Objektbezeichnung
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {smallObject.objectName}
                    </div>
                </Col>
            </Row>
            <Row gutter={[0, 24]} >
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Objekttyp
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {smallObject.objectTypeId}
                    </div>
                </Col>
            </Row>
            <Row gutter={[0, 24]} >
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Nettokaufpreis
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {smallObject.price}
                    </div>
                </Col>
            </Row>
            <Row gutter={[0, 24]} >
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Seriennummer
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {smallObject.serialNumber}
                    </div>
                </Col>
            </Row>
            <Row gutter={[0, 24]} >
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Hersteller
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {smallObject.manufacturer}
                    </div>
                </Col>
            </Row>
            <Row gutter={[0, 24]} >
                <Col className={styles.objectCell} span={5}>
                    <div className={styles.documentFont}>
                        Auszahlungsbetrag
			</div>
                </Col>
                <Col className={styles.objectCell} span={6}>
                    <div className={styles.documentFont + ' ' + styles.objectText}>
                        {smallObject.payoutAmount}
                    </div>
                </Col>
            </Row>
        </div>
    );
}
