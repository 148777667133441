/**
 * RoundToDecimalPoints rounds the number n to the given decimal points.
 * @usage RoundToDecimalPoints(123.4501, 2) -> 123.45
 * @param n The number to round.
 * @param decimalPoints The desired decimal points to round to. Must be integer, or else n is returned.
 */
export const roundToDecimalPoints = (n: number, decimalPoints: number = 2): number => {
	if (!Number.isInteger(decimalPoints)) {
		return n
	}

	const p = Math.pow(10, decimalPoints)
	return Math.round(n * p + Number.EPSILON) / p
}

/**
 * nanOrInfinityToZero returns 0 if the given number is NaN or Infinity, returns n otherwise.
 * @usage nanOrInfinityToZero( 1/0 ) -> 0
 * @param n The number to check.
 */
export const nanOrInfinityToZero = (n: number): number => {
	if (isNaN(n) || n == Infinity) {
		return 0
	}

	return n
}
