import * as React from "react";
import styles from "components/common/presenters/list-objects/ListObjects.module.scss";
import { List, Card } from "antd";
import { CloudDownloadOutlined, FileOutlined, CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import classnames from "classnames";


export interface ListObjectsProps {
	objects: {objectId: number; objectName: string; selected: boolean, insuranceQuote: boolean }[];
	onClick?: (e) => void;
}

/**
 * Used for lists with calculations, offers, documents.
 * @param props 
 */
export const ListObjects = (props: ListObjectsProps) => {


	return (
		<List
		grid={{ gutter: 16, column: 3 }}
		dataSource={props.objects}
		renderItem={o => (
			<List.Item onClick={props.onClick ? () => props.onClick(o.selected ? {} : o) : () => {}}>
				<div className={classnames(styles.item, {[styles.selected]: o.selected})}>
					<div className={styles.icon}><FileOutlined /></div>
					<div className={styles.textElement}>{o.objectName}</div>
					<div className={
						classnames(styles.insuranceQuoteText)
					}>{o.insuranceQuote ? <span className={styles.green}>Wird erstellt</span> : <span className={styles.grey}>Wird nicht erstellt</span>}</div>
				</div>
			</List.Item>
		)}
	/>
	);
};
