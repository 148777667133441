
export function isFieldInvalid(data, fieldName) {
    if (!data[fieldName]) {
        return {
            [`${fieldName}Error`]: true
        }
    }

    return {
        [`${fieldName}Error`]: false
    }
}

export function areFieldsInvalid(data, fields) {
    var validationResult = {};
    fields.forEach(field => {
        validationResult = {...validationResult, ...isFieldInvalid(data, field)}
    });

    return validationResult;
}

export function isTaxNumberInvalid(v) {
    return !/[0-9]{2}\/[0-9]{3}\/[0-9]{5}/.test(v);
}

export function taxNumberAutoFormat(v) {
    switch (v.length) {
        case 3:
        case 7:
            const newString = v.split('')
            newString[v.length - 1] = '/'
            return newString.join('');
        default:
            return v;
    }
}

export function isEmailInvalid(email) 
{
 if ( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
  {
    return false;
  }
    return true;
}
