import * as React from "react";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import styles from "./SettingsPage.module.scss";
import {SideMenu} from "../../presenters/side-menu/SideMenu";
import {useTranslation} from "react-i18next";
import {ChangePasswordSection} from "./ChangePasswordSection";
import ApiManager from "../../../../services/api/ApiManager";
import {ManageEmployeesSection} from "./ManageEmployeesSection";
import {ManageListsSection} from "./manage-lists-section/ManageListsSection";
import {Store, useStore} from "../../../../hooks/Store";
import {BoUserProfile, BoUserRole, DealershipConditionGroup} from "../../../../services/api/types";
import {ManageDealersSection} from "./ManageDealersSection";

export const SettingsPage = () => {
	const {t} = useTranslation()
	const history = useHistory()
	const store: Store = useStore()

	const isCurrentUserAdmin = store.data?.profile?.role === BoUserRole.ADMIN.toString()

	const [boUserList, setBOUserList] = useState([] as BoUserProfile[])
	const [dealerConditionGroups, setDealerConditionGroups] = useState([] as DealershipConditionGroup[])

	useEffect(() => {
		fetchBOUserList()
		fetchDealerGroups()
	}, [history[history.lenght - 1]])

	const fetchBOUserList = async () => {
		try {
			const result = await ApiManager.UserService.getBOUsers()
			setBOUserList(result)
		} catch (e) {
			console.error(e)
			setBOUserList([])
		}
	}

	const fetchSettings = async () => {
		try {
			const result = await ApiManager.UserService.getSettings()

			store.setData({
				settings: {
					...result.data
				}
			})
		} catch (e) {
			console.error(e)
		}

	}

	const fetchDealerGroups = async () => {
		try {
			const result = await ApiManager.UserService.getDealershipConditionGroups()
			setDealerConditionGroups(result.data)
		} catch (e) {
			console.error(e)
			setDealerConditionGroups([])
		}
	}

	return (
		<div className={styles.page}>
			<SideMenu/>

			<div className={styles.sectionRight}>
				<div className={styles.rightContainer}>
					<h1>{t('settings')}</h1>
					<p>{`${t('data:email_address')}: ${store.data?.profile?.email || ""}`}</p>

					<ChangePasswordSection showForgotPasswordHint={!isCurrentUserAdmin}/>

					{
						isCurrentUserAdmin ?
							<>
								<br/>
								<br/>
								<ManageListsSection settings={store.data?.settings}
													onDataChange={() => {
														fetchSettings()
													}}
								/>
								<br/>
								<br/>

								<ManageEmployeesSection existingBOUsers={boUserList}
														onDataChange={() => {
															fetchBOUserList()
														}}
								/>
								<br/>
								<br/>

								<ManageDealersSection existingDealershipConditionGroups={dealerConditionGroups}
													  onDataChange={() => {
														  fetchDealerGroups()
													  }}
								/>
							</> : null
					}
				</div>
			</div>
		</div>
	);
}
