import CollapsibleSection from "components/common/presenters/collapsible-section/CollapsibleSection";
import React, {useEffect, useState} from "react";
import styles from "components/common/presenters/application-page-tabs/contracts-tab/ConfirmationOfObjectAcceptance.module.scss";
import {Button, DatePicker, Form, Input, message, Radio} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import {ListObjectsAcceptance} from "components/common/presenters/list-objects-acceptance/ListObjectsAcceptance";
import {groupBy} from "helpers/arrayAndObjectsHelpers";
import {stringify} from "querystring";
import moment from "moment";
import {DATE_FORMAT} from "services/api/Globals";
import ApiManager from "services/api/ApiManager";

export const ConfirmationOfObjectAcceptance = (props) => {
    const [selectedGroup, setSelectedGroup] = useState({} as any);

    // const [objectsGroupedByLiferantId, setObjectsGroupedByLiferantId] = useState([] as any);

    // useEffect(() => {
    // 	setObjectsGroupedByLiferantId(groupBy(props.selectedContractTemplate.objects, 'lieferantId'));
    // }, [props.selectedContractTemplate.objects]);
    const objectsGroupedByLiferantId = groupBy(props.selectedContractTemplate.objects, 'lieferantId');

    return (
        <CollapsibleSection text="Abnahmebestätigung" initialCollapsedState={true}>
            <div className={styles.beneficiariesList}>
                <ListObjectsAcceptance
                    objectsInGroups={Object.values(objectsGroupedByLiferantId).map((group, i) => {return {group: group, groupIndex: i, groupName: `Abnahme ${i + 1}`, selected: i === selectedGroup.groupIndex}}) || []}
                    onClick={(g) => {
                        setSelectedGroup(g)
                    }}
                />
            </div>
            <br />
            <AcceptanceTable
                selectedGroupIndex={selectedGroup.groupIndex || 0}
                objectsInGroups={selectedGroup.group || []}
                selectedContractTemplate={props.selectedContractTemplate}
                onDataUpdated={props.onDataUpdated}
            />
        </CollapsibleSection>
    );
}


const AcceptanceTable = (props) => {
    const initialState = {
        yearBuilt: '',
        mileage: '',
        operatingHours: '',
        acceptanceDate: '',
        objectCondition: '',
        objectsInGroups: null
    };

    const [data, setData] = useState({
        ...initialState
    });

    useEffect(() => {
        const confirmationOfAcceptances = props.selectedContractTemplate?.confirmationOfAcceptances || [];
        setData({
            yearBuilt: (confirmationOfAcceptances[props.selectedGroupIndex] || {}).yearBuilt || '',
            mileage: (confirmationOfAcceptances[props.selectedGroupIndex] || {}).mileage || '',
            operatingHours: (confirmationOfAcceptances[props.selectedGroupIndex] || {}).operatingHours || '',
            acceptanceDate: (confirmationOfAcceptances[props.selectedGroupIndex] || {}).acceptanceDate || '',
            objectCondition: (confirmationOfAcceptances[props.selectedGroupIndex] || {}).objectCondition || '',
            objectsInGroups: props.objectsInGroups.map(o => o.objectId)
        });
    }, [props.selectedGroupIndex, props.objectsInGroups, props.selectedContractTemplate?.confirmationOfAcceptances]);

    async function onSaveObject() {
        try {
            const confirmationOfAcceptances = props.selectedContractTemplate?.confirmationOfAcceptances || [];
            const confirmationOfAcceptancesObject = {...confirmationOfAcceptances} as any;
            if (!confirmationOfAcceptancesObject[props.selectedGroupIndex]) {
                confirmationOfAcceptancesObject[props.selectedGroupIndex] = {};
            }
            confirmationOfAcceptancesObject[props.selectedGroupIndex].yearBuilt = data.yearBuilt;
            confirmationOfAcceptancesObject[props.selectedGroupIndex].mileage = data.mileage;
            confirmationOfAcceptancesObject[props.selectedGroupIndex].operatingHours = data.operatingHours;
            confirmationOfAcceptancesObject[props.selectedGroupIndex].acceptanceDate = data.acceptanceDate;
            confirmationOfAcceptancesObject[props.selectedGroupIndex].objectCondition = data.objectCondition;
            confirmationOfAcceptancesObject[props.selectedGroupIndex].objectsInGroups = data.objectsInGroups;
            const request = {
                confirmationOfAcceptances: JSON.stringify([...Object.values(confirmationOfAcceptancesObject)])
            }
            await ApiManager.ApplicationService.patchContractDraft(request, props.selectedContractTemplate.contractDraftId);

            message.success('Daten Gespeichert', 2);
            props.onDataUpdated();
        } catch (error) {
            console.error(error);
            console.error(error);
            const errorText = error?.response?.data?.errors?.join(' ');
            message.error('Konnte nicht Speichern' + ' ' + errorText, 2);
        }
    }


    if (!props.objectsInGroups.length) {
        return null;
    }
    return (
        <React.Fragment>
            <div className={styles.insuranceOfferTable}>
                <table>
                    <tr>
                        <td>Lieferant:</td>
                        <td className={styles.notEditable}>{`${props.objectsInGroups[0]?.lieferantFirstName || ''} ${props.objectsInGroups[0]?.lieferantLastName || ''}`}</td>
                    </tr>
                    <tr>
                        <td>Anschrift:</td>
                        <td className={styles.notEditable}>{`${props.objectsInGroups[0]?.lieferantAddressLine1 || ''} ${props.objectsInGroups[0]?.lieferantAddressCity || ''}`}</td>
                    </tr>
                    <tr>
                        <td>Objektbezechnung</td>
                        <td className={styles.notEditable}>{props.objectsInGroups.map(o => o.objectName).join(' + ')}</td>
                    </tr>
                    <tr>
                        <td>Maschinen- / Fahrgestellnummer</td>
                        <td className={styles.notEditable}>{props.objectsInGroups.map(o => o.serialNumber).join(' + ')}</td>
                    </tr>
                    <tr>
                        <td>Baujahr</td>
                        <td>
                            <Form.Item
                                validateStatus={''}
                                className={styles.formIem}
                            >
                                <Input data-cy="input_year_built"
                                    value={data.yearBuilt?.substr(0, 4)}
                                    placeholder="YYYY"
                                    onChange={(e) => {
                                        setData({...data, yearBuilt: e.target.value})
                                    }}>
                                </Input>
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td>Kilometerstand (Gebrauchtfahrzeug):</td>
                        <td>
                            <Form.Item
                                validateStatus={''}
                                className={styles.formIem}
                            >
                                <Input data-cy="input_mileage"
                                    value={data.mileage}
                                    onChange={(e) => {
                                        setData({...data, mileage: e.target.value})
                                    }}>
                                </Input>
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td>Betriebsstunden (Gebrauchtmachine):</td>
                        <td>
                            <Form.Item
                                validateStatus={''}
                                className={styles.formIem}
                            >
                                <Input data-cy="input_operating_hours"
                                    value={data.operatingHours}
                                    onChange={(e) => {
                                        setData({...data, operatingHours: e.target.value})
                                    }}>
                                </Input>
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td>Abnahmedatum</td>
                        <td>
                            <DatePicker data-cy="picker_acceptance_date"
                                format={DATE_FORMAT.DE}
                                value={data.acceptanceDate ? moment(data.acceptanceDate, DATE_FORMAT.API) : null}
                                showToday={false}
                                allowClear={false}
                                onChange={(e) => {
                                    setData({...data, acceptanceDate: e?.format(DATE_FORMAT.API)})
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Objektzustand
						</td>
                        <td>
                            <Radio.Group onChange={(e) => {
                                setData({...data, objectCondition: e.target.value})
                            }} value={data.objectCondition} >
                                <Radio value='new'>Fabrikneu</Radio>
                                <Radio value='secondHand'>Gebraucht</Radio>
                            </Radio.Group>
                        </td>
                    </tr>
                </table>
            </div>
            <br />
            <div className={styles.buttonsOnRight}>
                <Button data-cy="button_save"
                    type="primary"
                    size="small"
                    onClick={onSaveObject}>
                    <CheckOutlined /> Speichern
				</Button>
            </div>
        </React.Fragment>
    );
}
